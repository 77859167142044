import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { CATEGORY_ACTIONS } from 'app/entities/categories/category/entity.category.actions';

import { ICategory } from 'app/models/model.category';
import { ELoadStatus } from 'app/models/shared.model';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  items: [] as ICategory[],
  item: {} as ICategory,
  status: {
    items: ELoadStatus.not_ready,
    item: ELoadStatus.not_ready
  },
  error: {
    items: null as Error,
    item: null as Error
  }
};

export type ICategoryState = Readonly<typeof initialState>;

export default (state = initialState, action): ICategoryState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(CATEGORY_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.loading),
        set('errors.items', initialState.error.items)
      ])(state);
    case SUCCESS(CATEGORY_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.ready),
        set('items', payload.data.categories)
      ])(state);
    case FAILURE(CATEGORY_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.error),
        set('error.items', new Error('menu list fetch failed'))
      ])(state);
    case REQUEST(CATEGORY_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.loading),
        set('error.item', initialState.error.item)
      ])(state);
    case SUCCESS(CATEGORY_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.ready),
        set('item', payload.data.category)
      ])(state);
    case FAILURE(CATEGORY_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.error),
        set('error.item', new Error('category item fetch failed'))
      ])(state);
    case CATEGORY_ACTIONS.RESET_ITEM:
      return flow([
        set('status.item', ELoadStatus.not_ready)
      ])(state);
    default:
      return state;
  }
};
