import axios from 'axios';

import { BASENAME } from 'app/configs/const';

import { IUserState } from 'app/entities/user/user.reducer';

export const AUTH_ACTIONS = {
  RESET_PROP: 'AUTH::RESET:PROP',
  CHECK: 'AUTH::CHECK'
};

export const resetProp = (prop: keyof IUserState) => ({
  type: AUTH_ACTIONS.RESET_PROP,
  payload: prop
});

export const checkUser = () => ({
  type: AUTH_ACTIONS.CHECK,
  payload: axios.get(`${BASENAME}/api/user`)
});
