import { ModalTypes } from 'app/entities/modals/modals.reducer';

export function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, callback);
  },
  off(event, callback) {
    document.removeEventListener(event, callback);
  },
  dispatch(event, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  }
};

export const OEventName = {
  AudioBreak: 'audio.break',

  ModalOpenSubscribe: `modal.open.${ModalTypes.subscribe}`,
  ModalCloseSubscribe: `modal.close.${ModalTypes.subscribe}`,
  ModalCancelSubscribe: `modal.cancel.${ModalTypes.subscribe}`,
  ModalSuccessSubscribe: `modal.success.${ModalTypes.subscribe}`,

  ModalOpenUnsubscribe: `modal.open.${ModalTypes.unsubscribe}`,
  ModalCloseUnsubscribe: `modal.close.${ModalTypes.unsubscribe}`,
  ModalCancelUnsubscribe: `modal.cancel.${ModalTypes.unsubscribe}`,
  ModalSuccessUnsubscribe: `modal.success.${ModalTypes.unsubscribe}`,

  ModalOpenNoMoney: `modal.open.${ModalTypes.no_money}`,
  ModalCloseNoMoney: `modal.close.${ModalTypes.no_money}`,
  ModalCancelNoMoney: `modal.cancel.${ModalTypes.no_money}`
} as const;
