import axios from 'axios';
import { BASENAME } from 'app/configs/const';

import { IContent } from 'app/models/model.content';

export const CONTENT_ITEM_HTML5_ACTIONS = {
  FETCH_ITEM: 'CONTENT:ITEM:HTML5::FETCH',
  RESET_ITEM: 'CONTENT:ITEM:HTML5::RESET'
};

export type IFetchContentItemHTML5Props = IContent['id'];
export const fetchContentItemHtml5 = (id: IFetchContentItemHTML5Props) => ({
  type: CONTENT_ITEM_HTML5_ACTIONS.FETCH_ITEM,
  payload: axios.get(`${BASENAME}/api/html5/getUrl?contentId=${id}`)
});

export const resetContentItemHtml5 = () => ({
  type: CONTENT_ITEM_HTML5_ACTIONS.RESET_ITEM
});
