import React, { memo, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { ModalHeader } from 'a1s-tele2-react-ui/src/components/mobile/modalHeader/modal.header.index';

import flow from 'lodash/flow';

import { ContainerGame, IContainerGameProps } from 'app/containers/container.game';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { MAX_GAME_ROUND } from 'app/configs/const';

import { Typography } from 'app/components/desktop/typography/typography.index';
import Promo from 'app/components/promo/promo.index';
import subscribeCheck from 'app/utils/util.subscribe.check';

import { useContentItemStyles } from 'app/pages/mobile/content/item/page.content.item.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';

interface IGameResult {
  containerSubscribe: IContainerSubscribeProps;
  containerUser: IAuthContainerProps;
  modalsContainer: IModalsContainer;
  containerGame: IContainerGameProps;
}

const GameResult = memo<IGameResult>(props => {
  const { containerGame, containerUser, modalsContainer, containerSubscribe } = props;
  const { totalCount, correctCount, questions, actions: { resetGame } } = containerGame;

  const styles = useContentItemStyles();

  const history = useHistory();
  const location = useLocation<any>();
  const { t } = useTranslation();

  const currentTotalCount = totalCount ? totalCount : parseInt(localStorage.getItem('gameTotal'), MAX_GAME_ROUND);
  const currentCorrectCount = correctCount ? correctCount : parseInt(localStorage.getItem('gameResult'), MAX_GAME_ROUND);

  useEffect(() => {
    localStorage.setItem('gameTotal', `${totalCount || MAX_GAME_ROUND}`);
    localStorage.setItem('gameResult', `${correctCount || 0}`);

    return () => {
      if (localStorage.getItem('gameTotal')) {
        localStorage.removeItem('gameTotal');
      }
      if (localStorage.getItem('gameResult')) {
        localStorage.removeItem('gameResult');
      }
    };
  } , [totalCount, correctCount]);

  useEffect(() => {
    return () => {
      resetGame();
      if (localStorage.getItem('activePromoSubscribe')) {
        localStorage.removeItem('activePromoSubscribe');
      }
      if (localStorage.getItem('gameName')) {
        localStorage.removeItem('gameName');
      }
      if (localStorage.getItem('subscribe')) {
        localStorage.removeItem('subscribe');
      }
      if (localStorage.getItem('questions')) {
        localStorage.removeItem('questions');
      }
    };
  }, []);

  const goBack = () => {
    history.length > 2 ? history.goBack() : history.push('/');
  };

  useEffect(() => {
    if (history.location.search.includes('?login=success') && localStorage.getItem('subscribeId')) {
      subscribeCheck({
        subscribe: JSON.parse('subscribe'),
        history,
        callbackClose: () => history.push('/'),
        callbackCancel: () => localStorage.removeItem('openSubscribeModal'),
        containerUser,
        containerModals: modalsContainer
      });
      // modalsContainer.actions.subscribeModal(parseInt(localStorage.getItem('subscribeId'), 10), {
      //   handleClose: () => history.push('/'),
      //   handleCancel: () => localStorage.removeItem('openSubscribeModal')
      // })
    }
  }, []);

  const onSubscribe = (subscribe, index) => {
    if (!containerUser.authenticated) {
      localStorage.setItem('gameResult', correctCount.toString());
      localStorage.setItem('gameTotal', totalCount.toString());
      localStorage.setItem('activePromoSubscribeIndex', index.toString());
      localStorage.setItem('activePromoSubscribe', index.toString());
      localStorage.setItem('gameName', location?.state?.contentItem?.name);
      localStorage.setItem('subscribe', JSON.stringify(subscribe));
      localStorage.setItem('questions', JSON.stringify(questions));
    }
    subscribeCheck({
      subscribe,
      history,
      containerUser,
      containerModals: modalsContainer
    });
  };

  const renderQuestionExplanation = () => {
    const currentQuestionsStorage = JSON.parse(localStorage.getItem('questions')) || [];
    const currentQuestions = !!questions.length ? questions : currentQuestionsStorage;

    return (
      currentQuestions &&
      currentQuestions.map((question, index) => (
        <Box mb={ 1 } key={ index }>
          <Typography type={ 'text' } tag={ 'p' }>
            { question?.explanation }
          </Typography>
        </Box>
      ))
    );
  };

  const subActiveIndex = useMemo(() => {
    if (containerUser.authenticated) {
      const currentSubscribe = localStorage.getItem('subscribeId') && containerSubscribe.subscriptions.find(sub => sub.id === parseInt(localStorage.getItem('subscribeId'), 10));
      if (currentSubscribe && currentSubscribe.status === ESubscriptionStatus.ACTIVE) {
        return null;
      } else return parseInt(localStorage.getItem('activePromoSubscribe'), 10);
    } else return parseInt(localStorage.getItem('activePromoSubscribe'), 10);
  }, [containerUser.authenticated, containerSubscribe.subscriptions]);

  return (
    <div className={ styles.pageContent }>
      <ModalHeader isClose closeFn={ goBack } text={ location?.state?.contentItem?.name || localStorage.getItem('gameName') } className={ styles.modalHeader }/>

      <Typography className={ styles.headline } type="heading" level={ 2 } mobile>
        { t('game.gameResult') }
      </Typography>

      <Typography type="heading" level={ 5 } className={ styles.headline }>
        <Trans i18nKey="game.yourResult"
          values={ {
            correctCount: currentCorrectCount,
            totalCount: currentTotalCount
          } }
        />
      </Typography>

      { renderQuestionExplanation() }
      <Promo className={ styles.promo } onSubscribe={ onSubscribe } activeIndex={ subActiveIndex }/>
    </div>
  );
});

export default flow([ContainerUser, ContainerGame, ModalsContainer, ContainerSubscribe])(GameResult);
