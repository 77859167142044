//#region imports
import React, { FC, memo, forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { useIsomorphicLayoutEffect, useGetSetState } from 'react-use';
import { useForkRef, Slider } from '@material-ui/core';
import { IoMdPlay, IoMdPause, IoMdVolumeHigh, IoMdVolumeMute } from 'react-icons/io';
import { ImPlay3, ImPause2, ImVolumeHigh, ImVolumeMute2 } from 'react-icons/im';
import { isMobile, isIOS, isSafari } from 'mobile-device-detect';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import isElement from 'lodash/isElement';
import noop from 'lodash/noop';
import map from 'lodash/map';

import { eventBus, isModifiedEvent, OEventName } from 'app/utils/util.event';
import { AudioProps, EAudioStatus, EAudioPreload } from './audio.models';
import { useAudioStyles } from './audio.styles';
//#endregion

export function isNumeric(n: any): boolean {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getProgress = (currentTime: number, duration: number) => {
  if (isNumeric(currentTime) && isNumeric(duration)) {
    return parseFloat((100 * (currentTime / duration)).toString());
  }
  return 0;
};

export const getCurrentTime = (progress: number, duration: number) => {
  if (isNumeric(progress) && isNumeric(duration)) {
    return parseFloat(((progress * duration) / 100).toString());
  }
  return 0;
};

export const getRemaningTime = (progress: number, duration: number) => {
  if (isNumeric(progress) && isNumeric(duration)) {
    return parseFloat((((100 - progress) * duration) / 100).toString());
  }
  return 0;
};

export const appendZero = num => (num < 10 ? `0${num}` : num);

export const getFormattedTime = (time, remaning = false) => {
  const dateTime = new Date(0, 0, 0, 0, 0, time, 0);

  const dateTimeH = dateTime.getHours();
  const dateTimeM = dateTime.getMinutes();
  const dateTimeS = appendZero(dateTime.getSeconds());
  const minus = remaning ? '-' : '';

  return dateTimeH > 0 ? `${minus}${dateTimeH}:${dateTimeM}:${dateTimeS}` : `${minus}${dateTimeM}:${dateTimeS}`;
};

const Audio: FC<AudioProps> = memo(
  forwardRef<any, AudioProps>((props, ref) => {
    const { className, classes, style, src, autoplay, preload, onPlayed, onPaused, onFinished } = props;
    const styles = useAudioStyles({ classes });

    const rootRef = useRef<HTMLDivElement | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const audioKey: string = Array.isArray(src) ? src[0] : src;
    const [get, set] = useGetSetState({
      status: EAudioStatus.pause,
      current: 0,
      remaning: 0,
      duration: 0,
      progress: 0,
      volume: 0,
      muted: false,
      loop: false,
      autoplay: false
    });

    const onLoad = () => {
      const audioElement = audioRef.current;
      if (audioElement?.duration === Infinity) {
        audioElement.currentTime = 24 * 60 * 60;
        audioElement.currentTime = 0;
      }
      set({ duration: audioElement.duration });
      if (audioElement?.currentTime === 0) {
        if (audioElement?.autoplay || audioElement?.loop) {
          // @ts-ignore: no-empty
        } else {
          set({ status: EAudioStatus.pause });
        }
      }
    };

    const handleAudioTimeUpdate = () => {
      const audioElement = audioRef.current;
      const duration = audioElement?.duration;
      const current = audioElement?.currentTime;
      const progress = getProgress(current, duration);
      const remaning = getRemaningTime(progress, duration);
      set({ current, remaning, progress });
    };

    const handleAudioEnd = event => {
      set({ status: EAudioStatus.stop });
      if (isFunction(onFinished)) onFinished(event);
    };

    const handleAudioProgressChange = (event, progress) => {
      if (
        ((event.type === 'mousedown' && event.button === 0) ||
          (event.type === 'mousemove' && (event?.which === 1 || event?.nativeEvent?.which === 1))) && // ignore everything but left clicks
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        const audioElement = audioRef.current;
        const duration = audioElement?.duration;
        const current = getCurrentTime(progress, duration);
        const remaning = getRemaningTime(progress, duration);
        if (isElement(audioElement) && current) {
          audioElement.currentTime = current;
        }
        set({ current, remaning, progress });
        return;
      }
      event.preventDefault();
    };

    const handleAudioControlPlay = () => {
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        audioElement.play();
      }
      set({ status: EAudioStatus.play });
    };

    const handleAudioControlReplay = () => {
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        audioElement.play();
      }
      set({ status: EAudioStatus.play, current: 0, progress: 0 });
    };

    const handleAudioControlPause = () => {
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        audioElement.pause();
      }
      set({ status: EAudioStatus.pause });
    };

    const handleAudioVolumeMute = () => {
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        audioElement.muted = true;
      }
      set({ muted: true });
    };

    const handleAudioVolumeUnmute = () => {
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        audioElement.muted = false;
      }
      set({ muted: false });
    };

    const handleBreak = e => {
      const sourceElement = e?.detail?.source ?? null;
      const audioElement = audioRef.current;
      if (isElement(audioElement) && isElement(sourceElement)) {
        if (audioElement !== sourceElement && get().status === EAudioStatus.play) {
          handleAudioControlPause();
        }
      }
    };

    const handleLoop = () => {
      const loop = !get().loop;
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        audioElement.loop = loop;
      }
      set({ loop });
    };

    useIsomorphicLayoutEffect(() => {
      const audioElement = audioRef.current;
      if (isElement(audioElement)) {
        if (audioElement.readyState > 3) {
          onLoad();
        }
        if (!audioElement.autoplay && autoplay) {
          set({ status: EAudioStatus.play, autoplay: true });
        }
        if (isIOS || isSafari) {
          audioElement.load();
        }
        audioElement.addEventListener('canplay', onLoad);
        audioElement.addEventListener('timeupdate', handleAudioTimeUpdate);
        audioElement.addEventListener('ended', handleAudioEnd);
        audioElement.addEventListener('pause', onPaused);
        audioElement.addEventListener('play', onPlayed);
        eventBus.on(OEventName.AudioBreak, handleBreak);
      }
      return () => {
        if (isElement(audioElement)) {
          audioElement.removeEventListener('canplay', onLoad);
          audioElement.removeEventListener('timeupdate', handleAudioTimeUpdate);
          audioElement.removeEventListener('ended', handleAudioEnd);
          audioElement.removeEventListener('pause', onPaused);
          audioElement.removeEventListener('play', onPlayed);
          eventBus.off(OEventName.AudioBreak, handleBreak);
        }
      };
    }, [audioRef.current, src]);

    useImperativeHandle(ref, () => ({
      node: audioRef.current,
      play: handleAudioControlPlay,
      pause: handleAudioControlPause
    }));

    return (
      <div ref={ rootRef } className={ cx(className, styles.root) } style={ style }>
        <audio key={ audioKey } data-key={ audioKey } ref={ audioRef } preload={ preload } hidden>
          { Array.isArray(src) ? map(src, (srcLink, index) => <source key={ index } src={ srcLink } />) : <source src={ src } /> }
        </audio>

        <div className={ styles.panel }>
          <div className={ styles.controlBlock }>
            { get().status === EAudioStatus.pause && <ImPlay3 onClick={ handleAudioControlPlay } /> }
            { get().status === EAudioStatus.stop && <ImPlay3 onClick={ handleAudioControlReplay } /> }
            { get().status === EAudioStatus.play && <ImPause2 onClick={ handleAudioControlPause } /> }
          </div>

          <div className={ styles.controlBlock }>
            <span>{ getFormattedTime(get().current) }</span>
            <span> / </span>
            <span>{ getFormattedTime(get().duration) }</span>
          </div>

          <div className={ styles.sliderBlock }>
            <Slider
              classes={ {
                root: styles.slider,
                rail: styles.sliderRail,
                track: styles.sliderTrack,
                thumb: styles.sliderThumb,
                active: styles.sliderActive,
                focusVisible: styles.focusVisible
              } }
              onChange={ handleAudioProgressChange }
              value={ get().progress }
            />
          </div>

          <div className={ styles.controlBlock }>
            { get().muted ? <ImVolumeMute2 onClick={ handleAudioVolumeUnmute } /> : <ImVolumeHigh onClick={ handleAudioVolumeMute } /> }
          </div>
        </div>
      </div>
    );
  })
);
Audio.displayName = 'Audio';

Audio.defaultProps = { autoplay: false, preload: EAudioPreload.auto } as AudioProps;

export default Audio;
