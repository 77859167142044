import { DEVELOPMENT } from 'app/configs/const';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ru, en } from 'app/configs/locales';

i18n
  .use(initReactI18next)
  .init({
    lng: 'ru',
    fallbackLng: 'en',
    resources: {
      ru: {
        translations: { ...ru }
      },
      en: {
        translations: { ...en }
      }
    },
    debug: DEVELOPMENT,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
  });

export default i18n;
