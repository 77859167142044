const EN = {
  messages: {
    confirm_del: 'Confirm delete'
  },
  operator: {
    all: 'All operators'
  },
  errors: {
    loginContainSymbols: 'Login must contain only latin letters and `.` symbol',
    email: 'Must be an email',
    size: 'Size must be {{size}} symbols',
    minOperator: 'Should be at least one operator',
    maxOperator: 'Should be no more than 2147482647 operators',
    max32: 'Size must be not greater than 32 symbols',
    min3: 'Size must be not less than 3 symbols',
    required: 'Required field'
  },
  common: {
    titleApp: 'A1S Fitness',
    'URI': 'URI',
    'abonent': 'abonent',
    'action': 'action',
    'addParam': 'addParam',
    'additional': 'additional',
    'apply': 'apply',
    'back': 'back',
    'basic': 'basic',
    'contentType': 'contentType',
    'country': 'country',
    'create': 'create',
    'dateFrom': 'dateFrom',
    'dateTo': 'dateTo',
    'dcs': 'dcs',
    'delete': 'delete',
    'description': 'description',
    'direction': 'direction',
    'download': 'download',
    'edit': 'edit',
    'embeddedService': 'embeddedService',
    'encoding': 'encoding',
    'esmClass': 'esmClass',
    'file': 'file',
    'find': 'find',
    'found': 'found',
    'format': 'format',
    'formatType': 'formatType',
    'group': 'group',
    'groupByDate': 'groupByDate',
    'groupByFields': 'groupByFields',
    'host': 'host',
    'id': 'id',
    'language': 'language',
    'link': 'link',
    'linkIds': 'linkIds',
    'linkType': 'linkType',
    'message': 'message',
    'move': 'move',
    'msisdnList': 'msisdnList',
    'msisdn': 'msisdn',
    'name': 'name',
    'notFound': 'notFound',
    'num': 'num',
    'operator': 'operator',
    'operators': 'operators',
    'params': 'params',
    'pattern': 'pattern',
    'permissions': 'permissions',
    'plugin': 'plugin',
    'plugout': 'plugout',
    'priority': 'priority',
    'process': 'process',
    'protocolId': 'protocolId',
    'recipient': 'recipient',
    'runOnHost': 'runOnHost',
    'save': 'save',
    'sdpMlMessage': 'sdpMlMessage',
    'service': 'service',
    'serviceId': 'serviceId',
    'serviceIds': 'serviceIds',
    'serviceURIType': 'serviceURIType',
    'show': 'show',
    'state': 'state',
    'string': 'string',
    'teaserName': 'teaserName',
    'test': 'test',
    'text': 'text',
    'title': 'title',
    'type': 'type',
    'upload': 'upload',
    'uploadType': 'uploadType',
    'url': 'url',
    'urlMsisdn': 'urlMsisdn',
    'yourUri': 'yourUri'
  }
};

export default EN;
