import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

const stylesCircularLoader = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  node: {
    margin: theme.spacing(10)
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export interface ICircularLoader extends CircularProgressProps {
  center?: boolean;
  node?: boolean;
}

export default function CircularLoader(props: ICircularLoader) {
  const classes = stylesCircularLoader();

  return (
    <div className={ `${ classes.root } ${ props.node ? classes.node : '' } ${ props.center ? classes.center : '' }` }>
      <CircularProgress style={ { color: 'var(--main)' } }/>
    </div>
  );
}
