import React, { useState, useEffect, useCallback, memo } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { isMobile } from 'mobile-device-detect';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { IQuestion } from 'app/entities/game/game.reducer';

import { ContainerGame, IContainerGame } from 'app/containers/container.game';

import GameIndicates from 'app/components/game/indicates/indicate.index';
import GameAnswer from 'app/components/game/answer/answer.index';

import { IModalProps, Modal } from './modal.ui';
import { useModalGameStyles } from './modal.styles';

interface IGamePageParams {
  contentId: string;
  contentType: string;
}

interface IPropsModalGame extends IModalProps {
  isNotFetch?: boolean;
  onEnd?: () => void;
}

const ModalGameUI = memo<IPropsModalGame>(({ open, onClose = () => {}, onEnd, isNotFetch = false, mobile = false, ...props }) => {
  const { containerGame } = props as IContainerGame;
  const { question, questions, actions, gameId, result, totalCount, isGameOver, answerIndexes, isLastQuestion, canPostAnswer } = containerGame;
  const params = useParams<IGamePageParams>();
  const classes = useModalGameStyles({ mobile: isMobile });
  const [currentQuestion, setCurrentQuestion] = useState<IQuestion>({} as IQuestion);
  const [resultIndex, setResultIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCurrentQuestion(question);
      setResultIndex(questions.length);
    }, 500);
  }, [question, questions]);

  useEffect(() => {
    if (!isNotFetch && params?.contentId) {
      actions.startGame(params?.contentId);
    }
  }, [params, isNotFetch]);

  useEffect(() => {
    if (isGameOver) {
      setTimeout(() => {
        onEnd();
      }, 400);
    }
  }, [isGameOver, onEnd]);

  const onAnswer = useCallback(
    (index: number) => {
      if (!!gameId && canPostAnswer) {
        const indexes = [index];
        actions.postGameAnswer(indexes, gameId);
      }
    },
    [actions, gameId, totalCount, canPostAnswer]
  );

  useEffect(() => {
    if (result.length) {
      if (isLastQuestion) {
        actions.endGame(gameId);
      } else {
        actions.fetchGameQuestion(gameId);
      }
    }
  }, [isLastQuestion, result.length]);

  const currentIndex = questions.length === 0 ? questions.length : questions.length - 1;
  const currentResult = result.length === resultIndex ? result[resultIndex - 1] : null;

  const success = currentResult !== null ? currentResult : null;
  const failed = currentResult !== null ? !currentResult : null;

  const currentAnswer = answerIndexes.length > 0 ? answerIndexes[currentIndex] : [];
  return (
    <Modal
      style={ { top: isMobile ? '0' : '50%' } }
      open={ open }
      withClose
      onClose={ onClose }
      suptitle={ <GameIndicates totalCount={ totalCount } currentIndex={ currentIndex } result={ result } /> }
      mobile={ isMobile }
    >
      { !!currentQuestion?.id && (
        <div className={ classes.container }>
          <Typography className={ classes.title } type="heading" level={ 2 } mobile={ isMobile }>{ currentQuestion.text }</Typography>
            { currentQuestion.answerList.map(({ image, text, index }, i) => (
              <GameAnswer
                key={ index }
                className={ `${classes.answer} ${!image && classes.answerText} ${i === 0 && classes.answerFirst}` }
                success={ !!currentAnswer && currentAnswer[0] === index && success }
                failed={ !!currentAnswer && currentAnswer[0] === index && failed }
                index={ index }
                onSelect={ () => onAnswer(index) }
              >
                <div className={ cn(classes.answerContent, { [classes.answerPadding]: !!image }) }>
                  <div className={ cn(classes.text, {
                    [classes.textStart]: !image
                  }) }>{ text }</div>
                  { image && <img className={ classes.image } src={ image } /> }
                </div>
              </GameAnswer>
            )) }
        </div>
      ) }
    </Modal>
  );
});

export const ModalGame = ContainerGame(ModalGameUI);
