import { createStyles, Theme, makeStyles, styled } from '@material-ui/core';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import ButtonMaterial from '@material-ui/core/Button';

export const ButtonWrapper = styled(ButtonMaterial)(({ theme }) => ({
  textTransform: 'inherit',
  marginRight: 18,
  border: `1px solid ${configTheme.colors.background.neutral.neutral}`,
  boxSizing: 'border-box',
  borderRadius: '32px',
  boxShadow: 'none',
  backgroundColor: '#FFFFFF',
  fontWeight: 'bold',
  fontSize: '15px',
  '& .MuiButton-label': {
    justifyContent: 'space-between'
  },
  '&.activeBtn': {
    border: `1px solid ${configTheme.colors.border.primary.dark}`,
    '& .MuiButton-endIcon': {
      marginRight: 0
    }
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF'
  }
}));

export const ImgHolder = styled('div')({
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const ImgOnWrapper = styled('img')({
  width: 12,
  height: 9
});

export const ImgOffWrapper = styled('img')({
  width: 16,
  height: 16
});
