import uniqBy from 'lodash/uniqBy';
import { ELoadStatus } from 'app/models/shared.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { GAME_ACTIONS } from 'app/entities/game/game.actions';
import { IPrize } from 'app/models/model.prize';

export interface IAnswer {
  image?: string;
  text?: string;
  index: number;
}

export interface IQuestion {
  id: number;
  answerList: IAnswer[];
  text: string;
  image?: string;
  index: number;
  explanation?: string;
}

export interface IOver {
  gameId: string;
  percent?: number;
  correctCount: number;
  totalCount: number;
  prizes?: IPrize[];
}

export interface IGameInitialState {
  targetId: string;
  gameId: string;
  question: IQuestion;
  questions: IQuestion[];
  result: boolean[];
  answerIndexes: number[];
  isGameOver: boolean;
  isLastQuestion: boolean;
  canPostAnswer: boolean;
  totalCount: number;
  correctCount: number;
  percent: number;
  prizes: IPrize[];
  status: ELoadStatus;
  error: Error;
  buffering: boolean;
}

const initialState: IGameInitialState = {
  targetId: null,
  gameId: null,
  isLastQuestion: null,
  isGameOver: false,
  canPostAnswer: true,
  question: {} as IQuestion,
  questions: [] as IQuestion[],
  result: [] as boolean[],
  answerIndexes: [] as number[],
  totalCount: 0,
  correctCount: null,
  percent: null,
  prizes: [] as IPrize[],
  status: ELoadStatus.not_ready,
  error: null as Error,
  buffering: false
};

export type IGameState = Readonly<typeof initialState>;

export default (state = initialState, action): IGameState => {
  const { type, payload, meta } = action;
  const { targetId } = meta?.props ?? {};
  switch (type) {
    // НАЧАЛО ИГРЫ
    case REQUEST(GAME_ACTIONS.START_GAME):
      return {
        canPostAnswer: initialState.canPostAnswer,
        isGameOver: initialState.isGameOver,
        isLastQuestion: initialState.isLastQuestion,
        result: initialState.result,
        answerIndexes: initialState.answerIndexes,
        targetId: targetId ?? initialState.targetId,
        gameId: initialState.gameId,
        question: initialState.question,
        questions: initialState.questions,
        totalCount: initialState.totalCount,
        correctCount: initialState.correctCount,
        percent: initialState.percent,
        prizes: initialState.prizes,
        status: ELoadStatus.loading,
        error: initialState.error,
        buffering: initialState.buffering
      };
    case SUCCESS(GAME_ACTIONS.START_GAME):
      return {
        ...state,
        gameId: payload.data.gameId,
        totalCount: payload.data.totalCount,
        question: payload.data.question,
        // @ts-ignore
        questions: [...state.questions, payload.data.question],
        status: ELoadStatus.ready
      };
    case FAILURE(GAME_ACTIONS.START_GAME):
      return {
        ...state,
        status: ELoadStatus.error,
        error: new Error('game fetch failed')
      };

    // ОТПРАВКА ОТВЕТА НА ВОПРОС
    case REQUEST(GAME_ACTIONS.POST_ANSWER):
      return {
        ...state,
        canPostAnswer: false,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(GAME_ACTIONS.POST_ANSWER):
      return {
        ...state,
        isLastQuestion: payload.data.last,
        result: [...state.result, payload.data.correct],
        answerIndexes: Array.isArray(meta?.props?.answerIndexes) ? [...state.answerIndexes, meta.props.answerIndexes] : state.answerIndexes,
        status: ELoadStatus.ready
      };
    case FAILURE(GAME_ACTIONS.POST_ANSWER):
      return {
        ...state,
        status: ELoadStatus.error,
        error: new Error('game fetch failed')
      };

    // ПОЛУЧЕНИЕ СЛЕДУЮЩЕГО ВОПРОСА
    case REQUEST(GAME_ACTIONS.FETCH_QUESTION):
      return {
        ...state,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(GAME_ACTIONS.FETCH_QUESTION):
      return {
        ...state,
        canPostAnswer: true,
        question: payload.data.question,
        questions: uniqBy([...state.questions, payload.data.question], 'id'),
        status: ELoadStatus.ready
      };
    case FAILURE(GAME_ACTIONS.FETCH_QUESTION):
      return {
        ...state,
        question: initialState.question,
        status: ELoadStatus.error,
        error: new Error('game fetch failed')
      };

    // ЗАВЕРШЕНИЕ ИГРЫ
    case REQUEST(GAME_ACTIONS.END_GAME):
      return {
        ...state,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(GAME_ACTIONS.END_GAME):
      return {
        ...state,
        canPostAnswer: false,
        isGameOver: true,
        percent: payload.data.percent,
        prizes: payload.data.prizes ?? [],
        correctCount: payload.data.correctCount,
        gameId: payload.data.gameId,
        totalCount: payload.data.totalCount,
        question: initialState.question,
        status: ELoadStatus.ready
      };
    case FAILURE(GAME_ACTIONS.END_GAME):
      return {
        ...state,
        status: ELoadStatus.error,
        error: new Error('game fetch failed')
      };

    // КЕШИРОВАНИЕ ИГРЫ
    case GAME_ACTIONS.BUFFER_GAME:
      return { ...state, targetId, buffering: Boolean(payload) };

    // СБРОС ИГРЫ
    case GAME_ACTIONS.RESET_GAME:
      return initialState;

    default:
      return state;
  }
};
