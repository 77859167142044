import { makeStyles, createStyles } from '@material-ui/core';

export const useMobileDietStyles = makeStyles(
  createStyles({
    header: {
      width: '100%',
      marginBottom: 36
    },
    banner: {
      width: '100%'
    },
    wrapper: {
      '& p': {
        fontSize: '16px',
        lineHeight: '22px'
      },
      '& li': {
        display: 'flex',
        alignItems: 'center'
      }
    },
    title: {
      fontWeight: 'bold',
      fontSize: '38px',
      lineHeight: '44px',
      color: '#1F2229'
    },
    btn: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginTop: 36,
      marginBottom: 36
    },
    wrapDesc: {
      flexWrap: 'nowrap',
      '& :last-child': {
        marginBottom: 18
      }
    },
    desc: {
      color: '#FFFFFF',
      minWidth: '24px',
      minHeight: '24px',
      background: '#1F2229',
      display: 'flex',
      lineHeight: '24px',
      borderRadius: '50%',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center;'
    }
  })
);
