import React, { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { stylesApp } from 'app/app';

import { ISectionListContainerProps, SectionListContainer } from 'app/containers/section.list.container';
import { IPagerProps } from 'app/containers/container.pager';

import { ISectionItem } from 'app/models/model.section';
import { ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { ISubcategory } from 'app/models/model.subcategory';

import { ResponsiveImage } from 'app/components/image/component.image.responsive';
import { ContentHeader } from 'app/components/header/content.header';
import CircularLoader from 'app/components/loader/loader.circular';

import { ICategory } from 'app/models/model.category';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';

import get from 'lodash/get';

export interface IPageSectionList extends IRouteProps {
  sectionListContainer: ISectionListContainerProps;
  containerCategory: ICategoryContainerProps;
}

const SectionList: FC<IPageSectionList> = props => {
  const [categoryId, setCategoryId] = useState<ICategory['id']>();
  const [subcategoryId, setSubcategoryId] = useState<ISubcategory['id']>();
  const [sectionList, setSectionList] = useState<ISectionItem[]>([]);
  const [pagerContent, setPagerContent] = useState<IPagerProps['pager']>({ limit: 5, offset: 0 });

  const propsSectionItems = props.sectionListContainer.list;
  const contentHeaderTitle = props.containerCategory.item && props.containerCategory.item.name;
  const isSectionsLoad = propsSectionItems && propsSectionItems?.length > 0 && propsSectionItems[0].status === ELoadStatus.loading;

  const classes = stylesApp();
  const refContainer = useRef<HTMLDivElement>();

  const onPageChange = () => setPagerContent({ ...pagerContent, offset: pagerContent.offset + pagerContent.limit });

  useEffect(() => {
    props.computedMatch.params && props.computedMatch.params.subcategoryId &&
    setSubcategoryId(Number(props.computedMatch.params.subcategoryId));
    setCategoryId(Number(props.computedMatch.params.categoryId));
  }, [props.computedMatch.params]);

  useEffect(() => {
    categoryId && props.containerCategory.actions.fetchCategoryItem(categoryId);
  }, [categoryId]);

  useEffect(() => {
    subcategoryId && props.sectionListContainer.actions.fetchSectionItems({ pager: pagerContent, id: subcategoryId });
  }, [subcategoryId, pagerContent]);

  useEffect(() => {
    propsSectionItems && propsSectionItems?.length > 0 && propsSectionItems[0].items && propsSectionItems[0].items?.length > 0 &&
    // @ts-ignore
    setSectionList([...sectionList.concat(propsSectionItems[0].items)]);
  }, [propsSectionItems]);

  useEffect(() => {
    setSectionList([]);
    return () => {
      props.sectionListContainer.actions.resetSectionItems();
    };
  }, []);

  return (
    <>
      <div ref={ refContainer }>
        <ContentHeader isClose closeRoute={ '/' }>
          { `Все ${contentHeaderTitle ? contentHeaderTitle.toLowerCase() : ''}` }
        </ContentHeader>
        {
          propsSectionItems && propsSectionItems.length > 0
            ?
              <InfiniteScroll
                dataLength={ sectionList.length }
                next={ onPageChange }
                hasMore={ propsSectionItems && propsSectionItems?.length > 0 && propsSectionItems[0].more }
                loader={ <CircularLoader center/> }
              >
                {
                  sectionList && sectionList.length > 0 ? sectionList.map(si => (
                    <Link key={ si.id } className={ classes.linkDNone } to={ `/${categoryId}/content/list/${si.id}` }>
                      <ResponsiveImage key={ si.id } src={ si.preview } title={ si.name }/>
                    </Link>
                  )) : null
                }
              </InfiniteScroll>
            : null
        }
      </div>
    </>
  );
};

export default CategoryContainer(SectionListContainer(SectionList));
