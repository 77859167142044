import React, { FC, memo, forwardRef, useEffect, useState, useRef } from 'react';
import { Modal } from 'a1s-tele2-react-ui/src/components/shared/modal/modal.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { createStyles, makeStyles } from '@material-ui/core';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { ContainerQuiz, IContainerQuizProps } from 'app/containers/quiz.container';
import { IQuizInitialState, IAnswer } from 'app/entities/quiz/quiz.reducer';
import { useTranslation, Trans } from 'react-i18next';
import debounce from 'lodash.debounce';
import classnames from 'classnames';

export const stylesVariant = makeStyles(() => createStyles({
  itemWrap : {
    backgroundColor: `${configTheme.colors.background.white.default}`,
    color: `${configTheme.colors.text.dark.default}`,
    boxShadow: `0px 2px 5px 1px ${configTheme.colors.shadow.translucent}`,
    fontSize: '16px',
    padding: '18px 17px',
    borderRadius: '6px',
    marginBottom: `${configTheme.indents['indentX4'].margin / 3}px`,
    '&:last-child': {
      marginBottom: 0
    }
  },
  success: {
    boxShadow: `0px 2px 5px 1px ${configTheme.colors.notification.valid.default}`
  },
  failed: {
    boxShadow: `0px 2px 5px 1px ${configTheme.colors.notification.invalid.default}`
  }
}));
export const stylesQuizModal = makeStyles(() => createStyles({
  wrap: {
    paddingTop: '50px',
    position: 'relative'
  },
  itemsWrap: {
    marginTop: `${configTheme.indents['indentX2'].margin}px`
  },
  textWrap: {
    marginTop: `${configTheme.indents['indentX1'].margin}px`
  },
  buttonWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '186px',
    margin: 'auto',
    marginTop: `${configTheme.indents['indentX2'].margin}px`,
    '& button': {
      marginBottom: `${configTheme.indents['indentX1'].margin}px`
    },
    '& button:last-child': {
      marginBottom: 0,
      border: `2px solid ${configTheme.colors.background.dark.default}`
    }
  }
}));
export const stylesQuizIndicates = makeStyles(() => createStyles({
  indicateWrap: {
    display: 'inline-flex',
    position: 'absolute',
    top: '11px',
    left: 0
  },
  indicate: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: `${ configTheme.colors.text.light.medium }`,
    marginRight: `${configTheme.indents['indentX1'].margin - 8}px`,
    '&:last-child': {
      marginRight: 0
    }
  },
  success: {
    backgroundColor: `${ configTheme.colors.notification.valid.default }`
  },
  failed: {
    backgroundColor: `${ configTheme.colors.notification.invalid.default }`
  },
  selected: {
    backgroundColor: `${ configTheme.colors.border.primary.dark }`
  }
}));

interface IQuizProps {
  open: boolean;
  id: IQuizInitialState['quizId'];
  onClose: () => void;
}
interface IQuizModalFCProps extends IQuizProps {
  containerQuiz: IContainerQuizProps;
}
interface IVariantProps {
  onSelect: (index: IAnswer['index']) => void;
  item: IAnswer;
  status: boolean;
  isSelected: boolean;
  disabled: boolean;
}
interface IIndicatesProps {
  totalCount: number;
  result: IQuizInitialState['result'];
  currentIndex: number;
}

const QuizModalFC: FC<IQuizModalFCProps> = forwardRef<HTMLDivElement, IQuizModalFCProps>((props, ref) => {
  const { onClose, open, id } = props;
  const {
    actions: {
      startQuiz,
      endQuiz,
      fetchQuiz,
      postQuizAnswer,
      resetQuiz
    },
    quizId,
    totalCount,
    isLastQuestion,
    correctCount,
    result,
    question,
    isQuizOver,
    canPostAnswer
  } = props.containerQuiz;
  const classes = stylesQuizModal();
  const { t } = useTranslation();
  const [selectVariantIndex, setSelectVariantIndex] = useState(null);
  const isFirstRun = useRef(true);

  const onSelect = index => {
    if (canPostAnswer) {
      setSelectVariantIndex(index);
      postQuizAnswer([index], quizId);
    }
  };

  const onFetch = debounce(() => {
    fetchQuiz(quizId);
    setSelectVariantIndex(null);
  }, 1000);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (result.length) {
      if (isLastQuestion) {
        endQuiz(quizId);
      } else {
        onFetch();
      }
    }
  }, [isLastQuestion, result.length]);

  useEffect(() => {
    startQuiz(id);
    return () => {
      resetQuiz();
    };
  }, [id]);

  const onPlayAgain = () => {
    startQuiz(id);
  };

  return (
    <Modal ref={ ref } onClose={ onClose } open={ open } withClose>
      <div className={ classes.wrap }>
        <Indicates result={ result } totalCount={ totalCount } currentIndex={ question?.index - 1 }/>
          {
            !isQuizOver
              ? (<>
                <Typography type={ 'heading' } level={ 4 }>{ question?.text }</Typography>
                <div className={ classes.itemsWrap }>
                  {
                    question?.answerList?.length && question.answerList.map(answer => <Variant key={ answer.index } item={ answer } onSelect={ onSelect } status={
                      result[question.index - 1] } isSelected={ selectVariantIndex === answer.index } disabled={ selectVariantIndex }/>)
                  }
                </div>
              </>)
              : (<>
                <Typography type={ 'heading' } level={ 2 }>{ t('quiz.gameOver') }</Typography>
                <div className={ classes.textWrap }>
                  <Typography type={ 'text' }>
                    <Trans i18nKey="quiz.yourResult" values={ { correctCount, totalCount } }/>
                  </Typography>
                </div>
                <div className={ classes.buttonWrap }>
                  <Button mobile onClick={ onClose }>{ t('quiz.close') }</Button>
                  <Button mobile onClick={ onPlayAgain } type="secondary">{ t('quiz.playAgain') }</Button>
                </div>
              </>)
            }
      </div>
    </Modal>
  );
});
export const QuizModal = ContainerQuiz(QuizModalFC);

export const Quiz: FC<IQuizProps> = props => {
  const { onClose, open, id } = props;
  return (
    <>
      {
        open && <QuizModal onClose={ onClose } open={ open } id={ id }/>
      }
    </>
  );
};

const Variant: FC<IVariantProps> = memo<IVariantProps>(props => {
  const { onSelect, item, status, isSelected } = props;
  const classes = stylesVariant();
  return (
    <div
      onClick={ () => onSelect(item?.index) }
      className={ classnames(classes.itemWrap, {
        [classes['success']]: isSelected && status === true,
        [classes['failed']]: isSelected && status === false
      }) }>
      { item?.text }
    </div>
  );
});
const Indicates: FC<IIndicatesProps> = memo<IIndicatesProps>(props => {
  const { totalCount, result, currentIndex } = props;
  const classes = stylesQuizIndicates();
  const indicates = new Array(totalCount).fill(0);
  return (
    <div className={ classes.indicateWrap }>
      {
        indicates?.length
          ? indicates.map((item, index) => (
            <div key={ index } className={ classnames(classes.indicate, {
              [classes['success']]: result[index] === true,
              [classes['failed']]: result[index] === false,
              [classes['selected']]: index === currentIndex
            }) }/>
          ))
          : null
        }
    </div>
  );
});
