import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core';

export const useStylesModalCalcProgram = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    checkBoxLabel: {
      fontFamily: 'inherit!important',
      color: '#1F2229',
      '& .MuiTypography-root': {
        fontFamily: 'inherit!important',
        color: '#1F2229'
      }
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      width: 732,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    closeIcon: {
      cursor: 'pointer'
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    wrapperRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiTypography-body1': {
        fontFamily: 'inherit'
      }
    },
    paperLoader: {
      width: 732,
      height: 540,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: '36px 36px 24px',
      boxSizing: 'border-box'
    },
    progress: {
      marginTop: '54px',
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#E8E8ED'
      },
      '& .MuiLinearProgress-root': {
        height: 6,
        width: 523,
        margin: 'auto',
        borderRadius: 17,
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#3FCBFF'
        }
      }
    }
  })
);
