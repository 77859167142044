import React, { FC, Suspense, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { isMobile } from 'mobile-device-detect';
import map from 'lodash/map';

import { uiSetup } from 'app/configs/theme';

import PublicRoute from 'app/shared/routes/public.route';

import { ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { IContainerSubscribeProps, ContainerSubscribe } from 'app/containers/container.subscribe';
// import { ModalsContainer, IModalsContainer } from 'app/containers/modals.container';
import { ErrorServerContainer, IServerErrorContainer } from 'app/containers/container.server.error';

// import { ModalTypes } from 'app/entities/modals/modals.reducer';
import { MobileLayout } from 'app/layouts/mobile.layout';
import { DesktopLayout } from 'app/layouts/desktop.layout';
import { DesktopMediaLayout } from 'app/layouts/desktop.media.layout';

import CircularLoader from 'app/components/loader/loader.circular';
import Modals from 'app/entities/modals/modals.index';
import { Logger } from 'app/entities/logging/logger';

import {
  PROFILE,
  SECTIONS,
  CONTENT_LIST,
  CONTENT_ITEM,
  SUBCATEGORIES,
  ERROR,
  STUDY_RESULT,
  DIET,
  CONTENT_ITEM_VIDEO,
  EDietPageSection
} from 'app/configs/route.names';

import MobileProfilePage from 'app/pages/mobile/profile/profile.index';
import MobileMainPage from 'app/pages/mobile/main/main.index';
import MobileDietPage from 'app/pages/mobile/diet/diet.index';
import MobileDietIndividualProgram from 'app/pages/mobile/diet/program/individual.program.index';
import MobileSectionListPage from 'app/pages/mobile/section/page.section.list';
import MobileContentListPage from 'app/pages/mobile/content/page.content.list';
import MobileContentItemPage from 'app/pages/mobile/content/item/page.content.item';
import MobileCategoryListPage from 'app/pages/mobile/category/page.category.list';
import MobilePageGameResult from 'app/pages/mobile/game.result/page.game.result.index';
import MobilePageError from 'app/pages/mobile/error/page.error';

import DesktopGameResultPage from 'app/pages/desktop/game.result/page.game.result.index';
import DesktopMainPage from 'app/pages/desktop/main/page.main.index';
import DesktopProfilePage from 'app/pages/desktop/profile/profile.index';
import DesktopContentListPage from 'app/pages/desktop/content/list/page.content.list.index';
import DesktopContentItemPage from 'app/pages/desktop/content/item/page.content.item.index';
import DesktopContentMediaPage from 'app/pages/desktop/content/media/page.content.media.index';
import DesktopErrorPage from 'app/pages/desktop/error/error.index';
import DesktopDietPage from 'app/pages/desktop/diet/diet.index';
import DesktopDietIndividualProgram from 'app/pages/desktop/diet/program/individual.program.index';

// tslint:disable-next-line: no-empty-interface
interface IAppRoutesProps {}

export interface IRootRouteProps {
  isAuthenticated: boolean;
  status: IAuthContainerProps['request']['status'];
  token: boolean | null | string;
  location: IRouteProps['location'];
  history: IRouteProps['history'];
  dietSubscription: IAuthContainerProps['dietSubscription'];
  isSubscribed: boolean;
  authExternal: () => void;
}

const Routes: FC<
  IAppRoutesProps & {
    containerUser: IAuthContainerProps;
    containerSubscribe: IContainerSubscribeProps;
    containerDietIndividualProgram: IContainerDietIndividualProgramProps;
    errorServerContainer: IServerErrorContainer;
  }
> = props => {
  const { checkUser } = props.containerUser.actions;
  const { resetProp } = props.containerSubscribe.actions;
  const { fetchDietItem, getDietStatus } = props.containerDietIndividualProgram.actions;
  const {
    token,
    request: { status }
  } = props.containerUser;
  const {
    status: userStatus,
    actions: { fetchSubscription }
  } = props.containerSubscribe;

  const history = useHistory();
  const isAuthenticated = props.containerUser.authenticated;

  useEffect(() => {
    checkUser();
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchDietItem();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (userStatus.change === ELoadStatus.ready) {
        fetchSubscription();
        getDietStatus();
        resetProp('change');
        return;
      }
      if (![ELoadStatus.ready, ELoadStatus.loading].includes(userStatus.subscriptions)) {
        fetchSubscription();
        getDietStatus();
      }
    }
  }, [isAuthenticated, userStatus.change, userStatus.subscriptions]);

  useEffect(() => {
    props.errorServerContainer.errorCode && history.replace(ERROR);
  }, [props.errorServerContainer.errorCode]);

  const LayoutComponent = isMobile ? MobileLayout : DesktopLayout;

  return (
    <>
      <Modals mobile={ isMobile } />
      <Suspense fallback={ <CircularLoader /> }>
        <Switch>
          <PublicRoute layout={ LayoutComponent } path="/" component={ isMobile ? MobileMainPage : DesktopMainPage } exact />
          <PublicRoute layout={ LayoutComponent } path="/diet" component={ isMobile ? MobileDietPage : DesktopDietPage } exact />
          { map(EDietPageSection, (section, index) => (
            <PublicRoute
              key={ index }
              layout={ LayoutComponent }
              path={ DIET(section) }
              component={ isMobile ? MobileDietIndividualProgram : DesktopDietIndividualProgram }
              exact
            />
          )) }
          <PublicRoute layout={ LayoutComponent } component={ isMobile ? MobileProfilePage : DesktopProfilePage } path={ PROFILE } exact />
          <PublicRoute layout={ LayoutComponent } component={ MobileCategoryListPage } path={ SUBCATEGORIES(':categoryId') } exact />
          <PublicRoute layout={ LayoutComponent } component={ MobileSectionListPage } path={ SECTIONS(':categoryId', ':subcategoryId') } exact />
          <PublicRoute
            layout={ LayoutComponent }
            component={ isMobile ? MobilePageGameResult : DesktopGameResultPage }
            path={ STUDY_RESULT(':contentType' as EContentType, ':contentId') }
            exact
          />
          <PublicRoute
            layout={ LayoutComponent }
            component={ isMobile ? MobileContentListPage : DesktopContentListPage }
            path={ CONTENT_LIST(':categoryId', ':sectionId') }
            exact
          />
          { !isMobile &&
            map([EContentType.VIDEO, EContentType.GAME_HTML5], type => (
              <PublicRoute
                key={ type }
                component={ DesktopContentMediaPage }
                path={ CONTENT_ITEM(type, ':contentId') }
                exact
              />
            ))
          }
          <PublicRoute
            layout={ DesktopMediaLayout }
            component={ isMobile ? MobileContentItemPage : DesktopContentMediaPage }
            path={ CONTENT_ITEM_VIDEO(':contentType' as EContentType) }
            exact
          />
          <PublicRoute
            layout={ LayoutComponent }
            component={ isMobile ? MobileContentItemPage : DesktopContentItemPage }
            path={ CONTENT_ITEM(':contentType' as EContentType, ':contentId') }
            exact
          />
          <PublicRoute
            path={ ERROR }
            component={ isMobile ? MobilePageError : DesktopErrorPage }
            layoutProps={ { isVisibleFooter: uiSetup.pages.error.isVisibleFooter } }
          />
          <PublicRoute
            path="/"
            component={ isMobile ? MobilePageError : DesktopErrorPage }
            layoutProps={ { isVisibleFooter: uiSetup.pages.error.isVisibleFooter } }
          />
        </Switch>
      </Suspense>

      { /* <ModalComponent onClose={closeModal} open={show} /> */ }
      { /*<ModalDirector mobile={ isMobile } />*/ }
    </>
  );
};

// export const AppRoutes = ModalsContainer(ContainerSubscribe(ContainerUser(ErrorServerContainer(Routes))));
export const AppRoutes = ContainerDietIndividualProgram(ContainerSubscribe(ContainerUser(ErrorServerContainer(Routes))));
