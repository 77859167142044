import React, { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import ImgDietMobile from 'app/assets/img/diet.mobile.png';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { useMobileDietStyles } from 'app/pages/mobile/diet/diet.styles';
import { ContentHeader } from 'app/components/header/content.header';
import { useTranslation } from 'react-i18next';

import ModalMobileDietProgram from 'app/pages/mobile/diet/program/program.index';

import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { IContainerSubscribeProps, ContainerSubscribe } from 'app/containers/container.subscribe';

import userOpportunitiesCheck from 'app/utils/util.opportunities.check';
import { useHistory } from 'react-router';

const Diet: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
}> = ({ containerUser, containerDietIndividualProgram, containerSubscribe }) => {
  const style = useMobileDietStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    return () => {
      setShowModal(false);
    };
  }, []);

  useEffect(() => {
    userOpportunitiesCheck({
      subscribe: containerSubscribe.subscriptions.find(sub => sub?.id === containerDietIndividualProgram.programStatus.id),
      history,
      containerUser,
      containerDietIndividualProgram
    });
  }, [window.location]);

  const descItems: Array<{
    number: number;
    title: string;
  }> = [
    { number: 1, title: t('diet.params') },
    { number: 2, title: t('diet.getProgram') },
    { number: 3, title: t('diet.respawn') }
  ];

  return (
    <>
      <Box style={ { padding: 0 } }>
        { isShowModal && <ModalMobileDietProgram
          isShowModal={ isShowModal }
          setShowModal={ setShowModal }
          authenticated={ containerUser.authenticated }
          propsState={ containerDietIndividualProgram.itemUserAttributes }
          loading={ containerDietIndividualProgram.progressStatus }
          save={ containerDietIndividualProgram.actions.saveDietItem }
        /> }
        <Grid className={ style.header }>
          <ContentHeader isClose>{ t('header.diet') }</ContentHeader>
        </Grid>

        <div className={ style.banner }>
          <img src={ ImgDietMobile } alt={ t('header.diet') } className={ style.banner } />
        </div>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={ style.wrapper }>
          <p>{ t('diet.promo') }</p>
          { descItems.map(({ number, title }, index) => {
            return (
              <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={ style.wrapDesc } key={ number }>
                <div style={ { margin: '0 16px 0 16px' } }>
                  <span className={ style.desc } style={ { height: 16, marginBottom: index === descItems?.length - 1 && 0 } }>
                    { number }
                  </span>
                </div>

                <span style={ { marginBottom: index === descItems?.length - 1 && 0 } }>{ title }</span>
              </Grid>
            );
          }) }
        </Grid>
        <div className={ style.btn }>
          <Button onClick={ handleOpenModal } style={ { width: '173px', height: '48px' } }>
            { t('common.startNow') }
          </Button>
        </div>
      </Box>
    </>
  );
};

export default ContainerDietIndividualProgram(ContainerSubscribe(ContainerUser(Diet)));
