import React, { FC, forwardRef, useEffect, useState } from 'react';

import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormGroup, Grid, RadioGroup, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Modal } from 'app/components/modal/modal.ui';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { CustomTextField } from 'app/components/custom.input/custom.input';
import { CustomizedRadiosCheck } from 'app/components/customized.radios.check/customized.radios.check';
import CloseIcon from 'app/assets/img/close.svg';

import { ESex, ETarget, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { ELoadStatus } from 'app/models/shared.model';

import { useStylesModalParameters } from './modal.parameters.style';
import { CustomAutocomplete } from 'app/components/autocomplete/autocomplete';

interface IModalParametersProps {
  isModalParameters: boolean;
  setModalParameters: (boolean) => void;
  save: (data: IUserAttributes) => void;
  propsState?: IUserAttributes;
  loading?: ELoadStatus;
}

const GreenCheckbox = withStyles({
  root: {
    color: '#8F9399',
    '&$checked': {
      color: ' #1F2229'
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" { ...props } />);

export const ModalParameters: FC<IModalParametersProps> = forwardRef(
  ({ isModalParameters, setModalParameters, propsState, save, loading }, ref) => {
    const { t } = useTranslation();
    const options = [
      { id: ETarget.WEIGHT_DOWN, title: `${t('modalCalcProgram.loseWeight')}` },
      { id: ETarget.WEIGHT_UP, title: `${t('modalCalcProgram.toGainWeight')}` },
      { id: ETarget.WEIGHT_KEEP, title: `${t('modalCalcProgram.maintainWeight')}` }
    ];

    const styles = useStylesModalParameters();
    const [valueAutocomplete, setValueAutocomplete] = useState(options[0]);
    const [errorWeight, setErrorWeight] = useState(false);
    const [errorHeight, setErrorHeight] = useState(false);
    const [errorAge, setErrorAge] = useState(false);
    const [inputValueTextField, setInputValueTextField] = useState({
      height: '',
      weight: '',
      age: '',
      target: null
    });
    const [state, setState] = useState({
      breastFeeding: false
    });
    const [isSwitchWomenMen, setSwitchWomenMen] = useState<ESex>(ESex.FEMALE);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ breastFeeding: event.target.checked });
    };

    useEffect(() => {
      setInputValueTextField(prevState => ({
        ...prevState,
        target: valueAutocomplete.id
      }));
    }, [valueAutocomplete]);

    useEffect(() => {
      if (isSwitchWomenMen === ESex.MALE) {
        setState({ breastFeeding: false });
      }
    }, [isSwitchWomenMen]);

    const handleCloseModal = () => {
      setModalParameters(false);
    };

    const handleSave = async () => {
      if (errorWeight || errorHeight || errorAge) {
        return;
      }
      const { height, weight, age, target } = inputValueTextField;
      await save({
        height: !!height ? Number(height) : null,
        weight: !!weight ? Number(weight) : null,
        age: !!age ? Number(age) : null,
        sex: isSwitchWomenMen,
        target,
        ...state
      });
      if (loading === ELoadStatus.ready) {
        setModalParameters(false);
      }
    };

    const validateForm = (inputValidateItem, stateValidate, min, max) => {
      if (inputValidateItem < min || inputValidateItem > max) {
        stateValidate(true);
      } else {
        stateValidate(false);
      }

      if (inputValidateItem === '' || inputValidateItem === null) {
        stateValidate(false);
      }
    };

    useEffect(() => {
      validateForm(inputValueTextField.height, setErrorHeight, 100, 250);
    }, [inputValueTextField.height]);

    useEffect(() => {
      validateForm(inputValueTextField.weight, setErrorWeight, 20, 300);
    }, [inputValueTextField.weight]);

    useEffect(() => {
      validateForm(inputValueTextField.age, setErrorAge, 14, 114);
    }, [inputValueTextField.age]);

    useEffect(() => {
      if (propsState !== null && Object.keys(propsState).length !== 0) {
        const { height, weight, age, sex, breastFeeding, target } = propsState;

        setInputValueTextField({
          height: height === null ? '' : String(height),
          weight: weight === null ? '' : String(weight),
          age: age === null ? '' : String(age),
          target: target ? options.find(el => el.id === target)?.id : options[0]?.id
        });

        setValueAutocomplete(target ? options.find(el => el.id === target) : options[0]);
        setSwitchWomenMen(sex === null ? ESex.FEMALE : sex);
        setState({ breastFeeding });
      }
    }, [propsState]);

    return (
      <>
        <div className={ styles.root }>
          <Modal
            className={ styles.modal }
            open={ isModalParameters }
            onClose={ handleCloseModal }
            renderModal={
              <Grid className={ styles.paper }>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={ { marginBottom: 27, marginTop: 20 } }
                >
                  <Typography type="heading" level={ 2 }>
                    { t('modalCalcProgram.myParameters') }
                  </Typography>
                  <img src={ CloseIcon } alt="close" className={ styles.closeIcon } onClick={ () => setModalParameters(false) } />
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={ { marginBottom: 18 } }>
                  <Typography type="text" tag="p">
                    { t('modalCalcProgram.yourParametersForCalculation') }
                  </Typography>
                </Grid>
                <form noValidate className={ styles.formWrapper }>
                  <CustomTextField
                    type="number"
                    style={ { marginBottom: '18px', width: '50%' } }
                    label={ t('calc.height') }
                    value={ inputValueTextField?.height }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, height: e.target.value }) }
                    variant="filled"
                    id="height"
                    error={ errorHeight }
                    helperText={ errorHeight && t('modalCalcProgram.checkValidData') }
                    onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                    inputProps={ { pattern: '[0-9]*' } }
                  />
                  <CustomTextField
                    type="number"
                    style={ { marginBottom: '18px', width: '50%', borderBottom: 0 } }
                    label={ t('calc.weight') }
                    value={ inputValueTextField?.weight }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, weight: e.target.value }) }
                    variant="filled"
                    id="weight"
                    error={ errorWeight }
                    helperText={ errorWeight && t('modalCalcProgram.checkValidData') }
                    onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                    inputProps={ { pattern: '[0-9]*' } }
                  />
                  <CustomTextField
                    type="number"
                    style={ { marginBottom: '18px', width: '50%' } }
                    label={ t('modalCalcProgram.age') }
                    value={ inputValueTextField?.age }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, age: e.target.value }) }
                    variant="filled"
                    id="age"
                    error={ errorAge }
                    helperText={ errorAge && t('modalCalcProgram.checkValidData') }
                    onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                    inputProps={ { pattern: '[0-9]*' } }
                  />
                  <CustomAutocomplete
                    value={ valueAutocomplete }
                    options={ options }
                    id="controllable-states"
                    onChange={ setValueAutocomplete }
                    style={ { width: '50%' } }
                  />
                  <FormControl component="fieldset" style={ { marginTop: '27px' } }>
                    <RadioGroup
                      defaultValue={ t('modalCalcProgram.woman') }
                      aria-label="gender"
                      name="customized-radios"
                      className={ styles.wrapperRadioGroup }
                    >
                      <FormControlLabel
                        value={ t('modalCalcProgram.woman') }
                        checked={ isSwitchWomenMen === ESex.FEMALE }
                        control={ <CustomizedRadiosCheck /> }
                        label={ t('modalCalcProgram.woman') }
                        onClick={ () => setSwitchWomenMen(ESex.FEMALE) }
                      />
                      <FormControlLabel
                        value={ t('modalCalcProgram.man') }
                        checked={ isSwitchWomenMen === ESex.MALE }
                        control={ <CustomizedRadiosCheck /> }
                        label={ t('modalCalcProgram.man') }
                        onClick={ () => setSwitchWomenMen(ESex.MALE) }
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormGroup row style={ { marginTop: '10px' } }>
                    <FormControlLabel
                      control={ <GreenCheckbox checked={ state.breastFeeding } onChange={ handleChange } name={ t('calc.breastFeeding') } /> }
                      label={ t('calc.breastFeeding') }
                      classes={ { label: styles.checkBoxLabel } }
                      style={ isSwitchWomenMen === ESex.FEMALE ? { opacity: '1' } : { opacity: '0', cursor: 'default' } }
                    />
                  </FormGroup>

                  <Grid container direction="row" style={ { marginTop: '37px' } }>
                    <Button className={ styles.btnSave } onClick={ handleSave }>
                      { t('modalCalcProgram.saveParameters') }
                    </Button>
                    <Button className={ styles.btnCancel } onClick={ handleCloseModal }>
                      { t('modalCalcProgram.cancel') }
                    </Button>
                  </Grid>
                </form>
                <Typography type="text" tag="p" className={ styles.description } style={ { color: '#8A8F97' } }>
                  { t('modalCalcProgram.programDescription') }
                </Typography>
              </Grid>
            }
          />
        </div>
      </>
    );
  }
);
