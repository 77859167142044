import { BASENAME } from 'app/configs/const';
import { IRequestCancel, CancelToken } from 'app/models/shared.model';
import { IPrize } from 'app/models/model.prize';

import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const mockAxios = axios.create();
const mock = new MockAdapter(mockAxios, { delayResponse: 1500 });

export const PRIZE_ACTIONS = {
  FETCH_LIST: 'PRIZE:LIST::GET',
  RESET_LIST: 'PRIZE:LIST::RESET',
  FETCH_PERSONAL: 'PRIZE:PERSONAL::GET',
  RESET_PERSONAL: 'PRIZE:PERSONAL::RESET'
};

export const fetchList = (() => {
  let cancel;
  let url = null;
  return (code: string, props?: IRequestCancel) => {
    const { uncancelled, cancellable, cancelToken } = props ?? {};
    if (cancel && url) cancel(`Cancel fetchStream: ${url}`); // check and run cancel
    url = code ? `${BASENAME}/api/prize/list?code=${code}` : null;
    return {
      type: PRIZE_ACTIONS.FETCH_LIST,
      meta: { props: { uncancelled } },
      payload: axios
        .get(url, {
          cancelToken:
            cancelToken ??
            (cancellable &&
              new CancelToken(function executor(c) {
                cancel = c; // save cancel
              }))
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.warn(err?.message);
            return Promise.resolve<{ data: { prizes: IPrize[] }; canceled: boolean }>({
              data: { prizes: [] },
              canceled: true
            });
          }
          return Promise.reject(err);
        })
    };
  };
})();

export const fetchPersonal = () => ({
  type: PRIZE_ACTIONS.FETCH_PERSONAL,
  payload: axios.get(`${BASENAME}/api/prize/my`)
});

export const resetList = () => ({
  type: PRIZE_ACTIONS.RESET_LIST
});

export const resetPersonal = () => ({
  type: PRIZE_ACTIONS.RESET_PERSONAL
});

/**
 * Mock
 */
// mock.onGet(`${BASENAME}/api/prize/my`).reply(config => {
//   const data = {
//     prizes: [
//       {
//         name: 'Приз за олимпиаду 1',
//         minPercent: 60,
//         content: {
//           id: 'GAME_371_2343575',
//           text: null,
//           url: 'https://mc.a1-systems.com/contentForFun/2343575?sectionId=78',
//           name: '4 in Row Mania',
//           description: 'Играй в классическую игру &quot;четыре в ряд&quot;, любимую с детства!',
//           data: {
//             preview: {
//               width: 320,
//               height: 320,
//               mimeType: 'image/jpeg',
//               url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575preview320.jpg'
//             },
//             gameType: 'HTML5',
//             screenshotList: [
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshot_1.jpg'
//               },
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshotJpeg_1.jpg'
//               },
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshot_2.jpg'
//               },
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshotJpeg_2.jpg'
//               }
//             ],
//             contentMappings: [{ contentSource: 'MOBILE_CONTENT', externalContentId: '2343575' }]
//           },
//           type: 'GAME_HTML5',
//           imageUrl: 'http://mc.a1-systems.com/ftp_public/game/575/2343575preview320.jpg',
//           free: true,
//           new: false
//         }
//       },
//       {
//         name: 'Приз за олимпиаду 2',
//         minPercent: 60,
//         content: {
//           id: 'VIDEO_742_2319604',
//           text: '',
//           url:
//             'http://a1systemsmedia-euwe.streaming.media.azure.net/2b0de8cb-8e68-4afd-ae17-a27a93674900/manualconvert_autoconvert_pgravi.ism/manifest',
//           name: 'Гравити Фолз. Сезон 1. Серия 1. Секреты Гравити Фолз',
//           description: null,
//           data: {
//             format: [],
//             preview: {
//               width: 320,
//               height: 180,
//               mimeType: 'image/jpeg',
//               url: 'http://mc.a1-systems.com/ftp_public/video/604/2319604preview320.jpg'
//             },
//             cover: null,
//             contentMappings: [
//               { contentSource: 'MOBILE_CONTENT', externalContentId: '2319604' },
//               { contentSource: 'MPP', externalContentId: '9447' }
//             ],
//             artist: null
//           },
//           type: 'VIDEO',
//           imageUrl: 'http://mc.a1-systems.com/ftp_public/video/604/2319604preview320.jpg',
//           free: false,
//           new: false
//         }
//       },
//       {
//         name: 'Приз за олимпиаду 3',
//         minPercent: 60,
//         content: {
//           id: 'BOOK_1104_2345956',
//           text: null,
//           url: 'https://mc.a1-systems.com/contentForFun/2345956?sectionId=1382',
//           name: 'Аленький цветочек',
//           description:
// tslint:disable-next-line: max-line-length
//             '«В некиим царстве, в некиим государстве жил-был богатый купец, именитый человек. Много у него было всякого богатства, дорогих товаров заморских, жемчугу, драгоценных камениев, золотой и серебряной казны и было у того купца три дочери, все три красавицы писаные, а меньшая лучше всех...»',
//           data: {
//             artist: 'Аксаков Сергей Тимофеевич',
//             format: [{ mimeType: 'application/pdf', width: null, height: null, size: '427739' }],
//             preview: {
//               width: 235,
//               height: 320,
//               mimeType: 'image/jpeg',
//               url: 'http://mc.a1-systems.com/ftp_public/book/956/2345956preview320.jpg'
//             },
//             contentMappings: [{ contentSource: 'MOBILE_CONTENT', externalContentId: '2345956' }]
//           },
//           type: 'BOOK',
//           imageUrl: 'http://mc.a1-systems.com/ftp_public/book/956/2345956preview320.jpg',
//           free: true,
//           new: false
//         }
//       },
//       {
//         name: 'Приз за олимпиаду 4',
//         minPercent: 60,
//         content: {
//           id: 'GAME_371_2343575',
//           text: null,
//           url: 'https://mc.a1-systems.com/contentForFun/2343575?sectionId=78',
//           name: '4 in Row Mania',
//           description: 'Играй в классическую игру &quot;четыре в ряд&quot;, любимую с детства!',
//           data: {
//             preview: {
//               width: 320,
//               height: 320,
//               mimeType: 'image/jpeg',
//               url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575preview320.jpg'
//             },
//             gameType: 'HTML5',
//             screenshotList: [
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshot_1.jpg'
//               },
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshotJpeg_1.jpg'
//               },
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshot_2.jpg'
//               },
//               {
//                 width: 480,
//                 height: 480,
//                 mimeType: 'image/jpeg',
//                 url: 'http://mc.a1-systems.com/ftp_public/game/575/2343575screenshotJpeg_2.jpg'
//               }
//             ],
//             contentMappings: [{ contentSource: 'MOBILE_CONTENT', externalContentId: '2343575' }]
//           },
//           type: 'GAME_HTML5',
//           imageUrl: 'http://mc.a1-systems.com/ftp_public/game/575/2343575preview320.jpg',
//           free: true,
//           new: false
//         }
//       },
//       {
//         name: 'Приз за олимпиаду 5',
//         minPercent: 60,
//         content: {
//           id: 'VIDEO_742_2319604',
//           text: '',
//           url:
//             'http://a1systemsmedia-euwe.streaming.media.azure.net/2b0de8cb-8e68-4afd-ae17-a27a93674900/manualconvert_autoconvert_pgravi.ism/manifest',
//           name: 'Гравити Фолз. Сезон 1. Серия 1. Секреты Гравити Фолз',
//           description: null,
//           data: {
//             format: [],
//             preview: {
//               width: 320,
//               height: 180,
//               mimeType: 'image/jpeg',
//               url: 'http://mc.a1-systems.com/ftp_public/video/604/2319604preview320.jpg'
//             },
//             cover: null,
//             contentMappings: [
//               { contentSource: 'MOBILE_CONTENT', externalContentId: '2319604' },
//               { contentSource: 'MPP', externalContentId: '9447' }
//             ],
//             artist: null
//           },
//           type: 'VIDEO',
//           imageUrl: 'http://mc.a1-systems.com/ftp_public/video/604/2319604preview320.jpg',
//           free: false,
//           new: false
//         }
//       },
//       {
//         name: 'Приз за олимпиаду 6',
//         minPercent: 60,
//         content: {
//           id: 'BOOK_1104_2345956',
//           text: null,
//           url: 'https://mc.a1-systems.com/contentForFun/2345956?sectionId=1382',
//           name: 'Аленький цветочек',
//           description:
// tslint:disable-next-line: max-line-length
//             '«В некиим царстве, в некиим государстве жил-был богатый купец, именитый человек. Много у него было всякого богатства, дорогих товаров заморских, жемчугу, драгоценных камениев, золотой и серебряной казны и было у того купца три дочери, все три красавицы писаные, а меньшая лучше всех...»',
//           data: {
//             artist: 'Аксаков Сергей Тимофеевич',
//             format: [{ mimeType: 'application/pdf', width: null, height: null, size: '427739' }],
//             preview: {
//               width: 235,
//               height: 320,
//               mimeType: 'image/jpeg',
//               url: 'http://mc.a1-systems.com/ftp_public/book/956/2345956preview320.jpg'
//             },
//             contentMappings: [{ contentSource: 'MOBILE_CONTENT', externalContentId: '2345956' }]
//           },
//           type: 'BOOK',
//           imageUrl: 'http://mc.a1-systems.com/ftp_public/book/956/2345956preview320.jpg',
//           free: true,
//           new: false
//         }
//       }
//     ]
//   };
//   return [200, data];
// });
