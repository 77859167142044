import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useLastLocation } from 'react-router-last-location';

import trimStart from 'lodash/trimStart';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import flow from 'lodash/flow';
import get from 'lodash/get';

import { CONTENT_ITEM, CONTENT_LIST, PROFILE } from 'app/configs/route.names';
import { EContentType, IContentItem } from 'app/models/model.content';
import { ELoadStatus, IRouteProps } from 'app/models/shared.model';

import { ContainerContentItem, IContainerContentItemProps } from 'app/containers/container.content.item';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';

import ContentMediaVideo from 'app/pages/mobile/content/item/video/content.video.index';
import ContentItemBook from 'app/pages/mobile/content/item/book/content.item.book';
import { ContentItemStudy } from 'app/pages/mobile/content/item/study/content.item.study';
import ContentItemArticle from 'app/pages/mobile/content/item/article/content.item.article';

import { ESubscriptionStatus } from 'app/models/model.subscription';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerLike, IContainerLikeProps } from 'app/containers/like.container';

import Cookies from 'universal-cookie';
import ContentItemAudio from './audio/content.item.audio.player';
import axios from 'axios';
import subscribeCheck from 'app/utils/util.subscribe.check';
const cookies = new Cookies();

export interface IPageContentItem extends IRouteProps {
  containerContentItem: IContainerContentItemProps;
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  containerSectionItem: IContainerSectionItemProps;
  containerLike: IContainerLikeProps;
  modalsContainer: IModalsContainer;
}

const PageContentItemFC: FC<IPageContentItem> = props => {
  const params = useParams<any>();
  const [targetId, setTargetId] = useState<IContentItem['item']['id']>();
  const contentType: EContentType = ((params?.contentType?.toUpperCase() as unknown) as EContentType) ?? null;
  const cookiePrevRoute = contentType && cookies.get(`contentItemPrevRoute${contentType}`);

  const categoryId = props.containerContentItem.section?.subcategory?.categoryId ?? props.containerSectionItem.subcategory?.categoryId;
  const subcategoryId = props.containerContentItem.section?.subcategory?.id ?? props.containerSectionItem.subcategory?.id;
  const sectionId = props.containerContentItem.section?.section?.parentId ?? props.containerContentItem.section?.section?.id;

  const history = useHistory();
  const location = useLocation<any>();
  const lastLocation = useLastLocation();

  useEffect(() => {
    if (params?.contentId) {
      setTargetId(params?.contentId);
    }
  }, [params]);

  useEffect(() => {
    props.modalsContainer.actions.closeModal();
  }, [history.location]);

  useEffect(() => {
    if (targetId) {
      props.containerContentItem.actions.fetchContentItem(targetId);
      props.containerContentItem.actions.logView();
    }
    return () => {
      props.containerContentItem.actions.resetContentItem();
    };
  }, [targetId, props.containerSubscribe.status.change]);

  useEffect(() => {
    if (lastLocation && toUpper(lastLocation?.pathname)?.includes(toUpper(PROFILE))) {
      props.containerContentItem.actions.updateRoteItem(PROFILE);
    } else if (
      lastLocation &&
      includes([EContentType.BOOK, EContentType.GAME_HTML5, EContentType.VIDEO], contentType) &&
      toUpper(lastLocation?.pathname)?.includes(EContentType.OLYMPIAD) &&
      lastLocation.search?.includes('step=3')
    ) {
      props.containerContentItem.actions.updateRoteItem(
        `${lastLocation.pathname}${lastLocation.search?.length > 0 ? '?' + trimStart(lastLocation.search, '?') : ''}`
      );
    } else if (props.location.search.includes('login=success')) {
      props.containerContentItem.actions.updateRoteItem(cookiePrevRoute || '/');
      cookies.set(`contentItemPrevRoute${contentType}`, '', { path: '/' });
    } else if (lastLocation && (lastLocation.pathname === '/' || lastLocation.pathname.includes('content/list'))) {
      props.containerContentItem.actions.updateRoteItem(
        `${lastLocation.pathname}${lastLocation.search?.length > 0 ? '?' + trimStart(lastLocation.search, '?') : ''}`
      );
    }
  }, [props.history, lastLocation]);

  const subscribe = props.containerSubscribe?.subscriptions?.find(sub => sub.id === props.containerContentItem?.section?.subscribe?.id);
  const propsAuth = props.containerUser;
  const isFreeItem = get(props.containerContentItem, 'item.free') as IContainerContentItemProps['item']['free'];
  const ready = props.containerContentItem.status.item === ELoadStatus.ready;

  const onArticleLinkClick = async e => {
    e.preventDefault();
    if (e.target.tagName === 'A') {
      const arr = e.target.href.split('/');
      const id = arr[arr.length - 1];
      if (subscribe.status === ESubscriptionStatus.ACTIVE) {
        history.push(CONTENT_ITEM(EContentType.ARTICLE, id));
      } else {
        const payload = await axios.get(`/api/content/get?contentId=${id}`);
        if (payload?.data?.content?.free) {
          history.push(CONTENT_ITEM(EContentType.ARTICLE, id));
        } else {
          subscribeCheck({
            subscribe,
            callback: () => {
              history.push(CONTENT_ITEM(EContentType.ARTICLE, id));
            },
            history,
            containerUser: propsAuth,
            containerModals: props.modalsContainer
          });
        }
      }
    }
  };
    const lastUrl = props.containerContentItem.route?.length > 0 ? props.containerContentItem.route : undefined;
    const backUrl = lastUrl ?? (categoryId && subcategoryId && sectionId ? CONTENT_LIST(categoryId, sectionId) : '/');

  switch (contentType) {
    case EContentType.BOOK:
      return <ContentItemBook { ...props } prevRoute={ props.containerContentItem.route } />;
    case EContentType.STUDY:
      return <ContentItemStudy { ...props } />;
    case EContentType.ARTICLE:
      return <ContentItemArticle { ...props } onArticleLinkClick={ onArticleLinkClick }/>;
    case EContentType.VIDEO:
      return (
        <ContentMediaVideo
          { ...props }
          prevRoute={ props.containerContentItem.route }
          backUrl={ backUrl }
        />
        );
    case EContentType.AUDIO:
      return <ContentItemAudio { ...props } backUrl={ backUrl } />;
    default:
      return null;
  }
};

export default flow([ContainerUser, ContainerSubscribe, ContainerContentItem, ContainerSectionItem, ContainerLike, ModalsContainer])(PageContentItemFC);
