export interface IDeepMapTargetList<T, S, E> {
  targetId?: string | number;
  id?: number;
  items?: T[];
  status: S;
  error: E;
  more: boolean;
}

export interface IDeepMapTarget<T, S, E> {
  list: Array<IDeepMapTargetList<T, S, E>>;
  state: Array<IDeepMapTargetList<T, S, E>>;
  setState: (map: Array<IDeepMapTargetList<T, S, E>>) => void;
}

export function deepMapTarget<T, S, E>(props: IDeepMapTarget<T, S, E>) {
  props.list?.length > 0 && props.list.forEach(li => {
    if (props.state?.length > 0) {
      const currentSections = props.state.find(si => si.targetId === li.targetId);
      props.setState(currentSections
        ?
        [
          // @ts-ignore
          ...props.state.filter(si => si.targetId !== currentSections.targetId),
          {
            id: li.id,
            targetId: li.targetId,
            items: li.items,
            status: li.status,
            error: li.error,
            more: li.more
          }
        ]
        :
        [
          ...props.state,
          {
            id: li.id,
            targetId: li.targetId,
            items: li.items,
            status: li.status,
            error: li.error,
            more: li.more
          }
        ]
      );
    } else {
      props.setState(
        [
          {
            id: li.id,
            targetId: li.targetId,
            items: li.items,
            status: li.status,
            error: li.error,
            more: li.more
          }
        ]
      );
    }
  });
}
