import React, { FC, Suspense } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import flow from 'lodash/flow';

import toastify from 'app/configs/toastify.async';

import { IAuthContainerProps, ContainerUser } from 'app/containers/container.user';
import { IRouteProps } from 'app/models/shared.model';
import { stylesApp } from 'app/app';

import CircularLoader from 'app/components/loader/loader.circular';

export const stylesLayout = makeStyles(
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      margin: 'auto',
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column'
    },
    appRoot: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  }),
  { name: 'LayoutMedia' }
);

interface IMediaLayoutProps extends IRouteProps {
  containerUser: IAuthContainerProps;
}

const MediaLayoutFC: FC<IMediaLayoutProps> = props => {
  const { children, ...other } = props;

  const styles = stylesLayout();
  const classesApp = stylesApp();

  const { ToastContainer } = toastify;

  return (
    <div className={ styles.appRoot }>
      <ToastContainer style={ { width: '50%' } } position={ 'bottom-center' } />
      <div className={ styles.root }>
        <Suspense
          fallback={
            <div className={ classesApp.suspendLoader }>
              <CircularLoader />
            </div>
          }
        >
          { children }
        </Suspense>
      </div>
    </div>
  );
};

export const DesktopMediaLayout = flow([ContainerUser])(MediaLayoutFC);
