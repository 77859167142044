import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { CONTENT_ACTIONS } from 'app/entities/content/content.actions';

import { ELoadStatus } from 'app/models/shared.model';
import { IContent } from 'app/models/model.content';
import { ISectionItem } from 'app/models/model.section';

export interface IContentListMainInitialState {
  items?: IContent[];
  targetId?: ISectionItem['id'];
  status: ELoadStatus;
  error: Error;
  more: boolean;
}

const initialParams: IContentListMainInitialState = {
  items: [],
  targetId: null,
  status: ELoadStatus.not_ready,
  error: null,
  more: false
};

const initialState = {
  list: [] as IContentListMainInitialState[]
};

export type IContentListMainState = Readonly<typeof initialState>;

export default (state = initialState, action): IContentListMainState => {
  const { type, payload, meta } = action;
  const more = meta?.props?.more ?? false;
  switch (type) {
    case REQUEST(CONTENT_ACTIONS.FETCH_MAIN):
      const dataRequest =
        state.list && state.list.length
          ? more
          ? state.list.map(i => {
            if (i.targetId === meta.props.targetId) {
              return {
                ...i,
                status: ELoadStatus.loading,
                error: initialParams.error
              };
            }
            return i;
          })
          : [
            ...state.list.filter(li => li.targetId !== meta.props.targetId),
            {
              items: [],
              targetId: meta.props.targetId,
              status: ELoadStatus.loading,
              error: initialParams.error,
              more: initialParams.more
            }
          ]
          : [
            {
              items: [],
              targetId: meta.props.targetId,
              status: ELoadStatus.loading,
              error: initialParams.error,
              more: initialParams.more
            }
          ];
      return {
        list: dataRequest
      };
    case SUCCESS(CONTENT_ACTIONS.FETCH_MAIN):
      const dataSuccess =
        state.list && state.list.length
          ? more
          ? state.list.map(i => {
            if (i.targetId === meta.props.targetId) {
              return {
                ...i,
                items: [...i.items, ...payload.data.content],
                status: ELoadStatus.ready,
                error: initialParams.error,
                more: payload.data.more ?? initialParams.more
              };
            }
            return i;
          })
          : [
            ...state.list.filter(li => li.targetId !== meta.props.targetId),
            {
              items: payload.data.content,
              targetId: meta.props.targetId,
              status: ELoadStatus.ready,
              error: initialParams.error,
              more: payload.data.more ?? initialParams.more
            }
          ]
          : [
            {
              items: payload.data.content,
              status: ELoadStatus.ready,
              error: initialParams.error,
              targetId: meta.props.targetId,
              more: payload.data.more ?? initialParams.more
            }
          ];
      return {
        list: dataSuccess
      };
    case FAILURE(CONTENT_ACTIONS.FETCH_MAIN):
      const dataFailure =
        state.list && state.list.length
          ? more
          ? state.list.map(i => {
            if (i.targetId === meta.props.targetId) {
              return {
                ...i,
                status: ELoadStatus.error,
                error: new Error('content list of main fetch failed'),
                more: initialParams.more
              };
            }
            return i;
          })
          : [
            ...state.list.filter(li => li.targetId !== meta.props.targetId),
            {
              items: [],
              targetId: meta.props.targetId,
              status: ELoadStatus.error,
              error: new Error('content list of main fetch failed'),
              more: initialParams.more
            }
          ]
          : [
            {
              items: [],
              targetId: meta.props.targetId,
              status: ELoadStatus.error,
              error: new Error('content list of main fetch failed'),
              more: initialParams.more
            }
          ];
      return {
        list: dataFailure
      };

    case CONTENT_ACTIONS.RESET_MAIN:
      return { list: [] };

    default:
      return state;
  }
};
