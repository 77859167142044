import React from 'react';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { ICategoryState } from 'app/entities/categories/category/entity.category.reducer';

import { fetchCategoryItems, fetchCategoryItem, resetCategoryItem } from 'app/entities/categories/category/entity.category.actions';

import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

export type ICategoryContainerProps = ICategoryState & {
  actions: {
    fetchCategoryItems: typeof fetchCategoryItems;
    fetchCategoryItem: typeof fetchCategoryItem;
    resetCategoryItem: typeof resetCategoryItem;
  }
};

const mapStateToProps = (state: IRootState) => state.category;
const mapDispatchToProps = (dispatch: Dispatch): ICategoryContainerProps['actions'] => ({
  fetchCategoryItems: () => dispatch(fetchCategoryItems()),
  fetchCategoryItem: id => dispatch(fetchCategoryItem(id)),
  resetCategoryItem: () => dispatch(resetCategoryItem())
});

export const CategoryContainer = connect<ICategoryContainerProps>('containerCategory', mapStateToProps, mapDispatchToProps);
