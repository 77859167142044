import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { CONTENT_ACTIONS } from 'app/entities/content/content.actions';
import { ELoadStatus } from 'app/models/shared.model';
import { IContent } from 'app/models/model.content';

import concat from 'lodash/fp/concat';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  items: [] as IContent[],
  itemLikes: [],
  subItems: [] as IContent[],
  sectionId: null,
  subsectionId: null,
  more: false,
  route: '',
  status: {
    likeStatus: ELoadStatus.not_ready,
    item: ELoadStatus.not_ready,
    items: ELoadStatus.not_ready,
    subItems: ELoadStatus.not_ready,
    contentInfo: ELoadStatus.not_ready
  },
  error: {
    likeError: null as Error,
    item: null as Error,
    items: null as Error,
    subItems: null as Error,
    contentInfo: null as Error
  }
};

export type IContentListState = Readonly<typeof initialState>;

export default (state = initialState, action): IContentListState => {
  const { type, payload, meta } = action;
  const { status, error } = state || initialState;
  const more = meta?.props?.more ?? false;
  const isSub = meta?.props?.isSub ?? false;
  const uncancelled = meta?.props?.uncancelled ?? false;
  const curItems = isSub ? 'subItems' : 'items';
  switch (type) {
    case REQUEST(CONTENT_ACTIONS.FETCH_LIST):
      return flow([
        set(`status.${curItems}`, ELoadStatus.loading),
        set(`error.${curItems}`, initialState.error[curItems])
      ])(state);
    case SUCCESS(CONTENT_ACTIONS.FETCH_LIST):
      if (payload?.canceled && uncancelled) return state;
      return flow([
        set(isSub ? 'subsectionId' : 'sectionId', payload?.data?.sectionId),
        set(`${curItems}`, more ? concat(state?.[curItems] ?? [], payload?.data?.content) : payload?.data?.content),
        set(`status.${curItems}`, payload?.canceled ? ELoadStatus.error : ELoadStatus.ready),
        set(`more`, Boolean(payload?.data?.more))
      ])(state);
    case FAILURE(CONTENT_ACTIONS.FETCH_LIST):
      return flow([
        set(`status.${curItems}`, ELoadStatus.error),
        set(`error.${curItems}`, new Error(`${curItems} fetch failed`))
      ])(state);

    case CONTENT_ACTIONS.UPDATE_ROUTE_LIST:
      return flow([
        set('route', meta.props.route)
      ])(state);

    case CONTENT_ACTIONS.RESET_LIST:
      return flow([
        set(`${curItems}`, initialState[curItems]),
        set('more', initialState.more),
        set(`error.${curItems}`, initialState.error[curItems]),
        set(`status.${curItems}`, initialState.status[curItems])
      ])(state);
    case REQUEST(CONTENT_ACTIONS.GET_ITEM_LIKES):
      return {
        ...state,
        status: {
          ...status,
          likeStatus: ELoadStatus.not_ready
        },
        error: {
          ...error,
          likeError: null
        }
      };
    case SUCCESS(CONTENT_ACTIONS.GET_ITEM_LIKES):
      return {
        ...state,
        itemLikes: payload,
        status: {
          ...status,
          likeStatus: ELoadStatus.ready
        },
        error: {
          ...error,
          likeError: null
        }
      };
    case FAILURE(CONTENT_ACTIONS.GET_ITEM_LIKES):
      return {
        ...state,
        status: {
          ...status,
          likeStatus: ELoadStatus.error
        },
        error: {
          ...error,
          likeError: payload.error
        }
      };
    default:
      return state;
  }
};
