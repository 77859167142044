//#region imports
import React, { FC, memo, useRef, useMemo, useCallback } from 'react';
import { useIsomorphicLayoutEffect, useGetSet } from 'react-use';
import MuiAudioPlayer from 'material-ui-audio-player';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import { isIOS } from 'mobile-device-detect';
import isElement from 'lodash/isElement';
import uniqueId from 'lodash/uniqueId';

import { MediaReadyState } from 'app/models/model.media';
import { AudioPlayerProps } from './audio.mobile.player.models';
import { useAudioPlayerMobileStyles } from './audio.mobile.player.styles';
//#endregion

const AudioPlayerMobile: FC<AudioPlayerProps> = memo<AudioPlayerProps>(props => {
  const { className, style, classes, id, src, icons, autoplay, preload } = props;
  const playerRef = useRef<HTMLDivElement>(null);

  const srcSet = useMemo(() => [src], [src]);
  const [isAutoplayComplete, setIsAutoplayComplete] = useGetSet(false);
  const [isPlaying, setIsPlaying] = useGetSet(false);
  const [haveEnoughData, setHaveEnoughData] = useGetSet(false);

  const [key, setKey] = useGetSet(uniqueId('audio.player.'));
  const useStyles = useCallback(() => useAudioPlayerMobileStyles({ classes, haveEnoughData: haveEnoughData() }), [classes, haveEnoughData()]);

  useIsomorphicLayoutEffect(() => {
    if (isElement(playerRef.current)) {
      const audioElement: HTMLAudioElement = playerRef.current?.querySelector('audio');
      if (isElement(audioElement)) {
        setKey(uniqueId('audio.player.'));
        if (autoplay && !isAutoplayComplete()) {
          audioElement.volume = 0;
        }

        let tid;
        const handleCanPlay = e => {
          if (tid) clearTimeout(tid);
          const target: HTMLAudioElement = e?.target;
          (function checkUpEnoughData() {
            tid = setTimeout(() => {
              if (
                ((isIOS && target?.readyState !== MediaReadyState.HAVE_NOTHING) ||
                  target?.readyState === MediaReadyState.HAVE_ENOUGH_DATA) &&
                target?.duration > 0
              ) {
                if (!haveEnoughData()) setHaveEnoughData(true);
                tid = setTimeout(async () => {
                  if (autoplay && !isAutoplayComplete()) {
                    try {
                      audioElement.volume = 1;
                      await audioElement.play();
                      setIsAutoplayComplete(true);
                    } catch {}
                  }
                }, 300);
                return;
              }
              checkUpEnoughData();
            }, 300);
          })();
        };
        audioElement.addEventListener('progress', handleCanPlay);
        return () => {
          if (tid) clearTimeout(tid);
          audioElement.removeEventListener('progress', handleCanPlay);
        };
      }
    }
  }, [srcSet, autoplay]);

  return (
    <div ref={ playerRef } className={ className } style={ style } id={ id }>
      <MuiAudioPlayer
        key={ key() }
        useStyles={ useStyles }
        icons={ icons ?? { PlayIcon: MdPlayArrow, PauseIcon: MdPause } }
        variation="default"
        order="reverse"
        preload={ preload as any }
        autoplay={ autoplay }
        download={ false }
        loop={ false }
        src={ srcSet }
        onPlayed={ e => {
          const audioElement: HTMLAudioElement = playerRef.current?.querySelector('audio');
          if (autoplay && !isAutoplayComplete()) {
            audioElement.volume = 1;
            setIsAutoplayComplete(true);
          }
          setIsPlaying(true);
        } }
        onPaused={ e => setIsPlaying(false) }
      />
    </div>
  );
});

export default AudioPlayerMobile;
