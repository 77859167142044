//#region imports
import React, { FC, forwardRef, useCallback } from 'react';
import isFunction from 'lodash/isFunction';

import { isModifiedEvent } from 'app/utils/util.event';

import { AnchorProps, CompatElement } from './follow-link.model';
//#endregion

const ExternalAnchor: FC<AnchorProps> = forwardRef<CompatElement, AnchorProps>(
  ({ node: Node, anchor, replace, target, href: url, onClick, onAfterClick, ...props }, ref) => {
    const navigate = useCallback(() => {
      const { location, open } = window;
      if (!target || target === '_self') {
        const redirect = replace ? location.replace.bind(location, url) : location.assign.bind(location, url);
        redirect();
      } else {
        open(url, target);
      }
    }, [target, url, replace]);

    const handleClick = event => {
      try {
        if (isFunction(onClick)) onClick(event, url);
      } catch (ex) {
        event.preventDefault();
        throw ex;
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();
        navigate();
      }

      if (isFunction(onAfterClick)) onAfterClick(event, url);
    };

    const attrs = anchor ? { tabIndex: 0, role: 'link', target, href: url } : { tabIndex: 0, role: 'link' };
    // should be ReactElement
    if (typeof Node === 'object') {
      return React.cloneElement(Node, { ref, ...attrs, ...props, 'data-url': url, onClick: handleClick });
    }
    return <Node ref={ ref } { ...attrs } { ...props } data-url={ url } onClick={ handleClick } />;
  }
);
ExternalAnchor.displayName = 'ExternalAnchor';

export default ExternalAnchor;
