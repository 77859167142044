import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { IModalProps, Modal } from 'app/components/modal/modal.ui';
import { Button } from 'app/components/button/button.ui';
import { useModalPackStyles } from 'app/components/modal/modal.styles';

import { EDialogConfirmType } from 'app/models/shared.model';

export interface IModalConfirmProps extends IModalProps {
  onConfirm: () => void;
  confirmType: EDialogConfirmType;
  name?: string;
  isReverseButtons?: boolean;
  isArchive?: boolean;
}

export const ModalConfirm: FC<IModalConfirmProps> = props => {
  const { mobile, onConfirm, onClose, name } = props;
  const { t } = useTranslation();
  const styles = useModalPackStyles({ mobile });

  const confirmType = props?.confirmType?.toLowerCase();

  return (
    <Modal
      { ...props }
      title={ <span className={ styles.title } dangerouslySetInnerHTML={ { __html: t(`dialog.confirm.${confirmType}.header`) } } /> }
      mobile={ mobile }
      withClose
      onClose={ onClose }
    >
      <span
        className={ styles.text }
        dangerouslySetInnerHTML={ {
          __html: t(`dialog.confirm.${confirmType}.${props.isArchive ? 'archive' : 'text'}`, { name: name || '' })
        } }
      />
      <Grid
        className={ styles.buttonWrap }
        container
        direction={ mobile ? 'column' : 'row' }
        justifyContent={ mobile ? 'center' : 'flex-start' }
        alignItems="center"
      >
        { props.isReverseButtons ? (
          <>
            <Button onClick={ onConfirm } style={ { marginRight: mobile ? '0' : 36 } } className={ styles.confirmButton } mobile={ mobile }>
              { t(`dialog.confirm.${confirmType}.deactivate`) }
            </Button>
            <Button
              onClick={ onClose }
              style={ { marginTop: mobile ? 18 : 0 } }
              className={ styles.confirmButton }
              mobile={ mobile }
              type="secondary"
            >
              { t(`dialog.confirm.${confirmType}.close`) }
            </Button>
          </>
        ) : (
          <>
            <Button onClick={ onClose } style={ { marginRight: mobile ? '0' : 36 } } className={ styles.confirmButton } mobile={ mobile }>
              { t(`dialog.confirm.${confirmType}.close`) }
            </Button>
            <Button
              onClick={ onConfirm }
              style={ { marginTop: mobile ? 18 : 0 } }
              className={ styles.confirmButton }
              mobile={ mobile }
              type="secondary"
            >
              { t(`dialog.confirm.${confirmType}.deactivate`) }
            </Button>
          </>
        ) }
      </Grid>
    </Modal>
  );
};
