import { IRootState } from 'app/shared/reducers/root.reducer';
import { IErrorState, resetError, updateError } from 'app/shared/reducers/error.reducer';
import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

export type IServerErrorContainer = IErrorState & {
  actions: {
    resetError: typeof resetError,
    updateError: typeof updateError
  }
};

const mapStateToProps = (state: IRootState) => state.serverError;
const mapDispatchToProps = (dispatch: Dispatch): IServerErrorContainer['actions'] => ({
  resetError: () => dispatch(resetError()),
  updateError: payload => dispatch(updateError(payload))
});

export const ErrorServerContainer = connect<IServerErrorContainer>('errorServerContainer', mapStateToProps, mapDispatchToProps);
