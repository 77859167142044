import { createStyles, makeStyles } from '@material-ui/core/styles';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesComponentError = makeStyles(
  createStyles({
    wrapper: {
      paddingTop: 35
    },
    title: {
      display: 'block'
    },
    desc: {
      display: 'block'
    },
    img: {
      display: 'block',
      margin: 'auto'
    },
    contentWrap: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 25,
      textAlign: 'center',
      backgroundColor: ui.colors.background.neutral.default,

      [`$wrapper:not($mobile) &`]: { maxWidth: 622, margin: '0 auto' }
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    item: {
      paddingTop: 10
    },
    link: {
      width: 'max-content',
      margin: 'auto',
      color: ui.colors.text.dark.default,
      border: `2px solid ${ui.colors.text.dark.default}`,
      cursor: 'pointer',
      borderRadius: 24,
      padding: '12px 19px',
      fontWeight: 'bold',
      [`$wrapper:not($mobile) &`]: {  padding: '18px 29px', borderRadius: 30 }
    },
    mobile: {}
  })
);
