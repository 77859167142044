import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ButtonMaterial from '@material-ui/core/Button';
import { Checkbox, CheckboxProps, FormControlLabel, FormGroup, Grid, Modal, withStyles } from '@material-ui/core';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { CustomTextField } from 'app/components/custom.input/custom.input';
import CloseIcon from 'app/assets/img/close.svg';
import RadioOnBtn from 'app/assets/img/radio.on.svg';
import RadioOffBtn from 'app/assets/img/hide.radio.svg';

import { useStylesModalParameters } from './modal.parameters.style';

import { ESex, ETarget, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { ELoadStatus } from 'app/models/shared.model';
import { CustomAutocomplete } from 'app/components/autocomplete/autocomplete';

interface IModalParametersProps {
  setShowModal: (boolean: boolean) => void;
  isShowModal: boolean;
  propsState: any;
  save: (data: IUserAttributes) => void;
  loading?: ELoadStatus;
}

const GreenCheckbox = withStyles({
  root: {
    color: '#8F9399',
    '&$checked': {
      color: ' #1F2229'
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" { ...props } />);

export const ModalParameters: FC<IModalParametersProps> = ({ isShowModal, setShowModal, propsState, save, loading }) => {
  const { t } = useTranslation();
  const options = [
    { id: ETarget.WEIGHT_DOWN, title: `${t('modalCalcProgram.loseWeight')}` },
    { id: ETarget.WEIGHT_UP, title: `${t('modalCalcProgram.toGainWeight')}` },
    { id: ETarget.WEIGHT_KEEP, title: `${t('modalCalcProgram.maintainWeight')}` }
  ];

  const [valueAutocomplete, setValueAutocomplete] = useState(options[0]);
  const [errorWeight, setErrorWeight] = useState(false);
  const [errorHeight, setErrorHeight] = useState(false);
  const [errorAge, setErrorAge] = useState(false);
  const [inputValueTextField, setInputValueTextField] = useState({
    height: '',
    weight: '',
    age: '',
    target: null
  });
  const [state, setState] = useState({
    breastFeeding: false
  });
  const [isSwitchWomenMen, setSwitchWomenMen] = useState(ESex.FEMALE);
  const styles = useStylesModalParameters({ isSwitchWomenMen });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ breastFeeding: event.target.checked });
  };

  useEffect(() => {
    setInputValueTextField(prevState => ({
      ...prevState,
      target: valueAutocomplete.id
    }));
  }, [valueAutocomplete]);

  useEffect(() => {
    if (isSwitchWomenMen === ESex.MALE) {
      setState({ breastFeeding: false });
    }
  }, [isSwitchWomenMen]);

  const handleSave = async () => {
    if (errorWeight || errorHeight || errorAge) {
      return;
    }
    const { height, weight, age, target } = inputValueTextField;
    await save({
      height: !!height ? Number(height) : null,
      weight: !!weight ? Number(weight) : null,
      age: !!age ? Number(age) : null,
      sex: isSwitchWomenMen,
      target,
      ...state
    });
    if (loading === ELoadStatus.ready) {
      setShowModal(false);
    }
  };

  const validateForm = (inputValidateItem, stateValidate, min, max) => {
    if (inputValidateItem < min || inputValidateItem > max) {
      stateValidate(true);
    } else {
      stateValidate(false);
    }

    if (inputValidateItem === '' || inputValidateItem === null) {
      stateValidate(false);
    }
  };

  useEffect(() => {
    validateForm(inputValueTextField.height, setErrorHeight, 100, 250);
  }, [inputValueTextField.height]);

  useEffect(() => {
    validateForm(inputValueTextField.weight, setErrorWeight, 20, 300);
  }, [inputValueTextField.weight]);

  useEffect(() => {
    validateForm(inputValueTextField.age, setErrorAge, 14, 114);
  }, [inputValueTextField.age]);

  useEffect(() => {
    if (propsState !== null && Object.keys(propsState).length !== 0) {
      const { height, weight, age, sex, breastFeeding, target } = propsState;

      setInputValueTextField({
        height: height === null ? '' : String(height),
        weight: weight === null ? '' : String(weight),
        age: age === null ? '' : String(age),
        target: target ? options.find(el => el.id === target)?.id : options[0]?.id
      });
      setValueAutocomplete(target ? options.find(el => el.id === target) : options[0]);
      setSwitchWomenMen(sex === null ? ESex.FEMALE : sex);
      setState({ breastFeeding });
    }
  }, [propsState]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        open={ isShowModal }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={ { minHeight: '100vh' } }
      >
        <div className={ styles.wrapper }>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={ { marginTop: '36px', paddingLeft: '18px', paddingRight: '18px' } }
          >
            <Typography type="heading" className={ styles.title } level={ 2 }>{ t('modalCalcProgram.myParameters') }</Typography>
            <img src={ CloseIcon } alt="close modal" onClick={ () => setShowModal(false) } />
            <Grid container direction="row" justifyContent="center" alignItems="center" style={ { marginTop: '27px', color: '#1F2229' } }>
              { t('modalCalcProgram.yourParametersForCalculation') }
            </Grid>
            <form noValidate className={ styles.formWrapper }>
              <CustomTextField
                type="number"
                style={ { marginBottom: '18px', width: '100%', marginTop: '27px' } }
                label={ t('calc.height') }
                value={ inputValueTextField.height }
                onChange={ e => setInputValueTextField({ ...inputValueTextField, height: e.target.value }) }
                variant="filled"
                id="height"
                error={ errorHeight }
                helperText={ errorHeight && t('modalCalcProgram.checkValidData') }
                onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                inputProps={ { pattern: '[0-9]*' } }
              />
              <CustomTextField
                type="number"
                style={ { marginBottom: '18px', width: '100%' } }
                label={ t('calc.weight') }
                value={ inputValueTextField.weight }
                onChange={ e => setInputValueTextField({ ...inputValueTextField, weight: e.target.value }) }
                variant="filled"
                id="weight"
                error={ errorWeight }
                helperText={ errorWeight && t('modalCalcProgram.checkValidData') }
                onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                inputProps={ { pattern: '[0-9]*' } }
              />
              <CustomTextField
                type="number"
                style={ { marginBottom: '18px', width: '100%' } }
                label={ t('modalCalcProgram.age') }
                value={ inputValueTextField.age }
                onChange={ e => setInputValueTextField({ ...inputValueTextField, age: e.target.value }) }
                variant="filled"
                id="age"
                error={ errorAge }
                helperText={ errorAge && t('modalCalcProgram.checkValidData') }
                onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                inputProps={ { pattern: '[0-9]*' } }
              />
              <CustomAutocomplete
                value={ valueAutocomplete }
                options={ options }
                id="controllable-states"
                onChange={ setValueAutocomplete }
                style={ { width: '100%' } }
              />
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={ { marginTop: '27px' } }>
                <ButtonMaterial
                  variant="outlined"
                  className={ classnames(styles.btn, {
                    activeBtn: isSwitchWomenMen === ESex.FEMALE
                  }) }
                  endIcon={ isSwitchWomenMen === ESex.FEMALE ? <img src={ RadioOnBtn } alt="women" /> : <img src={ RadioOffBtn } alt="men" /> }
                  onClick={ () => setSwitchWomenMen(ESex.FEMALE) }
                >
                  { t('modalCalcProgram.woman') }
                </ButtonMaterial>
                <ButtonMaterial
                  variant="outlined"
                  className={ classnames(styles.btn, {
                    activeBtn: isSwitchWomenMen !== ESex.FEMALE
                  }) }
                  endIcon={ isSwitchWomenMen !== ESex.FEMALE ? <img src={ RadioOnBtn } alt="women" /> : <img src={ RadioOffBtn } alt="men" /> }
                  onClick={ () => setSwitchWomenMen(ESex.MALE) }
                >
                  { t('modalCalcProgram.man') }
                </ButtonMaterial>
              </Grid>

              { isSwitchWomenMen === ESex.FEMALE &&
                <FormGroup row style={ { marginTop: '10px' } }>
                  <FormControlLabel
                    classes={ { label: styles.checkBoxLabel } }
                    control={ <GreenCheckbox name={ t('calc.breastFeeding') } checked={ state.breastFeeding } onChange={ handleChange } /> }
                    label={ t('calc.breastFeeding') }
                    style={ isSwitchWomenMen === ESex.FEMALE ? { opacity: '1' } : { opacity: '0', cursor: 'default' } }
                  />
                </FormGroup>
              }

              <Grid container direction="row" justifyContent="space-around" alignItems="center" style={ { marginTop: '37px' } }>
                <Button className={ styles.btnSave } onClick={ handleSave }>
                  { t('modalCalcProgram.saveParameters') }
                </Button>
                <Button className={ styles.btnCancel } onClick={ handleCloseModal }>
                  { t('modalCalcProgram.cancel') }
                </Button>
              </Grid>
            </form>
            <Typography type="text" tag="p" className={ styles.description } size="small">{ t('modalCalcProgram.programDescription') }</Typography>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
