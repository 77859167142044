//#region imports
import React, { RefObject } from 'react';
import ReactResizeDetector from 'react-resize-detector';
//#endregion

//#region VirtualAutoSize
const VirtualAutoSize = props => {
  const { handleWidth, handleHeight, onResize, children } = props;
  if (!handleWidth && !handleHeight) return children;
  return (
    <ReactResizeDetector refreshMode="debounce" refreshRate={ 100 } handleWidth={ handleWidth } handleHeight={ handleHeight } onResize={ onResize }>
      { ({ targetRef }: { targetRef: RefObject<HTMLDivElement> }) => <div style={ { height: '100%' } } ref={ targetRef }>{ children }</div> }
    </ReactResizeDetector>
  );
};
VirtualAutoSize.displayName = 'VirtualAutoSize';
//#endregion

export default VirtualAutoSize;
