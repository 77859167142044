import React from 'react';
import { IRootState } from 'app/shared/reducers/root.reducer';
import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';
import { IUserState } from 'app/entities/user/user.reducer';
import { resetProp, checkUser } from 'app/entities/user/user.actions';

export type IAuthContainerProps = IUserState & {
  actions: {
    resetProp: typeof resetProp;
    checkUser: typeof checkUser;
  };
};

const mapStateToProps = (state: IRootState) => state.user;

const mapDispatchToProps = (dispatch: Dispatch): IAuthContainerProps['actions'] => ({
  resetProp: prop => dispatch(resetProp(prop)),
  checkUser: () => dispatch(checkUser())
});

export const ContainerUser = connect<IAuthContainerProps>('containerUser', mapStateToProps, mapDispatchToProps);
