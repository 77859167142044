import React, { FC, memo, useRef, useEffect, useMemo, useState, useCallback } from 'react';
import { useDefault } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import flow from 'lodash/flow';
import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';

import { ContainerContentItem, IContainerContentItemProps } from 'app/containers/container.content.item';
import { ContainerContentList, IContainerContentListProps } from 'app/containers/container.content.list';

import { EContentType, IContent } from 'app/models/model.content';
import { ISection } from 'app/models/model.section';
import { ContentImage, stylesContentImage } from 'app/components/image/content.image';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { Button } from 'app/components/button/button.ui';
import { stylesApp } from 'app/app';
import { ContentHeader } from 'app/components/header/content.header';

import { percent2Score } from 'app/utils/util.study';
import { IPagerContainerProps, PagerContainer } from 'app/containers/container.pager';
import { ELoadStatus } from 'app/models/shared.model';
import { ESubscribeStatus, ESubscriptionStatus } from 'app/models/model.subscription';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { useHistory } from 'react-router';
import { SubscribeModal } from 'app/components/modal/subscribe.modal';
import { ModalNotification } from 'app/components/modal/modal.notification';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerGame, IContainerGameProps } from 'app/containers/container.game';
import { IOver } from 'app/entities/game/game.reducer';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { ModalGame } from 'app/components/modal/modal.game';
import { CONTENT_ITEM, STUDY_RESULT } from 'app/configs/route.names';
import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { ImageFallback } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import { useContentItemStyles } from 'app/pages/mobile/content/item/page.content.item.styles';
import useLimiter from 'app/hooks/useLimiter';

//#region StudyResult
interface IStudyResultProps {
  className?: string;
  percent: number;
  correct: number;
  total: number;
}

const useStudyResultStyles = makeStyles(
  createStyles({
    root: {},
    title: { marginTop: 0, marginBottom: configTheme.indents['indentX1'].margin },
    message: { color: '#2B313E', fonstSize: 16, lineHeight: '22px', marginBottom: configTheme.indents['indentX1'].margin },
    center: { display: 'flex', justifyContent: 'center' },
    stars: { fontSize: 36 }
  }),
  { name: 'StudyResult' }
);

const StudyResult = memo<IStudyResultProps>(props => {
  const { className, percent, correct, total } = props;
  const styles = useStudyResultStyles();
  const { t } = useTranslation();
  return (
    <div className={ cx(className, styles.root) }>
      <Typography className={ styles.title } type="heading" level={ 2 } mobile>
        { t(`study.ranks.${percent2Score(percent)}`) }
      </Typography>
      <div className={ styles.message }>{ t('study.result', { correct: correct ?? 0, total: total ?? 0 }) }</div>
      <div className={ styles.center }>
        <Rating className={ styles.stars } defaultValue={ 0 } value={ percent2Score(percent) } max={ 5 } size="large" readOnly />
      </div>
    </div>
  );
});
//#endregion StudyResult

export interface IContentItemStudyProps {
  section: ISection;
  containerContentList: IContainerContentListProps;
  containerContentItem: IContainerContentItemProps;
  containerSubscribe: IContainerSubscribeProps;
  containerUser: IAuthContainerProps;
  containerGame: IContainerGameProps;
  pagerContainer: IPagerContainerProps;
}

export const useContentItemStudyStyles = makeStyles(
  createStyles({
    wrap: {
      flex: '1 1',
      marginTop: 0,
      marginBottom: configTheme.indents['indentX1.5'].margin
    },
    center: { display: 'flex', justifyContent: 'center' },
    questionImage: { border: `1px solid ${configTheme.colors.border.secondary.default}` },
    ['b-result']: { marginBottom: configTheme.indents['indentX2'].margin },
    descItem: { margin: `${configTheme.indents['indentX1'].margin}px 0 ${configTheme.indents['indentX1.5'].margin}px` },
    contentImgWrap: { margin: `${configTheme.indents['indentX2'].margin}px 0 ${configTheme.indents['indentX1'].margin}px` },
    btnWrapper: { margin: `${configTheme.indents['indentX2'].margin}px 0 0` },
    imgWrapper: { marginTop: configTheme.indents['indentX2'].margin },
    answerList: { margin: `${configTheme.indents['indentX1.5'].margin}px 0 0` }
  }),
  { name: 'ContentItemStudy' }
);

const ContentItemStudyFC: FC<IContentItemStudyProps> = props => {
  const [subscribeOpenModal, setSubscribeOpenModal] = useState<boolean>(false);
  const [errorSubscribeOpenModal, setErrorSubscribeOpenModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<IContent>();
  const [isOpenModalGame, setIsOpenModelGame] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const [paging, pagingEmiter] = useLimiter();
  const scrollRef = useRef<HTMLDivElement>();

  const classesApp = stylesApp();
  const classesStudy = useContentItemStudyStyles();
  const styles = useContentItemStyles();
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });

  const itemId = get(currentItem, 'id');
  const propsSectionItem = get(props.containerContentItem, 'section') as IContainerContentItemProps['section'];
  const propsSubscribeError =
    (get(props.containerSubscribe, 'error.change') && get(props.containerSubscribe, 'error.change') === ESubscribeStatus.FAIL) ||
    get(props.containerSubscribe, 'error.change') === ESubscribeStatus.NO_MONEY;

  const contents = props.containerContentList.items;
  const loading = props.containerContentList.status.items === ELoadStatus.loading;
  const more = props.containerContentList.more;

  const displayRecommendList = useMemo(() => {
    return filter(contents, (c: IContent) => c?.id !== currentItem?.id);
  }, [currentItem?.id, contents]);

  useEffect(() => {
    if (propsSectionItem?.section?.id) {
      props.containerContentList.actions.fetchContentList({
        id: propsSectionItem.section.id,
        pager: paging.current(),
        cancellable: true
      });
      props.containerContentList.actions.logView();
    }
    return () => {
      props.containerContentList.actions.resetContentList();
    };
  }, [propsSectionItem?.section?.id]);

  const loadData = useCallback(() => {
    if (propsSectionItem?.section?.id) {
      pagingEmiter.invokeNext();
      props.containerContentList.actions.fetchContentList({
        id: propsSectionItem?.section?.id,
        pager: paging.current(),
        more,
        cancellable: true
      });
      props.containerContentList.actions.logView();
    }
  }, [propsSectionItem?.section?.id, more]);

  const handleClick = (type, id) => {
    history.push(CONTENT_ITEM(type, id));
  };

  const onEndModalGame = useCallback(() => {
    setIsOpenModelGame(isPrev => !isPrev);
    history.push(STUDY_RESULT(currentItem?.type.toLowerCase() as EContentType, currentItem?.id), { contentItem: currentItem });
  }, [currentItem]);

  const {
    actions: { startGame, resetGame },
    gameId,
    totalCount,
    correctCount,
    percent,
    isGameOver
  } = props.containerGame;

  const onCloseGame = useCallback(() => {
    resetGame();
    setIsOpenModelGame(isPrev => !isPrev);
    history.push(CONTENT_ITEM(currentItem?.type.toLowerCase() as EContentType, currentItem?.id), { contentItem: currentItem });
  }, [currentItem]);

  const fetchContentItem = id => {
    props.containerContentItem.actions.fetchContentItem(id);
    props.containerContentItem.actions.logView();
  };

  useEffect(() => {
    props.containerContentItem.item && setCurrentItem(props.containerContentItem.item);
  }, [props.containerContentItem.item]);

  useEffect(() => {
    return () => {
      setIsOpenModelGame(false);
      props.containerContentItem.actions.resetContentItem();
    };
  }, []);

  const [played, setPlayed] = useState<boolean>(false);
  const [lastResult, setLastResult] = useDefault<Pick<IOver, 'percent' | 'correctCount' | 'totalCount'>>(
    {
      percent: 0,
      correctCount: 0,
      totalCount: 0
    },
    undefined
  );

  const actionPlayDelayedRef = useRef<Function>();
  const handlePlay = async () => {
    if (!itemId) return;

    actionPlayDelayedRef.current = undefined;
    const action = async () => {
      await startGame(String(itemId));
      setPlayed(false);
      setLastResult(undefined);
      setIsOpenModelGame(true);
    };

    action();
  };

  useEffect(() => {
    if (isGameOver) setLastResult({ totalCount, correctCount, percent });
  }, [isGameOver, totalCount, correctCount, percent]);

  useEffect(() => {
    if (played) {
      setPlayed(false);
      setLastResult(undefined);
    }
  }, [itemId]);

  const renderRecommends = () => {
    return (
      <>
        { displayRecommendList?.length > 0 && (
          <div className={ styles.recommends }>
            <Typography className={ styles.recommendsTitle } type="heading" level={ 2 } tag="div" mobile>
              { t('common.recommend') }
            </Typography>
            <VirtualHorizontalList
              className={ styles.virtual }
              parentRef={ scrollRef }
              size={ displayRecommendList?.length }
              width={ 280 }
              spacing={ configTheme.indents['indentX1'].margin }
              infinite={ more }
              loading={ loading }
              onLoad={ loadData }
              fallbackHeight={ 266 }
              autoHeight
              renderItem={ memo<any>(({ index }) => {
                const c = displayRecommendList?.[index];
                return (
                  <Postcard
                    width={ 280 }
                    height={ 192 }
                    src={ c?.imageUrl }
                    fallback={ ImageFallback }
                    classes={ {
                      picture: styles.virtualPicture
                    } }
                    onClick={ () => handleClick(c?.type, c?.id) }
                  >
                    <Typography className={ ellipsisStyles[EEllipsisClassKey.line] } type="text" size="large" tag="div" style={ { marginTop: 9 } }>
                      { c?.name }
                    </Typography>
                  </Postcard>
                );
              }) }
              renderFallback={ memo<any>(VirtualFallback) }
            />
          </div>
        ) }
      </>
    );
  };

  const renderStudyDescr = () => {
    return (
      <>
        <ContentImage
          classesWrap={ classesStudy.wrap }
          key={ props.containerContentItem.item.id }
          src={ props.containerContentItem.item.imageUrl || props.containerContentItem.item.url }
          type={ EContentType.STUDY }
        />

        { props.containerContentItem.item.name && (
          <Typography type="heading" level={ 2 } mobile>
            { props.containerContentItem.item.name }
          </Typography>
        ) }

        <div className={ classesApp.descItem }>{ props.containerContentItem.item.description }</div>
      </>
    );
  };

  const renderStudy = () => {
    const displayQuestion = gameId && !isGameOver;

    return (
      <>
        <section className={ classesApp.sectionItem }>
          { !displayQuestion && played && (
            <StudyResult
              className={ classesStudy['b-result'] }
              percent={ lastResult?.percent }
              correct={ lastResult?.correctCount }
              total={ lastResult?.totalCount }
            />
          ) }
          { !displayQuestion && renderStudyDescr() }
          { isOpenModalGame && <ModalGame open={ isOpenModalGame } onClose={ onCloseGame } onEnd={ onEndModalGame } isNotFetch mobile /> }

          { !displayQuestion && (
            <div className={ cx(classesStudy.center, classesStudy.btnWrapper) }>
              <Button onClick={ () => handlePlay() }>{ played ? t('study.playAgain') : t('study.start') }</Button>
            </div>
          ) }
        </section>

        { renderRecommends() }
      </>
    );
  };

  return (
    <>
      <ContentHeader
        isClose
        closeHandler={ () => {
          const displayQuestion = gameId && !isGameOver;
          if (displayQuestion) {
            resetGame();
            return;
          } else if (!displayQuestion && played) {
            resetGame();
            history.length > 2 ? history.goBack() : history.push('/');
            return;
          }
          history.push(props.containerContentItem.route);
        } }
      >
        { props.containerContentItem.item.name }
      </ContentHeader>

      { renderStudy() }

      { props.containerContentItem.status.item === ELoadStatus.ready &&
      propsSectionItem?.subscribe &&
      propsSectionItem?.subscribe?.status === ESubscriptionStatus.NOT_ACTIVE && (
        <SubscribeModal
          open={
            props.containerSubscribe.error.change !== ESubscribeStatus.NO_MONEY &&
            props.containerSubscribe.error.change !== ESubscribeStatus.FAIL &&
            subscribeOpenModal
          }
          subscribe={ propsSectionItem.subscribe }
          title={ propsSectionItem?.subscribe?.name }
          onClose={ () => setSubscribeOpenModal(!subscribeOpenModal) }
          callbackOnActivated={ async () => {
            setSubscribeOpenModal(!subscribeOpenModal);
            await fetchContentItem(props.containerContentItem.item.id);
            if (isFunction(actionPlayDelayedRef.current)) actionPlayDelayedRef.current();
          } }
          mobile
        />
      ) }

      <ModalNotification
        open={ errorSubscribeOpenModal || propsSubscribeError }
        onClose={ () => {
          setErrorSubscribeOpenModal(false);
          if (propsSubscribeError) {
            props.containerSubscribe.actions.resetProp('change');
            setSubscribeOpenModal(false);
          }
        } }
        type={
          propsSubscribeError
            ? get(props.containerSubscribe, 'error.change')
            : propsSectionItem.subscribe && propsSectionItem.subscribe.status
        }
        mobile
      />
      { /* <ModalGame open={isOpenModalGame} onClose={toggleIsOpenModalGame} mobile /> */ }
    </>
  );
};

export const ContentItemStudy = flow([
  ContainerUser,
  ContainerGame,
  ContainerSubscribe,
  ContainerContentItem,
  ContainerContentList,
  PagerContainer
])(ContentItemStudyFC);
