import React, { FC } from 'react';
import classnames from 'classnames';

import { ITypographyLinkProps, TypographyType, TextSize } from './typography.models';
import { useTypographyTextStyles } from './typography.styles';

export const TypographyLink: FC<ITypographyLinkProps> = props => {
  const { className, style, size, href, attrs, mobile, children, onClick } = props;
  const styles = useTypographyTextStyles();

  return (
    <a
      className={ classnames(className, styles?.[`${TypographyType.text}-${size}`], { [styles.mobile]: mobile }) }
      style={ style }
      href={ href }
      { ...attrs }
      onClick={ onClick }
    >
      { children }
    </a>
  );
};

TypographyLink.defaultProps = {
  attrs: null,
  mobile: false,
  size: TextSize.default
} as ITypographyLinkProps;
