import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { DIET_INDIVIDUAL_PROGRAM_ACTIONS } from './diet.program.actions';

import { ELoadStatus } from 'app/models/shared.model';
import { ISubscription } from 'app/models/model.subscription';

export enum EResult {
  WEIGHT_DOWN_BETTER = 'WEIGHT_DOWN_BETTER',
  WEIGHT_DOWN_FAIL = 'WEIGHT_DOWN_FAIL',
  WEIGHT_DOWN_SUCCESS = 'WEIGHT_DOWN_SUCCESS',
  WEIGHT_DOWN_WRONG = 'WEIGHT_DOWN_WRONG',

  WEIGHT_KEEP_BETTER = 'WEIGHT_KEEP_BETTER',
  WEIGHT_KEEP_FAIL = 'WEIGHT_KEEP_FAIL',
  WEIGHT_KEEP_SUCCESS = 'WEIGHT_KEEP_SUCCESS',
  WEIGHT_KEEP_WRONG = 'WEIGHT_KEEP_WRONG',

  WEIGHT_UNKNOWN_DOWN = 'WEIGHT_UNKNOWN_DOWN',
  WEIGHT_UNKNOWN_KEEP = 'WEIGHT_UNKNOWN_KEEP',
  WEIGHT_UNKNOWN_UP = 'WEIGHT_UNKNOWN_UP',
  WEIGHT_UNKNOWN_WRONG = 'WEIGHT_UNKNOWN_WRONG',

  WEIGHT_UP_BETTER = 'WEIGHT_UP_BETTER',
  WEIGHT_UP_FAIL = 'WEIGHT_UP_FAIL',
  WEIGHT_UP_SUCCESS = 'WEIGHT_UP_SUCCESS',
  WEIGHT_UP_WRONG = 'WEIGHT_UP_WRONG'
}

export interface INextWeek {
  result: EResult;
  weight: number;
}

export enum ESex {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum ETarget {
  WEIGHT_DOWN = 'WEIGHT_DOWN',
  WEIGHT_UP = 'WEIGHT_UP',
  WEIGHT_KEEP = 'WEIGHT_KEEP'
}

export interface IUserAttributes {
  age: number;
  breastFeeding: boolean;
  height: number;
  sex: ESex;
  target: ETarget;
  weight: number;
}

const initialState = {
  itemUserAttributes: {} as IUserAttributes,
  itemsDay: [],
  dataDays: null,
  foodBasketText: null,
  replacementText: null,
  last: false,
  programStatus: {} as ISubscription,
  progressStatus: ELoadStatus.not_ready,
  errorStatus: null as Error
};

export type IDietIndividualProgramState = Readonly<typeof initialState>;

export default (state = initialState, action): IDietIndividualProgramState => {
  const { type, payload, meta } = action;
  switch (type) {
    case REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.GET):
      return {
        ...state,
        progressStatus: ELoadStatus.loading,
        errorStatus: null
      };
    case SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.GET):
      return {
        ...state,
        itemsDay: payload === null ? [] : payload.dietDays,
        foodBasketText: payload.foodBasketText,
        replacementText: payload.replacementText,
        last: payload.last,
        progressStatus: ELoadStatus.ready,
        errorStatus: null
      };
    case FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.GET):
      return {
        ...state,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };
    case REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_DAY):
      return {
        ...state,
        progressStatus: ELoadStatus.loading,
        errorStatus: null
      };
    case SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_DAY):
      return {
        ...state,
        dataDays: payload,
        progressStatus: ELoadStatus.ready,
        errorStatus: null
      };
    case FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_DAY):
      return {
        ...state,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };
    case REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_STATUS):
      return {
        ...state,
        progressStatus: ELoadStatus.loading,
        errorStatus: null
      };
    case SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_STATUS):
      return {
        ...state,
        programStatus: payload,
        progressStatus: ELoadStatus.ready,
        errorStatus: null
      };
    case FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_STATUS):
      return {
        ...state,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };
    case REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH):
      return {
        ...state,
        progressStatus: ELoadStatus.loading,
        errorStatus: null
      };

    case SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH):
      return {
        ...state,
        itemUserAttributes: payload,
        progressStatus: ELoadStatus.ready,
        errorStatus: null
      };

    case FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH):
      return {
        ...state,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };

    case REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.SAVE):
      return {
        ...state,
        errorStatus: null,
        progressStatus: ELoadStatus.loading
      };
    case SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.SAVE):
      return {
        ...state,
        itemUserAttributes: meta?.data,
        progressStatus: ELoadStatus.ready,
        errorStatus: null
      };
    case FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.SAVE):
      return {
        ...state,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };

    case REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.NEXT_WEEK):
      return {
        ...state,
        errorStatus: null,
        progressStatus: ELoadStatus.loading
      };
    case SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.NEXT_WEEK):
      return {
        ...state,
        itemUserAttributes: { ...state.itemUserAttributes, weight: meta?.weight || state.itemUserAttributes.weight },
        progressStatus: ELoadStatus.ready,
        errorStatus: null
      };
    case FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.NEXT_WEEK):
      return {
        ...state,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };

    case DIET_INDIVIDUAL_PROGRAM_ACTIONS.RESET:
      return {
        ...state,
        itemsDay: initialState.itemsDay,
        foodBasketText: initialState.foodBasketText,
        replacementText: initialState.replacementText,
        last: initialState.last,
        progressStatus: ELoadStatus.not_ready
      };

    default:
      return state;
  }
};
