import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesProgramLayout = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginLeft: '162px',
      marginTop: '54px',
      width: 'auto'
    },
    titleDay: {
      fontSize: '14px',
      color: ui.colors.text.dark.neutral,
      marginRight: '27px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    btnNextDay: {
      marginTop: '54px',
      marginBottom: '18px',
      minWidth: '276px'
    },
    setReminders: {
      marginBottom: '72px',
      backgroundColor: 'inherit',
      minWidth: '276px',
      color: 'inherit'
    },
    active: {
      color: ui.colors.text.dark.default
    }
  })
);
