import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { BASENAME } from 'app/configs/const';
import { Dispatch } from 'redux';

export const DIET_INDIVIDUAL_PROGRAM_ACTIONS = {
  GET: 'DIET_INDIVIDUAL_PROGRAM::GET',
  RESET: 'DIET_INDIVIDUAL_PROGRAM::RESET',
  FETCH_DAY: 'DIET_INDIVIDUAL_PROGRAM_DAY::FETCH',
  FETCH_STATUS: 'DIET_STATUS_ACTIONS::FETCH',
  FETCH: 'DIET_ACTIONS::FETCH',
  SAVE: 'DIET_ACTIONS::SAVE',
  NEXT_WEEK: 'DIET_INDIVIDUAL_PROGRAM::POST_NEXT_WEEK'
};

export const getDietIndividualProgram = () => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.GET) });
  try {
    const response = await axios.get(`${BASENAME}api/diet/get`);
    dispatch({
      type: SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.GET),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.GET),
      payload: error
    });
    return;
  }
};

export const getDietIndividualProgramDayById = (id: number) => async (dispatch: Dispatch) => {

  dispatch({ type: REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_DAY) });
  try {
    const response = await axios.get(`${BASENAME}api/dietDay/get?dietDayId=${id}`);
    dispatch({
      type: SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_DAY),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_DAY),
      payload: error
    });
    return;
  }
};

export const getDietStatus = () => async (dispatch: Dispatch) => {

  dispatch({ type: REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_STATUS) });
  try {
    const response = await axios.get(`${BASENAME}/api/diet/status`);
    dispatch({
      type: SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_STATUS),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH_STATUS),
      payload: error
    });
    return;
  }
};

export const fetchDietItem = () => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH) });
  try {
    const response = await axios.get(`${BASENAME}/api/userAttributes/get`);
    dispatch({
      type: SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.FETCH),
      payload: error
    });
    return;
  }
};

export const saveDietItem = userAttributesContainer => async (dispatch: Dispatch) => {

  dispatch({ type: REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.SAVE) });
  try {
    const response = await axios.post(`${BASENAME}/api/userAttributes/save`, { ...userAttributesContainer });
    dispatch({
      type: SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.SAVE),
      payload: response.data,
      meta: { data: userAttributesContainer }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.SAVE),
      payload: error
    });
    return;
  }
};

export const postNextWeek = weekFinishDTO => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(DIET_INDIVIDUAL_PROGRAM_ACTIONS.NEXT_WEEK) });
  try {
    const response = await axios.post(`${BASENAME}api/diet/next`, { ...weekFinishDTO });
    dispatch({
      type: SUCCESS(DIET_INDIVIDUAL_PROGRAM_ACTIONS.NEXT_WEEK),
      payload: response.data,
      meta: { weight: weekFinishDTO?.weight }
    });
  } catch (error) {
    dispatch({
      type: FAILURE(DIET_INDIVIDUAL_PROGRAM_ACTIONS.NEXT_WEEK),
      payload: error
    });
    return;
  }
};

export const resetDietIndividualProgram = () => ({
  type: DIET_INDIVIDUAL_PROGRAM_ACTIONS.RESET
});
