import axios from 'axios';
import get from 'lodash/fp/get';

import { SERVER_API_URL } from 'app/configs/const';
import { getMobileOperatingSystem } from 'app/utils/get.mobile.operating.system';

const TIMEOUT = 1000000; // 10000
const setupAxiosInterceptors = () => {
  const onRequestSuccess = config => {
    config.timeout = TIMEOUT;
    config.url = `${SERVER_API_URL}${config.url}`;
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || get('status', err.response);
    if (status === 403 || status === 401) {
      // onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.defaults.headers.common['os-type'] = getMobileOperatingSystem();
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
