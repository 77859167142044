import React, { memo, useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import debounce from 'lodash/debounce';
import each from 'lodash/each';

import { Box, Grid } from '@material-ui/core';

import AudioMobilePlayer from './audio.mobile.player/audio.mobile.player.index';
import { usePlayerStyles } from './content.item.audio';
import CloseIcon from 'app/assets/img/close.icon.svg';
import { useUserInteraction } from 'app/providers/user.interaction';

import { IContainerContentItemProps } from 'app/containers/container.content.item';
import { IContentItemProps } from 'app/pages/mobile/content/item/item.models';
import { ELoadStatus } from 'app/models/shared.model';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { getMimeTypes } from 'app/utils/get.mime.types';

export interface IContentItemAudioProps extends IContentItemProps {
  backUrl?: string;
}

const ContentItemAudio = memo<IContentItemAudioProps>(props => {
  const { containerContentItem, containerLike, containerSubscribe } = props;
  const styles = usePlayerStyles();
  const history = useHistory();
  const params = useParams<any>();
  const contentId = params?.contentId || 0;
  const status = containerContentItem?.status?.item;
  const ready = status === ELoadStatus.ready;
  const isFree = containerContentItem?.item?.free;
  const subscribe = containerContentItem?.section?.subscribe;
  const content = containerContentItem.item;

  useEffect(() => {
    if (status === ELoadStatus.ready) {
      if (!isFree && subscribe?.status !== ESubscriptionStatus.ACTIVE) {
        history.replace('/');
      }
    }
  }, [status]);

  useEffect(() => {
    if (contentId) {
      containerContentItem.actions.fetchContentItem(contentId, { cancellable: true });
      containerContentItem.actions.logView();
    }
    return () => {
      containerContentItem.actions.resetContentItem();
      containerLike.actions.resetLikeItems();
    };
  }, [contentId, containerSubscribe.subscriptions]);

  const userInteracted = useUserInteraction();

  const [availHeight, setAvailHeight] = useState<string | number>(window.innerHeight);
  useEffect(() => {
    const handler = debounce(() => {
      setAvailHeight(window.innerHeight);
      try {
        window.scroll({ top: 0, behavior: 'smooth' });
      } catch {
        window.scroll(0, 0);
      }
    }, 300);
    window.addEventListener('resize', handler);
    window.addEventListener('scroll', handler);
    return () => {
      handler.cancel();
      window.removeEventListener('resize', handler);
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const mimeTypes = useMemo(() => getMimeTypes(content?.data?.format), [content]);

  return (
    <>
      {
        ready && (isFree || subscribe?.status === ESubscriptionStatus.ACTIVE) && (
          <Box className={ styles.wrapperPlayerAudioBook } style={ { minHeight: availHeight, maxHeight: availHeight, height: availHeight } }>
            <Grid container direction="column" justifyContent="space-between" alignItems="center" style={ { minHeight: '100%' } }>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <i className={ styles.iconClose } role="img" onClick={ () => history.goBack() }/>
              </Grid>
              <img src={ content?.imageUrl } alt="Book" style={ { height: 'auto', width: '100%' } } />

              <AudioMobilePlayer
                className={ styles.player }
                id={ String(content?.id) }
                src={ content?.url && `${content?.url}?mimeType=${mimeTypes.audio}` }
                preload="auto"
                autoplay={ userInteracted }
              />
            </Grid>
          </Box>
        )
      }
    </>
  );
});

export default ContentItemAudio;
