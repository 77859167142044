import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesMobileProgramLayout = makeStyles((theme: Theme) =>
  createStyles({
    titleDay: {
      fontSize: '14px',
      height: '40px',
      color: ui.colors.text.dark.neutral,
      marginRight: '27px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    btnNextDay: {
      marginTop: '36px',
      marginBottom: '18px',
      minWidth: '276px'
    },
    setReminders: {
      marginBottom: '72px',
      backgroundColor: 'inherit',
      minWidth: '276px',
      color: 'inherit'
    },
    active: {
      color: ui.colors.text.dark.default
    }
  })
);
