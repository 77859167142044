import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'mobile-device-detect';
import { createStyles, makeStyles } from '@material-ui/core';
import { useToggle } from 'react-use';

import truncate from 'lodash/truncate';

import ListSvg from 'a1s-tele2-react-ui/src/assets/icons/paper.svg';

import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';
import { Typography } from 'app/components/desktop/typography/typography.index';

interface IProductsList {
  foodBasketText?: string;
  replacementText?: string;
}

export const useProductsListStyles = makeStyles<unknown, { mobile?: boolean }>(
  createStyles({
    wrapper: {
      width: ({ mobile }) => mobile ? '100%' : 742,
      margin: '36px auto 0px',
      marginBottom: 72
    },
    title: {
      display: 'inline-flex',
      marginBottom: 28
    },
    icon: {
      marginRight: 19
    },
    foodBasketBlock: {
      minHeight: 450,
      marginTop: 36,
      color: ui.colors.text.dark.default,
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.14)',
      padding: 36,
      borderRadius: ({ mobile }) => mobile ? 10 : 0,
      '& table': {
        width: '100%',
        '& tr': {
          '& td': {
            paddingBottom: 5,
            borderBottom: `1px solid ${ui.colors.background.dashboard.neutral}`,
            '&:last-child': {
              textAlign: 'end'
            }
          }
        }
      }
    },
    replacementText: {
      marginTop: 36,
      height: 'auto',
      color: ui.colors.text.dark.default,
      '& h1': {
        fontFamily: 'Tele2 Display',
        fontSize: ({ mobile }) => mobile ? 24 : 28,
        lineHeight: ({ mobile }) => mobile ? '28px' : '36px',
        marginTop: 0,
        marginBottom: ({ mobile }) => mobile ? 18 : 28
      }
    },
    toggleText: {
      cursor: 'pointer',
      borderBottom: '1px dashed'
    }
  }));

const ProductsList = memo<IProductsList>(props => {
  const { t } = useTranslation();
  const { foodBasketText, replacementText } = props;
  const [on, toggle] = useToggle(false);
  const styles = useProductsListStyles({ mobile: isMobile });

  const returnTruncateText = useMemo(() => {
    const text = replacementText;
    if (!on) {
      return truncate(text, {
        length: isMobile ? 400 : 850,
        omission: '...'
      });
    } else return text;
  }, [replacementText, isMobile, on]);

  return (
    <div className={ styles.wrapper }>
      <Typography type="text" level={ 2 } mobile={ isMobile } tag="p">
        { t('diet.products.desc.1') }
      </Typography>

      <Typography type="text" level={ 2 } mobile={ isMobile } tag="p">
        { t('diet.products.desc.2') }
      </Typography>

      <Typography type="text" level={ 2 } mobile={ isMobile } tag="p">
        <span dangerouslySetInnerHTML={ { __html: t('diet.products.desc.3') } }/>
      </Typography>

      <div className={ styles.foodBasketBlock }>

        <div className={ styles.title }>
          <img src={ ListSvg } alt="rocket" className={ styles.icon } />
          <Typography type="heading" level={ 2 } mobile={ isMobile }>
            { t('diet.products.title') }
          </Typography>
        </div>
        <div dangerouslySetInnerHTML={ { __html: foodBasketText } }/>

      </div>

      <div dangerouslySetInnerHTML={ { __html: returnTruncateText } } className={ styles.replacementText }/>

      <Typography type="text" className={ styles.toggleText } onClick={ toggle }>{ on ? t('diet.products.collapse') : t('diet.products.expand') }</Typography>
    </div>
  );
});
export default ProductsList;
