//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { EContentType } from 'app/models/model.content';

import { OFormat } from './item.card.models';
//#endregion

export const useItemCardStyles = makeStyles(
  createStyles<any, {}>({
    root: {
      [`&.${EContentType.GAME_ANDROID}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.GAME_HTML5}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.BOOK}.${OFormat.Fit}`]: {
        padding: configTheme.indents['indentX2'].margin + configTheme.indents['indentX1'].margin * 0.5,
        paddingRight: configTheme.indents['indentX2'].margin
      },
      [`&.${EContentType.BOOK}.${OFormat.Compact}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.AUDIO}`]: { padding: configTheme.indents['indentX1'].margin }
    },

    picture: {
      [`$root.${EContentType.BOOK} & img`]: { cursor: 'pointer' },
      [`$root.${EContentType.ARTICLE} & img`]: { cursor: 'pointer' },
      [`$root.${EContentType.VIDEO} &`]: { cursor: 'pointer' },
      [`$root.${EContentType.IMAGE} &`]: { cursor: 'pointer' },
      [`$root.${EContentType.STUDY} & img`]: { cursor: 'pointer' },
      [`$root.${EContentType.OLYMPIAD} &`]: { cursor: 'pointer' },
      [`$root.${EContentType.GAME_ANDROID} & img`]: { cursor: 'pointer', borderRadius: 10 },
      [`$root.${EContentType.GAME_HTML5} & img`]: { cursor: 'pointer', borderRadius: 10 },
      [`$root.${EContentType.AUDIO} & img`]: { borderRadius: 10 }
    },

    descr: {
      display: 'flex',
      flexFlow: 'column nowrap',
      minWidth: 0,
      maxWidth: '99.98%',

      [`$root.${EContentType.GAME_ANDROID} &`]: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${configTheme.indents['indentX2'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.GAME_HTML5} &`]: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${configTheme.indents['indentX2'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: {
        alignItems: 'flex-start',
        margin: 0,
        marginLeft: configTheme.indents['indentX2'].margin,
        padding: 0,
        '& > button:last-child': { marginTop: 'auto' }
      },
      [`$root.${EContentType.BOOK}.${OFormat.Compact} &`]: {
        padding: `${configTheme.indents['indentX1'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.AUDIO} &`]: {
        padding: `0 ${configTheme.indents['indentX1'].margin * 0.5}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.VIDEO} &`]: {
        padding: `${configTheme.indents['indentX1'].margin - 2}px ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.IMAGE} &`]: {
        justifyContent: 'center',
        padding: `${configTheme.indents['indentX1'].margin}px ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.STUDY} &`]: {
        padding: `${configTheme.indents['indentX1'].margin - 2}px ${configTheme.indents['indentX1'].margin}px`,
        justifyContent: 'center'
      },
      [`$root.${EContentType.OLYMPIAD} &`]: {
        justifyContent: 'center',
        padding: `${configTheme.indents['indentX1'].margin}px ${configTheme.indents['indentX1'].margin}px`
      }
    },

    title: {
      minWidth: 0,
      maxWidth: '99.98%',
      [`$root.${EContentType.ARTICLE}.${OFormat.Fit} &`]: { fontWeight: 700, lineHeight: '28px' },
      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: { fontWeight: 700 }
      // [`$root.${EContentType.BOOK} &`]: { minHeight: 'calc(2em * 1.273)' },
    },

    subtitle: {
      minWidth: 0,
      maxWidth: '99.98%',
      paddingTop: configTheme.indents['indentX1'].margin * 0.5,
      fontSize: 16,
      lineHeight: 1.375,
      color: configTheme.colors.text.light.neutral,

      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: { paddingTop: configTheme.indents['indentX1'].margin }
    },

    text: {
      fontSize: 16,
      lineHeight: 1.375,
      paddingTop: configTheme.indents['indentX1'].margin * 0.5,
      [`$root.${EContentType.ARTICLE}.${OFormat.Fit} &`]: { paddingTop: configTheme.indents['indentX1'].margin - 3 },
      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: { paddingTop: configTheme.indents['indentX1'].margin }
    },

    media: { paddingTop: configTheme.indents['indentX1'].margin * 0.5 },

    mediaPlayIcon: {
      // cursor: 'pointer',
      width: 72,
      height: 80,
      paddingLeft: 8,
      borderRadius: '50%',
      fontSize: 36,
      lineHeight: 2.727,
      textAlign: 'center',
      color: configTheme.colors.text.dark.default,
      background: configTheme.colors.background.light.neutral
    },

    pictureFigure: {}
  }),
  { name: 'ItemCard' }
);
