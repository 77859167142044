//#region imports
import React, { FC, memo, useEffect, useState, useCallback } from 'react';
import { useGetSet } from 'react-use';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { STUDY_RESULT, CONTENT_ITEM } from 'app/configs/route.names';
import { stylesApp } from 'app/app';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';

import { EContentType, OContentItemModalType, IContentItemProps } from 'app/models/model.content';

import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';
import { Typography } from 'app/components/desktop/typography/typography.index';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { ImageFallback, EPostcardClassKey, EPostcardLayout } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { ModalGame } from 'app/components/modal/modal.game';

import { useDesktopContentItemPageStyles } from '../page.content.item.styles';
import Recommends from './content.item.study.recommends';
//#endregion

const ContentItemStudy: FC<IContentItemProps> = memo<IContentItemProps>(props => {
  const {
    containerGame,
    containerUser,
    containerContentList,
    content: contentItem,
    contentType,
    contentId,
    sectionId,
    subsectionId,
    subscribe,
    history,
    loading,
    ready
  } = props;
  const isUserAuthenticated = containerUser?.token && containerUser?.authenticated;
  const [modalTypeOpen, setModalTypeOpen] = useGetSet<OContentItemModalType>(null);
  const [modalGameOpen, setModalGameOpen] = useState(false);
  const { totalCount, correctCount } = containerGame;
  const { t } = useTranslation();
  const classesApp = stylesApp();
  const ellipsisStyles = useEllipsisStyles({});
  const styles = useDesktopContentItemPageStyles();

  useEffect(() => {
    if (sectionId && ready) {
      containerContentList.actions.fetchContentList({ id: sectionId, pager: { limit: 100, offset: 0 }, cancellable: true });
    }
    return () => {
      containerContentList.actions.resetContentList();
    };
  }, [sectionId, ready]);

  const onEndModalGame = useCallback(() => {
    setModalGameOpen(isPrev => !isPrev);
    history.push(`${STUDY_RESULT(contentType.toLowerCase() as EContentType, contentId)}`, { contentItem });
  }, [correctCount, totalCount]);

  const onCloseGame = useCallback(() => {
    setModalGameOpen(isPrev => !isPrev);
    history.push(CONTENT_ITEM(contentType.toLowerCase() as EContentType, contentId), { contentItem });
  }, []);

  const handlePlay = () => {
    if (contentId && ready) {
      setModalGameOpen(true);
    }
  };

  const loader = (
    <div className={ classesApp.suspendLoader }>
      <CircularLoader />
    </div>
  );

  const content = (
    <>
      <Typography className={ cx(styles.headline, ellipsisStyles[EEllipsisClassKey.line]) } type="heading" level={ 1 }>
        { contentItem?.name }
      </Typography>

      <Postcard
        classes={ {
          [EPostcardClassKey.root]: cx(styles.thumbnail, contentType),
          [EPostcardClassKey.picture]: styles.thumbnailPic,
          [EPostcardClassKey.pictureImage]: cx(styles.thumbnailPicImage, modalTypeOpen() === OContentItemModalType.StudyQuestions && styles.thumbnailPicBlur)
        } }
        layout={ EPostcardLayout.vertical }
        width="100%"
        height="auto"
        src={ contentItem?.imageUrl }
        fallback={ ImageFallback }
      />

      <Typography className={ styles.descr } type="text" tag="div">
        { contentItem?.description }
      </Typography>

      <div className={ styles.center }>
        <Button onClick={ () => handlePlay() }>{ t('study.start') }</Button>
      </div>

      <Recommends contentId={ contentId }/>

      <ModalGame open={ modalGameOpen } onClose={ onCloseGame } onEnd={ onEndModalGame } />
    </>
  );

  return (
    <ViewBox className={ styles.root }>
      <Wrapper>{ loading || !ready ? loader : content }</Wrapper>
    </ViewBox>
  );
});
ContentItemStudy.displayName = 'ContentItemStudy';

export default ContentItemStudy;
