// import { ISubscription } from 'app/models/model.subscription';
import { ISubscribePayload } from 'app/entities/modals/modals.reducer';
import { ISubscription } from 'app/models/model.subscription';

export const MODALS_ACTIONS = {
  MAX_NOTIFICATIONS: 'MODALS::MAX:NOTIFICATIONS',
  SUBSCRIBE: 'MODALS::SUBSCRIBE',
  SUCCESS_ACTIVATE: 'MODALS::SUCCESS_ACTIVATE',
  UNSUBSCRIBE: 'MODALS::UNSUBSCRIBE',
  SUBSCRIBE_NOT_FOUND: 'MODALS::SUBSCRIBE:NOT_FOUND',
  FAIL_ACTIVATE: 'MODALS::FAIL_ACTIVATE',
  NO_MONEY: 'MODALS::NO_MONEY',
  NETWORK: 'MODALS::NETWORK',
  RESET: 'MODALS::RESET',
  NOT_AUTHORIZED: 'MODALS::NOT_AUTHORIZED',
  HOW_LOAD: 'HOW_LOAD',
  LOGOUT: 'LOGOUT'
};

export const networkModal = () => ({
  type: MODALS_ACTIONS.NETWORK
});

export const logoutModal = () => ({
  type: MODALS_ACTIONS.LOGOUT
});

export const closeModal = () => ({
  type: MODALS_ACTIONS.RESET
});

interface ISubscribeModalArgs {
  subscribeId: ISubscription['id'];
  options?: {
    handleClose?: () => void;
    handleCancel?: () => void;
  };
}

export const noMoneyModal = (options?: ISubscribeModalArgs['options']) => ({
  type: MODALS_ACTIONS.NO_MONEY,
  payload: { ...options }
});

export const failActivateModal = () => ({
  type: MODALS_ACTIONS.FAIL_ACTIVATE
});

export const notAuthorizedModal = () => ({
  type: MODALS_ACTIONS.NOT_AUTHORIZED
});

export const maxNotificationsModal = () => ({
  type: MODALS_ACTIONS.MAX_NOTIFICATIONS
});

export const howLoadNotificationsModal = () => ({
  type: MODALS_ACTIONS.HOW_LOAD
});

export const successActivateModal = (subscribeId: ISubscribeModalArgs['subscribeId']) => ({
  type: MODALS_ACTIONS.SUCCESS_ACTIVATE,
  payload: { subscribeId }
});

export const subscribeModal = (subscribeId: ISubscribeModalArgs['subscribeId'], options?: ISubscribeModalArgs['options']) => ({
  type: MODALS_ACTIONS.SUBSCRIBE,
  payload: { subscribeId, ...options }
});

export const unSubscribeModal = (subscribeId: ISubscribeModalArgs['subscribeId'], options?: ISubscribeModalArgs['options']) => ({
  type: MODALS_ACTIONS.UNSUBSCRIBE,
  payload: { subscribeId, ...options }
});

export const subscribeNotFoundModal = (options?: ISubscribeModalArgs['options']) => ({
  type: MODALS_ACTIONS.SUBSCRIBE_NOT_FOUND,
  payload: { ...options }
});
