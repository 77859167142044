import { IRootState } from 'app/shared/reducers/root.reducer';
import { ISubscribeState } from 'app/entities/subscribe/subscribe.reducer';

import {
  fetchSubscription,
  setSubscription,
  resetProp
} from 'app/entities/subscribe/subscribe.actions';
import { Action, Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

export type IContainerSubscribeProps = ISubscribeState & {
  actions: {
    fetchSubscription: typeof fetchSubscription;
    setSubscription: (activate: boolean, subscribeId: number) => void;
    resetProp: typeof resetProp;
  }
};

const mapStateToProps = (state: IRootState) => state.subscribe;
const mapDispatchToProps = (dispatch: Dispatch): IContainerSubscribeProps['actions'] => ({
  fetchSubscription: () => dispatch(fetchSubscription()),
  setSubscription: (...props) => dispatch(setSubscription(...props) as unknown as Action),
  resetProp: prop => dispatch(resetProp(prop))
});

export const ContainerSubscribe = connect<IContainerSubscribeProps>('containerSubscribe', mapStateToProps, mapDispatchToProps);
