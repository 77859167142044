import React from 'react';
import { IRootState } from 'app/shared/reducers/root.reducer';
import { IMenuState } from 'app/entities/menu/menu.reducer';

import { fetchItems } from 'app/entities/menu/menu.actions';
import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

export type IContainerMenu = IMenuState & {
  actions: {
    itemsFetch: () => void;
  }
};

const mapStateToProps = (state: IRootState) => state.menu;
const mapDispatchToProps = (dispatch: Dispatch) => ({
  itemsFetch: () => dispatch(fetchItems())
});

export const ContainerMenu = connect<IContainerMenu>('containerMenu', mapStateToProps, mapDispatchToProps);
