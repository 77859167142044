//#region imports
import React, { FC, memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import map from 'lodash/map';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { IGameInitialState } from 'app/entities/game/game.reducer';
//#endregion

export const useGameIndicatesStyles = makeStyles(
  createStyles({
    indicateWrap: { display: 'inline-flex' },
    indicate: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: `${configTheme.colors.text.light.medium}`,
      marginRight: `${configTheme.indents['indentX1'].margin - 8}px`,
      '&:last-child': { marginRight: 0 }
    },
    success: { backgroundColor: `${configTheme.colors.notification.valid.default}` },
    failed: { backgroundColor: `${configTheme.colors.notification.invalid.default}` },
    selected: { backgroundColor: `${configTheme.colors.border.primary.dark}` }
  })
);

export interface IGameIndicatesProps {
  totalCount: number;
  currentIndex: number;
  result: IGameInitialState['result'];
}

const GameIndicates: FC<IGameIndicatesProps> = memo<IGameIndicatesProps>(props => {
  const { totalCount, currentIndex, result } = props;
  const classes = useGameIndicatesStyles();
  const indicates = new Array(totalCount).fill(0);
  return (
    <div className={ classes.indicateWrap }>
      { indicates?.length
        ? map(indicates, (item, index) => (
            <div
              key={ index }
              className={ cx(classes.indicate, {
                [classes['success']]: result[index] === true,
                [classes['failed']]: result[index] === false,
                [classes['selected']]: index === currentIndex
              }) }
            />
          ))
        : null }
    </div>
  );
});
GameIndicates.displayName = 'GameIndicates';

export default GameIndicates;
