//#region imports
import React, { FC, memo, useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { Grid } from '@material-ui/core';
import debounce from 'lodash/debounce';

import { eventBus, OEventName } from 'app/utils/util.event';
import { useValidateSubscribeCallback } from 'app/utils/util.subscribe';
import { stylesApp } from 'app/app';

import { ContainerContentItemHTML5 } from 'app/containers/container.content.item.html5';

import { EIconTextClassKey } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.models';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';

import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';

import { IContentMediaHtml5Props } from '../page.content.media.models';
import { useDesktopContentMediaPageStyles } from '../page.content.media.styles';
//#endregion

const ContentMediaHtml5: FC<IContentMediaHtml5Props> = memo<IContentMediaHtml5Props>(props => {
  const {
    containerUser,
    containerContentItemHTML5,
    content: contentItem,
    contentId,
    sectionId,
    subsectionId,
    subscribe,
    history,
    loading,
    ready
  } = props;
  const {
    item: { url }
  } = containerContentItemHTML5;
  const isUserAuthenticated = containerUser?.token && containerUser?.authenticated;
  const classesApp = stylesApp();
  const styles = useDesktopContentMediaPageStyles();

  const validateSubscribe = useValidateSubscribeCallback({ user: containerUser, subscribe });

  useEffect(() => {
    if (contentId && subscribe && ready) {
      const callback = () => {
        containerContentItemHTML5.actions.fetchContentItemHtml5(contentId);
        containerContentItemHTML5.actions.logView();
      };
      if (contentItem.free) {
        callback();
      } else {
        validateSubscribe(callback);
      }
    }
  }, [contentId, subscribe, ready]);

  useEffect(() => {
    const handleSubscribeModalCancel = () => history.goBack();
    // eventBus.on(OEventName.ModalCancelSubscribe, handleSubscribeModalCancel);
    return () => {
      // eventBus.off(OEventName.ModalCancelSubscribe, handleSubscribeModalCancel);
      containerContentItemHTML5.actions.resetContentItemHtml5();
    };
  }, []);

  const [availHeight, setAvailHeight] = useState<number>(window.innerHeight);
  useEffect(() => {
    const handler = debounce(() => {
      setAvailHeight(window.innerHeight);
      window.scrollTo(0, 0);
    }, 300);
    window.addEventListener('resize', handler);
    window.addEventListener('scroll', handler);
    return () => {
      handler.cancel();
      window.removeEventListener('resize', handler);
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const loader = (
    <div className={ classesApp.suspendLoader }>
      <CircularLoader />
    </div>
  );

  const vh = availHeight - 90;
  const content = (
    <>
      <Wrapper classes={ { root: styles.title } } spacing={ 0 }>
        <Grid
          className={ styles.titlePanel }
          container
          spacing={ 0 }
          direction="row"
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid className={ styles.titleLogo } item>
            { contentItem?.name }
          </Grid>
          <Grid item>
            <button className={ styles.closeBtn } onClick={ () => history.goBack() }>
              <IconText classes={ { [EIconTextClassKey.icon]: styles.closeBtnIcon } } icon="close" color="white" iconOnly />
            </button>
          </Grid>
        </Grid>
      </Wrapper>
      <div className={ styles.contentFrame } style={ { minHeight: vh, maxHeight: vh, height: vh } }>
        <Iframe className={ styles.iframe } position="absolute" allow="transparency" url={ url }>
          Ваш браузер не поддерживает iframe. Обновите версию своего браузера или воспользуйтесь другим.
        </Iframe>
      </div>
    </>
  );

  return (
    <div className={ styles.root }>
      <ViewBox>{ loading || !ready ? loader : content }</ViewBox>
    </div>
  );
});
ContentMediaHtml5.displayName = 'ContentMediaHtml5';

export default ContainerContentItemHTML5(ContentMediaHtml5);
