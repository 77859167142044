import React, { FC, forwardRef, useEffect, useState } from 'react';
import { FormControl, Grid, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'mobile-device-detect';
import cn from 'classnames';

import flow from 'lodash/flow';
import { ELoadStatus } from 'app/models/shared.model';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Switch from '@material-ui/core/Switch';
import { Button } from 'app/components/button/button.ui';
import { ContainerNotification, IContainerNotificationProps } from 'app/containers/container.notification';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';

import { CustomTextField } from 'app/components/custom.input/custom.input';
import { CustomizedRadiosCheck } from 'app/components/customized.radios.check/customized.radios.check';
import { validationTimeHoursMinutes } from 'app/utils/validationTimeHoursMinutes';
import { useStylesModalNotifications } from './modal.notifications.style';

import { EPeriod, EHowSend, INotification } from 'app/entities/notification/notification.reducer';
import { InputMaskTime } from 'app/components/custom.input/inputMaskTime.input';
import { stylesSwitch } from 'app/pages/shared/profile/profile.style';

import EditIcon from 'app/assets/img/edit.icon.svg';
import CloseIcon from 'app/assets/img/close.svg';
import { Modal } from 'app/components/modal/modal.ui';

interface IRenderCheckBoxDaily {
  value: string;
  checked: boolean;
  control: React.ReactNode;
  label: string;
  onClick: () => void;
  style?: any;
}
interface IModalNotificationsProps {
  open: boolean;
  onClose: () => void;
  containerNotification: IContainerNotificationProps;
  containerUser: IAuthContainerProps;
  renderCheckBox: (props: IRenderCheckBoxDaily) => React.ReactNode;
}

const ModalNotifications: FC<IModalNotificationsProps> = forwardRef(
  ({ open, onClose, containerNotification, containerUser, renderCheckBox }, ref) => {
    const {
      actions: {
        getNotifications,
        createNotification,
        deleteNotification,
        updateNotification,
        updateStatus,
        getNotificationType,
        updateNotificationType
      },
      status: requestStatusNotification,
      items,
      itemId,
      itemHowSend
    } = containerNotification;
    const { authenticated } = containerUser;
    const { t } = useTranslation();
    const hasSmSize = useMediaQuery('(max-width: 405px)');
    const styles = useStylesModalNotifications({ mobile: isMobile });
    const classesSwitch = stylesSwitch();

    const [errorTime, setErrorTime] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [inputValueTextField, setInputValueTextField] = useState({
      text: '',
      time: ''
    });

    const [switchPeriod, setSwitchPeriod] = useState<EPeriod>(EPeriod.DAILY);
    const [switchHowSend, setSwitchHowSend] = useState<EHowSend>(EHowSend.SMS);
    const [editItemId, setEditItemId] = useState(0);
    const [isFetchUpdateType, setIsFetchUpdateType] = useState(false);

    const resetInputValueTextField = () =>
      setInputValueTextField({
        text: '',
        time: ''
      });

    const resetSwitchPeriod = () => setSwitchPeriod(EPeriod.DAILY);
    const resetEditItemId = () => setEditItemId(0);
    const resetIsFetchUpdateType = () => setIsFetchUpdateType(false);

    const resetNotificationItem = () => {
      resetInputValueTextField();
      resetSwitchPeriod();
      resetEditItemId();
      resetIsFetchUpdateType();
    };

    useEffect(() => {
      if (!open) {
        updateStatus(ELoadStatus.ready);
        resetNotificationItem();
      }
    }, [open]);

    useEffect(() => {
      if (isFetchUpdateType && !!itemId) {
        resetNotificationItem();
        updateNotificationType({
          id: itemId,
          notificationType: switchHowSend
        });
      } else if (isFetchUpdateType) {
        resetNotificationItem();
      }
    }, [itemId, switchHowSend, isFetchUpdateType]);

    useEffect(() => {
      setSwitchHowSend(itemHowSend === EHowSend.PUSH ? EHowSend.PUSH : EHowSend.SMS);
    }, [itemHowSend]);

    useEffect(() => {
      if (!!itemId) {
        getNotificationType({ id: itemId });
      }
    }, [itemId]);

    useEffect(() => {
      if (authenticated) {
        getNotifications();
      }
    }, [authenticated]);

    useEffect(() => {
      if (!!editItemId) {
        getNotificationType({ id: editItemId });
      }
    }, [editItemId]);

    const handleCloseModal = e => {
      e.preventDefault();
      onClose();
    };

    const handleSave = e => {
      e.preventDefault();
      const isTime = validationTimeHoursMinutes(inputValueTextField.time);
      const isText = !!inputValueTextField.text;
      !isTime ? setErrorTime(true) : setErrorTime(false);
      !isText ? setErrorText(true) : setErrorText(false);

      const updateFetch = () =>
        setTimeout(() => {
          setIsFetchUpdateType(true);
        }, 500);

      const isValidate = isTime && isText;
      if (!!editItemId && isValidate) {
        const item = items.find(({ id }) => id === editItemId);

        !!isValidate &&
          updateNotification({
            ...item,
            ...inputValueTextField,
            period: switchPeriod
          });

        updateStatus(ELoadStatus.ready);
        updateFetch();
      } else if (isValidate) {
        !!isValidate &&
          createNotification({
            ...inputValueTextField,
            enabled: true,
            period: switchPeriod,
            id: 0
          });
        updateFetch();
      }
    };

    const onChangeTime = time => {
      setInputValueTextField(prev => ({ ...prev, time }));
    };

    const onChangeText = (text: string) => {
      if (text.length <= 25 && text.match(/^[A-Za-z0-9а-яА-ЯёЁ.,!?:\\+*%$#№=@ ]*$/)) {
        setInputValueTextField(prev => ({ ...prev, text }));
      }
    };

    const onUpdate = item => {
      updateNotification(item);
    };

    const onRemove = (id: number) => {
      deleteNotification(id);
    };

    const onChangeItem = (item: INotification) => {
      setInputValueTextField({ text: item.text, time: item.time });
      setSwitchPeriod(item.period === EPeriod.DAILY ? EPeriod.DAILY : EPeriod.WORK_DAYS);
      setEditItemId(item.id);

      updateStatus(ELoadStatus.not_ready);
    };

    const handleNewItem = e => {
      e.preventDefault();
      updateStatus(ELoadStatus.not_ready);
    };

    const renderAddForm = (
      <form noValidate className={ styles.formWrapper }>
        <CustomTextField
          className={ styles.field }
          label={ t('diet.notification.name') }
          value={ inputValueTextField.text }
          onChange={ e => onChangeText(e.target.value) }
          variant="filled"
          id="text"
          error={ errorText }
          helperText={ errorText && t('diet.notification.pleaseFillField') }
        />
        <CustomTextField
          className={ styles.field }
          type="text"
          label={ t('diet.notification.time') }
          value={ inputValueTextField.time }
          onChange={ e => onChangeTime(e.target.value) }
          variant="filled"
          id="time"
          error={ errorTime }
          helperText={ errorTime && t('diet.notification.pleaseFillField') }
          InputProps={ {
            inputComponent: InputMaskTime
          } }
        />

        <FormControl className={ styles.formRadioDaily } component="fieldset">
          <RadioGroup
            defaultValue={ t('diet.notification.daily') }
            aria-label="gender"
            name="customized-radios"
            className={ styles.wrapperRadioGroup }
          >
            { renderCheckBox({
              style: { marginRight: hasSmSize && 16, fontSize: hasSmSize && 14 },
              value: t('diet.notification.daily'),
              checked: switchPeriod === EPeriod.DAILY,
              control: <CustomizedRadiosCheck />,
              label: t('diet.notification.daily'),
              onClick: () => setSwitchPeriod(EPeriod.DAILY)
            }) }

            { renderCheckBox({
              style: { marginRight: 0, fontSize: hasSmSize && 14 },
              value: t('diet.notification.workingDays'),
              checked: switchPeriod === EPeriod.WORK_DAYS,
              control: <CustomizedRadiosCheck />,
              label: t('diet.notification.workingDays'),
              onClick: () => setSwitchPeriod(EPeriod.WORK_DAYS)
            }) }
          </RadioGroup>
        </FormControl>

        <p className={ styles.formRadioLabel }>{ t('diet.notification.howSend') }</p>

        <FormControl className={ styles.formRadioPush } component="fieldset">
          <RadioGroup
            defaultValue={ t('diet.notification.sms') }
            aria-label="gender"
            name="customized-radios"
            className={ styles.wrapperRadioGroup }
          >
            { renderCheckBox({
              style: { marginRight: 16, fontSize: hasSmSize && 14 },
              value: t('diet.notification.sms'),
              checked: switchHowSend === EHowSend.SMS,
              control: <CustomizedRadiosCheck style={ { marginBottom: 8 } } />,
              label: t('diet.notification.sms'),
              onClick: () => setSwitchHowSend(EHowSend.SMS)
            }) }

            { renderCheckBox({
              style: { marginRight: 0, fontSize: hasSmSize && 14 },
              value: t('diet.notification.pushNotification'),
              checked: switchHowSend === EHowSend.PUSH,
              control: <CustomizedRadiosCheck />,
              label: t('diet.notification.pushNotification'),
              onClick: () => setSwitchHowSend(EHowSend.PUSH)
            }) }
          </RadioGroup>
        </FormControl>

        <Grid className={ styles.formButton } container direction="row">
          <Button className={ styles.btnDark } onClick={ handleSave } mobile={ isMobile }>
            { t(`common.${!!editItemId ? 'save' : 'add'}`) }
          </Button>
          <Button className={ styles.btnWhite } onClick={ handleCloseModal } mobile={ isMobile }>
            { t('common.close') }
          </Button>
        </Grid>
      </form>
    );

    const renderNotificationsList = (
      <>
        <div>
          { items.map(({ id, period, text, time, enabled }) => (
            <div key={ id } className={ styles.notificationItem }>
              <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                <div>
                  <div className={ styles.notificationName }>{ text }</div>
                  <div className={ styles.notificationTime }>
                    { t(`diet.notification.${period === EPeriod.DAILY ? 'dailyAt' : 'onWeekdaysAt'}`) } { time }
                  </div>
                </div>
                <Switch
                  checked={ enabled }
                  onChange={ () => onUpdate({ id, period, text, time, enabled: !enabled }) }
                  focusVisibleClassName={ classesSwitch.focusVisible }
                  disableRipple
                  classes={ {
                    root: classesSwitch.root,
                    switchBase: classesSwitch.switchBase,
                    thumb: classesSwitch.thumb,
                    track: classesSwitch.track,
                    checked: classesSwitch.checked,
                    disabled: classesSwitch.disabled
                  } }
                />
              </Grid>
              <div className={ styles.formEdit }>
                <div className={ styles.itemFormEdit } onClick={ () => onChangeItem({ id, period, text, time, enabled }) }>
                  <img className={ styles.icon } src={ EditIcon } alt="EditIcon" />
                  <div className={ styles.itemFormEditText }>{ t('action.change') }</div>
                </div>
                <div className={ styles.itemFormEdit } onClick={ () => onRemove(id) }>
                  <img className={ styles.icon } src={ CloseIcon } alt="CloseIcon" />
                  <div className={ styles.itemFormEditText }>{ t('action.del') }</div>
                </div>
              </div>
            </div>
          )) }
        </div>
        <Grid className={ styles.formButton } container alignItems={ isMobile ? 'center' : 'flex-start' }>
          { items.length !== 5 && (
            <Button
              mobile={ isMobile }
              className={ cn(styles.btnAddIndent, {
                [styles.bthAdd]: isMobile
              }) }
              onClick={ handleNewItem }
            >
              { t(`diet.notification.addAnotherReminder`) }
            </Button>
          ) }
          <Button
            mobile={ isMobile }
            className={ cn({
              [styles.btnWhite]: !isMobile,
              [styles.bthClose]: isMobile
            }) }
            onClick={ handleCloseModal }
          >
            { t('common.close') }
          </Button>
        </Grid>
      </>
    );

    const isValidateForm = !editItemId && validationTimeHoursMinutes(inputValueTextField.time) && inputValueTextField.text;

    return (
      <Modal
        style={ { top: isMobile ? '0' : '50%' } }
        classes={ {
          dialog: isValidateForm ? styles.rootValidate : styles.root
        } }
        open={ open }
        onClose={ () => onClose() }
        title={ t('diet.notification.title') }
        mobile={ isMobile }
        withClose
      >
        { requestStatusNotification === ELoadStatus.ready && !!items.length ? renderNotificationsList : renderAddForm }
      </Modal>
    );
  }
);
export default flow([ContainerNotification, ContainerUser])(ModalNotifications);
