import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';

import unionBy from 'lodash/unionBy';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { IContainerPagerCallbackProps, IPagerContainerProps, PagerContainer } from 'app/containers/container.pager';

import { ResponsiveImage } from 'app/components/image/component.image.responsive';
import imgDummy from 'app/assets/img/img.dummy.jpg';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import { stylesApp } from 'app/app';

export const stylesSectionImageCarousel = makeStyles(theme => createStyles({
  wrap: {
    '&&': {
      width: 'calc(100% + 34px * 2)',
      margin: -34,
      padding: '18px',
      overflowX: 'auto',
      display: 'flex',
      flexWrap: 'nowrap',
      '&& > .MuiGrid-item': {
        flex: '0 0 280px',
        padding: 0,
        paddingRight: 18,
        minHeight: 266,
        '&:last-child': {
          paddingRight: 0
        }
      }
    }
  },
  boxWrap: {
    paddingBottom: 0
  },
  imageBox: {
    width: 280
  },
  imageWrap: {
    margin: 0
  },
  image: {
    width: '100%',
    height: 192,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  verticalImage: {
    width: '100%',
    height: 340,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  itemDummy: {
    flex: '0 0 100% !important',
    padding: '0px !important'
  },
  imageDummy: {
    backgroundImage: 'none !important'
  },
  title: {
    display: 'block',
    marginTop: 9
    // fontSize: 21,
    // lineHeight: '32px',
    // fontWeight: 'bold',
    // color: ui.colors.text.dark.default,
    // whiteSpace: 'nowrap',
    // overflowX: 'hidden',
    // textOverflow: 'ellipsis',
    // maxWidth: 270
  },
  verticalImageWrap: {
    flex: `0 0 254px !important`,
    minHeight: '374px !important'
  }
}));

export interface IImageCarouselProps {
  list: Array<{
    id?: string | number;
    src?: string;
    title?: string;
    height?: number;
    width?: number;
  }>;
  srcKey?: string;
  idKey?: string;
  titleKey?: string;
  callbackLink?: (id: number | string, e?: any) => void;
  callback?: (props: IContainerPagerCallbackProps) => void;
  id?: number;
  isLoading?: boolean;
  isMore?: boolean;
  pagerContainer: IPagerContainerProps;
  route: string;
  onPassItemProps?: Function;
  classes?: {
    wrap?: string;
    item?: string;
    imageWrap?: string;
    image?: string;
    title?: string;
  };
  inNotSet?: boolean;
  limitSize?: number;
  lineClamp?: number;
}

const Carousel = (props: IImageCarouselProps) => {
  const [list, setList] = useState({});
  const classes = stylesSectionImageCarousel();
  const classesApp = stylesApp();
  const propsClasses = props.classes && props.classes;
  const ellipsisStyles = useEllipsisStyles({});

  const refCarousel = useRef<HTMLDivElement>();
  const refImageItem = useRef<HTMLDivElement>();

  const onScroll = () => {
    const scrollLeft = refCarousel.current && refCarousel.current.scrollLeft;
    const clientWidthItem = refImageItem.current && refImageItem.current.clientWidth;
    const isParamsExist = list && list[props?.id] && list[props?.id]?.length > 0 && scrollLeft && clientWidthItem;

    if (!props.isLoading && props.isMore && isParamsExist && scrollLeft + clientWidthItem / 2 > (list[props?.id].length - 1) * clientWidthItem) {
      props.pagerContainer.actions.pageNext();
    }
  };

  useEffect(() => {
    props.callback && props.callback({ pager: props.pagerContainer.pager, id: props.id });
  }, [props.pagerContainer.pager]);

  useEffect(() => {
    if (props?.id) {
      refCarousel.current.scrollLeft = 0;
    }
  }, [props.id]);

  useEffect(() => {
    if (props?.list && props?.id) {
      props.inNotSet
        ? setList(prevState => ({
          ...prevState,
          [props.id]: props.limitSize ? props.list.slice(0, props.limitSize) : props.list
        }))
        : setList(prevState => ({
          ...prevState,
          [props.id]: list[props?.id] ? unionBy(list[props?.id], props.list, 'id') : unionBy([], props.list, 'id')
        }));
    }
  }, [props.list, props.id]);

  return (
    <Box p={ 2 } className={ classes.boxWrap }>
      <Grid
        onScroll={ onScroll }
        className={ propsClasses && propsClasses.wrap || classes.wrap }
        container
        ref={ refCarousel }
      >
        { list[props?.id] && list[props?.id]?.length > 0
          ? list[props?.id].map((i, index) =>
            <React.Fragment key={ i[props.idKey] || i.id }>
              <Grid
                className={
                  `${propsClasses && propsClasses.item}
                  ${i.height > i.width ? classes.verticalImageWrap : ' ' }`
                }
                ref={ refImageItem }
                container
                item
                xs={ 12 }
                sm={ 6 }
                md={ 3 }
                key={ i[props.idKey] || i.id }
                direction={ 'column' }
              >
                {
                  props.onPassItemProps
                    ?
                      <span
                        onClick={ () => props.onPassItemProps(`${props.route}${i.id}`) }
                        className={ `${classesApp.linkDNone} ${classes.imageBox}` }
                      >
                        <ResponsiveImage
                          classesWrap={ propsClasses && propsClasses.imageWrap || classes.imageWrap }
                          classesImg={ propsClasses && propsClasses.image || i.height > i.width ? classes.verticalImage : classes.image }
                          src={ i[props.srcKey] || i.src }
                        />
                        <Typography
                          type={ 'text' }
                          size={ 'large' }
                          className={ classnames(ellipsisStyles[EEllipsisClassKey.line], propsClasses && propsClasses.title || classes.title) }
                        >
                          { i[props.titleKey] || i.title }
                        </Typography>
                      </span>
                    :
                      <Link
                        onClick={ e => props.callbackLink && props.callbackLink(i.id, e) }
                        to={ `${props.route}${i.id}` }
                        className={ `${classesApp.linkDNone} ${classes.imageBox}` }
                      >
                        <ResponsiveImage
                          classesWrap={ propsClasses && propsClasses.imageWrap || classes.imageWrap }
                          classesImg={ propsClasses && propsClasses.image || classes.image }
                          src={ i[props.srcKey] || i.src }
                        />
                        <Typography
                          type={ 'text' }
                          size={ 'large' }
                          className={ classnames(ellipsisStyles[EEllipsisClassKey.line], propsClasses && propsClasses.title || classes.title) }
                        >
                          { i[props.titleKey] || i.title }
                        </Typography>
                      </Link>
                }
              </Grid>
            </React.Fragment>
           )
          :
            <Grid className={ classes.itemDummy } container item xs={ 12 } sm={ 6 } md={ 3 } direction={ 'column' }>
              <ResponsiveImage classesImg={ `${classes.image} ${classes.imageDummy}` } src={ imgDummy } />
            </Grid>
        }
      </Grid>
    </Box>
  );
};

export const ImageCarousel = PagerContainer(Carousel);
