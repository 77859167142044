//#region imports
import React, { FC, memo, forwardRef, useRef } from 'react';
import { Link as __Link, NavLink as __NavLink } from 'react-router-dom';
import { useForkRef } from '@material-ui/core';

import { ITypographyProps } from 'app/components/desktop/typography/typography.models';
import { Typography } from 'app/components/desktop/typography/typography.index';

import { FollowLinkProps, CompatLinkProps, CompatElement } from './follow-link.model';
import InternalAnchor from './follow-link.internal-anchor';
import ExternalAnchor from './follow-link.external-anchor';
//#endregion

//#region FollowLink
const Link = (__Link as unknown) as FC<CompatLinkProps>;
const NavLink = (__NavLink as unknown) as FC<CompatLinkProps>;

export const FollowLink: FC<FollowLinkProps> = forwardRef<CompatElement, FollowLinkProps>(
  ({ type, to, replace, activeClassName, ...props }, ref) => {
    const isExternal = typeof to === 'string' && to.match(/^(http|https)/);
    const isNavLink = type === 'navlink';

    const rootRef = useRef(null);
    const handleRef = useForkRef(rootRef, ref);

    const Component = isNavLink ? NavLink : Link;
    return (
      <>
        { isExternal ? (
          <ExternalAnchor ref={ handleRef } { ...props } href={ to as string } replace={ replace } />
        ) : (
          <Component ref={ handleRef } { ...props } to={ to } replace={ replace } component={ InternalAnchor } />
        ) }
      </>
    );
  }
);
FollowLink.displayName = 'FollowLink';

FollowLink.defaultProps = { type: 'link', node: `a`, anchor: true };
//#endregion

//#region TypographyFollowLink
const TypographyFollowLinkNode = forwardRef<HTMLElement, ITypographyProps>((props, ref) => {
  const { href, children, onClick, size, strong, serif, ...attrs } = props;
  return (
    <span ref={ ref }>
      <Typography type="link" href={ href } size={ size } strong={ strong } serif={ serif } attrs={ attrs } onClick={ onClick }>
        { children }
      </Typography>
    </span>
  );
});
TypographyFollowLinkNode.displayName = 'TypographyFollowLinkNode';

export const TypographyFollowLink: FC<FollowLinkProps & Omit<ITypographyProps, 'type' | 'onClick'>> = memo(props => {
  const { to, children, onClick, onAfterClick, ...attrs } = props;
  return (
    <FollowLink to={ to } onClick={ onClick } onAfterClick={ onAfterClick } node={ <TypographyFollowLinkNode { ...(attrs as any) } /> }>
      { children }
    </FollowLink>
  );
});
TypographyFollowLink.displayName = 'TypographyFollowLink';
//#endregion

export default FollowLink;
