import React, { forwardRef } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const stylesHeader = makeStyles((theme: Theme) => createStyles({
  h1: {
    fontFamily: '"Tele2 Display"',
    fontSize: '1.7rem',
    lineHeight: '128%',
    letterSpacing: 0.4
  },
  h2: {
    fontSize: 24,
    lineHeight: '28px'
  },
  h3: {
    fontSize: 24,
    lineHeight: '1.167',
    margin: '18px 0 9px'
  },
  h4: {
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: 1.455,
    margin: 0
  },
  h5: {
    fontWeight: 'normal',
    fontSize: 20,
    lineHeight: '28px',
    margin: 0
  },
  h6: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '15px',
    margin: '0 !important',
    opacity: 0.5
  }
}));

export interface IHeaderProps extends THeadingProps {
  component: keyof typeof Headers;
  center?: boolean;
}

type THeadingProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

const Headers = {
  h1: forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => <h1 ref={ ref } { ...props }/>),
  h2: forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => <h2 ref={ ref } { ...props }/>),
  h3: forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => <h3 ref={ ref } { ...props }/>),
  h4: forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => <h4 ref={ ref } { ...props }/>),
  h5: forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => <h5 ref={ ref } { ...props }/>),
  h6: forwardRef<HTMLHeadingElement, THeadingProps>((props, ref) => <h6 ref={ ref } { ...props }/>)
};

export const Header = forwardRef<HTMLHeadingElement, IHeaderProps>((props, ref) => {
  const { component, className, ...other } = props;
  const classes = stylesHeader();
  const internalClassName = [
    classes[component],
    className
  ].filter(cls => cls).join(' ');
  const Component = Headers[component];
  return (
    <Component style={ props.center && { textAlign: 'center', width: '100%' } } className={ internalClassName } ref={ ref as any } { ...other } />
  );
});
