//#region imports
import React, { FC, memo, useRef, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import shuffle from 'lodash/shuffle';
import slice from 'lodash/slice';
import filter from 'lodash/filter';
import flow from 'lodash/fp/flow';
import map from 'lodash/map';

import { CONTENT_ITEM } from 'app/configs/route.names';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType, IContent } from 'app/models/model.content';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerContentList } from 'app/containers/container.content.list';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import useLimiter from 'app/hooks/useLimiter';
import useEllipsisStyles from 'app/hooks/useEllipsisStyles';

import { IRecommendsProps } from '../item.models';
import { useDesktopContentItemPageStyles } from '../page.content.item.styles';
import ItemCard from 'app/components/desktop/item.card/item.card.index';
//#endregion

const Recommends: FC<IRecommendsProps> = memo(props => {
  const { containerContentItem, containerContentList, contentId } = props;
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
  const styles = useDesktopContentItemPageStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const sectionId = containerContentItem.section?.section?.id;
  const contentType = containerContentItem.item?.type;

  const contents = containerContentList.items;
  const loading = containerContentList.status.items === ELoadStatus.loading;
  const more = containerContentList.more;

  const [paging, pagingEmiter] = useLimiter();
  useEffect(() => {
    if (sectionId) {
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        cancellable: true
      });
      containerContentList.actions.logView();
    }
    return () => {
      pagingEmiter.invokeReset();
      containerContentList.actions.resetContentList();
    };
  }, [sectionId]);

  const scrollRef = useRef<HTMLDivElement>();

  const displayRecommendList = useMemo(() => {
    const recommendList = filter(containerContentList?.items, (c: IContent) => c?.id !== contentId && c?.type === contentType);
    return slice(shuffle(recommendList), 0, 3);
  }, [contentId, contentType, contents]);

  const loadData = useCallback(() => {
    if (sectionId) {
      pagingEmiter.invokeNext();
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        more,
        cancellable: true
      });
      containerContentList.actions.logView();
    }
  }, [sectionId, more]);

  const recommendsExists = displayRecommendList?.length > 0;
  if (!recommendsExists) return null;

  const handleClick = (type, id) => {
    history.push(CONTENT_ITEM(type, id));
  };

  switch (contentType) {
    case EContentType.STUDY:
      return (
        <div className={ styles.recommends }>
          <Typography className={ styles.recommendsTitle } type="heading" level={ 1 } tag="div">
            { t('common.recommend') }
          </Typography>
          <div className={ styles.recommendList }>
            { map(displayRecommendList, s => (
              <div key={ s?.id } className={ styles.recommendItem }>
                <ItemCard
                  key={ s?.id }
                  format="compact"
                  contentType={ EContentType.IMAGE }
                  preview={ { src: s?.desktopImageUrl || s?.imageUrl } }
                  title={ s.name }
                  onPictureClick={ () => handleClick(s?.type, s?.id) }
                  onTitleClick={ () => handleClick(s?.type, s?.id) }
                  withIndicator={ false }
                />
              </div>
            )) }
          </div>
        </div>
      );
    default:
      return <div className={ styles.recommends } />;
  }
});

export default flow([ContainerContentItem, ContainerContentList])(Recommends);
