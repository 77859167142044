import React, { FC, useMemo } from 'react';
import { isMobile } from 'mobile-device-detect';
import { useToggle } from 'react-use';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Grid, Paper } from '@material-ui/core';

import truncate from 'lodash/truncate';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import playSvg from 'a1s-tele2-react-ui/src/assets/icons/playCircle.svg';
import { EPostcardClassKey } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';

import {
  ContainerDietIndividualProgram,
  IContainerDietIndividualProgramProps
} from 'app/containers/container.diet.program';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';

import mockBackgroundMobile from 'app/assets/img/workout_mock_mobile.png';
import mockBackgroundDesktop from 'app/assets/img/workout_mock_desktop.png';
import ClassSvg from 'app/assets/img/сlass.svg';
import { useStylesIndividualProgramWorkout } from './workout.style';
import { CONTENT_ITEM_VIDEO } from 'app/configs/route.names';
import { EContentType } from 'app/models/model.content';
import { ESubscriptionStatus } from 'app/models/model.subscription';

const MyProgramWorkout: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerSubscribe: IContainerSubscribeProps;
  isLastDay?: boolean;
  activeDayId?: number;
}> = ({ containerDietIndividualProgram, isLastDay, containerSubscribe, activeDayId }) => {
  const subscribe = containerSubscribe.subscriptions.find(sub => sub.id === containerDietIndividualProgram?.programStatus?.id);
  const [on, toggle] = useToggle(false);
  const styles = useStylesIndividualProgramWorkout({ mobile: isMobile });
  const { t } = useTranslation();
  const history = useHistory();

  const { dataDays } = containerDietIndividualProgram;

  const onPlay = () => {
    if (dataDays?.trainingVideoUrl) {
      localStorage.setItem('activeDayId', activeDayId.toString());
      history.push(CONTENT_ITEM_VIDEO(EContentType.VIDEO), { src: dataDays?.trainingVideoUrl, title: t('individualProgramWorkout.training') });
    }
  };

  const returnTruncateText = useMemo(() => {
    const text = dataDays?.trainingText;
    const mockText = 'Начальные занятия будут довольно простыми, и со временем начнём наращивать темп и нагрузку. ' +
      '<br>• Если пока сложно выполнять прописанное кол-во подходов и повторов - уменьшайте, делайте в меру своих сил, но на максимум! ' +
      '<br>• Будет довольно много упражнений, которые необходимо выполнять в прыжках, если у вас есть ограничения по здоровью - болезни ' +
      'суставов, грыжи, протрузии и т.д. - выполняйте эти же упражнения, но не в прыжках, на месте. <br>• Всегда тренируйтесь по самочувствию. ' +
      'Если чувствуете, что выполняя упражнение стали плохо себя чувствовать - остановитесь, отдохните, либо сделайте тренировку позже! <br>• ' +
      'Между подходами отдыхайте 15-30 секунд, но чем меньше отдыха - тем эффективнее будет результат! <br>• Во время занятия пейте воду небольшими глотками. ' +
      '<br>• Старайтесь тренироваться в одно и то же время! Если не сможете до завтрака. <br>• Включайте любимую энергичную музыку и делайте своё тело лучше с каждым днём!';
    const finalText = subscribe?.status !== ESubscriptionStatus.ACTIVE ? mockText : text;
    if (isMobile) {
      if (!on && finalText) {
        return truncate(finalText, {
          length: 300,
          omission: '...'
        });
      } else return finalText;
    } else return finalText;
  }, [dataDays?.trainingText, isMobile, on, subscribe]);

  const isRenderPreview = useMemo(() => {
    return ((dataDays?.trainingVideoUrl && subscribe?.status === ESubscriptionStatus.ACTIVE) || subscribe?.status !== ESubscriptionStatus.ACTIVE);
  }, [dataDays?.trainingVideoUrl, subscribe?.status]);

  const isDayOfRest = returnTruncateText?.length && returnTruncateText?.length < 30;

  return (
    <>
      <Paper elevation={ 3 } className={ styles.blockWorkout }>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={ styles.paddingByMobile }>
          <img src={ ClassSvg } alt="class" className={ styles.imgTitle } />
          <Typography type="heading" level={ 2 } mobile={ isMobile }>
            { t('individualProgramWorkout.title') }
          </Typography>
        </Grid>

        { returnTruncateText && <div
          dangerouslySetInnerHTML={ { __html: returnTruncateText } }
          className={ styles.displayText }
          style={ { marginBottom: !(isMobile && returnTruncateText && !isDayOfRest) && 0 } }
        /> }

        { isMobile && returnTruncateText && !isDayOfRest && (
          <Typography tag="div" type="text" className={ styles.toggleText } onClick={ toggle }>
            { on ? t('individualProgramMenuBlock.hideAllTips') : t('individualProgramMenuBlock.showAllTips') }
          </Typography>
        ) }

        { isRenderPreview &&
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <div style={ { position: 'relative', marginTop: isMobile ? !returnTruncateText?.length ? 0 : 27 : 27, padding: isMobile ? '0 18px' : 0 } }>
              <Postcard
                src={ isMobile ? mockBackgroundMobile : mockBackgroundDesktop }
                width={ isMobile ? '100%' : 670 }
                height={ isMobile ? 'auto' : 375 }
                classes={ { [EPostcardClassKey.picture]: styles.picture } }
              />
              <img src={ playSvg } alt={ 'play' } className={ styles.videoIcon } onClick={ onPlay } />
            </div>
          </Grid>
        }
      </Paper>
    </>
  );
};

export default ContainerDietIndividualProgram(ContainerSubscribe(MyProgramWorkout));
