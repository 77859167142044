export enum EMePageContent {
  DATA = 'DATA',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS'
}

export enum EMenuItem {
  GAME = 'GAME',
  BACKEND_CATEGORIES = 'BACKEND_CATEGORIES',
  MAIN = 'MAIN'
}

export enum EUnsubscribeButtons {
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  CANCEL = 'CANCEL'
}
