import React, { FC } from 'react';
import { isMobile } from 'mobile-device-detect';
import { Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import smsBlock from 'app/assets/img/sms.dieta.block.svg';
import { useStylesIndividualProgramAdviceDiet } from './dietolog.style';
import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import mockBackgroundMobile from 'app/assets/img/dietolog_mock_mobile.png';
import mockBackgroundDesktop from 'app/assets/img/dietolog_mock_desktop.png';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { EPostcardClassKey } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import playSvg from 'a1s-tele2-react-ui/src/assets/icons/playCircle.svg';
import { CONTENT_ITEM_VIDEO } from 'app/configs/route.names';
import { EContentType } from 'app/models/model.content';

const AdviceDiet: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  adviceVideoPreview: string;
  activeDayId?: number;
}> = ({ containerDietIndividualProgram, activeDayId }) => {
  const styles = useStylesIndividualProgramAdviceDiet({ mobile: isMobile });
  const { t } = useTranslation();
  const history = useHistory();

  const { dataDays } = containerDietIndividualProgram;

  const onPlay = () => {
    if (dataDays?.adviceVideoUrl) {
      localStorage.setItem('activeDayId', activeDayId.toString());
      history.push(CONTENT_ITEM_VIDEO(EContentType.VIDEO), { src: dataDays?.adviceVideoUrl, title: t('individualProgramDietolog.title') });
    }
  };

  return (
    <>
      <Paper elevation={ 3 } className={ styles.blockAdviceDiet }>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <img src={ smsBlock } alt="smsBlock" className={ styles.imgAdviceDiet } />
          <Typography type="heading" level={ 2 } mobile={ isMobile }>
            { t('individualProgramDietolog.title') }
          </Typography>
        </Grid>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <p className={ styles.discDiet }>{ t('individualProgramDietolog.desc') }</p>
          <div style={ { position: 'relative' } }>
            <Postcard
              src={ isMobile ? mockBackgroundMobile : mockBackgroundDesktop }
              width={ isMobile ? '100%' : 670 }
              height={ isMobile ? 'auto' : 375 }
              classes={ { [EPostcardClassKey.picture]: styles.picture } }
            />
            <img src={ playSvg } alt={ 'play' } className={ styles.videoIcon } onClick={ onPlay } />
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default ContainerDietIndividualProgram(AdviceDiet);
