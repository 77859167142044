import { makeStyles, createStyles } from '@material-ui/core';

export const useDesktopDietStyles = makeStyles(
  createStyles({
    root: {
      marginTop: '54px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    title: {
      fontWeight: 700,
      fontSize: '38px',
      lineHeight: '44px',
      color: '#1F2229',
      fontFamily: 'Tele2 Display'
    },
    banner: {
      width: '100%',
      height: '416px',
      marginTop: '36px'
    },
    btn: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginTop: '54px',
      marginBottom: 72
    },
    li: {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      lineHeight: '24px',
      background: '#1F2229',
      margin: '1em',
      borderRadius: '50%',
      color: '#FFFFFF',
      textAlign: 'center',
      '&:last-child': {
        marginBottom: 0
      }
    }
  })
);
