//#region imports
import React, { FC, memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import isFunction from 'lodash/isFunction';
import toLower from 'lodash/toLower';
import each from 'lodash/each';
import flow from 'lodash/fp/flow';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerUser } from 'app/containers/container.user';
import { ModalsContainer } from 'app/containers/modals.container';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';

import FollowLink from 'app/components/follow-link/follow-link.index';

import { IActionsProps } from '../item.models';
import { useContentItemStyles } from '../page.content.item.styles';

import subscribeCheck from 'app/utils/util.subscribe.check';
//#endregion

const Actions: FC<IActionsProps> = memo(props => {
  const { containerContentItem, modalsContainer, containerUser, contentId, onOpenQuiz, backUrl } = props;
  const styles = useContentItemStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const backUrlRef = useRef(backUrl);

  const content = containerContentItem.item;
  const mimeTypes = useMemo<{ [key: string]: string }>(() => {
    const dict = {};
    each(content?.data?.format, f => {
      if (f?.mimeType) {
        if (f.mimeType?.includes('pdf')) dict['pdf'] = f.mimeType;
        if (f.mimeType?.includes('audio')) dict['audio'] = f.mimeType;
      }
    });
    return dict;
  }, [content]);

  useEffect(() => {
    backUrlRef.current = backUrl;
  }, [backUrl]);

  const onClickButtonBook = (url, blank = false) =>
    subscribeCheck({
      subscribe: containerContentItem.section.subscribe,
      callback: () => blank ? window.open(url) : history.push(url),
      history,
      containerUser,
      containerModals: modalsContainer
    })
  ;

  if (containerContentItem.status.item !== ELoadStatus.ready) return null;

  switch (content?.type) {
    case EContentType.BOOK:
      return (
        <div className={ styles.actions }>
          { containerContentItem.item?.free ? (
            <>
              { mimeTypes?.pdf && (
                <FollowLink
                  anchor={ false }
                  node={
                    <Button className={ styles.actionBtn } type="secondary" disabled={ content?.url === null } block mobile>
                      { t('common.read') }
                    </Button>
                  }
                  to={ content?.url ? `${content?.url}?mimeType=${mimeTypes?.pdf}&download=false` : {} }
                  target="_blank"
                />
              ) }
              { mimeTypes?.audio && (
                <FollowLink
                  anchor={ false }
                  node={
                    <Button className={ styles.actionBtn } type="secondary" disabled={ content?.url === null } block mobile>
                      { t('common.listen') }
                    </Button>
                  }
                  to={ CONTENT_ITEM(toLower(EContentType.AUDIO) as EContentType.AUDIO, contentId) }
                />
              ) }
            </>
          ) : (
            <>
              { mimeTypes?.pdf && (
                <Button
                  onClick={ () => onClickButtonBook(content?.url ? `${content?.url}?mimeType=${mimeTypes?.pdf}&download=false` : {}, true) }
                  className={ styles.actionBtn }
                  type="secondary"
                  disabled={ content?.url === null }
                  block
                  mobile
                >
                  { t('common.read') }
                </Button>
              ) }
              { mimeTypes?.audio && (
                <Button
                  onClick={ () => onClickButtonBook(CONTENT_ITEM(toLower(EContentType.AUDIO) as EContentType.AUDIO, contentId)) }
                  className={ styles.actionBtn }
                  type="secondary"
                  disabled={ content?.url === null }
                  block
                  mobile
                >
                  { t('common.listen') }
                </Button>
              ) }
            </>
          ) }
          <Button className={ styles.actionBtn } type="secondary" block mobile onClick={ () => isFunction(onOpenQuiz) && onOpenQuiz() }>
            { t('common.passTest') }
          </Button>
        </div>
      );
    default:
      return null;
  }
});

export default flow([ModalsContainer, ContainerContentItem, ContainerUser])(Actions);
