import { createStyles, makeStyles } from '@material-ui/core/styles';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesMainPage = makeStyles(theme => createStyles({
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  header: {
    fontFamily: 'Tele2 Display',
    color: ui.colors.text.dark.default,
    margin: '0 0 22px',
    ['@media (max-width:380px)']: {
      fontSize: 22,
      paddingRight: 15
    },
    ['@media (max-width:345px)']: {
      fontSize: 21,
      paddingRight: 15
    }
  },
  titleLink: {
    lineHeight: '22px',
    padding: 1,
    textDecoration: 'underline',
    color: ui.colors.text.dark.default
  },
  wrapNavbar: {
    marginBottom: 27
  }
}));
