import { ISection, ISectionItem } from 'app/models/model.section';
import { IRouteProps } from 'app/models/shared.model';
import { ISubscription } from 'app/models/model.subscription';

import { IAuthContainerProps } from 'app/containers/container.user';
import { IContainerContentItemProps } from 'app/containers/container.content.item';
import { IContainerSectionItemProps } from 'app/containers/container.section.item';
import { IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { IContainerLikeProps } from 'app/containers/like.container';
import { IContainerContentListProps } from 'app/containers/container.content.list';

import { History, Location } from 'history';
import { IContainerGameProps } from 'app/containers/container.game';

export enum EContentType {
  AUDIO = 'AUDIO',
  BOOK = 'BOOK',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  TEASER_BLOCK = 'TEASER_BLOCK',
  TEXT = 'TEXT',
  HOROSCOPE = 'HOROSCOPE',
  GAME_HTML5 = 'GAME_HTML5',
  GAME_ANDROID = 'GAME_ANDROID',
  STUDY = 'STUDY',
  OLYMPIAD = 'OLYMPIAD',
  ARTICLE = 'ARTICLE'
}

export interface IFormat {
  mimeType: string;
  width?: number;
  height?: number;
  size: number;
}

interface IContentMapping {
  contentSource: string;
  externalContentId: string;
}

export interface IContent {
  link?: string;
  id: number | string;
  new?: boolean;
  free?: boolean;
  text?: string;
  description?: string;
  url?: string;
  name: string;
  imageUrl?: string;
  desktopImageUrl?: string;
  data?: {
    contentMappings: IContentMapping[];
    preview: IFormat & {
      url: string;
    };
    format: IFormat[];
    cover: null;
    artist: string | null;
  };
  type: EContentType;
}

export interface IContentGame extends Omit<IContent, 'data'> {
  data: {
    contentMappings: IContentMapping[];
    preview: IFormat & {
      url: string;
    };
    gameType: 'ANDROID' | 'IOS' | 'HTML5';
    screenshotList: IFormat & Array<{
      url: string;
    }>;
  };
}

export interface IContentOlympiad extends Omit<IContent, 'data'> {
  data?: {
    step1: string;
    step2: string;
    step3: string;
    videoContentId?: string;
    questionDescription?: string;
  };
}

export interface IContentItem {
  item: IContent;
  section: ISection;
}

export interface IContentHtml5 {
  url: string;
}

interface ITag {
  name: string;
  id: number;
}

export enum OContentItemModalType {
  SetupAndroid,
  StudyQuestions,
  OlympiadAbort,
  OlympiadPrize
}

export interface IDesktopContentItemPageProps extends Omit<IRouteProps, 'history'> {
  containerUser: IAuthContainerProps;
  containerContentItem: IContainerContentItemProps;
  containerSectionItem: IContainerSectionItemProps;
  containerSubscribe: IContainerSubscribeProps;
  containerContentList: IContainerContentListProps;
  containerLike?: IContainerLikeProps;
  containerGame?: IContainerGameProps;
}

export interface IContentItemProps extends Partial<IDesktopContentItemPageProps> {
  history?: History;
  location?: Location;
  contentId: IContent['id'];
  sectionId?: number;
  subsectionId?: number;
  content?: IContent;
  section?: ISectionItem;
  subscribe?: ISubscription;
  contentType?: EContentType;
  accessible?: boolean;
  loading?: boolean;
  ready?: boolean;
}

export interface IContentItemOlympiadProps extends Omit<IContentItemProps, 'content'> {
  content?: IContent | IContentOlympiad;
}
