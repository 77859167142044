import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

interface IStylesModalNotificationsProps {
  mobile: boolean;
}

export const useStylesModalNotifications = makeStyles<Theme, IStylesModalNotificationsProps>((theme: Theme) =>
  createStyles({
    root: {
      padding: ({ mobile }) => mobile ? '36px 18px 36px 18px !important' : '36px 40px 54px 36px !important'
    },
    rootValidate: {
      padding: ({ mobile }) => mobile ? '36px 18px 36px 18px !important' : '36px 40px 115px 36px !important'
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      width: 732,
      // height: 807,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    closeIcon: {
      cursor: 'pointer'
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: '#fcfcfb',
        borderRadius: '4px',
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    wrapperRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: -8,
      '& > *': {
        marginBottom: 8
      }
    },
    paperLoaderMyDiete: {
      width: 732,
      height: 540,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    progress: {
      marginTop: '54px'
    },
    field: {
      width: ({ mobile }) => mobile ? (theme.breakpoints.down(405) ? 332 : 348) : 370,
      marginTop: 18,
      borderBottom: 0,

      '&:first-child': {
        margin: 0
      },
      '& > p': {
        margin: '8px 0 0 0'
      },
      '&.MuiFormLabel-root.Mui-focused': {
        color: '#9297A0!important'
      }
    },
    formRadioLabel: {
      color: '#9297A0',
      margin: ({ mobile }) => mobile ? '19px 0 0 0' : '18px 0 0 0',
      fontSize: ({ mobile }) => mobile ? 13 : 14,
      lineHeight: '18px'
    },
    formRadioDaily: {
      margin: ({ mobile }) => mobile ? '27px 0 0 0' : '18px 0 0 0'
    },
    formRadioPush: {
      marginTop: 9
    },
    formButton: {
      marginTop: ({ mobile }) => mobile ? 38 : 36,
      display: 'flex',
      flexDirection: ({ mobile }) => mobile ? 'column' : 'row',
      alignItems: ({ mobile }) => mobile ? 'center' : 'flex-start'
    },
    btnDark: {
      width: ({ mobile }) => mobile ? 140 : 160,
      margin: ({ mobile }) => mobile ? '0 0 18px 0' : '0 36px 0 0'
    },
    btnWhite: {
      width: ({ mobile }) => mobile ? 140 : 151,
      background: ui.colors.background.white.default,
      color: ui.colors.text.dark.default
    },
    btnAddIndent: {
      margin: ({ mobile }) => mobile ? '0 0 18px 0' : '0 36px 0 0'
    },
    btnAdd: {
      width: 276
    },
    bthClose: {
      width: 276,
      background: ui.colors.background.white.default,
      color: ui.colors.text.dark.default
    },
    notificationItem: {
      marginTop: 54,
      maxWidth: ({ mobile }) => mobile ? 375 : 370,
      width: '100%',

      '&:first-child': {
        margin: 0
      }
    },
    notificationName: {
      fontSize: ({ mobile }) => mobile ? 16 : 22,
      lineHeight: ({ mobile }) => mobile ? '22px' : '28px'
    },
    notificationTime: {
      fontSize: ({ mobile }) => mobile ? 14 : 16,
      lineHeight: ({ mobile }) => mobile ? '18px' : '22px',
      color: '#8A8F97',
      marginTop: 9
    },
    formEdit: {
      marginTop: 18,
      display: 'flex'
    },
    itemFormEdit: {
      width: 100,
      display: 'flex',
      cursor: 'pointer',
      marginLeft: 27,
      '&:first-child': {
        margin: 0
      }
    },
    itemFormEditText: {
      width: '100%',
      textDecoration: 'underline',
      marginLeft: 9,
      '&:first-child': {
        margin: 0
      }
    },
    icon: {
      width: 18,
      height: 18
    }
  })
);
