//#region imports
import React, { FC, memo, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import isFunction from 'lodash/isFunction';
import toLower from 'lodash/toLower';
import each from 'lodash/each';
import flow from 'lodash/fp/flow';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerUser } from 'app/containers/container.user';
import { ModalsContainer } from 'app/containers/modals.container';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import AudioSvg from 'a1s-tele2-react-ui/src/assets/icons/headphones.svg';
import ReadSvg from 'a1s-tele2-react-ui/src/assets/icons/magazine.svg';

import FollowLink from 'app/components/follow-link/follow-link.index';

import { IActionsProps } from '../item.models';
import { useDesktopContentItemPageStyles } from '../page.content.item.styles';

import subscribeCheck from 'app/utils/util.subscribe.check';
//#endregion

const Actions: FC<IActionsProps> = memo(props => {
  const { containerContentItem, modalsContainer, containerUser, contentId, onOpenQuiz, backUrl, onOpenAudio, onCloseAudio, isAudioClose, mimeTypes } = props;
  const styles = useDesktopContentItemPageStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const backUrlRef = useRef(backUrl);

  const content = containerContentItem.item;

  useEffect(() => {
    backUrlRef.current = backUrl;
  }, [backUrl]);

  const onClickButtonBook = (callback, blank = false) => {
    subscribeCheck({
      subscribe: containerContentItem.section.subscribe,
      callback: () => {
        if (isFunction(callback)) {
          callback();
        } else {
          if (blank) {
            isFunction(onCloseAudio) && onCloseAudio();
            isAudioClose && window.open(callback);
          } else history.push(callback);
        }
      },
      history,
      containerUser,
      containerModals: modalsContainer
    });
  };

  if (containerContentItem.status.item !== ELoadStatus.ready) return null;

  switch (content?.type) {
    case EContentType.BOOK:
      return (
        <div className={ styles.actions }>
          { containerContentItem.item?.free
            ? (
              <>
                { mimeTypes?.pdf && (
                  <FollowLink
                    anchor={ false }
                    node={
                      <Button
                        className={ styles.actionBtn }
                        startIcon={ ReadSvg }
                        type="secondary"
                        disabled={ content?.url === null }
                        size="large"
                      >
                        { t('common.read') }
                      </Button>
                    }
                    to={ content?.url ? `${content?.url}?mimeType=${mimeTypes?.pdf}&download=false` : {} }
                    target="_blank"
                  />
                ) }
                { mimeTypes?.audio &&
                  <Button
                    className={ styles.actionBtn }
                    onClick={ () => isFunction(onOpenAudio) && onOpenAudio() }
                    type="secondary"
                    disabled={ content?.url === null }
                    size="large"
                    startIcon={ AudioSvg }
                  >
                    { t('common.listen') }
                  </Button>
                }
              </>
            ) : (
              <>
                { mimeTypes?.pdf && (
                  <Button
                    onClick={ () => onClickButtonBook(content?.url ? `${content?.url}?mimeType=${mimeTypes?.pdf}&download=false` : {}, true) }
                    className={ styles.actionBtn }
                    startIcon={ ReadSvg }
                    type="secondary"
                    disabled={ content?.url === null }
                    size="large"
                  >
                    { t('common.read') }
                  </Button>
                ) }
                { mimeTypes?.audio && (
                  <Button
                    onClick={
                      () => onClickButtonBook(onOpenAudio)
                    }
                    className={ styles.actionBtn }
                    type="secondary"
                    disabled={ content?.url === null }
                    size="large"
                    startIcon={ AudioSvg }
                  >
                    { t('common.listen') }
                  </Button>
                ) }
              </>
            ) }
          <Button
            className={ styles.actionBtn }
            type="secondary"
            disabled={ content?.url === null }
            size="large"
            onClick={
            () => {
              if (isFunction(onOpenQuiz)) {
                onOpenQuiz();
                isFunction(onCloseAudio) && onCloseAudio();
              }
            }
          }>
            { t('common.passTest') }
          </Button>
        </div>
      );
    default:
      return null;
  }
});

export default flow([ModalsContainer, ContainerContentItem, ContainerUser])(Actions);
