import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { AppRoutes } from 'app/shared/routes/root.routes';

import 'a1s-tele2-react-ui/src/assets/font.styles.css';
import 'app/assets/global.styles.css';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesApp = makeStyles(
  createStyles({
    serif: {
      fontFamily: 'TELE2 DISPLAY'
    },
    contentWrap: {
      margin: '36px 0'
    },
    content: {
      position: 'relative'
    },
    contentWrapTwoLevel: {
      margin: '36px 0 18px'
    },
    skeletonImage: {
      backgroundColor: ui.colors.notification.neutral.translucent,
      '&:before': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: '-1',
        backgroundImage: `linear-gradient(to right, transparent 0%, ${ui.colors.background.dashboard.neutral} 50%, transparent 100%)`,
        animation: '$load 1.5s infinite cubic-bezier(0.4, 0.0, 0.2, 1)'
      }
    },
    '@keyframes load': {
      from: {
        left: '0'
      },
      to: {
        left: '100%'
      }
    },
    linkDNone: {
      textDecoration: 'none'
    },
    suspendLoader: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)'
    },
    sscLine: {
      width: '27%',
      display: 'inline-block',
      margin: '16px 3% !important',
      verticalAlign: 'middle'
    },
    section: {
      position: 'relative',
      margin: '0 -18px',
      padding: '36px 18px 16px',
      overflow: 'hidden'
    },
    sectionItem: {
      position: 'relative',
      margin: '36px -18px',
      padding: '0 18px 0',
      overflow: 'hidden'
    },
    even: {
      '&:before': {
        content: '""',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        background: ui.colors.background.light.neutral,
        zIndex: '-1'
      }
    },
    descItem: {
      fontSize: 16,
      lineHeight: 1.375,
      margin: '18px 0 36px',
      '& -webkit-line-clamp': 3
    },
    mtb36: {
      margin: '36px 0'
    },
    mb36: {
      marginBottom: 36
    },
    mb27: {
      marginBottom: 27
    },
    mb0: {
      marginBottom: 0
    },
    between: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline'
    },
    contentList: {
      padding: '36px 0',
      transform: 'translate3d(0px, 0px, 0px)',
      overflowY: 'auto',
      transitionProperty: 'transform',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minHeight: 216
    },
    contentListMain: {
      padding: '36px 0 18px'
    },
    contentListMainOffset: {
      marginTop: -36,
      padding: '36px 0 18px'
    },
    contentColumn: {
      flexGrow: 1,
      minWidth: 272,
      '&:not(:last-child)': {
        marginRight: 18
      },
      '&.book': {
        minWidth: 'auto',
        display: 'flex',
        flexDirection: 'row'
      },
      '&.study': {
        minWidth: 'auto',
        display: 'flex',
        flexDirection: 'row',
        '&:not(:last-child)': {
          marginRight: 14
        }
      },
      '&.olympiad': {
        minWidth: 'auto',
        display: 'flex',
        flexDirection: 'row',
        '&:not(:last-child)': {
          marginRight: 14
        }
      }
    },
    contentItem: {
      flex: '1 0 26%',
      margin: '0 5px',
      overflow: 'hidden',
      '&:not(:last-child)': {
        marginBottom: 18
      },
      '&.book': {
        marginBottom: 0
      },
      '&.study': {
        flex: '0 0 auto',
        width: 280,
        margin: 0
      },
      '&.olympiad': {
        flex: '0 0 auto',
        width: 280,
        margin: 0
      }
    },
    contentItemWrap: {
      display: 'flex',
      alignItems: 'center',
      '&.book': {
        display: 'block',
        '&& $contentItemTitle': {
          marginTop: 10
        }
      },
      '&.study': {
        display: 'block',
        '&& $contentItemTitle': {
          marginTop: 10
        }
      },
      '&.olympiad': {
        display: 'block',
        '&& $contentItemTitle': {
          marginTop: 10
        }
      }
    },
    contentItemThumb: {
      width: 60,
      height: 60,
      flexShrink: 0,
      marginRight: 16,
      '& img': {
        borderRadius: 6
      },
      '&.book': {
        width: 135,
        height: 202,
        '& img': {
          borderRadius: 0
        }
      },
      '&.study': {
        width: 280,
        height: 190,
        '& img': {
          borderRadius: 0
        }
      },
      '&.olympiad': {
        width: 280,
        height: 190,
        '& img': {
          borderRadius: 0
        }
      }
    },
    contentItemThumbImg: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    contentItemNameWrap: {},
    contentItemTitle: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
      maxHeight: 40,
      overflow: 'hidden',
      color: ui.colors.text.dark.default
    },
    contentItemTitleMain: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 150
    },
    contentItemCaptionMain: {
      display: 'block',
      marginTop: 9.5,
      fontSize: 21,
      lineHeight: '32px',
      fontWeight: 'bold',
      color: ui.colors.text.dark.default,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis'
    },
    contentItemTitleDesc: {
      opacity: 0.5
    },
    contentItemDesc: {
      maxHeight: 18,
      marginTop: 4,
      fontSize: 13,
      lineHeight: '18px',
      margin: 0,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: ui.colors.text.light.neutral
    },
    imgPreviewCircleWrap: {
      width: 138,
      height: 138,
      textAlign: 'center',
      margin: '0 auto',
      display: 'block',
      marginBottom: 36,
      overflow: 'hidden',
      borderRadius: 1000
    },
    imgPreviewCircle: {
      maxHeight: 138,
      display: 'block'
    },
    offerText: {
      color: '#8F9399',
      margin: `${ui.indents['indentX2'].margin}px 0`,
      minHeight: ui.indents['indentX1'].margin,
      lineHeight: `${ui.indents['indentX1'].margin}px`,
      '& a': { color: 'inherit' }
    },
    center: { display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }
  })
);

const App = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('common.titleApp');
  }, []);

  return <AppRoutes />;
};

export default App;
