//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { EContentType } from 'app/models/model.content';
//#endregion

export const useContentItemStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1'
    },

    virtual: {
      width: 'auto',
      marginRight: '-18px',
      paddingRight: 18
    },

    virtualPicture: {
      marginBottom: '0px!important'
    },

    previewImg: {
      display: 'block',
      maxWidth: '100%',
      width: '100%',
      height: 416,
      objectFit: 'cover',
      margin: '15px auto 0'
    },

    article: {
      color: configTheme.colors.text.dark.default,
      fontFamily: 'Tele2 Text',
      wordWrap: 'break-word',
      '& a': {
        color: configTheme.colors.text.dark.default,
        fontFamily: 'Tele2 Text',
        fontStyle: 'normal'
      },
      '& h2': {
        fontFamily: 'Tele2 Display',
        fontSize: 22,
        lineHeight: '28px',
        marginBottom: 0,
        marginTop: 36
      },
      '& h3, h4, h5': {
        fontFamily: 'Tele2 Display'
      },
      '& :nth-child(-n+3) img ': {
        display: 'none'
      },
      '& img': {
        maxWidth: '100%',
        display: 'flex',
        margin: '36px auto'
      }
    },
    articleTitle: {
      marginTop: 36,
      marginBottom: 20
    },

    headline: { marginBottom: configTheme.indents['indentX1'].margin },

    promo: {
      marginTop: configTheme.indents['indentX2'].margin
    },

    pageContent: {
      marginBottom: `${configTheme.indents['indentX2'].margin}px`
    },

    thumbnail: {
      marginBottom: configTheme.indents['indentX1'].margin,
      marginTop: configTheme.indents['indentX2'].margin
    },
    thumbnailPic: {
      margin: `0 0 !important`
    },

    thumbnailPicBlur: {
      filter: 'blur(60px)'
    },

    thumbnailPicImage: {
      height: '233px!important'
    },

    thumbnailDescr: {
      '& img': { width: '100%' }
    },

    announce: {
      padding: `${configTheme.indents['indentX2'].margin}px 0`
    },

    actions: {
      margin: '0 auto',
      paddingBottom: 18
    },

    actionBtn: {
      margin: `${configTheme.indents['indentX1'].margin}px 0`,
      width: 158,
      '&:first-child': {
        marginTop: 0
      }
    },

    studyButton: {
      marginBottom: configTheme.indents['indentX2'].margin
    },

    descr: {
      marginBottom: configTheme.indents['indentX2'].margin,
      [`&.${EContentType.OLYMPIAD}`]: { marginBottom: configTheme.indents['indentX2'].margin * 1.33 }
    },

    modalHeader: {
      marginBottom: configTheme.indents['indentX2'].margin
    },

    center: { display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' },

    recommends: {
      margin: `auto -${configTheme.indents['indentX1'].margin}px 0`,
      padding: `${configTheme.indents['indentX2'].margin}px ${configTheme.indents['indentX1'].margin}px 0`,
      background: configTheme.colors.background.light.neutral
    },

    recommendsTitle: {
      marginBottom: `${configTheme.indents['indentX1.5'].margin}px`
    },

    recommendsName: {
      fontSize: 16,
      lineHeight: '22px',
      marginBottom: `${configTheme.indents['indentX1'].margin / 2}px`
    },

    recommendPack: { marginTop: configTheme.indents['indentX4'].margin },
    recommendList: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
      marginTop: configTheme.indents['indentX3'].margin
    },
    recommendItem: {
      cursor: 'pointer',
      flex: '1 1 calc((100% / 3) - 15px)',
      maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
      width: 'calc((100% / 3) - 15px + (15px / 3))',
      margin: `${configTheme.indents['indentX3'].margin}px 15px 0 0`,
      '&:nth-child(-n + 3)': { marginTop: 0 },
      '&:nth-child(3n)': { marginRight: 0 }
    },
    recommendElement: {
      cursor: 'pointer',
      flex: '1 1 calc((100% / 5) - 30px)',
      maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
      width: 'calc((100% / 5) - 30px + (30px / 5))',
      margin: `${configTheme.indents['indentX3'].margin}px 30px 0 0`,
      '&:nth-child(-n + 5)': { marginTop: 0 },
      '&:nth-child(5n)': { marginRight: 0 }
    },
    iconFree: {
      marginBottom: '5%',
      marginLeft: '5%',
      padding: '2px 2px',
      borderRadius: '50%',
      backgroundColor: configTheme.colors.background.dark.default
    }
  }),
  { name: 'ContentItem' }
);
