import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { isMobile } from 'mobile-device-detect';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesIndividualProgramWorkout = makeStyles<unknown, { mobile?: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    picture: {
      marginBottom: '0px!important'
    },
    blockWorkout: {
      marginTop: '36px',
      // minHeight: '593px',
      maxWidth: isMobile ? '100%' : 742,
      minWidth: isMobile ? '100%' : 742,
      padding: isMobile ? 'none' : '36px',
      borderRadius: ({ mobile }) => mobile ? '10px' : 0,
      paddingBottom: 27,
      boxSizing: 'border-box',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.14)'
    },
    imgTitleBlockMenu: {
      marginRight: '19px'
    },
    imgTitle: {
      marginRight: '18px'
    },
    toggleText: {
      cursor: 'pointer',
      borderBottom: '1px dashed',
      marginLeft: 18,
      width: 'max-content'
    },
    descWorkout: {
      fontSize: '18px',
      fontFamily: 'Tele2 TextSans Web SHORT',
      color: configTheme.colors.text.dark.default
    },
    item: {
      marginBottom: '10px',
      color: configTheme.colors.text.dark.default
    },
    paddingByMobile: {
      padding: isMobile ? '27px 18px' : 'none'
    },
    displayText: {
      fontFamily: 'Tele2 Text',
      color: configTheme.colors.text.dark.default,
      margin: isMobile ? '0 18px 18px' : '27px 0',
      height: 'auto'
    },
    listItemText: {
      fontFamily: 'Tele2 Text',
      color: configTheme.colors.text.dark.default
    },
    videoIcon: {
      display: 'inline-block',
      position: 'absolute',
      height: ({ mobile }) => mobile ? 59 : 80,
      width: ({ mobile }) => mobile ? 59 : 80,
      borderRadius: 50,
      color: configTheme.colors.text.dark.default,
      zIndex: 9,
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      '&:hover': {
        cursor: 'pointer'
      }
    }

  })
);
