import { ESectionLayout } from 'app/models/model.section';

export enum ESubcategoryLayout {
  MENU = 'MENU',
  NONE = 'NONE'
}

export interface ISubcategory {
  id: number;
  name: string;
  categoryId: number;
  preview?: string;
  desktopPreview?: string;
  link?: string;
  sectionLayout?: ESectionLayout;
}
