import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core';
import { isMobile } from 'mobile-device-detect';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesIndividualProgramMenuByDay = makeStyles<unknown, { mobile?: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: isMobile ? 0 : 10
    },
    listItemText: {
      fontFamily: 'Tele2 Text',
      color: configTheme.colors.text.dark.default
    },
    listItem: {
      padding: '0px!important'
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    blockMenuByDay: {
      marginTop: isMobile ? '20px' : '15px',
      maxWidth: isMobile ? '100%' : 742,
      minWidth: isMobile ? '100%' : 742,
      padding: isMobile ? '27px 18px' : '36px',
      borderRadius: ({ mobile }) => mobile ? '10px' : 0,
      boxSizing: 'border-box',
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.14)'
    },
    imgTitleBlockMenu: {
      marginRight: '19px'
    },
    displayDescription: {
      padding: isMobile ? '0 18px' : 'none',
      fontFamily: 'Tele2 Text',
      color: configTheme.colors.text.dark.default,
      marginBottom: 26
    }
  })
);
