//#region imports
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'app/containers/connect';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { INotificationState, INotification } from 'app/entities/notification/notification.reducer';
import {
  getNotifications,
  updateNotification,
  deleteNotification,
  createNotification,
  updateNotificationEntityValue,
  getNotificationType,
  updateNotificationType
} from 'app/entities/notification/notification.actions';
import { ELoadStatus } from 'app/models/shared.model';
import { IQueryNotification } from 'app/models/model.notification';
import { IUpdateNotificationTypeProps } from 'app/models/model.notificationType';

//#endregion

const mapStateToProps = (state: IRootState): INotificationState => state.notification;
const mapDispatchToProps = (dispatch: ThunkDispatch<INotificationState, undefined, any>) => ({
  getNotifications: () => dispatch(getNotifications()),
  updateNotification: (props: INotification) => dispatch(updateNotification(props)),
  createNotification: (props: INotification) => dispatch(createNotification(props)),
  deleteNotification: (id: INotification['id']) => dispatch(deleteNotification(id)),
  updateStatus: (status: ELoadStatus) => dispatch(updateNotificationEntityValue<ELoadStatus>('status', status)),
  getNotificationType: (query: IQueryNotification) => dispatch(getNotificationType(query)),
  updateNotificationType: (props: IUpdateNotificationTypeProps) => dispatch(updateNotificationType(props))
});

export type IContainerNotificationProps = INotificationState & {
  actions: ReturnType<typeof mapDispatchToProps>;
};

export const ContainerNotification = connect<IContainerNotificationProps>('containerNotification', mapStateToProps, mapDispatchToProps);
