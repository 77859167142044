import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { NOTIFICATION_ACTIONS } from './notification.actions';

import { ELoadStatus } from 'app/models/shared.model';

export enum EPeriod {
  DAILY = 'DAILY',
  WORK_DAYS = 'WORK_DAYS'
}

export enum EHowSend {
  SMS = 'SMS',
  PUSH = 'PUSH'
}

export interface INotification {
  enabled: boolean;
  id: number;
  period: EPeriod;
  text: string;
  time: string;
}

const initialState = {
  items: [] as INotification[],
  itemId: 0 as INotification['id'],
  itemHowSend: '' as EHowSend,
  status: ELoadStatus.not_ready,
  error: null as Error
};

export type INotificationState = Readonly<typeof initialState>;

export default (state = initialState, action): INotificationState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(NOTIFICATION_ACTIONS.GET):
      return {
        ...state,
        status: ELoadStatus.loading,
        error: null
      };
    case SUCCESS(NOTIFICATION_ACTIONS.GET):
      return {
        ...state,
        items: payload,
        status: ELoadStatus.ready,
        error: null
      };
    case FAILURE(NOTIFICATION_ACTIONS.GET):
      return {
        ...state,
        status: ELoadStatus.error,
        error: payload.error
      };

    case REQUEST(NOTIFICATION_ACTIONS.CREATE):
      return {
        ...state,
        status: ELoadStatus.loading,
        error: null
      };
    case SUCCESS(NOTIFICATION_ACTIONS.CREATE):
      return {
        ...state,
        items: payload,
        status: ELoadStatus.ready,
        error: null
      };
    case FAILURE(NOTIFICATION_ACTIONS.CREATE):
      return {
        ...state,
        status: ELoadStatus.error,
        error: payload.error
      };

    case REQUEST(NOTIFICATION_ACTIONS.UPDATE):
      return {
        ...state,
        error: null
      };
    case SUCCESS(NOTIFICATION_ACTIONS.UPDATE):
      return {
        ...state,
        items: payload,
        error: null
      };
    case FAILURE(NOTIFICATION_ACTIONS.UPDATE):
      return {
        ...state,
        status: ELoadStatus.error,
        error: payload.error
      };

    case REQUEST(NOTIFICATION_ACTIONS.DELETE):
      return {
        ...state,
        error: null
      };
    case SUCCESS(NOTIFICATION_ACTIONS.DELETE):
      return {
        ...state,
        items: payload,
        error: null
      };
    case FAILURE(NOTIFICATION_ACTIONS.DELETE):
      return {
        ...state,
        status: ELoadStatus.error,
        error: payload.error
      };
    case NOTIFICATION_ACTIONS.UPDATE_ENTITY_VALUE:
      return {
        ...state,
        [payload.entity]: payload.value
      };
    case SUCCESS(NOTIFICATION_ACTIONS.GET_TYPE):
      return {
        ...state,
        itemId: payload.id,
        itemHowSend: payload.notificationType
      };

    default:
      return state;
  }
};
