//#region imports
import { StandardProps } from '@material-ui/core';
//#endregion

export enum EAudioStatus {
  play = 'play',
  pause = 'pause',
  stop = 'stop'
}

export enum EAudioPreload {
  auto = 'auto',
  metadata = 'metadata',
  none = 'none'
}

export interface IAudioProps {
  src: string | string[];
  autoplay?: boolean;
  preload?: EAudioPreload;
  onPlayed?: (event: any) => void;
  onPaused?: (event: any) => void;
  onFinished?: (event: any) => void;
}

export type AudioProps = StandardProps<IAudioProps, never>;
