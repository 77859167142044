import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

interface IProps {
  isMobile434: boolean;
}

export default makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {
      overflowY: ({ isMobile434 }) => (isMobile434 ? 'hidden' : 'initial'),
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    content: {
      display: 'flex'
    },
    dayActive: {
      color: ui.colors.text.dark.default
    },
    dayItem: {
      marginTop: 10,
      whiteSpace: 'pre',
      marginLeft: ({ isMobile434 }) => (isMobile434 ? 18 : 0),
      '&:first-child': {
        marginLeft: ({ isMobile434 }) => (isMobile434 ? 2 : 0)
      }
    }
  })
);
