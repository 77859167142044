import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { isMobile } from 'mobile-device-detect';

export const useStylesIndividualProgramAdviceDiet = makeStyles<unknown, { mobile?: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    picture: {
      marginBottom: '0px!important'
    },
    blockAdviceDiet: {
      marginTop: 36,
      // minHeight: '593px',
      maxWidth: isMobile ? '100%' : 742,
      minWidth: isMobile ? '100%' : 742,
      padding: isMobile ? '27px 18px' : '36px',
      borderRadius: ({ mobile }) => mobile ? '10px' : 0,
      boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.14)',
      boxSizing: 'border-box'
    },
    imgAdviceDiet: {
      marginRight: '19px'
    },
    discDiet: {
      fontSize: '16px',
      color: '#1F2229',
      margin: '27px 0px'
    },
    imgTitle: {
      marginRight: '18px'
    },
    videoIcon: {
      display: 'inline-block',
      position: 'absolute',
      height: isMobile ? 59 : 80,
      width: isMobile ? 59 : 80,
      color: configTheme.colors.text.dark.default,
      zIndex: 9,
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  })
);
