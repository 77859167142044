import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import { ELoadStatus } from 'app/models/shared.model';
import { IPrize } from 'app/models/model.prize';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { PRIZE_ACTIONS } from 'app/entities/prize/prize.actions';

const initialState = {
  list: [] as IPrize[],
  personal: [] as IPrize[],
  request: {
    status: {
      list: ELoadStatus.not_ready,
      personal: ELoadStatus.not_ready
    },
    error: {
      list: null as Error,
      personal: null as Error
    }
  }
};

export type IPrizeState = Readonly<typeof initialState>;

export default (state = initialState, action): IPrizeState => {
  const { type, payload, meta } = action;
  const uncancelled = meta?.props?.uncancelled ?? false;
  switch (type) {
    case REQUEST(PRIZE_ACTIONS.FETCH_LIST):
      return flow([set(`request.status.list`, ELoadStatus.loading), set(`request.error.list`, initialState.request.error.list)])(state);
    case SUCCESS(PRIZE_ACTIONS.FETCH_LIST):
      if (payload?.canceled && uncancelled) return state;
      return flow([
        set(`list`, payload.data.prizes ?? []),
        set(`request.status.list`, payload?.canceled ? ELoadStatus.error : ELoadStatus.ready)
      ])(state);
    case FAILURE(PRIZE_ACTIONS.FETCH_LIST):
      return flow([
        set(`list`, initialState.list),
        set(`request.status.list`, ELoadStatus.error),
        set(`request.error.list`, new Error(`list fetch failed`))
      ])(state);

    case REQUEST(PRIZE_ACTIONS.FETCH_PERSONAL):
      return flow([
        set(`request.status.personal`, ELoadStatus.loading),
        set(`request.error.personal`, initialState.request.error.personal)
      ])(state);
    case SUCCESS(PRIZE_ACTIONS.FETCH_PERSONAL):
      return flow([set(`personal`, payload.data.prizes ?? []), set(`request.status.personal`, ELoadStatus.ready)])(state);
    case FAILURE(PRIZE_ACTIONS.FETCH_PERSONAL):
      return flow([
        set(`personal`, initialState.personal),
        set(`request.status.personal`, ELoadStatus.error),
        set(`request.error.personal`, new Error(`personal fetch failed`))
      ])(state);

    case PRIZE_ACTIONS.RESET_LIST:
      return flow([
        set(`list`, initialState.list),
        set(`request.status.list`, initialState.request.status.list),
        set(`request.error.list`, initialState.request.error.list)
      ])(state);

    case PRIZE_ACTIONS.RESET_PERSONAL:
      return flow([
        set(`personal`, initialState.personal),
        set(`request.status.personal`, initialState.request.status.personal),
        set(`request.error.personal`, initialState.request.error.personal)
      ])(state);

    default:
      return state;
  }
};
