import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStylesModalIndividualProgram = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center'
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    paper: {
      position: 'absolute',
      width: 732,
      height: 457,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: '36px 36px 54px'
    },
    imgClose: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    telNumber: {
      fontWeight: 'bold'
    },
    btnBlock: {
      marginTop: 36
    },
    btnLinkCancel: {
      color: 'inherit',
      textDecoration: 'none'
    },
    btnCancel: {
      backgroundColor: 'inherit',
      width: 183,
      height: 60,
      color: 'inherit',
      fontFamily: 'Tele2 TextSans Web SHORT',
      fontWeight: 'bold'
    },
    btnSubscribe: {
      marginRight: 36,
      width: 183,
      height: 60
    },
    descBlock: {
      marginTop: 36,
      color: '#8A8F97'
    },
    linkUserAgreements: {
      color: '#8A8F97'
    }
  })
);
