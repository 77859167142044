//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { ECategoriesClassKey, CategoriesClasses } from './categories.models';
//#endregion

export const useCategoriesStyles = makeStyles(
  createStyles<CategoriesClasses, {}>({
    [ECategoriesClassKey.root]: {
      paddingBottom: 54
    }
  }),
  { name: 'CategoriesMain' }
);
