import React from 'react';
import ContentLoader from 'react-content-loader';

export interface IPlugContentLoader {
  amount: number;
}

export const PlugContentLoader = (props: IPlugContentLoader) => <>
  {
    Array(props.amount).fill(props.amount).map((i, index) => (
      <ContentLoader viewBox="0 0 320 280" height={ 280 } width={ '100%' } key={ index }>
        <rect x="0" y="17" rx="0" ry="0" width="100%" height="200" />
        <rect x="0" y="225" rx="2" ry="2" width="275" height="15" />
        <rect x="0" y="245" rx="2" ry="2" width="140" height="15" />
      </ContentLoader>
    ))
  }
</>;
