//#region imports
import { FC } from 'react';
import { StandardProps } from '@material-ui/core';

import { EAgeType } from 'app/models/shared.model';
import { ICategory } from 'app/models/model.category';
import { ICategoryContainerProps, CategoryContainer } from 'app/containers/category.container';
//#endregion

//#region Categories
export interface ICategoriesProps {
  data?: ICategory[];
  loading?: boolean;
  uAgeType?: string;
  onConfirm?: (e: { type?: string; url?: string }) => void;
}

export const ECategoriesClassKey = {
  root: 'root'
} as const;

// const assertions (TS 3.4+)
export type CategoriesClasses = typeof ECategoriesClassKey[keyof typeof ECategoriesClassKey];

export type CategoriesProps = StandardProps<ICategoriesProps, CategoriesClasses>;
//#endregion

//#region ConnectedCategories
export interface IConnectedCategoriesProps {
  containerCategory: ICategoryContainerProps;
}

export type ConnectedCategoriesProps = IConnectedCategoriesProps & CategoriesProps;
//#endregion
