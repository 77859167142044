//#region imports
import React, { FC, memo, useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import shuffle from 'lodash/shuffle';
import filter from 'lodash/filter';
import flow from 'lodash/fp/flow';
import slice from 'lodash/slice';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType, IContent } from 'app/models/model.content';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerContentList } from 'app/containers/container.content.list';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { EPostcardClassKey, ImageFallback } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';

import useLimiter from 'app/hooks/useLimiter';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';

import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import FollowLink from 'app/components/follow-link/follow-link.index';

import { IRecommendsProps } from '../item.models';
import { useDesktopContentItemPageStyles } from '../page.content.item.styles';
import { useHistory } from 'react-router';
//#endregion

const Recommends: FC<IRecommendsProps> = memo(props => {
  const { containerContentItem, containerContentList, contentId } = props;
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
  const styles = useDesktopContentItemPageStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const sectionId = containerContentItem.section?.section?.id;
  const contentType = containerContentItem.item?.type;

  const contents = containerContentList.items;
  const loading = containerContentList.status.items === ELoadStatus.loading;
  const more = containerContentList.more;

  const [paging, pagingEmiter] = useLimiter();
  useEffect(() => {
    if (sectionId) {
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        cancellable: true
      });
      containerContentList.actions.logView();
    }
    return () => {
      pagingEmiter.invokeReset();
      containerContentList.actions.resetContentList();
    };
  }, [sectionId]);

  const scrollRef = useRef<HTMLDivElement>();
  const [recommends, setRecommends] = useState([]);

  useEffect(() => {
    const recommendList = slice(shuffle(filter(contents, (c: IContent) => c?.id !== contentId && c?.type === contentType)), 0, 3);
    setRecommends(recommendList);
  }, [contentId, contents]);

  const recommendsExists = recommends?.length > 0;
  if (!recommendsExists) return null;

  switch (contentType) {
    case EContentType.BOOK:
      return (
        <div className={ styles.recommends }>
          <Typography className={ styles.recommendsTitle } type="heading" level={ 1 } tag="div">
            { t('common.recommend') }
          </Typography>
          <VirtualHorizontalList
            style={ { overflowX: 'initial' } }
            parentRef={ scrollRef }
            classes={ {
              item: styles.virtualItem,
              root: styles.recommendsVirtual
            } }
            size={ recommends?.length }
            width={ 362 }
            spacing={ configTheme.indents['indentX1'].margin - 3 }
            fallbackHeight={ 498 }
            autoHeight
            renderItem={ memo<any>(({ index }) => {
              const c = recommends?.[index];
              return (
                <Postcard
                  width={ 362 }
                  height={ 362 }
                  classes={ {
                    [EPostcardClassKey.picture]: styles.recommendsPicture,
                    [EPostcardClassKey.descr]: styles.recommendsCardDescr,
                    [EPostcardClassKey.root]: styles.recommendsCard
                  } }
                  src={ c?.imageUrl }
                  fallback={ ImageFallback }
                  extraTop={ c?.new && <PostcardBadge text={ t('content.new') } type="secondary" offset={ { top: '36px' } } size="large"/> }
                  extraBottom={ c?.free && <IconText classes={ { root: styles.iconFree } } color="white" iconOnly /> }
                  onPictureClick={ () => history.push(CONTENT_ITEM(c?.type, c?.id)) }
                >
                  <Typography
                    className={ classnames(ellipsisStyles[EEllipsisClassKey.multiline], styles.recommendsName) }
                    type="text"
                    size="large"
                    theme="light"
                    onClick={ () => history.push(CONTENT_ITEM(c?.type, c?.id)) }
                  >
                    { c?.name }
                  </Typography>
                </Postcard>
              );
            }) }
            renderFallback={ memo<any>(VirtualFallback) }
          />
        </div>
      );
    default:
      return <div className={ styles.recommends } />;
  }
});

export default flow([ContainerContentItem, ContainerContentList])(Recommends);
