import React, {
  SyntheticEvent,
  CSSProperties,
  DependencyList,
  FC,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  createContext,
  memo
} from 'react';
import { useDebounce, useIsomorphicLayoutEffect } from 'react-use';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import every from 'lodash/every';
import flow from 'lodash/flow';
import find from 'lodash/find';
import each from 'lodash/each';
import map from 'lodash/map';

import { IAuthContainerProps, ContainerUser } from 'app/containers/container.user';
import { ILoggingContainer, LoggingContainer } from 'app/containers/logging.container';
import { CategoryContainer } from 'app/containers/category.container';
import { SectionMenuContainer } from 'app/containers/menu.section.container';

import toastify from 'app/configs/toastify.async';
import { PROFILE, SECTIONS, CONTENT_LIST, SUBCATEGORIES } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { phoneMaskDry } from 'a1s-tele2-react-ui/src/utils/utils.mask';

import { stylesApp } from 'app/app';
import { Footer } from 'app/components/desktop/footer/footer.index';
import CircularLoader from 'app/components/loader/loader.circular';
import AppBar from 'app/components/desktop/app.bar/app.bar.index';
import NavBar from 'app/components/desktop/nav.bar/nav.bar.index';
import UserChip from 'app/components/desktop/user.chip/user.chip.index';

import { ECategoryAllButtonLink } from 'app/models/model.category';
import { ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { loginExternal } from 'app/utils/auth.external';
import useLimiter from 'app/hooks/useLimiter';

export const stylesLayout = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: { minWidth: 1024 }
    },

    root: {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column'
    },
    appRoot: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: configTheme.colors.background.white.default
    },
    contentRoot: {}
  })
);

export interface IDropdownLinks {
  text: string;
  link: string;
}

export interface IDropdownItemModalProps {
  name: string;
  link: string;
  links?: IDropdownLinks[];
  isActive?: (args?: unknown) => boolean | void;
}

interface IDesktopLayoutProps extends IRouteProps {
  isVisibleFooter?: boolean;
  containerUser: IAuthContainerProps;
  loggingContainer: ILoggingContainer;
}

//#region CategoryMenu
const CategoryMenuContext = createContext({ data: [], loading: false });
const CategoryMenuProvider = flow([CategoryContainer, SectionMenuContainer])(props => {
  const isAllButtonLinkLikeFirstSection = true; // hard code
  const { containerCategory, sectionMenuContainer, children } = props;

  const categoryItems = containerCategory.items;
  const sectionItems = sectionMenuContainer.list;
  const [sectionItemsPaging, sectionItemsPagingEmitter] = useLimiter(1, 0);
  const [data, setData] = useState([]);

  useEffect(() => {
    containerCategory.actions.fetchCategoryItems();
    return () => {
      sectionItemsPagingEmitter.invokeReset();
      sectionMenuContainer.actions.resetSectionMenu();
    };
  }, []);

  useEffect(() => {
    each(categoryItems, c => {
      const isSubcategoriesExist = c.subcategories && c.subcategories.length > 0;
      const currentSections = isSubcategoriesExist && find(sectionItems, { targetId: c.subcategories[0].id });
      if (!currentSections?.targetId && (isAllButtonLinkLikeFirstSection || c.allButtonLink === ECategoryAllButtonLink.FIRST_SECTION)) {
        sectionMenuContainer.actions.fetchSectionMenu({ id: c.subcategories[0].id, pager: sectionItemsPaging.current() });
      }
    });
  }, [categoryItems, sectionItems]);

  useDebounce(
    () => {
      if (categoryItems.length > 0 && sectionItems.length > 0 && every(sectionItems, s => s.status === ELoadStatus.ready)) {
        const menu = map(
          filter(categoryItems, c => c.subcategories && c.subcategories.length > 0),
          c => {
            const currentSections = find(sectionItems, { targetId: c.subcategories[0].id });
            const { id, name, ageType } = c;
            const categoryId = c.id;
            const subcategoryId = c.subcategories[0].id;
            const firstSectionId = currentSections?.items?.[0]?.id;
            let link;
            const isActive = (() => {
              return () => {
                return window.location.pathname.includes(`${c.id}/content/list`);
              };
            })();
            if (isAllButtonLinkLikeFirstSection) {
              link = CONTENT_LIST(c.id, currentSections?.items?.[0]?.id);
            } else {
              switch (c.allButtonLink) {
                case ECategoryAllButtonLink.CUSTOM:
                  link = c.allButtonUrl;
                  break;
                case ECategoryAllButtonLink.FIRST_SECTION:
                  link = CONTENT_LIST(c.id, currentSections?.items?.[0]?.id);
                  break;
                case ECategoryAllButtonLink.ALL_SUBCATEGORIES:
                  link = SUBCATEGORIES(c.id);
                  break;
                case ECategoryAllButtonLink.ALL_SECTIONS:
                case ECategoryAllButtonLink.NONE:
                default:
                  link = SECTIONS(c.id, c.subcategories[0].id);
                  break;
              }
            }
            return { id, name, ageType, link, categoryId, subcategoryId, firstSectionId, isActive };
          }
        );
        const dietItem = {
          ageType: 'ALL',
          allButtonLink: 'ALL_SECTIONS',
          link: '/diet',
          content: '',
          id: 1,
          name: 'Моя диета',
          sectionLayout: 'MENU'
        };
        setData([dietItem, ...menu]);
      }
    },
    300,
    [categoryItems, sectionItems]
  );

  return <CategoryMenuContext.Provider value={ { data, loading: !data?.length } }>{ children }</CategoryMenuContext.Provider>;
});

const CategoryMenu = memo(() => {
  const { data } = useContext<any>(CategoryMenuContext);
  return <NavBar data={ data } />;
});
//#endregion CategoryMenu

const DesktopLayoutFC: FC<IDesktopLayoutProps> = props => {
  const { containerUser, children, isVisibleFooter = true } = props;
  const {
    actions: { logLogin }
  } = props.loggingContainer;

  const [footerHeight, setFooterHeight] = useState(0);
  const [, rerender] = useState<unknown>();
  const history = useHistory();
  const lastLocation = useLastLocation();

  const footerRef = useRef<HTMLElement>(null);

  const styles = stylesLayout();
  const classesApp = stylesApp();
  const rootStyles: CSSProperties = (() => {
    return {
      minHeight: window.innerHeight,
      paddingBottom: footerHeight
    };
  })();

  const { ToastContainer } = toastify;

  useIsomorphicLayoutEffect(() => {
    const anchor = props?.location && props?.location?.hash;
    if (anchor && anchor.match('#')) {
      const domElement = document.getElementById(anchor.replace('#', ''));
      if (domElement) {
        domElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, [props?.location?.hash, props?.location?.key]);

  useIsomorphicLayoutEffect(() => {
    const setHeight = debounce(() => {
      const footerEl = footerRef.current;
      if (footerEl) setFooterHeight(footerEl.clientHeight);
      rerender(performance.now());
    }, 300);
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, [footerRef.current]);

  const toolbarData = useMemo(() => {
    return [
      {
        name: 'profile',
        component: (
          <UserChip
            link={ PROFILE }
            label={ containerUser?.msisdn && phoneMaskDry(containerUser.msisdn) }
            authenticated={ containerUser?.authenticated }
            onClick={ e => {
              if (!containerUser?.authenticated) {
                e?.preventDefault();
                loginExternal(props?.location?.pathname || (lastLocation && lastLocation.pathname) || '/');
              }
            } }
          />
        )
      }
    ];
  }, [containerUser.authenticated]);

  return (
    <div className={ styles.appRoot }>
      <ToastContainer style={ { width: '50%' } } position={ 'bottom-center' } />
      <AppBar toolbarData={ toolbarData } />
      <CategoryMenuProvider>
        <CategoryMenu />
      </CategoryMenuProvider>
      <div className={ styles.contentRoot }>
        <div className={ styles.root } style={ rootStyles }>
          <Suspense
            fallback={
              <div className={ classesApp.suspendLoader }>
                <CircularLoader />
              </div>
            }
          >
            { children }
          </Suspense>
          { isVisibleFooter && <Footer ref={ footerRef } /> }
        </div>
      </div>
    </div>
  );
};

export const DesktopLayout = flow([ContainerUser, LoggingContainer])(DesktopLayoutFC);
