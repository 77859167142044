import React, { useEffect } from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Logger } from 'app/entities/logging/logger';
import { uiSetup } from 'app/configs/theme';

import { Header } from 'app/components/header/header.ui';
import { stylesComponentError } from 'app/components/error/component.error.style';

import errorImg from 'app/assets/img/error.png';

export interface IComponentErrorProps {
  code: number;
  callback: Function;
  isCommon?: boolean;
  mobile?: boolean;
}

export const ComponentError = ({ code, callback, isCommon = true, mobile = true }: IComponentErrorProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classesError = stylesComponentError();
  const currentCode = code || 404;
  const localeKey = isCommon ? 'message' : currentCode;

  useEffect(() => {
    return () => callback();
  }, []);

  return (
    <>
      { uiSetup && <Logger canSend /> }
      <div className={ cx(classesError.wrapper, { [classesError.mobile]: mobile }) }>
        <img src={ errorImg } alt={ 'pageNotFound' } className={ classesError.img } />
        <div className={ classesError.contentWrap }>
          <Header className={ classesError.title } component={ 'h1' }>
            { mobile ? (
              <>
                { t('error.errorWord') }
                { '\u00A0' }
                { currentCode || 404 }
              </>
            ) : (
              <>{ t('error.message.desc') }!</>
            ) }
          </Header>
          <span className={ classesError.desc }>
            { mobile ? t(`error.${localeKey}.desc`) : 'Мы уже знаем об этом и работаем над решением проблемы.' }
          </span>
          <br />
          <ul className={ classesError.list }>
            <li className={ classesError.item }>
              <div className={ classesError.link } onClick={ () => (history.length > 2 ? history.goBack() : history.push('/')) }>
                { t('error.message.goBack') }
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
