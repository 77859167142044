import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { ELoadStatus } from 'app/models/shared.model';

import { ANNOUNCE_ACTION } from 'app/entities/announce/announce.actions';
import { IAnnounce } from 'app/entities/announce/announce.models';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

export interface IAnnounceInitialState {
  section?: IAnnounce;
  target: {
    section?: number | string;
  };
  status: {
    section?: ELoadStatus;
  };
  error: {
    section?: Error;
  };
}

const initialState: IAnnounceInitialState = {
  section: {} as IAnnounce,
  target: {
    section: null
  },
  status: {
    section: ELoadStatus.not_ready
  },
  error: {
    section: null as Error
  }
};

export type IAnnounceState = Readonly<IAnnounceInitialState>;

export default (state = initialState, action): IAnnounceState => {
  const { type, payload, meta } = action;
  switch (type) {
    case REQUEST(ANNOUNCE_ACTION.FETCH_SECTION):
      return flow([
        set('target.section', meta.props.targetId),
        set('status.section', ELoadStatus.loading),
        set('error.section', initialState.error.section)
      ])(state);
    case SUCCESS(ANNOUNCE_ACTION.FETCH_SECTION):
      return flow([
        set('target.section', meta.props.targetId),
        set('status.section', payload?.canceled ? ELoadStatus.error : ELoadStatus.ready),
        set('section', payload.data.announcement as IAnnounce)
      ])(state);
    case FAILURE(ANNOUNCE_ACTION.FETCH_SECTION):
      return flow([
        set('target.section', meta.props.targetId),
        set('status.section', ELoadStatus.error),
        set('error.section', new Error('section of announce fetch failed'))
      ])(state);
    case ANNOUNCE_ACTION.RESET_SECTION:
      return flow([
        set('section', initialState.section),
        set('target.section', initialState.target.section),
        set('error.section', initialState.error.section),
        set('status.section', initialState.status.section)
      ])(state);

    default:
      return state;
  }
};
