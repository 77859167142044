import React, { memo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToggle, useIsomorphicLayoutEffect } from 'react-use';
import { isMobile } from 'mobile-device-detect';
import { makeStyles, createStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import classnames from 'classnames';
import isElement from 'lodash/isElement';
import debounce from 'lodash/debounce';

import assign from 'lodash/assign';
import map from 'lodash/map';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import Wrapper from 'app/components/wrapper/wrapper.index';
import { FollowLink } from 'app/components/follow-link/follow-link.index';

export const useNavBarStyles = makeStyles(
  createStyles({
    wrapper: {
      borderBottom: `2px solid ${configTheme.colors.border.primary.light}`
    },

    root: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'stretch',
      justifyContent: 'start',
      boxSizing: 'border-box',
      height: '100%',
      minHeight: 56,
      maxHeight: 56,
      margin: 0,
      listStyle: 'none'
    },

    item: {
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      minHeight: '100%',
      padding: `0 ${configTheme.indents['indentX1'].margin * 0.5}px`,
      fontSize: 16,
      lineHeight: '22px',
      '&:first-child': { paddingLeft: 0 },
      '&:last-child': { paddingRight: 0 },
      '@media(max-width: 1440px)': { fontSize: 16 },
      '@media(max-width: 1366px)': { fontSize: 14 },
      '@media(max-width: 1280px)': { fontSize: 12 }
    },

    link: {
      textDecoration: 'none',
      color: configTheme.colors.text.light.neutral,
      '&.active': { 'pointer-events': 'none', color: configTheme.colors.text.dark.default },
      '&.active:after': { opacity: 1 },
      '&:after': {
        content: '""',
        opacity: 0,
        position: 'absolute',
        top: '100%',
        left: 9,
        right: 9,
        height: 2,
        backgroundColor: configTheme.colors.background.dark.default
      },
      '$item:first-child &:after': { left: 0 },
      '$item:last-child &:after': { right: 0 }
    }
  }),
  { name: 'NavBar' }
);

const NavBar = memo<any>(props => {
  const { t } = useTranslation();
  const { data, loading } = props;
  const styles = useNavBarStyles();

  const render = () => {
    const loader = <Skeleton variant="rect" width="100%" height={ 56 } animation="wave" />;
    const menu = (
      <nav className={ styles.root }>
        { map(data, (item, index) => (
          <span key={ item.id || `${index}.${item.name}` } className={ styles.item }>
            <FollowLink
              className={ styles.link }
              activeClassName="active"
              type="navlink"
              to={ item.link }
              isActive={ item?.isActive }
              exact={ item?.exact }
              strict={ item?.strict }
              onClick={ item?.onClickMenuItemBefore }
            >
              { item.name }
            </FollowLink>
          </span>
        )) }
      </nav>
    );
    return (
      <Wrapper className={ styles.wrapper } htmlColor={ configTheme.colors.background.light.default }>
        { loading ? loader : menu }
      </Wrapper>
    );
  };

  return <>{ render() }</>;
});
NavBar.displayName = 'NavBar';

export default NavBar;
