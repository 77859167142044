//#region imports
import React, { FC, memo, forwardRef, useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForkRef } from '@material-ui/core';
import classnames from 'classnames';

import isFunction from 'lodash/isFunction';
import includes from 'lodash/includes';
import find from 'lodash/find';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { EContentType, IContent } from 'app/models/model.content';
import { EAgeType, ELoadStatus } from 'app/models/shared.model';
import { ContentProps, ConnectedContentProps, EContentClassKey } from './content.model';

import { ContainerContentListMain } from 'app/containers/container.content.list.main';
import { IContentListMainInitialState } from 'app/entities/content/content.list.main.reducer';

import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import FollowLink from 'app/components/follow-link/follow-link.index';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { ImageFallback } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';

import useLimiter from 'app/hooks/useLimiter';

import { useContentStyles } from './content.styles';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
//#endregion

//#region Content
const Content: FC<ContentProps> = memo(
  forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
    const { className, style, classes, category, subcategory, sectionItem, data, loadData, loading, more, isSubsectionItem, uAgeType, onConfirm } = props;
    const targetId = sectionItem?.id;
    const styles = useContentStyles({ classes });
    const ellipsisStyles = useEllipsisStyles({});
    const { t } = useTranslation();

    const [contentType, setContentType] = useState(null);
    useEffect(() => {
      const ctype = sectionItem?.contentType ?? data?.[0]?.type;
      if (includes(Object.values(EContentType), ctype)) setContentType(ctype);
      else setContentType(null);
    }, [targetId, data]);

    const rootRef = useRef<HTMLDivElement>(null);
    const handleRef = useForkRef<HTMLDivElement>(rootRef, ref);
    const scrollRef = useRef<HTMLDivElement>();

    const isAgeAdult = uAgeType === EAgeType.ADULT;
    const handleClick = (e, url) => {
      if (!isAgeAdult && category.ageType === EAgeType.ADULT) {
        e.preventDefault();
        if (isFunction(onConfirm)) onConfirm({ type: EAgeType.ADULT, url });
      }
    };

    switch (contentType) {
      case EContentType.BOOK:
        return (
          <VirtualHorizontalList
            className={ styles.virtual }
            key={ contentType }
            parentRef={ scrollRef }
            size={ data?.length }
            width={ 135 }
            spacing={ configTheme.indents['indentX1'].margin }
            infinite={ more }
            loading={ loading }
            onLoad={ loadData }
            fallbackHeight={ 218 }
            autoHeight
            renderItem={ memo<any>(({ index }) => {
              const c = data?.[index];
              return (
                <FollowLink
                  anchor={ false }
                  node={
                    <div>
                      <Postcard
                        width={ 135 }
                        height={ 135 }
                        src={ c?.imageUrl }
                        fallback={ ImageFallback }
                        extraTop={ c?.new && <PostcardBadge type="secondary" text={ t('content.new') } offset={ { top: '18px' } } /> }
                        extraBottom={ c?.free && <IconText classes={ { root: styles.iconFree } } color="white" iconOnly /> }
                      >
                        <Typography className={ classnames(styles.itemText, ellipsisStyles[EEllipsisClassKey.multiline]) } type="text" size="small">
                          { c?.name }
                        </Typography>
                      </Postcard>
                    </div>
                  }
                  // to={ c?.link ?? CONTENT_ITEM(c?.type, c?.id) }
                  to={ {
                    pathname: CONTENT_ITEM(c?.type, c?.id),
                    state: !isSubsectionItem
                      ? { sectionId: sectionItem.id }
                      : { sectionId: sectionItem.parentId, subsectionId: sectionItem.id }
                  } }
                  onClick={ handleClick }
                />
              );
            }) }
            renderFallback={ memo<any>(VirtualFallback) }
          />
      );
      case EContentType.STUDY:
        return (
          <VirtualHorizontalList
            style={ { marginRight: '-18px', paddingRight: '18px' } }
            parentRef={ scrollRef }
            size={ data?.length }
            width={ 280 }
            spacing={ configTheme.indents['indentX1'].margin }
            infinite={ more }
            loading={ loading }
            onLoad={ loadData }
            fallbackHeight={ 256 }
            autoHeight
            renderItem={ memo<any>(({ index }) => {
              const c = data?.[index];
              return (
                <FollowLink
                  anchor={ false }
                  node={
                    <div>
                      <Postcard
                        width={ 280 }
                        height={ 192 }
                        src={ c?.imageUrl }
                        fallback={ ImageFallback }
                      >
                        <Typography className={ ellipsisStyles[EEllipsisClassKey.line] } type="text" size="large" tag="div">
                          { c?.name }
                        </Typography>
                      </Postcard>
                    </div>
                  }
                  to={ {
                    pathname: CONTENT_ITEM(c?.type, c?.id),
                    state: !isSubsectionItem
                        ? { sectionId: sectionItem.id }
                        : { sectionId: sectionItem.parentId, subsectionId: sectionItem.id }
                  } }
                  onClick={ handleClick }
                />
              );
            }) }
            renderFallback={ memo<any>(VirtualFallback) }
          />
        );

      default:
        return <div ref={ handleRef } className={ classnames(className, styles[EContentClassKey.root]) } style={ style } />;
    }
  })
);
Content.displayName = 'ContentMain';
//#endregion

//#region ConnectedContent
const ConnectedContent: FC<ConnectedContentProps> = forwardRef<typeof Content, ConnectedContentProps>((connectedProps, ref) => {
  const { containerContentListMain, ...props } = connectedProps;
  const { sectionItem } = props;
  const targetId = sectionItem?.id;
  const [paging, pagingEmiter] = useLimiter();

  useEffect(() => {
    if (sectionItem?.id) {
      containerContentListMain.actions.fetchContentMain({ ...sectionItem, pager: paging.current() });
      containerContentListMain.actions.logView();
    }
    return () => {
      pagingEmiter.invokeReset();
      containerContentListMain.actions.resetContentMain();
    };
  }, [targetId, sectionItem]);

  const content: IContentListMainInitialState = useMemo(() => {
    return find(containerContentListMain.list, i => i.targetId === targetId);
  }, [containerContentListMain?.list, targetId]);

  const loadData = useCallback(() => {
    if (sectionItem?.id) {
      pagingEmiter.invokeNext();
      containerContentListMain.actions.fetchContentMain({ ...sectionItem, pager: paging.current(), more: content?.more });
      containerContentListMain.actions.logView();
    }
  }, [sectionItem, content?.more]);

  return (
    <Content
      ref={ ref }
      { ...props }
      data={ content?.items }
      loading={ content?.status === ELoadStatus.loading }
      more={ content?.more }
      loadData={ loadData }
    />
  );
});
ConnectedContent.displayName = 'ConnectedContentMain';
//#endregion

export default ContainerContentListMain(ConnectedContent) as typeof Content;
