import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { BANNER_ACTIONS } from 'app/entities/banner/banner.actions';
import { IBanner } from 'app/models/model.banner';
import { ELoadStatus } from 'app/models/shared.model';

const initialState = {
  items: [] as IBanner[],
  request: {
    status: {
      items: ELoadStatus.ready
    },
    error: {
      items: null as Error
    }
  }
};

export type IBannerState = Readonly<typeof initialState>;

export default (state = initialState, action): IBannerState => {
  const { type, payload } = action;
  const { error, status } = state.request;
  switch (type) {
    case REQUEST(BANNER_ACTIONS):
      return {
        ...state,
        request: {
          status: {
            ...status,
            items: ELoadStatus.loading
          },
          error: {
            ...error,
            items: initialState.request.error.items
          }
        }
      };
    case SUCCESS(BANNER_ACTIONS.FETCH):
      return {
        ...state,
        items: payload.data.banners,
        request: {
          ...state.request,
          status: {
            ...status,
            items: ELoadStatus.ready
          }
        }
      };
    case FAILURE(BANNER_ACTIONS.FETCH):
      return {
        ...state,
        request: {
          status: {
            ...status,
            items: ELoadStatus.error
          },
          error: {
            ...error,
            items: new Error('banner fetch failed')
          }
        }
      };
    default:
      return state;
  }
};
