import React, { FC, forwardRef } from 'react';

import Modal from '@material-ui/core/Modal';

import { useStylesModalMobileCalcProgram } from '../program.style';
import { ProgramLoader } from 'app/pages/desktop/diet/program/components/program.loader';

interface IModalProgramLoaderProps {
  isModalProgramLoader: boolean;
  setModalProgramLoader: (boolean) => void;
}

export const ModalProgramLoader: FC<IModalProgramLoaderProps> = forwardRef(({ isModalProgramLoader, setModalProgramLoader }, ref) => {
  const styles = useStylesModalMobileCalcProgram();

  return (
    <>
      <Modal
        open={ isModalProgramLoader }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={ { minHeight: '100vh' } }
      >
        <div className={ styles.wrapper }>
          <ProgramLoader setModalCalcProgram={ setModalProgramLoader }/>
        </div>
      </Modal>
    </>
  );
});
