//#region imports
import React, { CSSProperties, FC, forwardRef, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useForkRef } from '@material-ui/core';
import PlaySvg from 'a1s-tele2-react-ui/src/assets/icons/playCircle.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import assign from 'lodash/assign';

import { EContentType } from 'app/models/model.content';
import { EContentCardClassKey } from 'app/components/desktop/content.card/content.card.models';
import { EPostcardLayout } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';

import ContentCard from 'app/components/desktop/content.card/content.card.index';
import Audio from 'app/components/desktop/audio/audio.index';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';

import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';

import { ItemCardProps, OFormat } from './item.card.models';
import { useItemCardStyles } from './item.card.styles';
import AudioImg from 'app/assets/img/music.svg';

//#endregion

//#region ItemCard
const ItemCard: FC<ItemCardProps> = forwardRef<HTMLDivElement, ItemCardProps>((props, ref) => {
  const {
    className,
    style,
    classes,
    format,
    contentType,
    preview,
    title,
    titleClamp,
    data,
    descr,
    mediaSrc,
    onClick,
    onPictureClick,
    onTitleClick,
    onButtonClick,
    free,
    handleLike,
    handleDecline,
    subscribeStatus,
    itemCardId,
    contentItemId,
    authStatus,
    liked,
    onMediaAudioPlayed,
    withIndicator,
    isNew
  } = props;

  const ellipsisStyles = useEllipsisStyles({});
  const styles = useItemCardStyles({ classes });

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);
  const { t } = useTranslation();

  const computedStyle = useMemo(() => {
    const _style = {};
    switch (contentType) {
      case EContentType.GAME_ANDROID:
      case EContentType.GAME_HTML5:
        assign(_style, { width: '100%', height: 360 });
        break;
      case EContentType.BOOK:
        if (format === OFormat.Compact) assign(_style, { width: '100%', height: 380 });
        else assign(_style, { width: 742, height: 341 });
        break;
      case EContentType.ARTICLE:
        if (format === OFormat.Compact) assign(_style, { width: '100%', height: 380 });
        else assign(_style, { height: '341px', width: 742, padding: 45 });
        break;
      case EContentType.AUDIO:
        assign(_style, { width: '100%', height: 136 });
        break;
      case EContentType.VIDEO:
        if (format === OFormat.Compact) assign(_style, { width: '100%', height: 300 });
        else assign(_style, { width: '100%', height: 538 });
        break;
      case EContentType.IMAGE:
      case EContentType.STUDY:
      case EContentType.OLYMPIAD:
      default:
        assign(_style, { width: '100%', height: format === OFormat.Compact ? 300 : 511 });
        break;
    }
    return assign(_style, style);
  }, [style, format, contentType]);

  const computedPreview = useMemo(() => {
    const _preview = { src: null };
    switch (contentType) {
      case EContentType.GAME_ANDROID:
      case EContentType.GAME_HTML5:
        assign(_preview, { width: 158, height: 158 });
        break;
      case EContentType.ARTICLE:
        if (format === OFormat.Compact) assign(_preview, { width: 158, height: 212 });
        else assign(_preview, { width: 250 });
        break;
      case EContentType.BOOK:
        if (format === OFormat.Compact) assign(_preview, { width: 158, height: 212 });
        else assign(_preview, { width: 250 });
        break;
      case EContentType.AUDIO:
        assign(_preview, { width: 98, height: '100%', src: AudioImg });
        break;
      case EContentType.VIDEO:
        if (format === OFormat.Compact) assign(_preview, { width: '100%', height: 260 });
        else assign(_preview, { width: '100%', height: 415 });
        break;
      case EContentType.IMAGE:
      case EContentType.STUDY:
      case EContentType.OLYMPIAD:
      default:
        assign(_preview, { width: '100%', height: format === OFormat.Compact ? 200 : 416 });
        break;
    }
    return assign(_preview, contentType === EContentType.AUDIO ? {} : preview);
  }, [preview, format, contentType]);

  const computedTitleClamp = useMemo(() => {
    let _titleClamp;
    switch (contentType) {
      case EContentType.GAME_ANDROID:
      case EContentType.GAME_HTML5:
      case EContentType.AUDIO:
        _titleClamp = 1;
        break;
      default:
        _titleClamp = 2;
        break;
    }
    return titleClamp ?? _titleClamp;
  }, [titleClamp, contentType]);

  const computedLayout = useMemo(() => {
    if (contentType === EContentType.AUDIO) return EPostcardLayout.horizontal;
    else if (contentType === EContentType.BOOK && format !== OFormat.Compact) return EPostcardLayout.horizontal;
    else if (contentType === EContentType.ARTICLE && format !== OFormat.Compact) return EPostcardLayout.horizontal;
    return EPostcardLayout.vertical;
  }, [format, contentType]);

  const renderExtraMiddle = () => {
    let _node: ReactNode;
    switch (contentType) {
      case EContentType.VIDEO:
        _node = (
          <div>
            <img src={ PlaySvg } alt="play" />
          </div>
        );
        break;
      default:
        _node = null;
        break;
    }
    return _node;
  };

  const renderExtraTop = () => {
    let _node: ReactNode;
    if (isNew) {
      switch (contentType) {
        case EContentType.VIDEO:
          _node = (
            <PostcardBadge text={ t('content.new') } offset={ { top: '36px' } } type="secondary" size="large"/>
          );
          break;
        case EContentType.ARTICLE:
        case EContentType.BOOK:
          _node = (
            <PostcardBadge text={ t('content.new') } offset={ { top: '18px' } } type="secondary" size="large"/>
          );
          break;
        default:
          _node = (
            <PostcardBadge text={ t('content.new') } offset={ { top: '36px' } } type="secondary" size="large"/>
          );
          break;
      }
    }
    return _node;
  };

  const renderDescr = () => {
    let _node: ReactNode;
    switch (contentType) {
      case EContentType.GAME_ANDROID:
      case EContentType.GAME_HTML5:
        _node = (
          <Button type="secondary" size="small" onClick={ onButtonClick }>
            Играть
          </Button>
        );
        break;
      case EContentType.BOOK:
        if (format === OFormat.Compact) {
          _node = <div className={ cx(styles.subtitle, ellipsisStyles[EEllipsisClassKey.line]) }>{ data?.artist }</div>;
        } else {
          _node = (
            <>
              { data?.artist && <div className={ cx(styles.subtitle, ellipsisStyles[EEllipsisClassKey.line]) }>{ data.artist }</div> }
              { descr && (
                <div
                  className={ cx(styles.text, ellipsisStyles[EEllipsisClassKey.multiline]) }
                  style={ { ['--lineClamp']: 4 } as CSSProperties }
                >
                  { descr }
                </div>
              ) }
            </>
          );
        }
        break;
      case EContentType.AUDIO:
        _node = (
          <>
            { data?.artist && <div className={ cx(styles.subtitle, ellipsisStyles[EEllipsisClassKey.line]) }>{ data.artist }</div> }
            <Audio className={ styles.media } src={ mediaSrc } onPlayed={ onMediaAudioPlayed } />
          </>
        );
        break;
      case EContentType.ARTICLE:
        if (format === OFormat.Compact) {
          _node = <div className={ cx(styles.subtitle, ellipsisStyles[EEllipsisClassKey.line]) }>{ data?.artist }</div>;
        } else {
          _node = (
            <>
              { data?.artist && <div className={ cx(styles.subtitle, ellipsisStyles[EEllipsisClassKey.line]) }>{ data.artist }</div> }
              { descr && (
                <div
                  className={ cx(styles.text, ellipsisStyles[EEllipsisClassKey.multiline]) }
                  style={ { ['--lineClamp']: 4 } as CSSProperties }
                >
                  { descr }
                </div>
              ) }
            </>
          );
        }
        break;
      default:
        _node = null;
        break;
    }
    return _node;
  };

  return (
    <ContentCard
      ref={ handleRef }
      className={ cx(className, format, contentType) }
      classes={ {
        [EContentCardClassKey.root]: styles.root,
        [EContentCardClassKey.picture]: styles.picture,
        [EContentCardClassKey.descr]: styles.descr,
        [EContentCardClassKey.title]: styles.title,
        [EContentCardClassKey.pictureFigure]: styles.pictureFigure
      } }
      style={ computedStyle }
      preview={ computedPreview }
      layout={ computedLayout }
      titleClamp={ computedTitleClamp }
      title={ <span>{ title }</span> }
      descr={ renderDescr() }
      extraMiddle={ renderExtraMiddle() }
      extraTop={ renderExtraTop() }
      free={ free }
      liked={ liked }
      handleLike={ handleLike }
      handleDecline={ handleDecline }
      subscribeStatus={ subscribeStatus }
      itemCardId={ itemCardId }
      authStatus={ authStatus }
      contentItemId={ contentItemId }
      onClick={ onClick }
      onPictureClick={ onPictureClick }
      onTitleClick={ onTitleClick }
      withIndicator={ withIndicator }
    />
  );
});
ItemCard.displayName = 'ItemCard';

ItemCard.defaultProps = { format: OFormat.Fit, contentType: EContentType.IMAGE, withIndicator: true } as ItemCardProps;
//#endregion

export default ItemCard;
