import axios, * as Axios from 'axios';

import { IAnnounce } from 'app/entities/announce/announce.models';

export const CancelToken = axios.CancelToken;

export const ANNOUNCE_ACTION = {
  FETCH_SECTION: 'ANNOUNCE::FETCH:SECTION',
  RESET_SECTION: 'ANNOUNCE::RESET:SECTION'
} as const;

export const fetchSection = (() => {
  let cancel;
  let url = null;
  return (id, props?: { cancellable?: boolean; cancelToken?: Axios.CancelToken }) => {
    const { cancellable, cancelToken } = props;
    if (cancel && url) cancel(`Cancel fetchStream: ${url}`); // check and run cancel
    url = id ? `/api/announcement/get?sectionId=${id}` : null;
    return {
      type: ANNOUNCE_ACTION.FETCH_SECTION,
      meta: { props: { targetId: id } },
      payload: axios
        .get(url, {
          cancelToken:
            cancelToken ??
            (cancellable &&
              new CancelToken(function executor(c) {
                cancel = c; // save cancel
              }))
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.warn(err?.message);
            return Promise.resolve<{ data: { announcement: IAnnounce }; canceled: boolean }>({
              data: { announcement: null },
              canceled: true
            });
          }
          return Promise.reject(err);
        })
    };
  };
})();

export const resetSection = () => ({
  type: ANNOUNCE_ACTION.RESET_SECTION
});
