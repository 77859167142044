import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { BASENAME } from 'app/configs/const';
import { Dispatch } from 'redux';
import { IQueryNotification } from 'app/models/model.notification';
import { IUpdateNotificationTypeProps } from 'app/models/model.notificationType';
import { INotification } from './notification.reducer';

export const NOTIFICATION_ACTIONS = {
  GET: 'NOTIFICATIONS::GET',
  DELETE: 'NOTIFICATION::DELETE',
  CREATE: 'NOTIFICATION::POST',
  UPDATE: 'NOTIFICATION::UPDATE',
  UPDATE_ENTITY_VALUE: 'UPDATE_ENTITY_VALUE',
  GET_TYPE: 'NOTIFICATION_TYPE::GET',
  UPDATE_TYPE: 'UPDATE_TYPE::PATCH'
};

export const updateNotificationEntityValue = <T = unknown>(entity: string, value: T) => ({
  type: NOTIFICATION_ACTIONS.UPDATE_ENTITY_VALUE,
  payload: { entity, value }
});

export const getNotifications = () => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(NOTIFICATION_ACTIONS.GET) });
  try {
    const response = await axios.get(`${BASENAME}/api/userNotification/list`);
    dispatch({
      type: SUCCESS(NOTIFICATION_ACTIONS.GET),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(NOTIFICATION_ACTIONS.GET),
      payload: error
    });
    return;
  }
};

export const deleteNotification = id => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(NOTIFICATION_ACTIONS.DELETE) });
  try {
    const response = await axios.delete(`${BASENAME}/api/userNotification/delete/${id}`);
    dispatch({
      type: SUCCESS(NOTIFICATION_ACTIONS.DELETE),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(NOTIFICATION_ACTIONS.DELETE),
      payload: error
    });
    return;
  }
};

export const createNotification = dto => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(NOTIFICATION_ACTIONS.CREATE) });
  try {
    const response = await axios.post<INotification[]>(`${BASENAME}/api/userNotification/create`, { ...dto });
    dispatch({
      type: SUCCESS(NOTIFICATION_ACTIONS.CREATE),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(NOTIFICATION_ACTIONS.CREATE),
      payload: error
    });
    return;
  }
};

export const updateNotification = dto => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(NOTIFICATION_ACTIONS.UPDATE) });
  try {
    const response = await axios.patch(`${BASENAME}/api/userNotification/update`, { ...dto });
    dispatch({
      type: SUCCESS(NOTIFICATION_ACTIONS.UPDATE),
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FAILURE(NOTIFICATION_ACTIONS.UPDATE),
      payload: error
    });
    return;
  }
};

const notificationTypeRequest = () => ({
  type: REQUEST(NOTIFICATION_ACTIONS.GET_TYPE)
});

const notificationTypeSuccess = data => ({
  type: SUCCESS(NOTIFICATION_ACTIONS.GET_TYPE),
  payload: data
});

const notificationTypeFailure = error => ({
  type: REQUEST(NOTIFICATION_ACTIONS.GET_TYPE),
  payload: error
});

export const getNotificationType = (query: IQueryNotification) => async (dispatch: Dispatch) => {
  dispatch(notificationTypeRequest());

  try {
    const response = await axios.get(`${BASENAME}/api/userNotificationType/get`, { params: query });

    const data = {
      ...response.data,
      id: query.id
    };

    dispatch(notificationTypeSuccess(data));
  } catch (error) {
    dispatch(notificationTypeFailure(error));
  }
};

const updateNotificationTypeRequest = () => ({
  type: REQUEST(NOTIFICATION_ACTIONS.UPDATE_TYPE)
});

const updateNotificationTypeSuccess = data => ({
  type: SUCCESS(NOTIFICATION_ACTIONS.UPDATE_TYPE),
  payload: data
});

const updateNnotificationTypeFailure = error => ({
  type: REQUEST(NOTIFICATION_ACTIONS.UPDATE_TYPE),
  payload: error
});

export const updateNotificationType = (props: IUpdateNotificationTypeProps) => async (dispatch: Dispatch) => {
  dispatch(updateNotificationTypeRequest());

  try {
    const { id, notificationType } = props;
    const dto = {
      notificationType
    };
    const params = {
      id
    };

    const response = await axios.patch(`${BASENAME}/api/userNotificationType/update`, dto, { params });

    dispatch(updateNotificationTypeSuccess(response.data));
  } catch (error) {
    dispatch(updateNnotificationTypeFailure(error));
  }
};
