import { isAndroid, isIOS, isWinPhone } from 'mobile-device-detect';

export enum EOSType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WINDOWS_PHONE = 'WINDOWS_PHONE',
  UNKNOWN = 'UNKNOWN'
}

export function getMobileOperatingSystem() {
  if (isWinPhone) {
    return EOSType.WINDOWS_PHONE;
  }
  if (isAndroid) {
    return EOSType.ANDROID;
  }
  if (isIOS) {
    return EOSType.IOS;
  }
  return EOSType.UNKNOWN;
}
