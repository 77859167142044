//#region imports
import { ReactNode, MouseEventHandler } from 'react';
import { StandardProps } from '@material-ui/core';

import { PostcardLayout } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
//#endregion

export interface IContentCardProps {
  layout?: PostcardLayout;
  origin?: null | 'category';
  contentType?: string;
  preview?: { src: string; width?: string | number; height?: string | number };
  extraMiddle?: ReactNode;
  extraTop?: ReactNode;
  descr?: ReactNode;
  titleClamp?: number;
  title: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  onPictureClick?: MouseEventHandler<HTMLElement>;
  onTitleClick?: MouseEventHandler<HTMLElement>;
}

export const EContentCardClassKey = {
  root: 'root',
  inner: 'inner',
  title: 'title',
  descr: 'descr',
  picture: 'picture',
  pictureFigure: 'picture__figure',
  freeBlock: 'freeBlock',
  imgLike: 'img_like'
} as const;

// const assertions (TS 3.4+)
export type ContentCardClasses = typeof EContentCardClassKey[keyof typeof EContentCardClassKey];

export type ContentCardProps = StandardProps<IContentCardProps, ContentCardClasses>;

export interface IContentCardStylesProps {
  classes?: ContentCardProps['classes'];
  origin?: ContentCardProps['origin'];
  mobile?: boolean;
  isPictureClickable?: boolean;
  isTitleClickable?: boolean;
  isAllClickable?: boolean;
}
