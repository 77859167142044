import React from 'react';
import { IRootState } from 'app/shared/reducers/root.reducer';
import { Dispatch } from 'redux';

import { connect } from 'app/containers/connect';
import { startGame, endGame, fetchGameQuestion, postGameAnswer, IAnswerIndexes, resetGame, bufferGame } from 'app/entities/game/game.actions';
import { IGameInitialState, IGameState } from 'app/entities/game/game.reducer';

export type IContainerGameProps = IGameState & {
  actions: {
    startGame: (id: IGameInitialState['gameId']) => void;
    endGame: (id: IGameInitialState['gameId']) => void;
    fetchGameQuestion: (id: IGameInitialState['gameId']) => void;
    postGameAnswer: (indexes: IAnswerIndexes, id: IGameInitialState['gameId']) => void;
    resetGame: () => void;
    bufferGame: typeof bufferGame;
  };
};

const mapStateToProps = (state: IRootState) => state.game;
const mapDispatchToProps = (dispatch: Dispatch): IContainerGameProps['actions'] => ({
  startGame: id => dispatch(startGame(id)),
  endGame: id => dispatch(endGame(id)),
  fetchGameQuestion: id => dispatch(fetchGameQuestion(id)),
  postGameAnswer: (indexes, id) => dispatch(postGameAnswer(indexes, id)),
  resetGame: () => dispatch(resetGame()),
  bufferGame: (...props) => dispatch(bufferGame(...props))
});

type TContainerGameDispatch = ReturnType<typeof mapDispatchToProps>;
type TContainerGameState = ReturnType<typeof mapStateToProps>;

export interface IContainerGame {
  containerGame: {
    actions: TContainerGameDispatch;
  } & TContainerGameState;
}

export const ContainerGame = connect<IContainerGameProps>('containerGame', mapStateToProps, mapDispatchToProps);
