//#region imports
import { StandardProps } from '@material-ui/core';

import { ICategory } from 'app/models/model.category';
import { ISectionItem } from 'app/models/model.section';
import { IContent } from 'app/models/model.content';
import { ISectionListContainerProps } from 'app/containers/section.list.container';
import { IContainerContentListMainProps } from 'app/containers/container.content.list.main';
// import { Cancelable } from "@material-ui/core/utils/debounce";
//#endregion

//#region Category
export interface ICategoryProps {
  data: ICategory;
  sections?: ISectionItem[];
  contents?: IContent[];
  loading?: boolean;
  uAgeType?: string;
  onConfirm?: (e: { type?: string; url?: string }) => void;
  onSectionsChangeBy?: (index: number, id: number) => void;
  onContentsChangeBy?: (index: number, id: number) => void;
}

export const ECategoryClassKey = {
  root: 'root',
  badge: 'badge'
} as const;

// const assertions (TS 3.4+)
export type CategoryClasses = typeof ECategoryClassKey[keyof typeof ECategoryClassKey];

export type CategoryProps = StandardProps<ICategoryProps, CategoryClasses>;
//#endregion

//#region ConnectedCategories
export interface IConnectedCategoryProps {
  sectionListContainer: ISectionListContainerProps;
  containerContentListMain: IContainerContentListMainProps;
}

export type ConnectedCategoryProps = IConnectedCategoryProps & CategoryProps;
//#endregion

//#region CategoryBlocks
export interface ICategoryBlocksProps {
  type: 'section' | 'content';
  view: 'box' | 'cell';
  category?: ICategory;
  data?: ISectionItem[] | IContent[];
  loading?: boolean;
  uAgeType?: string;
  onConfirm?: (e: { type?: string; url?: string }) => void;
}

export const ECategoryBlocksClassKey = {
  root: 'root',
  box: 'box',
  cell: 'cell',
  item: 'item',
  itemTitle: 'itemTitle',
  iconFree: 'iconFree',
  itemRoot: 'itemRoot',
  itemDescr: 'itemDescr',
  itemPicture: 'itemPicture',
  pictureFigure: 'pictureFigure'
} as const;

// const assertions (TS 3.4+)
export type CategoryBlocksClasses = typeof ECategoryBlocksClassKey[keyof typeof ECategoryBlocksClassKey];

export type CategoryBlocksProps = StandardProps<ICategoryBlocksProps, CategoryBlocksClasses>;
//#endregion

//#region CategoryMenu
export interface ICategoryMenuProps {
  data?: Array<unknown>;
  selectedKey?: string;
  loading?: boolean;
  onSelect?: ((key: number | string) => Promise<void> | void);
}

export const ECategoryMenuClassKey = {
  root: 'root',
  menu: 'menu',
  arrow: 'arrow',
  arrowDisabled: 'arrowDisabled',
  container: 'container',
  list: 'list',
  listItem: 'listItem',
  item: 'item',
  loading: 'loading',
  selected: 'selected',
  prevActive: 'prevActive',
  nextActive: 'nextActive'
} as const;

// const assertions (TS 3.4+)
export type CategoryMenuClasses = typeof ECategoryMenuClassKey[keyof typeof ECategoryMenuClassKey];

export type CategoryMenuProps = StandardProps<ICategoryMenuProps, CategoryMenuClasses>;
//#endregion
