import React, { FC, useCallback, useEffect, useMemo, useState, useLayoutEffect } from 'react';
import flow from 'lodash/flow';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { MobileNavbarMenu } from 'a1s-tele2-react-ui/src/components/mobile/menu/navbar/menu.navbar.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { ContainerGame, IContainerGameProps } from 'app/containers/container.game';
import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';

import ProgramLayout from './program.layout';
import ProductsList from 'app/pages/shared/diet/products/products.list.index';
import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import { useStylesIndividualProgram } from './individual.program.style';
import { DIET, EDietPageSection } from 'app/configs/route.names';
import subscribeCheck from 'app/utils/util.subscribe.check';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import ViewBox from 'app/components/view.box/view.box.index';
import Wrapper from 'app/components/wrapper/wrapper.index';
import { loginExternal } from 'app/utils/auth.external';
import { ELoadStatus } from 'app/models/shared.model';

interface IMyDietIndividualProgramDesktop {
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  modalsContainer: IModalsContainer;
  containerGame: IContainerGameProps;
}

const DietIndividualProgramDesktop: FC<IMyDietIndividualProgramDesktop> = props => {
  const { authenticated, request: { status: userStatus } } = props.containerUser;
  const {
    getDietIndividualProgram,
    getDietStatus,
    getDietIndividualProgramDayById,
    postNextWeek,
    saveDietItem,
    fetchDietItem,
    resetDietIndividualProgram
  } = props.containerDietIndividualProgram.actions;
  const {
    itemsDay,
    programStatus,
    dataDays,
    foodBasketText,
    itemUserAttributes,
    last,
    replacementText,
    progressStatus
  } = props.containerDietIndividualProgram;

  const { t } = useTranslation();
  const [dietDays, setDietDays] = useState([
    { id: 1, title: `${t('individualProgram.day')} 1`, eatings: [] },
    { id: 2, title: `${t('individualProgram.day')} 2`, eatings: [] },
    { id: 3, title: `${t('individualProgram.day')} 3`, eatings: [] },
    { id: 4, title: `${t('individualProgram.day')} 4`, eatings: [] },
    { id: 5, title: `${t('individualProgram.day')} 5`, eatings: [] },
    { id: 6, title: `${t('individualProgram.day')} 6`, eatings: [] },
    { id: 7, title: `${t('individualProgram.day')} 7`, eatings: [] }
  ]);
  const [activeDayId, setActiveDayId] = useState(dietDays[0].id);
  const [storageActiveDayId, setStorageActiveDayId] = useState(null);
  const styles = useStylesIndividualProgram();
  const history = useHistory();
  const location = useLocation<any>();

  const activeSection = location?.state?.section || EDietPageSection.PROGRAM;

  const subscribe = props.containerSubscribe.subscriptions.find(sub => sub.id === programStatus?.id);

  const sectionList = [
    {
      name: t('diet.myProgram'),
      id: EDietPageSection.PROGRAM
    },
    {
      name: t('diet.listProduct'),
      id: EDietPageSection.PRODUCTS
    },
    {
      name: t('diet.myParameters'),
      id: EDietPageSection.PARAMETERS
    }
  ];

  useEffect(() => {
    return () => {
      if (localStorage.getItem('backUrl')) {
        localStorage.removeItem('backUrl');
      }
      setStorageActiveDayId(null);
      props.modalsContainer.actions.closeModal();
      resetDietIndividualProgram();
    };
  }, []);

  const backUrl = localStorage.getItem('backUrl');

  const onCancel = () => {
    if (itemUserAttributes && Object.values(itemUserAttributes).some(value => value === null)) {
      return backUrl === '/' ? history.push(backUrl) : history.goBack();
    } else {
      if (subscribe.status === ESubscriptionStatus.NOT_ACTIVE) {
        return backUrl === '/' ? history.push(backUrl) : history.goBack();
      } else if (subscribe.status === ESubscriptionStatus.NO_MONEY) {
        if (backUrl) {
          return backUrl === '/' ? history.push(backUrl) : history.go(-2);
        } else {
          history.goBack();
        }
      }
    }
  };

  useEffect(() => {
    if (!authenticated && userStatus.msisdn === ELoadStatus.ready) {
      loginExternal(history.location.pathname, false);
      return;
    }
    if (subscribe) {
      subscribeCheck({
        subscribe,
        callback: () => {
          getDietIndividualProgram();
        },
        callbackCancel: () => {
          onCancel();
        },
        containerUser: props.containerUser,
        containerModals: props.modalsContainer
      });
    }
  }, [authenticated, subscribe, userStatus.msisdn]);

  const fetchDietById = useCallback(() => {
    if (authenticated && subscribe?.status === ESubscriptionStatus.ACTIVE) {
      if (localStorage.getItem('activeDayId') || storageActiveDayId) {
        if (localStorage.getItem('activeDayId')) {
          const id = parseInt(localStorage.getItem('activeDayId'), 10);
          setStorageActiveDayId(id);
          getDietIndividualProgramDayById(id);
          localStorage.removeItem('activeDayId');
      } else {
        getDietIndividualProgramDayById(storageActiveDayId);
      }
      } else {
        if (itemsDay?.length) {
          getDietIndividualProgramDayById(itemsDay[0].id);
        }
      }
    }
  }, [authenticated, subscribe, itemsDay, storageActiveDayId]);

  useLayoutEffect(() => {
    fetchDietById();
  }, [itemsDay]);

  useEffect(() => {
    if (Object.keys(itemUserAttributes).length) {
      if (localStorage.getItem('individualParameters')) {
        const individualParameters = JSON.parse(localStorage.getItem('individualParameters'));
        const mergeUserAttributes = {} as IUserAttributes;
        Object.keys(itemUserAttributes).forEach(key => {
          mergeUserAttributes[key] = key === 'breastFeeding' ? individualParameters[key] || false : individualParameters[key] || itemUserAttributes[key];
        });
        saveDietItem(mergeUserAttributes);
        localStorage.removeItem('individualParameters');
      }
    }
  }, [itemUserAttributes]);

  const renderContent = useMemo(() => {
    switch (activeSection) {
      case EDietPageSection.PROGRAM:
        return (
          <ProgramLayout
            defaultDayId={ storageActiveDayId }
            authenticated={ authenticated }
            dataDays={ dataDays }
            itemsDay={ itemsDay }
            getDietIndividualProgram={ getDietIndividualProgram }
            getDietIndividualProgramDayById={ getDietIndividualProgramDayById }
            dietDays={ dietDays }
            setDietDays={ setDietDays }
            activeDayId={ activeDayId }
            setActiveDayId={ setActiveDayId }
            itemUserAttributes={ itemUserAttributes }
            postNextWeek={ postNextWeek }
            last={ last }
            saveUserParameters={ saveDietItem }
          />
        );
      case EDietPageSection.PRODUCTS:
        return <ProductsList foodBasketText={ foodBasketText } replacementText={ replacementText } />;
      case EDietPageSection.PARAMETERS:
        return <Parameters className={ styles.parameters } />;
      default:
        return (
          <ProgramLayout
            defaultDayId={ storageActiveDayId }
            authenticated={ authenticated }
            dataDays={ dataDays }
            itemsDay={ itemsDay }
            getDietIndividualProgram={ getDietIndividualProgram }
            getDietIndividualProgramDayById={ getDietIndividualProgramDayById }
            dietDays={ dietDays }
            setDietDays={ setDietDays }
            activeDayId={ activeDayId }
            setActiveDayId={ setActiveDayId }
            itemUserAttributes={ itemUserAttributes }
            postNextWeek={ postNextWeek }
            last={ last }
            saveUserParameters={ saveDietItem }
          />
        );
    }
  }, [activeSection, dataDays, itemsDay, getDietIndividualProgramDayById, foodBasketText, itemUserAttributes, last]);

  const handleClickSectionItem = section => {
    history.replace(DIET(section), { section });
  };

  const handleClickActiveDay = id => {
    setActiveDayId(id);
    getDietIndividualProgramDayById(id);
  };

  return (
    <>
      <ViewBox className={ styles.wrapper }>
        <Wrapper color="light">
          <Typography type="heading" level={ 1 }>
            { t('header.diet') }
          </Typography>
          <MobileNavbarMenu
            classesNavbar={ styles.navbar }
            navMenuItems={ sectionList }
            activeItemId={ activeSection }
            onClickItem={ handleClickSectionItem }
            key={ activeSection }
          />
          { activeSection === EDietPageSection.PROGRAM ? (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              { dietDays.map(({ id, title }) => (
                <span key={ id } className={ `${styles.btn} ${activeDayId === id && styles.btnActive}` } onClick={ () => handleClickActiveDay(id) }>
                  { title }
                </span>
              )) }
            </Grid>
          ) : null }
          { renderContent }
        </Wrapper>
      </ViewBox>
    </>
  );
};

export default flow([ContainerUser, ContainerDietIndividualProgram, ContainerSubscribe, ModalsContainer, ContainerGame])(DietIndividualProgramDesktop);
