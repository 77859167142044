import React from 'react';
import { Dispatch } from 'redux';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { connect } from 'app/containers/connect';
import { IPrizeState } from 'app/entities/prize/prize.reducer';
import { fetchList, fetchPersonal, resetList, resetPersonal } from 'app/entities/prize/prize.actions';

export type IContainerPrizeProps = IPrizeState & {
  actions: {
    fetchList: typeof fetchList;
    fetchPersonal: typeof fetchPersonal;
    resetList: typeof resetList;
    resetPersonal: typeof resetPersonal;
  };
};

const mapStateToProps = (state: IRootState) => state.prize;

const mapDispatchToProps = (dispatch: Dispatch): IContainerPrizeProps['actions'] => ({
  fetchList: (...props) => dispatch(fetchList(...props)),
  fetchPersonal: () => dispatch(fetchPersonal()),
  resetList: () => dispatch(resetList()),
  resetPersonal: () => dispatch(resetPersonal())
});

export const ContainerPrize = connect<IContainerPrizeProps>('containerPrize', mapStateToProps, mapDispatchToProps);
