//#region imports
import React, { Fragment, FC, SyntheticEvent, forwardRef, useRef, useEffect, useState, lazy } from 'react';
import { useUpdateEffect, useSize } from 'react-use';
import { useForkRef } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import shuffle from 'lodash/shuffle';
import map from 'lodash/map';

import lazyUtil from 'a1s-essence-utils-ts/fp/react/lazy';
import { EPostcardClassKey } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import Wrapper from 'app/components/wrapper/wrapper.index';

import { ELoadStatus } from 'app/models/shared.model';
import { IBanner } from 'app/models/model.banner';
import { BannerContainer } from 'app/containers/banner.container';

import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';

import { BannerProps, ConnectedBannerProps, EBannerClassKey } from 'app/entities/banner/banner.model';
import { useBannerStyles } from './banner.styles';

const Carousel = lazyUtil({
  importFunc: () => {
    // tslint:disable-next-line: no-submodule-imports
    import(`react-responsive-carousel/lib/styles/carousel.min.css`);
    return import('react-responsive-carousel');
  },
  name: 'Carousel',
  reactLazy: lazy
});

//#endregion

//#region Banner
export const Banner: FC<BannerProps> = forwardRef<HTMLDivElement, BannerProps>((props, ref) => {
  const { className, style, classes, data, shuffled, loading, masked } = props;
  const styles = useBannerStyles({ classes, masked });
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
  const history = useHistory();

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  const [carouselKey, setCarouselKey] = useState(uniqueId('carousel_'));
  const [displayData, setDisplayData] = useState(shuffled ? shuffle(data) : data);
  useUpdateEffect(() => {
    setCarouselKey(uniqueId('carousel_'));
    setDisplayData(shuffled ? shuffle(data) : data);
  }, [data, shuffled]);

  const handleRedirect = (e: SyntheticEvent, url: string) => {
    e.preventDefault();
    e.stopPropagation();
    url.match('http') || url.match('https') ? window.open(url) : history.push(url);
  };

  const [loader] = useSize(({ width }) => {
    if (width === Infinity) return <div />;
    return (
      <div className={ styles[EBannerClassKey.banner] }>
        <Skeleton variant="rect" width="100%" height={ 590 } animation="wave" />
      </div>
    );
  });

  return (
    <div ref={ handleRef } className={ classnames(className, styles[EBannerClassKey.root]) } style={ style }>
      <div hidden={ !loading }>{ loader }</div>
      <div hidden={ loading }>
        <Carousel
          key={ carouselKey }
          showIndicators={ false }
          showStatus={ false }
          showThumbs={ false }
          showArrows={ false }
          swipeable={ false }
          stopOnHover={ false }
          useKeyboardArrows={ false }
          transitionTime={ 300 }
          autoPlay={ !loading }
          interval={ 5000 }
          infiniteLoop
        >
          { map(displayData, (item: IBanner, index: number) => (
            <Fragment key={ `banner_${index.toString(36)}/${item.url}` }>
              <div className={ styles[EBannerClassKey.backdrop] }>
                <img className={ styles[EBannerClassKey.backdropPic] } src={ item.desktopImageUrl || item.imageUrl } height={ 590 } />
              </div>
              <Wrapper color="light" spacing={ 0 }>
                <Postcard
                  classes={ {
                    [EPostcardClassKey.root]: styles[EBannerClassKey.banner],
                    [EPostcardClassKey.picture]: styles[EBannerClassKey.bannerPic],
                    [EPostcardClassKey.extraBottom]: styles[EBannerClassKey.bannerDescr],
                    [EPostcardClassKey.pictureFigure]: masked && styles[EBannerClassKey.mask]
                  } }
                  height={ 590 }
                  src={ item.desktopImageUrl || item.imageUrl }
                  link={ item.url }
                  extraBottom={
                    <>
                      <div
                        dangerouslySetInnerHTML={ { __html: item.displayTextDesktop } }
                        className={ classnames(styles[EBannerClassKey.title], ellipsisStyles[EEllipsisClassKey.multiline]) }
                      />
                      <Button
                        className={ styles[EBannerClassKey.button] }
                        type="primary"
                        size="small"
                        ghost
                        onClick={ e => handleRedirect(e, item.url) }
                      >
                        { item.buttonText }
                      </Button>
                    </>
                  }
                  onPictureClick={ e => handleRedirect(e, item.url) }
                />
              </Wrapper>
            </Fragment>
          )) }
        </Carousel>
      </div>
    </div>
  );
});
Banner.displayName = 'Banner';

Banner.defaultProps = { data: [], loading: false, shuffled: false } as BannerProps;
//#endregion

//#region ConnectedBanner
const ConnectedBanner: FC<ConnectedBannerProps> = forwardRef<typeof Banner, ConnectedBannerProps>((connectedProps, ref) => {
  const { bannerContainer, ...props } = connectedProps;

  useEffect(() => {
    /** Запрос списка для баннера */
    bannerContainer.actions.fetchItems();
  }, []);

  return <Banner ref={ ref } { ...props } data={ bannerContainer.items } loading={ bannerContainer.request.status.items !== ELoadStatus.ready } />;
});
ConnectedBanner.displayName = 'ConnectedBanner';
//#endregion

export default BannerContainer(ConnectedBanner) as typeof Banner;
