import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDietBannerStyles } from './diet.banner.styles';

import { isMobile } from 'mobile-device-detect';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import myDietMobileImg from 'app/assets/img/diet.mobile.png';
import myDietDesktopImg from 'app/assets/img/diet.desktop.jpg';

const DietBanner: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const styles = useDietBannerStyles({ isMobile });

  return (
    <div
      style={ {
        marginTop: isMobile ? configTheme.indents['indentX2'].margin : configTheme.indents['indentX3'].margin,
        marginBottom: isMobile ? configTheme.indents['indentX1.5'].margin : configTheme.indents['indentX1'].margin
      } }
    >
      {
        isMobile &&
        <Typography mobile={ isMobile } type={ 'heading' } level={ isMobile ? 2 : 1 } className={ styles.header }>
          { t('header.diet') }
        </Typography>
      }
      <Postcard
        src={ isMobile ? myDietMobileImg : myDietDesktopImg }
        layout={ isMobile ? 'vertical' : 'horizontal' }
        classes={ {
          picture__image: styles.image
        } }
      >
        <div className={ styles.textBlock }>
          <div className={ styles.textContent }>
            {
              !isMobile &&
                <Typography type={ 'heading' } level={ 1 } className={ styles.header }>
                  { t('header.diet') }
                </Typography>
            }
            <Typography type={ 'text' } size={ isMobile ? 'default' : 'large' } className={ styles.desc }>
              { t('banner.start.title') }
            </Typography>
            { !isMobile && <Typography type={ 'text' } size={ isMobile ? 'default' : 'large' } className={ styles.desc }>
              { t('banner.start.desc') }
            </Typography> }
          </div>
          <div>
            <Button
              onClick={ () => history.push('/diet') }
              size="large"
              mobile={ isMobile }
              style={ {
                marginTop: isMobile && configTheme.indents['indentX2'].margin,
                marginBottom: isMobile && configTheme.indents['indentX1'].margin
              } }
            >
              { t('common.startNow') }
            </Button>
          </div>
        </div>
      </Postcard>
    </div>
  );
};

export default DietBanner;
