//#region imports
import React, { FC, memo, useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import filter from 'lodash/filter';
import flow from 'lodash/fp/flow';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerContentList } from 'app/containers/container.content.list';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { ImageFallback } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';

import useLimiter from 'app/hooks/useLimiter';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';

import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import FollowLink from 'app/components/follow-link/follow-link.index';

import { IRecommendsProps } from '../item.models';
import { useContentItemStyles } from '../page.content.item.styles';
//#endregion

const Recommends: FC<IRecommendsProps> = memo(props => {
  const { containerContentItem, containerContentList, contentId } = props;
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
  const styles = useContentItemStyles();
  const { t } = useTranslation();

  const sectionId = containerContentItem.section?.section?.id;
  const contentType = containerContentItem.item?.type;

  const contents = containerContentList.items;
  const loading = containerContentList.status.items === ELoadStatus.loading;
  const more = containerContentList.more;

  const [paging, pagingEmiter] = useLimiter();
  useEffect(() => {
    if (sectionId) {
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        cancellable: true
      });
      containerContentList.actions.logView();
    }
    return () => {
      pagingEmiter.invokeReset();
      containerContentList.actions.resetContentList();
    };
  }, [sectionId]);

  const scrollRef = useRef<HTMLDivElement>();
  const [recommends, setRecommends] = useState([]);
  useEffect(() => {
    setRecommends(filter(contents, c => c.id !== contentId));
  }, [contentId, contents]);

  const loadData = useCallback(() => {
    if (sectionId) {
      pagingEmiter.invokeNext();
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        more,
        cancellable: true
      });
      containerContentList.actions.logView();
    }
  }, [sectionId, more]);

  const recommendsExists = recommends?.length > 0;
  if (!recommendsExists) return null;

  switch (contentType) {
    case EContentType.BOOK:
      return (
        <div className={ styles.recommends }>
          <Typography className={ styles.recommendsTitle } type="heading" level={ 2 } tag="div" mobile>
            { t('common.recommend') }
          </Typography>
          <VirtualHorizontalList
            className={ styles.virtual }
            parentRef={ scrollRef }
            size={ recommends?.length }
            width={ 135 }
            spacing={ configTheme.indents['indentX1'].margin }
            infinite={ more }
            loading={ loading }
            onLoad={ loadData }
            fallbackHeight={ 218 }
            autoHeight
            renderItem={ memo<any>(({ index }) => {
              const c = recommends?.[index];
              return (
                <FollowLink
                  anchor={ false }
                  node={
                    <div>
                      <Postcard
                        width={ 135 }
                        height={ 135 }
                        src={ c?.imageUrl }
                        fallback={ ImageFallback }
                        extraTop={ c?.new && <PostcardBadge type="secondary" text={ t('content.new') } offset={ { top: '18px' } } /> }
                        extraBottom={ c?.free && <IconText classes={ { root: styles.iconFree } } color="white" iconOnly /> }
                      >
                        <Typography
                          className={ classnames(styles.recommendsName, ellipsisStyles[EEllipsisClassKey.multiline]) }
                          type="text"
                          size="small"
                        >
                          { c?.name }
                        </Typography>
                      </Postcard>
                    </div>
                  }
                  to={ c?.link ?? CONTENT_ITEM(c?.type, c?.id) }
                />
              );
            }) }
            renderFallback={ memo<any>(VirtualFallback) }
          />
        </div>
      );
    default:
      return <div className={ styles.recommends } />;
  }
});

export default flow([ContainerContentItem, ContainerContentList])(Recommends);
