import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesFooter = makeStyles(theme => createStyles({
  footer: {
    position: 'absolute',
    bottom: 0,
    padding: `27px 18px`,
    background: ui.colors.background.dark.default,
    margin: `0 -18px`,
    width: '100%',
    boxSizing: 'border-box'
  },
  company: {
    color: ui.colors.text.light.medium,
    marginBottom: 12,
    lineHeight: '22px',
    minHeight: '22px',
    display: 'flex',
    '& a': {
      display: 'contents',
      color: '#fff'
    }
  },
  desc: {
    marginTop: 27,
    fontSize: 13,
    color: '#8A8F97',
    lineHeight: '18px',
    '& a': {
      color: '#8A8F97'
    }
  },
  terms: {
    color: ui.colors.text.light.medium,
    lineHeight: '22px',
    fontSize: 16,
    height: '22px',
    display: 'flex',
    marginBottom: 10
  }
}));

export const Footer = forwardRef<HTMLElement>((props, ref) => {
  const { t } = useTranslation();
  const classes = stylesFooter();

  return (
    <footer className={ classes.footer } ref={ ref }>
      <div className={ classes.company }>{ t('footer.company') }</div>
      <a href="https://msk.tele2.ru/help" target="_blank" className={ classes.terms }>Обратная связь</a>
      <a href="/oferta.pdf" target="_blank" className={ classes.terms }>{ t('footer.terms') }</a>
      <div className={ classes.desc }>
        Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookie в соответствии с&nbsp;
        <a href={ 'https://tele2.ru/api/media/content?contentId=m3100004' } target="_blank"><span>Политикой Компаний Группы Tele2</span></a>&nbsp;
        в области использования файлов Cookie.
      </div>
    </footer>
  );
});
