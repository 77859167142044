import React from 'react';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { ISectionItemState } from 'app/entities/section/entity.section.item.reducer';

import { fetchSectionItem, resetSectionItem, cancelFetchSectionItem } from 'app/entities/section/entity.section.actions';

import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

import { ISubcategory } from 'app/models/model.subcategory';

export type IContainerSectionItemProps = ISectionItemState & {
  actions: {
    fetchSectionItem: typeof fetchSectionItem;
    resetSectionItem: typeof resetSectionItem;
    cancelFetchSectionItem: typeof cancelFetchSectionItem;
  }
};

const mapStateToProps = (state: IRootState) => state.sectionItem;
const mapDispatchToProps = (dispatch: Dispatch): IContainerSectionItemProps['actions'] => ({
  fetchSectionItem: (...props) => dispatch(fetchSectionItem(...props)),
  resetSectionItem: () => dispatch(resetSectionItem()),
  cancelFetchSectionItem: (id: ISubcategory['id']) => cancelFetchSectionItem(id)
});

export const ContainerSectionItem = connect<IContainerSectionItemProps>('containerSectionItem', mapStateToProps, mapDispatchToProps);
