import React from 'react';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { ISectionMenuState } from 'app/entities/section/entity.section.menu.reducer';

import { fetchSectionMenu, resetSectionMenu, IFetchSectionItemsProps } from 'app/entities/section/entity.section.actions';

import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

import { ISubcategory } from 'app/models/model.subcategory';
import { ThunkDispatch } from 'redux-thunk';

export type ISectionMenuContainerProps = ISectionMenuState & {
  actions: {
    fetchSectionMenu: (props: IFetchSectionItemsProps) => any;
    resetSectionMenu: typeof resetSectionMenu;
  };
};

const mapStateToProps = (state: IRootState) => state.sectionMenu;
const mapDispatchToProps = (dispatch: ThunkDispatch<ISectionMenuState, undefined, any>): ISectionMenuContainerProps['actions'] => ({
  fetchSectionMenu: (props: IFetchSectionItemsProps) => dispatch(fetchSectionMenu(props)),
  resetSectionMenu: () => dispatch(resetSectionMenu())
});

export const SectionMenuContainer = connect<ISectionMenuContainerProps>('sectionMenuContainer', mapStateToProps, mapDispatchToProps);
