import React, { FC, useMemo } from 'react';

import cn from 'classnames';

import Grid from '@material-ui/core/Grid';

import useMobile from 'app/hooks/use.mobile.hook';

import styles from './diet.days.styles';

interface IProps {
  dayList: Array<{
    id: number;
    title: string;
  }>;
  handleClickDay: (id: number) => void;
  activeId: number;
  className?: string;
}

const DietDays: FC<IProps> = ({ dayList = [], handleClickDay = () => {}, activeId = 0, className = '' }) => {
  const { isMobile434 } = useMobile();

  const classes = styles({ isMobile434 });

  const renderDayList = useMemo(
    () =>
      dayList.map(({ id, title }) => (
        <span
          key={ id }
          onClick={ () => handleClickDay(id) }
          className={ cn(classes.dayItem, {
            [classes.dayActive]: activeId === id
          }) }
        >
          { title }
        </span>
      )),
    [dayList, handleClickDay]
  );

  return (
    <Grid className={ `${classes.root} ${className}` } container direction="row" justifyContent="space-between" alignItems="center">
      { isMobile434 ? <div className={ classes.content }>{ renderDayList }</div> : renderDayList }
    </Grid>
  );
};

export default DietDays;
