import React, { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';

import ModalDietProgram from 'app/pages/desktop/diet/program/program.index';
import userOpportunitiesCheck from 'app/utils/util.opportunities.check';
import { useDesktopDietStyles } from 'app/pages/desktop/diet/diet.styles';
import ImgDietDesktopFull from 'app/assets/img/diet.desktop.full.jpg';

import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { IContainerSubscribeProps, ContainerSubscribe } from 'app/containers/container.subscribe';

const DesktopDiet: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
}> = ({ containerDietIndividualProgram, containerUser, containerSubscribe }) => {
  const style = useDesktopDietStyles();
  const [isModalCalcProgram, setModalCalcProgram] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (containerUser.authenticated) {
      containerDietIndividualProgram.actions.fetchDietItem();
    }
  }, [containerUser.authenticated]);

  useEffect(() => {
    return () => {
      setModalCalcProgram(false);
    };
  }, []);

  useEffect(() => {
    userOpportunitiesCheck({
      subscribe: containerSubscribe.subscriptions.find(sub => sub?.id === containerDietIndividualProgram.programStatus.id),
      history,
      containerUser,
      containerDietIndividualProgram
    });
  }, [window.location]);

  return (
    <>
      <Box className={ style.root }>
        { isModalCalcProgram && (
          <ModalDietProgram
            isModalCalcProgram={ isModalCalcProgram }
            setModalCalcProgram={ setModalCalcProgram }
            propsState={ containerDietIndividualProgram.itemUserAttributes }
            loading={ containerDietIndividualProgram.progressStatus }
            save={ containerDietIndividualProgram.actions.saveDietItem }
            authenticated={ containerUser.authenticated }
          />
        ) }

        <span className={ style.title }>{ t('header.diet') }</span>
        <div className={ style.banner }>
          <img src={ ImgDietDesktopFull } alt={ t('header.diet') } style={ { maxWidth: '100%' } } />
        </div>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <p>{ t('diet.promo') }</p>
          <li style={ { listStyleType: 'none' } }>
            <p className={ style.li }>1</p>

            { t('diet.params') }
          </li>
          <li style={ { listStyleType: 'none' } }>
            <p className={ style.li }>2</p> { t('diet.getProgram') }
          </li>
          <li style={ { listStyleType: 'none' } }>
            <p className={ style.li }>3</p>

            { t('diet.respawn') }
          </li>
        </Grid>
        <div className={ style.btn }>
          <Button
            onClick={ () => {
              setModalCalcProgram(true);
            } }
          >
            { t('common.startNow') }
          </Button>
        </div>
      </Box>
    </>
  );
};

export default ContainerDietIndividualProgram(ContainerSubscribe(ContainerUser(DesktopDiet)));
