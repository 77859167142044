//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { AudioPlayerClasses, IAudioPlayerStylesProps } from './audio.mobile.player.models';
//#endregion

export const useAudioPlayerMobileStyles = makeStyles(
  theme =>
    createStyles<AudioPlayerClasses, IAudioPlayerStylesProps>({
      root: {
        opacity: ({ haveEnoughData }) => (haveEnoughData ? 1 : 0),
        visibility: ({ haveEnoughData }) => (haveEnoughData ? 'visible' : 'hidden'),
        margin: 0,
        background: '#fff',
        boxShadow: 'none',
        padding: '0 10px',
        willChange: 'opacity, visibility',
        transition: 'opacity 0.3s, visibility 0s linear 0.3s',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
        '& :nth-child(2)': {
          order: 1
        },
        '& :nth-child(3)': {
          order: 3
        },
        '& :nth-child(4)': {
          order: 2,
          alignItems: 'center',
          '& :nth-child(1)': {
            order: 1
          },
          '& :nth-child(3)': {
            order: 2
          }
        }
      },
      playIcon: {
        color: configTheme.colors.icon.primary.default,
        '&:hover': {
          color: configTheme.colors.icon.primary.default
        }
      },
      muteIcon: {},
      pauseIcon: {
        color: configTheme.colors.icon.primary.default,
        '&:hover': {
          color: configTheme.colors.icon.primary.default
        }
      },
      volumeIcon: {
        color: configTheme.colors.icon.primary.default,
        fontSize: 20,
        '&:hover': {
          color: configTheme.colors.icon.primary.default
        }
      },
      closeIcon: {},
      loopIcon: {},
      downloadsIcon: {},
      downloadsContainer: {},
      downloadsItemText: {},
      downloadsItemLink: {},
      progressTime: {
        color: '#828282',
        marginBottom: 4,
        fontSize: 14
      },
      mainSlider: {
        padding: '6px 0',
        color: '#3f51b5',
        '& .MuiSlider-rail': {
          color: configTheme.colors.icon.secondary
        },
        '& .MuiSlider-track': {
          color: configTheme.colors.icon.primary.default
        },
        '& .MuiSlider-thumb': {
          color: configTheme.colors.icon.primary.default,
          width: 6,
          height: 6,
          marginTop: '-2px',
          '&:hover': {
            color: configTheme.colors.icon.primary.default
          }
        }
      },
      volumeSlider: {},
      volumeSliderContainer: {}
    }),
  { name: 'AudioPlayerMobile' }
);
