import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { CONTENT_ACTIONS } from 'app/entities/content/content.actions';

import { ELoadStatus } from 'app/models/shared.model';
import { IContent } from 'app/models/model.content';
import { ISection } from 'app/models/model.section';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  item: {} as IContent,
  section: {} as ISection,
  route: '',
  status: {
    item: ELoadStatus.not_ready,
    section: ELoadStatus.not_ready
  },
  error: {
    item: null as Error,
    section: null as Error
  }
};

export type IContentItemState = Readonly<typeof initialState>;

export default (state = initialState, action): IContentItemState => {
  const { type, payload, meta } = action;
  const uncancelled = meta?.props?.uncancelled ?? false;
  switch (type) {
    case REQUEST(CONTENT_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.loading),
        set('error.item', initialState.error.item),
        set('status.section', ELoadStatus.loading),
        set('error.section', initialState.error.item)
      ])(state);
    case SUCCESS(CONTENT_ACTIONS.FETCH_ITEM):
      if (payload?.canceled && uncancelled) return state;
      return flow([
        set('item', payload.data.content),
        set('section', payload.data.sectionDetails),
        set('status.item', payload?.canceled ? ELoadStatus.error : ELoadStatus.ready),
        set('status.section', payload?.canceled ? ELoadStatus.error : ELoadStatus.ready)
      ])(state);
    case FAILURE(CONTENT_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.error),
        set('error.item', new Error('item fetch failed')),
        set('status.section', ELoadStatus.error),
        set('error.section', new Error('section details fetch failed'))
      ])(state);

    case CONTENT_ACTIONS.UPDATE_ROUTE_ITEM:
      return flow([
        set('route', meta.props.route)
      ])(state);

    case CONTENT_ACTIONS.RESET_ITEM:
      return flow([
        set('item', initialState.item),
        set('section', initialState.section),
        set('error.item', initialState.error.item),
        set('status.item', initialState.status.item),
        set('error.section', initialState.error.item),
        set('status.section', initialState.status.item)
      ])(state);

    default:
      return state;
  }
};
