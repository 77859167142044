export enum EMenuItemType {
  ANCHOR = 'ANCHOR',
  URL = 'URL'
}

export interface IMenu {
  title?: string;
  menuItemType:	string;
  name:	string;
  value: string;
}
