import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import flow from 'lodash/flow';
import map from 'lodash/map';
import get from 'lodash/get';
import { isMobile } from 'mobile-device-detect';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';

import { stylesApp } from 'app/app';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerPrize, IContainerPrizeProps } from 'app/containers/container.prize';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ModalsContainer, IModalsContainer } from 'app/containers/modals.container';
import { ILoggingContainer, LoggingContainer } from 'app/containers/logging.container';
import { EDialogConfirmType, ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import { ModalConfirm } from 'app/components/modal/modal.confirm';
import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';
import ItemCard from 'app/components/desktop/item.card/item.card.index';
import { Typography } from 'app/components/desktop/typography/typography.index';
import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import FollowLink from 'app/components/follow-link/follow-link.index';
import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import { Header } from 'app/components/header/header.ui';
import Switch from '@material-ui/core/Switch';

import { loginExternal, logoutExternal } from 'app/utils/auth.external';
import { phoneMaskDry } from 'a1s-tele2-react-ui/src/utils/utils.mask';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import { stylesProfile, stylesSwitch } from 'app/pages/shared/profile/profile.style';

export const useDesktopProfilePageStyles = makeStyles(
  createStyles({
    root: {},
    item: {
      '&:last-child': {
        marginBottom: 0,
        marginTop: 0
      },
      marginTop: 36,
      marginBottom: 28
    },
    upper: {},
    lower: { marginTop: 'auto' },
    content: {},

    btnBorder: { borderWidth: '2px !important' },
    exitButton: {
      cursor: 'pointer',
      marginTop: configTheme.indents['indentX2'].margin,
      marginBottom: configTheme.indents['indentX4'].margin
    },

    caption: {
      marginTop: configTheme.indents['indentX3'].margin
    },

    header: {
      marginTop: configTheme.indents['indentX2'].margin
    },

    data: {
      display: 'flex',
      flextFlow: 'row nowrap',
      marginTop: configTheme.indents['indentX1'].margin,
      lineHeight: '22px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        marginRight: configTheme.indents['indentX2'].margin,
        lineHeight: '22px'
      },
      '& b': {
        marginRight: configTheme.indents['indentX3'].margin,
        fontWeight: 'normal'
      }
    },

    prizeItem: {},

    mediaPlayIcon: {
      width: 45,
      height: 50,
      paddingLeft: 5,
      borderRadius: 50,
      fontSize: 22,
      lineHeight: '60px',
      textAlign: 'center',
      color: configTheme.colors.text.dark.default,
      background: configTheme.colors.background.light.neutral
    },

    primarySection: {
      margin: `${configTheme.indents['indentX1'].margin}px 0`
    },
    secondarySection: {
      margin: `${configTheme.indents['indentX1'].margin}px ${configTheme.indents['indentX1'].margin}px ${configTheme.indents['indentX1'].margin}px 0`
    },
    floorSection: {
      display: 'flex',
      justifyContent: 'center',
      margin: `${configTheme.indents['indentX1'].margin}px 0`
    },
    itemSuit: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    itemDesc: { margin: `${configTheme.indents['indentX1'].margin}px 0` },
    parametersWrapper: {
      marginTop: 36,
      margin: 0,
      width: 700
    },
    parametersTable: {
      padding: 0
    },
    parametersBtn: {
      margin: 0,
      marginTop: 36
    },
    subscriptions: {
      width: 700
    }
  })
);

export interface IDesktopProfilePageProps extends IRouteProps {
  containerUser: IAuthContainerProps;
  containerPrize: IContainerPrizeProps;
  containerSubscribe: IContainerSubscribeProps;
  modalsContainer: IModalsContainer;
  loggingContainer: ILoggingContainer;
}

const DesktopProfilePage: FC<IDesktopProfilePageProps> = props => {
  const { containerUser, containerPrize, containerSubscribe } = props;
  const {
    actions: { logLogout }
  } = props.loggingContainer;
  const {
    actions: { unSubscribeModal, closeModal }
  } = props.modalsContainer;

  const [confirmDialog, setConfirmDialog] = useState<EDialogConfirmType>();
  const { t } = useTranslation();
  const history = useHistory();

  const classesApp = stylesApp();
  const classesProfile = stylesProfile({ mobile: isMobile });
  const classesSwitch = stylesSwitch();
  const styles = useDesktopProfilePageStyles();
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
  const scrollRef = useRef<HTMLDivElement>();

  const token = get(containerUser, 'token');
  const statusToken = get(containerUser, 'request.status.token');

  useEffect(() => {
    if (statusToken === ELoadStatus.ready && !token) {
      loginExternal(history.location.pathname);
    } else if (statusToken === ELoadStatus.ready && token) {
      containerPrize.actions.fetchPersonal();
    }

    return () => {
      containerPrize.actions.resetPersonal();
    };
  }, [token, statusToken]);

  const onActivate = (s: ISubscription) => {
    containerSubscribe.actions.setSubscription(true, s.id);
  };
  const onDeactivate = (s: ISubscription) => {
    unSubscribeModal(s.id);
  };

  const onConfirmLogout = () => {
    setConfirmDialog(null);
    const response = logLogout();
    response.then(res => {
      if (res.status === 200) {
        logoutExternal();
        history.push('/');
      }
    });
  };

  useEffect(() => {
    return () => {
      closeModal();
      setConfirmDialog(null);
    };
  }, []);

  const loader = (
    <div className={ classesApp.suspendLoader }>
      <CircularLoader />
    </div>
  );

  const content = (
    <>
      <section className={ styles.primarySection }>
        <Typography className={ styles.header } type="heading" level={ 2 }>
          { t('me.myData') }
        </Typography>
        <div className={ styles.data }>
          <span>{ t('me.number') }:</span>
          <b>{ containerUser.msisdn && phoneMaskDry(containerUser.msisdn) }</b>
        </div>
      </section>

      { containerPrize.personal.length > 0 && (
        <section className={ styles.primarySection }>
          <Typography className={ styles.header } type="heading" level={ 2 }>
            Призы
          </Typography>
          <VirtualHorizontalList
            parentRef={ scrollRef }
            style={ { margin: '0 -15px', padding: '15px 0' } }
            size={ containerPrize.personal?.length }
            estimateWidth={ index => {
              const c = containerPrize.personal?.[index]?.content;
              const contentType = c?.type;
              switch (contentType) {
                case EContentType.VIDEO:
                  return 440;
                case EContentType.GAME_ANDROID:
                case EContentType.GAME_HTML5:
                case EContentType.BOOK:
                default:
                  return 200;
              }
            } }
            height={ 360 }
            paddingStart={ 15 }
            paddingEnd={ 15 }
            spacing={ 30 }
            overscan={ 3 }
            renderItem={ memo<any>(({ index }) => {
              const c = containerPrize.personal?.[index]?.content;
              const contentType = c?.type;
              return (
                <FollowLink
                  anchor={ false }
                  node={
                    <div className={ styles.prizeItem }>
                      <ItemCard
                        key={ c?.id }
                        style={ { height: 360 } }
                        format="compact"
                        contentType={ contentType }
                        preview={ { src: c.imageUrl } }
                        title={ c.name }
                        data={ c?.data }
                      />
                    </div>
                  }
                  to={ CONTENT_ITEM(contentType, c?.id) }
                />
              );
            }) }
            renderFallback={ memo<any>(VirtualFallback) }
          />
        </section>
      ) }

      <Parameters
        classes={ {
          wrapper: styles.parametersWrapper,
          table: styles.parametersTable,
          actionButton: styles.parametersBtn
        } }
        isNoIcon
        isNoCard
        isNoParametersInfo
      />

      <section className={ `${classesProfile.secondarySection} ${styles.subscriptions}` }>
        <Typography className={ styles.header } type="heading" level={ 2 }>
          { t('me.subscriptions') }
        </Typography>
        { containerSubscribe && containerSubscribe.subscriptions.length > 0
          ? containerSubscribe.subscriptions.map(s => {
              const isDisabled = containerSubscribe?.disabledSubscriptions?.includes(s.id);
              return (
                <div className={ styles.item } key={ s.id }>
                  <Typography tag="div" type="text" size="large">
                    { s.name }
                  </Typography>
                  <Grid container justifyContent="space-between" className={ s.name && classesProfile.itemDesc }>
                    <Typography tag="div" type="text">
                      { s.price }
                    </Typography>
                    { !isDisabled && (
                      <div className={ s.name && classesProfile.switchWrap }>
                        <Switch
                          checked={ s.status === ESubscriptionStatus.ACTIVE || s.status === ESubscriptionStatus.NO_MONEY }
                          onChange={ () => (s.status === ESubscriptionStatus.NOT_ACTIVE ? onActivate(s) : onDeactivate(s)) }
                          focusVisibleClassName={ classesSwitch.focusVisible }
                          disableRipple
                          classes={ {
                            root: classesSwitch.root,
                            switchBase: classesSwitch.switchBase,
                            thumb: classesSwitch.thumb,
                            track: classesSwitch.track,
                            checked: classesSwitch.checked,
                            disabled: classesSwitch.disabled
                          } }
                        />
                      </div>
                    ) }
                  </Grid>
                </div>
              );
            })
          : null }
      </section>
    </>
  );

  return (
    <>
      <ViewBox className={ styles.root }>
        <Wrapper className={ styles.upper } color="light">
          <Typography className={ styles.caption } type="heading" level={ 1 }>
            Профиль
          </Typography>
          <div className={ styles.content }>{ statusToken === ELoadStatus.ready && !token ? loader : content }</div>
        </Wrapper>
        { statusToken === ELoadStatus.ready && token && (
          <Wrapper className={ styles.lower } color="light">
            <section className={ styles.floorSection }>
              <Button
                className={ cx(styles.btnBorder, styles.exitButton) }
                type="secondary"
                onClick={ () => setConfirmDialog(EDialogConfirmType.LOGOUT) }
              >
                Выйти
              </Button>
            </section>
          </Wrapper>
        ) }
      </ViewBox>

      <ModalConfirm
        confirmType={ EDialogConfirmType.LOGOUT }
        onConfirm={ onConfirmLogout }
        onClose={ () => {
          setConfirmDialog(null);
        } }
        open={ confirmDialog === EDialogConfirmType.LOGOUT }
      />
    </>
  );
};
DesktopProfilePage.displayName = 'DesktopProfilePage';

export default flow([LoggingContainer, ContainerUser, ContainerPrize, ContainerSubscribe, ModalsContainer])(DesktopProfilePage);
