import { IRootState } from 'app/shared/reducers/root.reducer';
import { IContentListMainState } from 'app/entities/content/content.list.main.reducer';

import { fetchContentMain, resetContentMain, IFetchContentListProps } from 'app/entities/content/content.actions';
import { logView, logDownload } from 'app/entities/logging/logging.actions';

import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';

export type IContainerContentListMainProps = IContentListMainState & {
  actions: {
    fetchContentMain: (props: IFetchContentListProps) => void;
    resetContentMain: typeof resetContentMain;
    logView: () => void;
    logDownload: (url: string, id: number) => void;
  }
};

const mapStateToProps = (state: IRootState) => state.contentListMain;
const mapDispatchToProps = (dispatch: Dispatch): IContainerContentListMainProps['actions'] => ({
  fetchContentMain: props => dispatch(fetchContentMain(props) as unknown as Action),
  resetContentMain: () => dispatch(resetContentMain()),
  logView: () => dispatch(logView() as unknown as Action),
  logDownload: (url, id) => dispatch(logDownload(url, id) as unknown as Action)
});

export const ContainerContentListMain =
  connect<IContainerContentListMainProps>('containerContentListMain', mapStateToProps, mapDispatchToProps);
