//#region imports
import React, { FC, forwardRef } from 'react';
import isFunction from 'lodash/isFunction';

import { isModifiedEvent } from 'app/utils/util.event';

import { AnchorProps, CompatElement } from './follow-link.model';
//#endregion

const InternalAnchor: FC<AnchorProps> = forwardRef<CompatElement, AnchorProps>(
  ({ node: Node, anchor, navigate, target, href: url, onClick, onAfterClick, ...props }, ref) => {
    const handleClick = event => {
      try {
        if (isFunction(onClick)) onClick(event, url);
      } catch (ex) {
        event.preventDefault();
        throw ex;
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === 0 && // ignore everything but left clicks
        !isModifiedEvent(event) && // ignore clicks with modifier keys
        (!target || target === '_self') // let browser handle "target=_blank" etc.
      ) {
        event.preventDefault();
        navigate();
      }

      if (isFunction(onAfterClick)) onAfterClick(event, url);
    };

    const attrs = anchor ? { tabIndex: 0, role: 'link', target, href: url } : { tabIndex: 0, role: 'link' };
    // should be ReactElement
    if (typeof Node === 'object') {
      return React.cloneElement(Node, { ref, ...attrs, ...props, 'data-url': url, onClick: handleClick });
    }
    return <Node ref={ ref } { ...attrs } { ...props } data-url={ url } onClick={ handleClick } />;
  }
);
InternalAnchor.displayName = 'InternalAnchor';

export default InternalAnchor;
