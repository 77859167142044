//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { EContentClassKey, ContentClasses } from './content.model';
//#endregion

export const useContentStyles = makeStyles(
  createStyles<ContentClasses, {}>({
    [EContentClassKey.root]: {},
    [EContentClassKey.itemText]: {
      fontSize: 16,
      lineHeight: '22px',
      marginBottom: `${configTheme.indents['indentX1'].margin / 2}px`
    },
    [EContentClassKey.iconFree]: {
      marginBottom: '5%',
      marginLeft: '5%',
      padding: '2px 2px',
      borderRadius: '50%',
      backgroundColor: configTheme.colors.background.dark.default
    },
    [EContentClassKey.virtual]: {
      width: 'auto',
      marginRight: '-18px',
      paddingRight: 18
    }
  }),
  { name: 'ContentMain' }
);
