import axios from 'axios';
import { BASENAME } from 'app/configs/const';

export const MENU_ACTIONS = {
  FETCH_LIST: 'MENU:LIST::FETCH'
};

export const fetchItems = () => ({
  type: MENU_ACTIONS.FETCH_LIST,
  payload: axios.get(`${BASENAME}/api/menuItem/list`)
});
