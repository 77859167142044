import axios from 'axios';
import { BASENAME } from 'app/configs/const';

export const BANNER_ACTIONS = {
  FETCH: 'BANNER::FETCH',
  FETCH_INFO_BLOCK: 'BANNER::INFO:BLOCK:FETCH'
};

export const fetchItems = () => ({
  type: BANNER_ACTIONS.FETCH,
  payload: axios.get(`${BASENAME}/api/banner/list`)
});

export const fetchInfoBlock = () => ({
  type: BANNER_ACTIONS.FETCH_INFO_BLOCK,
  payload: axios.get(`${BASENAME}/api/infoBlock/list`)
});
