import { CSSProperties, AllHTMLAttributes, MouseEventHandler, ReactHTML } from 'react';

export enum ThemePalette {
  light = 'light',
  dark = 'dark'
}

export enum TypographyType {
  heading = 'heading',
  text = 'text',
  link = 'link'
}

export enum TextColor {
  default = 'default',
  secondary = 'secondary'
}

export enum TextSize {
  default = 'default',
  tiny = 'tiny',
  small = 'small',
  large = 'large',
  huge = 'huge'
}

export type HeadingLevel = 1 | 2 | 3 | 4 | 5;

interface IProps {
  className?: string;
  style?: CSSProperties;
  tag?: keyof ReactHTML | string;
  mobile?: boolean;
  attrs?: AllHTMLAttributes<HTMLElement>;
  onClick?: MouseEventHandler<HTMLElement>;
}

export interface ITypographyRootProps extends IProps {
  /**
   * Тип оформления текста.
   */
  type: keyof typeof TypographyType;
  /**
   * Используемая тема.
   */
  theme?: keyof typeof ThemePalette;
  /**
   * Вариант цвета для текста.
   */
  color?: keyof typeof TextColor;
  /**
   * Если `true`, текст будет с засечками.
   * Работает с типом: `text` | `link`.
   */
  serif?: boolean;
  /**
   * Если `true`, выделение текста жирным шрифтом.
   * Работает с типом: `text` | `link`.
   */
  strong?: boolean;
}

export interface ITypographyHeadingProps extends IProps {
  /**
   * Уровень заголовка: `1` | `2` | `3` | `4` | `5`.
   * Работает с типом: `heading`.
   */
  level?: HeadingLevel;
}

export interface ITypographyTextProps extends IProps {
  /**
   * Размер текста.
   * Работает с типом: `text` | `link`.
   */
  size?: Exclude<keyof typeof TextSize, 'huge'>;
}

export interface ITypographyLinkProps extends Omit<ITypographyTextProps, 'tag'> {
  /**
   * Адрес ссылки.
   * Работает с типом: `link`.
   */
  href?: string;
}

export type ITypographyProps = ITypographyRootProps & ITypographyHeadingProps & ITypographyLinkProps & ITypographyTextProps;
