import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesMobileIndividualProgram = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      position: 'relative'
    },
    lineTitleMenu: {
      margin: '0 -18px'
    },
    wrapperDayMenu: {
      height: 43
    },
    btn: {
      color: ui.colors.text.dark.neutral,
      fontSize: '14px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    btnActive: {
      color: ui.colors.text.dark.default
    },
    navbar: {
      boxShadow: 'none',
      marginLeft: '-20px',
      '& li:first-child': {
        paddingLeft: 20
      },
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '44px',
        left: '-20px',
        width: 'calc(100% + 20px)',
        height: '1px'
      }
    }
  })
);
