import React from 'react';
import clsx from 'clsx';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { useStylesRadiosCheck } from './customized.radios.check.style';

export const CustomizedRadiosCheck = (props: RadioProps) => {
  const classes = useStylesRadiosCheck();

  return (
    <Radio
      className={ classes.root }
      disableRipple
      color="default"
      checkedIcon={ <span className={ clsx(classes.icon, classes.checkedIcon) } /> }
      icon={ <span className={ classes.icon } /> }
      { ...props }
    />
  );
};
