//#region imports
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Player } from 'app/components/player/player.index';

import { EDistribution, ELoadStatus } from 'app/models/shared.model';

import { ContainerContentVideoItem } from 'app/containers/container.content.item.video';

import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';

import { IContentMediaVideoProps } from 'app/pages/desktop/content/media/page.content.media.models';
import { useMobileContentVideoStyles } from './page.content.video.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';
//#endregion

enum EAzureSkin {
  DEFAULT = 'amp-default',
  FLUSH = 'amp-flush'
}

const ContentVideo: FC<IContentMediaVideoProps> = memo<IContentMediaVideoProps>(props => {
  const {
    containerContentVideoItem,
    content: contentItem,
    history,
    title,
    distribution,
    computedMatch: { params: { contentId } }
  } = props;
  const { src, token, progressStatus, free, subscribeStatus, actions: videoActions } = containerContentVideoItem;
  const styles = useMobileContentVideoStyles();
  const { t } = useTranslation();

  useEffect(() => {
    contentId && videoActions.fetchVideoItem(contentId);
  }, [contentId]);

  useEffect(() => {
    if (progressStatus === ELoadStatus.ready && !free && subscribeStatus !== ESubscriptionStatus.ACTIVE) {
      history.goBack();
    }
  }, [subscribeStatus, free, progressStatus]);

  useEffect(() => {
    return () => videoActions.resetVideoItem();
  }, []);

  const handleClose = () => {
    history.goBack();
  };

  const maskedSrc = useMemo(() => src && src?.replace(`https:`, '')?.replace(`http:`, ''), [src]);

  const loader = (
    <div>
      <CircularLoader />
    </div>
  );

  const PlayerComponent = () => {
    const streamFormats = ['HLS', 'HLS-V3', 'HLS-V4', 'SMOOTH', 'DASH'];

    let protectInfo = null;
    if (token) {
      protectInfo = [
        { type: 'Widevine', authenticationToken: token }, // azureHtml5JS
        { type: 'PlayReady', authenticationToken: token },
        // { type: 'FairPlay', certificateUrl: `//${location.host}/fairplay.cer` },
        { type: 'FairPlay', certificateUrl: '/fairplay.cer' },
        { type: 'AES', authenticationToken: token }
      ];
    }

    let mime;
    if (maskedSrc?.includes('.mp4')) mime = 'video/mp4';
    else if (maskedSrc?.includes('.ogv')) mime = 'video/ogg';
    else if (maskedSrc?.includes('format=m3u8-aapl')) mime = 'application/vnd.apple.mpegurl';
    else if (maskedSrc?.includes('format=mpd-csf-time')) mime = 'application/dash+xml';
    else if (maskedSrc?.includes('format=mpd-time-csf')) mime = 'application/dash+xml';
    else mime = 'application/vnd.ms-sstr+xml';

    const source = [
      {
        src: maskedSrc,
        type: mime,
        protectionInfo: protectInfo,
        streamingFormats: streamFormats
        // disableUrlRewriter: true,
      }
    ];

    return (
      <Player
        isDrm={ distribution === EDistribution.DRM }
        skin={ EAzureSkin.FLUSH }
        title={ title || contentItem?.name }
        onClose={ handleClose }
        token={ token }
        contendId={ contentId?.toString() }
        src={ source }
        button={ { title: t('action.close') } }
        isMobile
      />
    );
  };

  const renderItem = (s: ELoadStatus) => {
    switch (s) {
      case ELoadStatus.error:
        return <h3>Error happens, sorry...</h3>;
      case ELoadStatus.ready:
        return <div className={ styles.root }><PlayerComponent /></div>;
      case ELoadStatus.loading:
      default:
        return (
          <div className={ styles.root }>
            <ViewBox>{ loader }</ViewBox>
          </div>
        );
    }
  };

  return <>{ renderItem(progressStatus) }</>;
});
ContentVideo.displayName = 'ContentVideo';

export default ContainerContentVideoItem(ContentVideo);
