import axios from 'axios';
import { BASENAME } from 'app/configs/const';

import { ISectionItem, ISection } from 'app/models/model.section';
import { IContent } from 'app/models/model.content';
import { IOver } from 'app/entities/game/game.reducer';

import { IPagerProps } from 'app/containers/container.pager';
import { IRequestCancel, CancelToken } from 'app/models/shared.model';
import { Dispatch } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';

export const CONTENT_ACTIONS = {
  FETCH_LIST: 'CONTENT:LIST::GET',
  RESET_LIST: 'CONTENT:LIST::RESET',
  FETCH_MAIN: 'CONTENT:MAIN::GET',
  RESET_MAIN: 'CONTENT:MAIN::RESET',
  FETCH_ITEM: 'CONTENT:ITEM::GET',
  RESET_ITEM: 'CONTENT:ITEM::RESET',
  UPDATE_ROUTE_LIST: 'CONTENT:LIST::SET_ROUTE',
  UPDATE_ROUTE_ITEM: 'CONTENT:ITEM::SET_ROUTE',

  SET_OLYMPIAD_ID: 'CONTENT:OLYMPIAD:ID::SET',
  RESET_OLYMPIAD_ID: 'CONTENT:OLYMPIAD:ID::RESET',
  SET_OLYMPIAD_STEP: 'CONTENT:OLYMPIAD:STEP::SET',
  RESET_OLYMPIAD_STEP: 'CONTENT:OLYMPIAD:STEP::RESET',
  SET_OLYMPIAD_RESULT: 'CONTENT:OLYMPIAD:RESULT::SET',
  RESET_OLYMPIAD_RESULT: 'CONTENT:OLYMPIAD:RESULT::RESET',
  FETCH_OLYMPIAD_LESSON: 'CONTENT:OLYMPIAD:LESSON::GET',
  RESET_OLYMPIAD_LESSON: 'CONTENT:OLYMPIAD:LESSON::RESET',
  GET_ITEM_LIKES: 'GET_ITEM_LIKES::GET'
};

export interface IFetchContentListProps extends IPagerProps, IRequestCancel {
  id: ISectionItem['id'];
  more?: boolean;
  isSub?: boolean;
}
export const fetchContentList = (() => {
  let cancel;
  let url = null;
  return (props: IFetchContentListProps) => {
    const { id, more, isSub, pager, uncancelled, cancellable, cancelToken } = props ?? {};
    if (cancel && url) cancel(`Cancel fetchStream: ${url}`); // check and run cancel
    url = `${BASENAME}/api/content/list?sectionId=${id}&limit=${pager ? pager.limit : ''}&offset=${pager ? pager.offset : ''}`;
    return {
      type: CONTENT_ACTIONS.FETCH_LIST,
      meta: { props: { targetId: id, more, isSub, uncancelled } },
      payload: axios
        .get(url, {
          cancelToken:
            cancelToken ??
            (cancellable &&
              new CancelToken(function executor(c) {
                cancel = c; // save cancel
              }))
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.warn(err?.message);
            return Promise.resolve<{ data: { sectionId: number | null; content: IContent[]; more: boolean }; canceled: boolean }>({
              data: { sectionId: id, content: [], more: false },
              canceled: true
            });
          }
          return Promise.reject(err);
        })
    };
  };
})();

export type IFetchContentItemProps = IContent['id'];
export const fetchContentItem = (() => {
  let cancel;
  let url = null;
  return (id: IFetchContentItemProps, props?: IRequestCancel) => {
    const { uncancelled, cancellable, cancelToken } = props ?? {};
    if (cancel && url) cancel(`Cancel fetchStream: ${url}`); // check and run cancel
    url = id ? `/api/content/get?contentId=${id}` : null;
    return {
      type: CONTENT_ACTIONS.FETCH_ITEM,
      meta: { props: { uncancelled } },
      payload: axios
        .get(url, {
          cancelToken:
            cancelToken ??
            (cancellable &&
              new CancelToken(function executor(c) {
                cancel = c; // save cancel
              }))
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.warn(err?.message);
            return Promise.resolve<{ data: { content: IContent; sectionDetails: ISection }; canceled: boolean }>({
              data: {
                content: null,
                sectionDetails: null
              },
              canceled: true
            });
          }
          return Promise.reject(err);
        })
    };
  };
})();

export const fetchContentLesson = (id: IFetchContentItemProps) => ({
  type: CONTENT_ACTIONS.FETCH_OLYMPIAD_LESSON,
  payload: axios.get(`${BASENAME}/api/content/get?contentId=${id}`)
});

export const fetchContentMain = (props: IFetchContentListProps) => ({
  type: CONTENT_ACTIONS.FETCH_MAIN,
  meta: { props: { targetId: Number(props.id), more: props.more } },
  payload: axios.get(
    `${BASENAME}/api/content/main?sectionId=${props.id}&limit=${props.pager ? props.pager.limit : ''}&offset=${props.pager ? props.pager.offset : ''
    }`
  )
});

export const updateRoteList = (route: string) => ({
  type: CONTENT_ACTIONS.UPDATE_ROUTE_LIST,
  meta: { props: { route } }
});

export const updateRoteItem = (route: string) => ({
  type: CONTENT_ACTIONS.UPDATE_ROUTE_ITEM,
  meta: { props: { route } }
});

export const resetContentMain = () => ({
  type: CONTENT_ACTIONS.RESET_MAIN
});

export const resetContentList = () => ({
  type: CONTENT_ACTIONS.RESET_LIST
});

export const resetContentItem = () => ({
  type: CONTENT_ACTIONS.RESET_ITEM
});

export const resetContentLesson = () => ({
  type: CONTENT_ACTIONS.RESET_OLYMPIAD_LESSON
});

export const setOlympiadResult = (result: Pick<IOver, 'percent' | 'correctCount' | 'totalCount' | 'prizes'>) => ({
  type: CONTENT_ACTIONS.SET_OLYMPIAD_RESULT,
  payload: result
});

export const resetOlympiadResult = () => ({
  type: CONTENT_ACTIONS.RESET_OLYMPIAD_RESULT
});

export const setOlympiadStep = (step: number) => ({
  type: CONTENT_ACTIONS.SET_OLYMPIAD_STEP,
  payload: step
});

export const resetOlympiadStep = () => ({
  type: CONTENT_ACTIONS.RESET_OLYMPIAD_STEP
});

export const setOlympiadId = (contentId: IContent['id']) => ({
  type: CONTENT_ACTIONS.SET_OLYMPIAD_ID,
  payload: contentId
});

export const resetOlympiadId = () => ({
  type: CONTENT_ACTIONS.RESET_OLYMPIAD_ID
});

export const getItemLikes = arr => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(CONTENT_ACTIONS.GET_ITEM_LIKES) });
  const newArr = await arr.map(item => item.id);

  try {
    const response = await axios.post(`${BASENAME}api/heart/list`, {
      'contentIdList': newArr
    });
    dispatch({
      type: SUCCESS(CONTENT_ACTIONS.GET_ITEM_LIKES),
      payload: response.data.hearts
    });
  } catch (error) {
    dispatch({
      type: FAILURE(CONTENT_ACTIONS.GET_ITEM_LIKES),
      payload: error
    });
    return;
  }
};
