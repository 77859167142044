//#region imports
import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import { isIOS } from 'mobile-device-detect';
import cx from 'classnames';

import isElement from 'lodash/isElement';
import throttle from 'lodash/throttle';
import get from 'lodash/get';

import './player.styles.css';
import initLibrary from 'app/components/player/loader/player.loader';
import PlayerWrapper from './wrapper/player.wrapper';
import PlayerControls from './controls/player.controls';
import { IPlayerProps } from './player.models';
import { EIcons } from 'a1s-tele2-react-ui/src/model/icons.model';
import { Icon } from 'a1s-tele2-react-ui/src/components/shared/icon/icon.index';
//#endregion

let isStarted = false;
let timerId: any;

// TODO: find types, make tests, remove inline classes
export class Player extends PureComponent<IPlayerProps> {
  private initialization: any;
  private player: any;
  private _userActivityTimer: any;
  private _handleUserActivity: any;

  ampRoot: any;
  videoNode: any;
  controlBar: any;
  closeButton: any;

  state = {
    viewportHeight: window.innerHeight,
    currentTime: 0,
    duration: 0,
    muted: false,
    paused: true,
    started: false,
    playing: false,
    isFullscreen: false,
    isUserActive: false
  };

  constructor(props: any) {
    super(props);

    this.ampRoot = React.createRef();
    this.videoNode = React.createRef();
    this.controlBar = React.createRef();
    this.closeButton = React.createRef();

    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onPaused = this.onPaused.bind(this);
    this.onEnded = this.onEnded.bind(this);

    this._handleUserActivity = throttle(() => {
      clearTimeout(this._userActivityTimer);
      if (!this.state.isUserActive) this.setState({ isUserActive: true });
      this._userActivityTimer = setTimeout(() => {
        if (this.state.isUserActive) this.setState({ isUserActive: false });
      }, 5000);
    }, 300);
  }

  componentWillUnmount() {
    this._handleUserActivity?.cancel();
    clearTimeout(this._userActivityTimer);

    this.destroyPlayer();
    this.stopTimer();

    isStarted = false;
    window.removeEventListener('resize', () => this.setState({ viewportHeight: window.innerHeight }));
  }

  componentDidMount() {
    const { skin } = this.props as IPlayerProps;
    this.initialization = initLibrary(skin).then(() => {
      this.createPlayer();
      this.setVideo();
    });
    window.addEventListener('resize', () => this.setState({ viewportHeight: window.innerHeight }));
  }

  componentDidUpdate(prevProps: any) {
    const { src } = this.props as IPlayerProps;
    if (prevProps.src !== src) {
      this.initialization.then(() => this.setVideo());
    }
  }

  setVideo() {
    const { src } = this.props as IPlayerProps;
    if (this.player) this.player.src(src);
    if (isElement(this.controlBar.current)) this.player.playerElement()?.appendChild(this.controlBar.current);
    if (isElement(this.closeButton.current)) this.player.playerElement()?.appendChild(this.closeButton.current);
  }

  destroyPlayer() {
    if (this.player) {
      this.player.dispose();
    }
  }

  createPlayer() {
    const { onInstanceCreated } = this.props as IPlayerProps;
    const defaultOptions = {
      controls: true,
      logo: { enabled: false },
      preload: 'auto',
      playsInline: true
    };
    if (isElement(this.videoNode.current)) {
      // @ts-ignore
      this.player = window.amp(this.videoNode.current, { ...defaultOptions, autoplay: !isIOS });

      this.player && this.player.addEventListener('timeupdate', this.onTimeUpdate.bind(this), false);
      this.player && this.player.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this), false);
      this.player && this.player.addEventListener('durationchange', this.onDurationChange.bind(this), false);
      this.player && this.player.addEventListener('unmute', this.onUnmuted.bind(this), false);
      this.player && this.player.addEventListener('mute', this.onMuted.bind(this), false);
      this.player && this.player.addEventListener('play', this.onPlay.bind(this), false);
      this.player && this.player.addEventListener('pause', this.onPaused.bind(this), false);
      this.player && this.player.addEventListener('start', this.onStart.bind(this), false);
      this.player && this.player.addEventListener('ended', this.onEnded.bind(this), false);

      onInstanceCreated && onInstanceCreated(this.player);
    }
  }

  onTimeUpdate() {
    const currentTime = get(this.player, 'cache_.currentTime');
    if (!isStarted && parseFloat(currentTime.toFixed(0)) === 0) {
      isStarted = true;
      const panelLeft = document.querySelector('.amp-controlbaricons-left');
      const panelMiddle = document.querySelector('.amp-controlbaricons-middle');
      const panelRight = document.querySelector('.amp-controlbaricons-right');
      // @ts-ignore
      if (panelLeft) panelLeft.style.cssText = 'display: table-cell !important';
      // @ts-ignore
      if (panelMiddle) panelMiddle.style.cssText = 'display: table !important';
      // @ts-ignore
      if (panelRight) panelRight.style.cssText = 'display: table-cell !important';
      {
        this.props.loggingContainer?.actions.logStartVideo({
          accessToken: this.props.token,
          contentId: this.props.contendId
        });
        this.startTimer();
      }
    }
    this.setState({ currentTime: this.player?.currentTime() });
  }

  onFullscreenChange() {
    this.setState({ isFullscreen: this.player?.isFullscreen() });
  }

  onDurationChange() {
    this.setState({ duration: this.player?.duration() });
  }

  onUnmuted() {
    this.setState({ muted: false });
  }

  onMuted() {
    this.setState({ muted: true });
  }

  onPlay() {
    this.setState({ paused: false });
  }

  onPaused() {
    this.setState({ paused: true });
    this.stopTimer();
  }

  onStart() {
    this.setState({ started: true });
  }

  onEnded() {
    this.stopTimer();
  }

  startTimer() {
    timerId = setInterval(() => {
      this.props.loggingContainer?.actions.logPartVideo({
        contentViewId: this.props.loggingContainer.contentViewId,
        contentId: this.props.contendId,
        tickSeconds: '5'
      });
    }, 5000);
  }

  stopTimer() {
    clearInterval(timerId);
  }

  renderDesktop() {
    const { skin = 'amp-default', title, cornerPlayBtn, button, onClose, isTablet } = this.props as IPlayerProps;
    const { currentTime, duration, muted, paused, isFullscreen } = this.state;
    const vh = this.state.viewportHeight - (90 + 107 + 50 + 24);
    return (
      <>
        <style>
          { `
              .vjs-control-bar {
                display: none !important;
              }
              .amp-controlbaricons-left {
              }
              .amp-controlbaricons-right {
              }
              .amp-controlbaricons-middle {
              }
              body {
                overflow: hidden;
              }
              .amp-flush-skin .vjs-control-bar .amp-controlbaricons-left .vjs-play-control.vjs-control {
                display: inline-block !important;
              }
              .amp-flush-skin .vjs-control-bar .amp-controlbaricons-middle {
                min-width: 100px;
              }
              .amp-flush-skin .vjs-control-bar .vjs-progress-control {
                top: 0;
              }
              .amp-flush-skin .vjs-control-bar .vjs-current-time,
              .amp-flush-skin .vjs-control-bar .amp-quality-control,
              .amp-flush-skin .vjs-control-bar .amp-moreoptions-control {
                display: none !important;
              }
              .amp-flush-skin.vjs-user-inactive .vjs-playing .vjs-control-bar {
                opacity: 0 !important;
              }
              .amp-video__container .azuremediaplayer {
                background: #292929;
              }
          ` }
        </style>
        <PlayerWrapper classes={ { root: 'amp-title', shape: 'amp-wrapper__shape' } } style={ { background: '#4D4D4D' } } spacing={ 0 }>
          <Grid
            className="amp-title__panel"
            container
            spacing={ 0 }
            direction="row"
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid className="amp-title__logo" item>
              { title }
            </Grid>
            <Grid item>
              <div
                className="amp-video__btn-close"
                style={ {
                  display: 'flex',
                  height: '45px',
                  width: '45px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  paddingTop: '3px',
                  userSelect: 'none',
                  fontWeight: 'normal'
                } }
                onClick={ onClose }
              >
                <Icon iKey={ EIcons.close } color={ 'white' } fontSize={ 35 }/>
              </div>
            </Grid>
          </Grid>
        </PlayerWrapper>

        <PlayerWrapper
          classes={ { root: 'amp-video', shape: cx('amp-wrapper__shape', 'amp-video__shape') } }
          style={ {
            willChange: 'height',
            height: vh
          } }
          spacing={ 0 }
        >
          <div
            style={ {
              willChange: 'height',
              height: vh,
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 1000,
              transition: 'height 0.3s'
            } }
          >
            <div className="amp-video__container" style={ { height: '100%', background: '#292929' } }>
              <video
                ref={ this.videoNode }
                key="player"
                id="player"
                className={ cx('azuremediaplayer', `${skin}-skin`, { 'amp-big-play-centered': !cornerPlayBtn }) }
                aria-hidden="true"
                preload="auto"
                tabIndex={ 0 }
                playsInline
                autoPlay
              />

              <PlayerControls
                ref={ this.controlBar }
                className="amp-video__control-bar"
                style={ { transform: !isFullscreen ? 'translateY(100%)' : undefined, marginBottom: !isFullscreen ? -24 : 24 } }
                currentTime={ currentTime }
                duration={ duration }
                paused={ paused }
                muted={ muted }
                isFullscreen={ isFullscreen }
                onPlayed={ () => {
                  this.player?.play();
                } }
                onPaused={ () => {
                  this.player?.pause();
                } }
                onMuted={ (b: any) => {
                  this.player?.muted(b);
                } }
                onTimeChange={ (n: any) => {
                  this.player?.currentTime(n);
                } }
                onFullscreen={ (b: any) => {
                  if (b) this.player?.enterFullscreen();
                  else this.player?.exitFullscreen();
                } }
              />
            </div>
          </div>
        </PlayerWrapper>
      </>
    );
  }

  renderMobile() {
    const { skin = 'amp-default', cornerPlayBtn, onClose } = this.props as IPlayerProps;
    return (
      <>
        <div className="amp-video" style={ { willChange: 'height', height: '100%' } }>
          <div
            style={ {
              willChange: 'height',
              height: '100%',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 1000,
              transition: 'height 0.3s'
            } }
          >
            <div className="amp-video__container" style={ { height: '100%' } }>
              <video
                ref={ this.videoNode }
                className={ cx('azuremediaplayer', `${skin}-skin`, { 'amp-big-play-centered': !cornerPlayBtn }) }
                preload="auto"
                tabIndex={ 0 }
                aria-hidden="true"
                playsInline
                autoPlay
              />

              <div
                ref={ this.closeButton }
                className="amp-video__btn-close"
                style={ {
                  display: 'flex',
                  position: 'fixed',
                  top: '3%',
                  right: '5%',
                  height: '45px',
                  width: '45px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  paddingTop: '3px',
                  userSelect: 'none',
                  zIndex: 1000,
                  backgroundColor: 'rgb(31, 34, 41)'
                } }
                onClick={ onClose }
              >
                <Icon iKey={ EIcons.close } color={ 'white' }/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { paused, started, isFullscreen, isUserActive } = this.state;
    const { isMobile } = this.props;
    return (
      <section
        ref={ this.ampRoot }
        className={ cx('amp-root', {
          'amp-root_mobile': isMobile,
          'amp-root_started': started,
          'amp-root_playing': !paused,
          'amp-root_fullscreen': isFullscreen,
          'amp-root_user-active': isUserActive,
          'amp-root_user-inactive': !isUserActive
        }) }
        style={ { height: this.state.viewportHeight } }
        onMouseEnter={ this._handleUserActivity }
        onMouseMove={ this._handleUserActivity }
        onMouseDown={ this._handleUserActivity }
        onMouseUp={ this._handleUserActivity }
        onTouchStart={ this._handleUserActivity }
      >
        <style>
          { `
              .vjs-control-bar.vjs-hidden {
                display: flex !important;
              }
              .amp-controlbaricons-left {
                display: none !important;
              }
              .amp-controlbaricons-right {
                display: none !important;
              }
              .amp-controlbaricons-middle {
                display: none !important;
              }
              body {
                overflow: hidden;
              }
              .amp-flush-skin .vjs-control-bar .amp-controlbaricons-middle {
                min-width: 100px;
              }
          ` }
        </style>
        { isMobile ? this.renderMobile() : this.renderDesktop() }
      </section>
    );
  }
}
