import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesModalParameters = makeStyles((theme: Theme) =>
    createStyles({
      wrapper: {
        width: '100%',
        height: '100vh',
        backgroundColor: '#FFFFFF',
        overflow: 'auto'
      },
      title: {
        margin: 0
      },
      checkBoxLabel: {
        fontFamily: 'inherit!important',
        color: '#1F2229',
        '& .MuiTypography-root': {
          fontFamily: 'inherit!important',
          color: '#1F2229'
        }
      },
      formWrapper: {
        display: 'flex',
        flexDirection: 'column'
      },
      autocomplete: {
        '& .MuiFilledInput-root': {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          border: '1px solid #D5D8DD'
        },
        '& .MuiFilledInput-underline:before': {
          borderBottom: 'none'
        },
        '& .MuiFilledInput-underline:hover:before': {
          borderBottom: 'none'
        },
        '& .MuiFilledInput-underline:after': {
          borderBottom: 'none'
        }
      },
      btn: {
        textTransform: 'inherit',
        margin: theme.spacing(1),
        marginLeft: '0px',
        marginTop: '0px',
        border: `1px solid ${configTheme.colors.background.neutral.neutral}`,
        boxSizing: 'border-box',
        borderRadius: '32px',
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '15px',
        width: 142,
        '& .MuiButton-label': {
          justifyContent: 'space-between'
        },
        '&.activeBtn': {
          border: `1px solid ${configTheme.colors.border.primary.dark}`,
          '& .MuiButton-endIcon': {
            marginRight: 0
          }
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#FFFFFF'
        }

      },
      paperLoaderMyDiete: {
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
      },
      progress: {
        marginTop: '54px'
      },
      btnSave: {
        width: '234px',
        height: '48px',
        marginBottom: '18px'
      },
      description: {
        marginTop: '36px',
        color: '#8A8F97!important'
      },
      btnCancel: {
        backgroundColor: '#FFFFFF',
        color: '#1F2229',
        width: '234px',
        height: '48px'
      }
    })
);
