import React, { FC, useEffect, useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useStylesModalMobileCalcProgram } from './program.style';

import { withStyles, CheckboxProps } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import ButtonMaterial from '@material-ui/core/Button';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { CustomTextField } from 'app/components/custom.input/custom.input';

import CloseIcon from 'app/assets/img/close.svg';
import RadioOnBtn from 'app/assets/img/radio.on.svg';
import RadioOffBtn from 'app/assets/img/hide.radio.svg';
import calcProgramModalImg from 'app/assets/img/calc.program.modal.img.png';

import { ESex, ETarget, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { ELoadStatus } from 'app/models/shared.model';
import { DIET, EDietPageSection } from 'app/configs/route.names';
import { loginExternal } from 'app/utils/auth.external';
import { CustomAutocomplete } from 'app/components/autocomplete/autocomplete';

interface IModalMobileDietProgramProps {
  setShowModal: (boolean: boolean) => void;
  isShowModal: boolean;
  propsState: any;
  save: (data: IUserAttributes) => void;
  loading?: ELoadStatus;
  authenticated?: boolean;
}

interface ILoaderDietProgramProps {
  modalClosePayment: () => void;
  setShowModal: (boolean) => void;
  auth?: boolean;
}

const GreenCheckbox = withStyles({
  root: {
    color: '#8F9399',
    '&$checked': {
      color: ' #1F2229'
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" { ...props } />);

const LoaderDietProgram: FC<ILoaderDietProgramProps> = forwardRef(({ modalClosePayment, setShowModal, auth }, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const styles = useStylesModalMobileCalcProgram();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 450);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!auth) {
        localStorage.setItem('backUrl', '/');
        loginExternal(DIET(EDietPageSection.PROGRAM));
      } else {
        localStorage.setItem('backUrl', '/diet');
        history.push(DIET(EDietPageSection.PROGRAM));
      }
    }, 5000);
    return () => {
      modalClosePayment();
      clearInterval(timeout);
    };
  }, []);

  return (
    <>
      <div className={ styles.paperLoader }>
        <Grid>
          <Typography type="heading" level={ 2 }>{ t('modalCalcProgram.WaitMinute') }!</Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <img src={ calcProgramModalImg } alt="calcProgramModalImg" style={ { marginTop: '54px', height: 146 } } />
        </Grid>
        <div className={ styles.progress }>
          <LinearProgress variant="determinate" value={ progress } />
        </div>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={ { marginTop: '27px' } }>
          { t('modalCalcProgram.calcProgram') }
        </Grid>
      </div>
    </>
  );
});

const ModalMobileDietProgram: FC<IModalMobileDietProgramProps> = ({
  isShowModal,
  setShowModal,
  propsState,
  loading,
  save,
  authenticated
}) => {
  const { t } = useTranslation();
  const options = [
    { id: ETarget.WEIGHT_DOWN, title: `${t('modalCalcProgram.loseWeight')}` },
    { id: ETarget.WEIGHT_UP, title: `${t('modalCalcProgram.toGainWeight')}` },
    { id: ETarget.WEIGHT_KEEP, title: `${t('modalCalcProgram.maintainWeight')}` }
  ];

  const [valueAutocomplete, setValueAutocomplete] = useState(options[0]);
  const [errorWeight, setErrorWeight] = useState(false);
  const [errorHeight, setErrorHeight] = useState(false);
  const [errorAge, setErrorAge] = useState(false);
  const [inputValueTextField, setInputValueTextField] = useState({
    height: '',
    weight: '',
    age: '',
    target: null
  });
  const [state, setState] = useState({
    breastFeeding: false
  });
  const [isSwitchWomenMen, setSwitchWomenMen] = useState(ESex.FEMALE);
  const [payment, setPayment] = useState(false);
  const styles = useStylesModalMobileCalcProgram({ isSwitchWomenMen });

  const handleCalcIndividualProgram = async () => {
    const { height, weight, age, target } = inputValueTextField;
    if (errorWeight || errorHeight || errorAge) {
      return;
    }

    if (!authenticated) {
      localStorage.setItem(
        'individualParameters',
        JSON.stringify(
          { height: !!height ? Number(height) : null,
            weight:  !!weight ? Number(weight) : null,
            age: !!age ? Number(age) : null,
            sex: isSwitchWomenMen,
            target,
            ...state }
        )
      );
      setPayment(true);
    } else {
      await save({
        height: !!height ? Number(height) : null,
        weight: !!weight ? Number(weight) : null,
        age: !!age ? Number(age) : null,
        sex: isSwitchWomenMen,
        target,
        ...state
      });
      if (loading === ELoadStatus.ready) {
        setPayment(true);
      }
    }
  };

  const validateForm = (inputValidateItem, stateValidate, min, max) => {
    if (inputValidateItem < min || inputValidateItem > max) {
      stateValidate(true);
    } else {
      stateValidate(false);
    }

    if (inputValidateItem === '' || inputValidateItem === null) {
      stateValidate(false);
    }
  };

  useEffect(() => {
    validateForm(inputValueTextField.height, setErrorHeight, 100, 250);
  }, [inputValueTextField.height]);

  useEffect(() => {
    validateForm(inputValueTextField.weight, setErrorWeight, 20, 300);
  }, [inputValueTextField.weight]);

  useEffect(() => {
    validateForm(inputValueTextField.age, setErrorAge, 14, 114);
  }, [inputValueTextField.age]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ breastFeeding: event.target.checked });
  };

  useEffect(() => {
    setInputValueTextField(prevState => ({
      ...prevState,
      target: valueAutocomplete.id
    }));
  }, [valueAutocomplete]);

  useEffect(() => {
    if (propsState !== null && Object.keys(propsState).length !== 0) {
      const { height, weight, age, sex, breastFeeding, target } = propsState;
      setInputValueTextField({
        height: height === null ? '' : String(height),
        weight: weight === null ? '' : String(weight),
        age: age === null ? '' : String(age),
        target: target ? options.find(el => el.id === target)?.id : options[0]?.id
      });
      setValueAutocomplete(target ? options.find(el => el.id === target) : options[0]);
      setSwitchWomenMen(sex === null ? ESex.FEMALE : sex);
      setState({ breastFeeding });
    }
  }, [propsState]);

  useEffect(() => {
    if (!isShowModal) {
      setState({ breastFeeding: false });
      setSwitchWomenMen(ESex.FEMALE);
      setInputValueTextField({
        height: '',
        weight: '',
        age: '',
        target: options[0].id
      });
    }
  }, [isShowModal]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const modalClosePayment = () => {
    setPayment(false);
  };

  useEffect(() => {
    return () => {
      modalClosePayment();
    };
  }, []);

  return (
    <>
      <Modal
        open={ isShowModal }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={ { minHeight: '100vh' } }
      >
        <div className={ styles.wrapper }>
          { payment ? (
            <LoaderDietProgram modalClosePayment={ modalClosePayment } setShowModal={ setShowModal } auth={ authenticated }/>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={ { marginTop: '36px', paddingLeft: '18px', paddingRight: '18px' } }
            >
              <Typography type="heading" className={ styles.title } level={ 2 }>{ t('modalCalcProgram.yourParameters') }</Typography>
              <img src={ CloseIcon } alt="close modal" onClick={ () => setShowModal(false) } />
              <Grid container direction="row" justifyContent="center" alignItems="center" style={ { marginTop: '27px', color: '#1F2229' } }>
                { t('modalCalcProgram.yourParametersForCalculation') }
              </Grid>
              <form noValidate className={ styles.formWrapper }>
                <CustomTextField
                  type="number"
                  style={ { marginBottom: '18px', width: '100%', marginTop: '27px' } }
                  label={ t('calc.height') }
                  value={ inputValueTextField.height }
                  onChange={ e => setInputValueTextField({ ...inputValueTextField, height: e.target.value }) }
                  variant="filled"
                  id="height"
                  error={ errorHeight }
                  helperText={ errorHeight && t('modalCalcProgram.checkValidData') }
                  onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                  inputProps={ { pattern: '[0-9]*' } }
                />
                <CustomTextField
                  type="number"
                  style={ { marginBottom: '18px', width: '100%' } }
                  label={ t('calc.weight') }
                  value={ inputValueTextField.weight }
                  onChange={ e => setInputValueTextField({ ...inputValueTextField, weight: e.target.value }) }
                  variant="filled"
                  id="weight"
                  error={ errorWeight }
                  helperText={ errorWeight && t('modalCalcProgram.checkValidData') }
                  onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                  inputProps={ { pattern: '[0-9]*' } }
                />
                <CustomTextField
                  type="number"
                  style={ { marginBottom: '18px', width: '100%' } }
                  label={ t('modalCalcProgram.age') }
                  value={ inputValueTextField.age }
                  onChange={ e => setInputValueTextField({ ...inputValueTextField, age: e.target.value }) }
                  variant="filled"
                  id="age"
                  error={ errorAge }
                  helperText={ errorAge && t('modalCalcProgram.checkValidData') }
                  onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                  inputProps={ { pattern: '[0-9]*' } }
                />
                <CustomAutocomplete
                  value={ valueAutocomplete }
                  options={ options }
                  id="controllable-states"
                  onChange={ setValueAutocomplete }
                  style={ { width: '100%' } }
                />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={ { marginTop: '27px' } }>
                  <ButtonMaterial
                    variant="outlined"
                    className={ classnames(styles.btn, {
                      activeBtn: isSwitchWomenMen === ESex.FEMALE
                    }) }
                    endIcon={ isSwitchWomenMen === ESex.FEMALE ? <img src={ RadioOnBtn } alt="women" /> : <img src={ RadioOffBtn } alt="men" /> }
                    onClick={ () => setSwitchWomenMen(ESex.FEMALE) }
                  >
                    { t('modalCalcProgram.woman') }
                  </ButtonMaterial>
                  <ButtonMaterial
                    variant="outlined"
                    className={ classnames(styles.btn, {
                      activeBtn: isSwitchWomenMen !== ESex.FEMALE
                    }) }
                    endIcon={ isSwitchWomenMen !== ESex.FEMALE ? <img src={ RadioOnBtn } alt="women" /> : <img src={ RadioOffBtn } alt="men" /> }
                    onClick={ () => setSwitchWomenMen(ESex.MALE) }
                  >
                    { t('modalCalcProgram.man') }
                  </ButtonMaterial>
                </Grid>

                {
                  isSwitchWomenMen === ESex.FEMALE &&
                    <FormGroup row style={ { marginTop: '10px' } }>
                      <FormControlLabel
                        classes={ { label: styles.checkBoxLabel } }
                        control={ <GreenCheckbox name={ t('calc.breastFeeding') } checked={ state.breastFeeding } onChange={ handleChange } /> }
                        label={ t('calc.breastFeeding') }
                      />
                    </FormGroup>
                }

                <Grid container direction="row" justifyContent="space-around" alignItems="center" style={ { marginTop: '37px' } }>
                  <Button style={ { width: '273px', height: '48px', marginBottom: '18px' } } onClick={ handleCalcIndividualProgram }>
                    { t('modalCalcProgram.calculateMyProgram') }
                  </Button>
                  <Button
                    style={ { backgroundColor: '#FFFFFF', color: '#1F2229', width: '273px', height: '48px' } }
                    onClick={ handleCloseModal }
                  >
                    { t('modalCalcProgram.cancel') }
                  </Button>
                </Grid>
              </form>
              <Typography type="text" tag="p" size="small" style={ { marginTop: '36px', color: '#8A8F97' } }>{ t('modalCalcProgram.programDescription') }</Typography>
            </Grid>
          ) }
        </div>
      </Modal>
    </>
  );
};

export default ModalMobileDietProgram;
