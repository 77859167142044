import React, { FC, createElement } from 'react';
import classnames from 'classnames';

import { ITypographyTextProps, TypographyType, TextSize } from './typography.models';
import { useTypographyTextStyles } from './typography.styles';

export const TypographyText: FC<ITypographyTextProps> = props => {
  const { className, style, tag, size, attrs, mobile, children, onClick } = props;
  const styles = useTypographyTextStyles();

  return createElement(
    tag,
    { className: classnames(className, styles?.[`${TypographyType.text}-${size}`], { [styles.mobile]: mobile }), style, ...attrs, onClick },
    children
  );
};

TypographyText.defaultProps = {
  tag: 'span',
  attrs: null,
  mobile: false,
  size: TextSize.default
} as ITypographyTextProps;
