import React from 'react';
import { IRootState } from 'app/shared/reducers/root.reducer';
import { IBannerState } from 'app/entities/banner/banner.reducer';

import { fetchItems, fetchInfoBlock } from 'app/entities/banner/banner.actions';
import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

export type IBannerContainer = IBannerState & {
  actions: {
    /** @deprecated */
    itemsFetch: typeof fetchItems;
    fetchItems: typeof fetchItems;
    fetchInfoBlock: typeof fetchInfoBlock;
  }
};

const mapStateToProps = (state: IRootState) => state.banner;
const mapDispatchToProps = (dispatch: Dispatch): IBannerContainer['actions'] => ({
  itemsFetch: () => dispatch(fetchItems()),
  fetchItems: () => dispatch(fetchItems()),
  fetchInfoBlock: () => dispatch(fetchInfoBlock())
});

export const BannerContainer = connect<IBannerContainer>('bannerContainer', mapStateToProps, mapDispatchToProps);
