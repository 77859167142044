import React from 'react';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { ISubcategoryState } from 'app/entities/categories/subcategory/entity.subcategory.reducer';

import { fetchSubcategoryItems, fetchSubcategoryItem } from 'app/entities/categories/subcategory/entity.subcategory.actions';

import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

import { ISubcategory } from 'app/models/model.subcategory';
import { ICategory } from 'app/models/model.category';

export type ISubcategoryContainerProps = ISubcategoryState & {
  actions: {
    fetchSubcategoryItems: typeof fetchSubcategoryItems;
    fetchSubcategoryItem: (id: ISubcategory['id']) => any;
  }
};

const mapStateToProps = (state: IRootState) => state.subcategory;
const mapDispatchToProps = (dispatch: Dispatch): ISubcategoryContainerProps['actions'] => ({
  fetchSubcategoryItems: (id: ICategory['id']) => dispatch(fetchSubcategoryItems(id)),
  fetchSubcategoryItem: (id: ISubcategory['id']) => dispatch(fetchSubcategoryItem(id))
});

export const SubcategoryContainer = connect<ISubcategoryContainerProps>('containerSubcategory', mapStateToProps, mapDispatchToProps);
