import { createStyles, makeStyles } from '@material-ui/core/styles';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesNavbar = makeStyles(theme => createStyles({
  wrap: {
    position: 'relative'
  },
  navbar: {
    boxShadow: 'none',
    '& li': {
      marginRight: 18,
      '& div': {
        paddingLeft: 0,
        paddingRight: 0,
        '&:before': {
          height: '2px!important',
          background: ui.colors.background.dark.default
        }
      },
      '&:last-child': {
        marginRight: 0
      }
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '44px',
      left: `-${ui.indents['indentX1'].margin}px`,
      width: `calc(100% + ${ui.indents['indentX2'].margin}px)`,
      height: '1px',
      background: ui.colors.background.neutral.neutral
    }
  },
  subnavbar: {
    marginBottom: `${ui.indents['indentX1'].margin}px`,
    boxShadow: 'none',
    '& li': {
      border: '0 none',
      '&:first-child div': {
        paddingLeft: 0
      },
      '& .exact:before': {
        display: 'none'
      }
    }
  },
  headUnderLine: {
    '&:before': {
      content: '""',
      left: -18,
      width: 'calc(100% + 36px)',
      top: 44,
      height: 1,
      position: 'absolute',
      background: ui.colors.border.primary.light
    }
  },
  wrapCommon: {
    position: 'relative',
    height: 35
  },
  wrapNavbar: {
    boxShadow: 'none',
    position: 'relative',
    left: -18,
    width: 'calc(100% + 36px)',
    scrollbarWidth: 'none',
    '& ::-moz-scrollbar': {
      display: 'none'
    }
  },
  wrapNavbarCommon: {
    boxShadow: 'none',
    scrollbarWidth: 'none',
    '& ::-moz-scrollbar': {
      display: 'none'
    }
  }
}));
