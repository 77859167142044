//#region imports
import React, { FC } from 'react';

import CircularLoader from 'app/components/loader/loader.circular';

import { IVirtualFallbackProps } from './virtual.model';
//#endregion

//#region VirtualFallback
const VirtualFallback: FC<IVirtualFallbackProps> = props => {
  const { width, height, component } = props;
  if (typeof component === 'function') return React.createElement(component, { width, height });
  else if (component) return React.cloneElement(component, { width, height });
  // return <CircularLoader unsuspend center style={ { height } } />;
  return <CircularLoader center style={ { height } } />;
};
VirtualFallback.displayName = 'VirtualFallback';
//#endregion

export default VirtualFallback;
