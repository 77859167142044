import React, { FC, memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import map from 'lodash/map';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import Wrapper from 'app/components/wrapper/wrapper.index';
import AppBarLogo from 'app/components/desktop/app.bar/app.bar.logo';

export const useAppBarStyles = makeStyles(
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: '100%',
      minHeight: 40,
      maxHeight: 40,
      '@media(max-width: 1023.98px)': { flexFlow: 'row wrap' }
    },

    panel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },

    toolbar: { marginLeft: configTheme.indents['indentX1'].margin, '& span': { display: 'inline-flex' } }
  }),
  { name: 'AppBar' }
);

const AppBar: FC<any> = memo<any>(props => {
  const { className, style, color, toolbarData } = props;
  const styles = useAppBarStyles();
  return (
    <Wrapper className={ className } style={ style } color={ color }>
      <header className={ styles.root }>
        <AppBarLogo color={ color } />

        <div className={ styles.panel }>
          { toolbarData?.length > 0 && (
            <div className={ styles.toolbar }>
              { map(toolbarData, (item, index) => (
                <span key={ `${index}.${item.name}` }>{ item.component }</span>
              )) }
            </div>
          ) }
        </div>
      </header>
    </Wrapper>
  );
});
AppBar.displayName = 'AppBar';

AppBar.defaultProps = { color: 'dark' };

export default AppBar;
