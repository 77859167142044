import React from 'react';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { ISectionListState } from 'app/entities/section/entity.section.list.reducer';

import {
  fetchSectionItems,
  resetSectionItems,
  removeSectionItems,
  cancelFetchSectionItems,
  IFetchSectionItemsProps
} from 'app/entities/section/entity.section.actions';

import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';

import { ISubcategory } from 'app/models/model.subcategory';
import { ThunkDispatch } from 'redux-thunk';

export type ISectionListContainerProps = ISectionListState & {
  actions: {
    fetchSectionItems: (props: IFetchSectionItemsProps) => any;
    resetSectionItems: typeof resetSectionItems;
    removeSectionItems: typeof removeSectionItems;
    cancelFetchSectionItems: typeof cancelFetchSectionItems;
  }
};

const mapStateToProps = (state: IRootState) => state.sectionList;
const mapDispatchToProps = (dispatch: ThunkDispatch<ISectionListState, undefined, any>): ISectionListContainerProps['actions'] => ({
  fetchSectionItems: (props: IFetchSectionItemsProps) => dispatch(fetchSectionItems(props)),
  resetSectionItems: () => dispatch(resetSectionItems()),
  removeSectionItems: targetIdList => dispatch(removeSectionItems(targetIdList)),
  cancelFetchSectionItems: (id: ISubcategory['id']) => cancelFetchSectionItems(id)
});

export const SectionListContainer = connect<ISectionListContainerProps>('sectionListContainer', mapStateToProps, mapDispatchToProps);
