import { History } from 'history';

import get from 'lodash/get';

import { ELoadStatus } from 'app/models/shared.model';
import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import { IAuthContainerProps } from 'app/containers/container.user';
import { IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { DIET, EDietPageSection } from 'app/configs/route.names';

interface IUtilUserOpportunitiesCheck {
  readonly subscribe: ISubscription;
  readonly callback?: () => void;
  readonly callbackClose?: () => void;
  readonly callbackCancel?: () => void;
  readonly containerUser: IAuthContainerProps;
  readonly containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  readonly history?: History;
}

const UtilUserOpportunitiesCheck = ({
  subscribe,
  containerUser,
  history
}: IUtilUserOpportunitiesCheck) => {
 if (!get(containerUser, 'authenticated')
   || subscribe?.status === ESubscriptionStatus.NOT_ACTIVE
 ) {
   return;
 } else {
   return history.replace(DIET(EDietPageSection.PROGRAM));
 }
};

export default UtilUserOpportunitiesCheck;
