import axios from 'axios';
import { BASENAME } from 'app/configs/const';
import { ISubscribeState } from 'app/entities/subscribe/subscribe.reducer';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/actions/action.types';
import { logSubscriptionChange } from 'app/entities/logging/logging.actions';

export const USER_ACTIONS = {
  SUBSCRIPTION_FETCH: 'USER::FETCH:SUBSCRIPTION',
  SUBSCRIPTION_ACTIVATE: 'USER::ACTIVATE:SUBSCRIPTION',
  SUBSCRIPTION_DEACTIVATE: 'USER::DEACTIVATE:SUBSCRIPTION',
  RESET_PROP: 'USER::PROP:RESET'
};

export const fetchSubscription = () => ({
  type: USER_ACTIONS.SUBSCRIPTION_FETCH,
  payload: axios.get(`${BASENAME}/api/subscribe/list`)
});

export const setSubscription = (activate: boolean, subscribeId: number) => async dispatch => {
  const type = activate ? USER_ACTIONS.SUBSCRIPTION_ACTIVATE : USER_ACTIONS.SUBSCRIPTION_DEACTIVATE;
  dispatch({ type: REQUEST(type) });
  try {
    const payload = await axios.post(`${BASENAME}/api/subscribe/${activate ? 'activate' : 'deactivate'}`, { subscribeId });
    dispatch({ type: SUCCESS(type), payload });
    dispatch(logSubscriptionChange(activate, subscribeId));
  } catch (e) {
    dispatch({ type: FAILURE(type), payload: e });
  }
};

export const resetProp = (prop: keyof ISubscribeState | keyof ISubscribeState['status']) => ({
  type: USER_ACTIONS.RESET_PROP,
  payload: prop
});
