import { IFormat } from 'app/models/model.content';

export const getMimeTypes = (formats: IFormat[] = []): { [key: string]: string } => {
  const dict = {};
  formats.forEach(({ mimeType }) => {
    if (mimeType) {
      if (mimeType?.includes('pdf')) dict['pdf'] = mimeType;
      if (mimeType?.includes('audio')) dict['audio'] = mimeType;
    }
  });
  return dict;
};
