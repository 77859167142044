import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IModalProps, Modal } from 'app/components/modal/modal.ui';
import { Button } from 'app/components/button/button.ui';

import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';

import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { useModalPackStyles } from 'app/components/modal/modal.styles';
import { IModalButton } from 'app/components/modal/modal.notification';

import { phoneMaskDry } from 'a1s-tele2-react-ui/src/utils/utils.mask';

const SubscribeModalEl: FC<
  IModalProps & {
    containerSubscribe: IContainerSubscribeProps;
    containerUser: IAuthContainerProps;
    subscribe?: ISubscription;
    title: string;
    callbackOnActivated?: () => void;
    button: {
      cancel: IModalButton;
      subscribe: IModalButton;
    };
  }
> = props => {
  const { t } = useTranslation();
  const { subscriptions } = props.containerSubscribe;
  const { msisdn } = props.containerUser;
  const { title, mobile, onClose, button } = props;

  const [sent, setSent] = useState(false);
  const styles = useModalPackStyles({ mobile });

  useEffect(() => {
    setSent(false);
  }, [props?.subscribe?.id]);

  useEffect(() => {
    sent &&
      subscriptions &&
      subscriptions.length > 0 &&
      subscriptions.map(s => {
        if (props.subscribe && s.id === props.subscribe.id && s.status === ESubscriptionStatus.ACTIVE) {
          props.callbackOnActivated && props.callbackOnActivated();
          setSent(false);
        }
      });
  }, [subscriptions, sent]);

  return (
    <Modal
      { ...props }
      title={ title }
      mobile={ mobile }
      withClose
      onClose={ onClose }
    >
      <div className={ styles.text } dangerouslySetInnerHTML={ { __html: props.subscribe?.subscribeCondition } } />
      <p className={ styles.text }>
        { t('subscribe.modal.paymentPhone') }
        <strong>{ phoneMaskDry(msisdn) }</strong>
      </p>
      <div className={ styles.subscribeButtonsWrapper }>
        <Button className={ styles.subscribeButton } onClick={ button?.subscribe?.onClick } mobile={ mobile }>
          { button?.subscribe?.title || t('subscribe.modal.activate') }
        </Button>
        <Button className={ styles.subscribeButton } type="secondary" onClick={ button?.cancel?.onClick } mobile={ mobile }>
          { button?.cancel?.title || t('subscribe.modal.cancel') }
        </Button>
      </div>
      <div className={ styles.bottomTextWrapper }>
        { mobile && props.subscribe?.id === subscriptions[1]?.id && (
          <p>При отмене подписки вы не сможете получить доступ к вашей программе питания и тренировок.</p>
        ) }
        Нажимая кнопку «{ t('subscribe.modal.activate') }»,&nbsp; вы подтверждаете заказ услуги «{ props.subscribe?.name }» на условиях&nbsp;
        <a style={ { color: '#8F9399' } } target={ '_blank' } href={ '/oferta.pdf' }>
          Пользовательского соглашения
        </a>
        . Стоимость { props.subscribe?.price } (с НДС).&nbsp; Оплата с контентного лицевого счета, а при его отсутствии — с основного лицевого
        счета Абонента.
      </div>
    </Modal>
  );
};

export const SubscribeModal = ContainerUser(ContainerSubscribe(SubscribeModalEl));
