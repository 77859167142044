import { createStyles, makeStyles } from '@material-ui/core/styles';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesContentAudio = makeStyles(theme =>
  createStyles({
    wrapItem: {
      border: '0.5px solid #C4C4C4',
      boxSizing: 'border-box',
      boxShadow: '0px 6px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: 5,
      marginTop: 22,
      width: '100%',
      background: '#fff'
    },
    title: {
      fontSize: '18px',
      margin: '15px 0 5px',
      fontWeight: 700,
      color: ui.colors.text.dark.default,
      padding: '0 20px'
    },
    titleDesc: {
      padding: '0 20px',
      margin: '5px 0 10px',
      fontSize: 14
    }
  })
);

export const usePlayerStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      'nav, footer': {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        transform: 'translateX(-9999px) translateY(-9999px)'
      },
      '.layoutMobileRoot': {
        minHeight: 'auto!important'
      }
    },
    root: {
      margin: 0,
      marginBottom: 10,
      background: '#fff',
      boxShadow: 'none',
      padding: '0 10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      '& :nth-child(2)': {
        order: 1
      },
      '& :nth-child(3)': {
        order: 3
      },
      '& :nth-child(4)': {
        order: 2,
        alignItems: 'center',
        '& :nth-child(1)': {
          order: 1
        },
        '& :nth-child(3)': {
          order: 2
        }
      }
    },
    iconClose: {
      'i&': {
        fontFamily: 'icons',
        fontStyle: 'normal',
        fontSize: '38px',
        marginTop: 33,
        marginBottom: 34,
        marginRight: 30,
        color: 'white',
        '&:before': {
          content: `'\\e902'`,
          color: 'currentColor',
          verticalAlign: 'baseline'
        }
      }
    },
    playIcon: {
      color: ui.colors.icon.primary.default,
      '&:hover': {
        color: ui.colors.icon.primary.default
      }
    },
    pauseIcon: {
      color: ui.colors.icon.primary.default,
      '&:hover': {
        color: ui.colors.icon.primary.default
      }
    },
    volumeIcon: {
      color: ui.colors.icon.primary.default,
      fontSize: 20,
      '&:hover': {
        color: ui.colors.icon.primary.default
      }
    },
    progressTime: {
      color: '#828282',
      marginBottom: 4,
      fontSize: 14
    },
    mainSlider: {
      padding: '6px 0',
      color: '#3f51b5',
      '& .MuiSlider-rail': {
        color: ui.colors.icon.secondary
      },
      '& .MuiSlider-track': {
        color: ui.colors.icon.primary.default
      },
      '& .MuiSlider-thumb': {
        color: ui.colors.icon.primary.default,
        width: 6,
        height: 6,
        marginTop: '-2px',
        '&:hover': {
          color: ui.colors.icon.primary.default
        }
      }
    },
    wrapperPlayerAudioBook: {
      zIndex: 1000,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      backgroundColor: ui.colors.background.dark.default
    },
    player: {
      width: '100%',
      padding: '18px 18px 36px',
      boxSizing: 'border-box'
    }
  })
);
