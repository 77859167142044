import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';

import { LIKE_ACTION } from 'app/entities/like/like.actions';
import { ELoadStatus } from 'app/models/shared.model';
import { ILikeItem } from 'app/entities/like/like.model';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  item: {} as ILikeItem,
  list: [] as ILikeItem[],
  status: {
    list: ELoadStatus.not_ready,
    item: ELoadStatus.not_ready
  },
  error: {
    list: null as Error,
    item: null as Error
  }
};

export type ILikeState = Readonly<typeof initialState>;

export default (state = initialState, action): ILikeState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(LIKE_ACTION.FETCH_ITEMS):
      return flow([
        set('status.list', ELoadStatus.loading),
        set('error.list', initialState.error.list)
      ])(state);
    case SUCCESS(LIKE_ACTION.FETCH_ITEMS):
      return flow([
        set('list', payload.data.hearts),
        set('status.list', ELoadStatus.ready)
      ])(state);
    case FAILURE(LIKE_ACTION.FETCH_ITEMS):
      return flow([
        set('status.list', ELoadStatus.error),
        set('error.list', new Error('list of like fetch failed'))
      ])(state);

    case REQUEST(LIKE_ACTION.CREATE_ITEM):
      return flow([
        set('status.item', ELoadStatus.loading),
        set('error.item', initialState.error.item)
      ])(state);
    case SUCCESS(LIKE_ACTION.CREATE_ITEM):
      return flow([
        set('status.item', ELoadStatus.ready)
      ])(state);
    case FAILURE(LIKE_ACTION.CREATE_ITEM):
      return flow([
        set('status.item', ELoadStatus.error),
        set('error.item', new Error('item of like not created'))
      ])(state);

    case REQUEST(LIKE_ACTION.REMOVE_ITEM):
      return flow([
        set('status.item', ELoadStatus.loading),
        set('error.item', initialState.error.item)
      ])(state);
    case SUCCESS(LIKE_ACTION.REMOVE_ITEM):
      return flow([
        set('status.item', ELoadStatus.ready)
      ])(state);
    case FAILURE(LIKE_ACTION.REMOVE_ITEM):
      return flow([
        set('status.item', ELoadStatus.error),
        set('error.item', new Error('item of like not removed'))
      ])(state);

    case LIKE_ACTION.RESET_ITEMS:
      return flow([
        set('list', initialState.list),
        set('error.list', initialState.error.list),
        set('status.list', initialState.status.list)
      ])(state);
    default:
      return state;
  }
};
