//#region imports
import React, { FC, memo, forwardRef, useRef, useEffect, useState, useMemo } from 'react';
import { useGetSet, useGetSetState } from 'react-use';
import { useForkRef } from '@material-ui/core';
import { NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import isElement from 'lodash/isElement';
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { CategoryMenuProps, ECategoryMenuClassKey } from './category.models';
import { useCategoryMenuStyles } from './category.styles';
//#endregion

const MenuItem = memo<any>(({ classes, name, selected }) => (
  <div className={ cx(classes[ECategoryMenuClassKey.item], { [classes[ECategoryMenuClassKey.selected]]: selected }) }>{ name }</div>
));

const buildMenu = ({ classes, list, selected }) =>
  map(list, item => {
    const { id, name } = item;
    return <MenuItem key={ id } classes={ classes } name={ name } selected={ selected } />;
  });

//#region CategoryMenu
export const CategoryMenu: FC<CategoryMenuProps> = memo(
  forwardRef<ScrollMenu, CategoryMenuProps>((props, ref) => {
    const { className, style, classes, data, selectedKey, loading, onSelect } = props;
    const styles = useCategoryMenuStyles({ classes });

    const menuRef = useRef(null);
    const handleRef = useForkRef(menuRef, ref);

    const [selected, setSelected] = useState<string>(selectedKey);
    const handleSelect = key => {
      setSelected(key);
      if (isFunction(onSelect)) onSelect(key);

      // const instance = menuRef.current;
      // if (isEmpty(instance)) return;
      // instance.scrollTo(key);
    };

    const menu = useMemo(() => buildMenu({ list: data, selected, classes: styles }), [data, selected]);
    const [menuState, setMenuState] = useGetSetState({ isPrevActive: false, isNextActive: false });

    useEffect(() => {
      if (selectedKey !== selected) setSelected(selectedKey);
    }, [selectedKey]);

    useEffect(() => {
      const instance = menuRef.current;
      if (isEmpty(instance)) return;

      // tslint:disable-next-line: no-shadowed-variable
      const handleDrag = function handleDrag(e) {
        // tslint:disable-next-line: one-variable-per-declaration
        const t = instance.props,
          r = t.dragging,
          i = t.rtl,
          a = instance.state,
          s = a.translate,
          l = a.dragging,
          o = a.xPoint,
          d = a.xDraggedDistance;
        if (!r || !l) return !1;
        // tslint:disable-next-line: one-variable-per-declaration
        const f = instance.getPoint(e),
          c = o === t.xPoint ? t.xPoint : o - f;
        let m = s - (i ? -c : c);

        if (instance.itBeforeStart(m)) m -= Math.abs(c) / 2;
        else if (instance.itAfterEnd(m)) m += Math.abs(c) / 2;

        const diffWidth = instance.menuWidth - instance.allItemsWidth;
        if (diffWidth <= 0) {
          if (m > 0) m = 0;
          else if (m < diffWidth) m = diffWidth;
        } else {
          m = 0;
        }

        if (0 !== c) instance.dragHistory.push({ time: Date.now(), position: m });
        const p = m;
        instance.setState({ translate: p, xDraggedDistance: d + Math.abs(c), xPoint: f });
      };
      instance.handleDrag = handleDrag.bind(instance);

      const callback = target => {
        let isPrevActive = false;
        let isNextActive = false;
        if (isElement(target)) {
          const firstChild = target?.firstElementChild;
          const lastChild = target?.lastElementChild;
          const translatableWidth = target.scrollWidth - target.clientWidth;
          if (translatableWidth > 0) {
            const transform = target.style.transform;
            const re = /translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/;
            const results = re.exec(transform);
            const { x, y, z } = (results as any)?.groups ?? {};

            isPrevActive = Math.abs(x) > 0;
            isNextActive = Math.abs(x) < translatableWidth;

            setMenuState({ isPrevActive, isNextActive });
            return;
          }
        }
        setMenuState({ isPrevActive, isNextActive });
      };
      const mutationCallback = debounce(mutationList => {
        const record = mutationList?.[0];
        if (record?.type === 'attributes' && record?.attributeName === 'style') {
          const target = record?.target as HTMLElement;
          callback(target);
        }
      }, 100);

      const observer = new MutationObserver(mutationCallback);
      const tid = setInterval(() => {
        const node = (instance.menuWrapper as HTMLElement)?.firstElementChild;
        if (isElement(node)) {
          clearInterval(tid);
          observer.observe(node, { attributes: true, attributeFilter: ['style'] });
          callback(node);
        }
      }, 300);
      return () => {
        clearInterval(tid);
        observer.disconnect();
        mutationCallback?.cancel();
      };
    }, [menuRef.current]);

    return (
      <div
        className={ cx(className, styles[ECategoryMenuClassKey.root], {
          [styles[ECategoryMenuClassKey.loading]]: loading,
          [styles[ECategoryMenuClassKey.prevActive]]: menuState().isPrevActive,
          [styles[ECategoryMenuClassKey.nextActive]]: menuState().isNextActive
        }) }
      >
        <ScrollMenu
          ref={ handleRef }
          menuClass={ styles[ECategoryMenuClassKey.menu] }
          arrowClass={ styles[ECategoryMenuClassKey.arrow] }
          arrowDisabledClass={ styles[ECategoryMenuClassKey.arrowDisabled] }
          wrapperClass={ styles[ECategoryMenuClassKey.container] }
          innerWrapperClass={ styles[ECategoryMenuClassKey.list] }
          itemClass={ styles[ECategoryMenuClassKey.listItem] }
          itemClassActive={ styles[ECategoryMenuClassKey.selected] }
          data={ menu }
          arrowLeft={ <NavigateBeforeIcon /> }
          arrowRight={ <NavigateNextIcon /> }
          selected={ selected }
          alignCenter={ false }
          wheel={ false }
          scrollBy={ 2 }
          transition={ 0.3 }
          useButtonRole
          alignOnResize
          scrollToSelected
          hideArrows
          dragging
          onSelect={ handleSelect }
        />
      </div>
    );
  })
);
CategoryMenu.displayName = 'CategoryMenuMain';

CategoryMenu.defaultProps = { data: [], selectedKey: null };
//#endregion

//#region useCategoryMenuAgent
export function useCategoryMenuAgent(props): [() => [number, number], (id: string) => void] {
  const { data, defaultValue, onSelect } = props;
  const [selected, setSelected] = useGetSet<[number, number]>(defaultValue ?? [0, data?.[0]?.id]);
  const [selectedIndex, selectedId] = selected();

  useEffect(() => {
    if (selectedId !== data?.[selectedIndex]?.id) setSelected(defaultValue ?? [0, data?.[0]?.id]);
  }, [data, selectedId]);

  const handleSelect = (id: string) => {
    const index = findIndex(data, ['id', +id]);
    if (index !== -1) {
      setSelected([index, +id]);
      if (isFunction(onSelect)) onSelect(index, +id);
    }
  };

  return [selected, handleSelect];
}
//#endregion
