import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useGetSet } from 'react-use';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import Cookies from 'universal-cookie';

import { MobileNavbarMenu } from 'a1s-tele2-react-ui/src/components/mobile/menu/navbar/menu.navbar.index';

import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';
import { ISubcategoryContainerProps, SubcategoryContainer } from 'app/containers/subcategory.container';
import { IContainerPagerCallbackProps } from 'app/containers/container.pager';
import { ContainerContentListMain, IContainerContentListMainProps } from 'app/containers/container.content.list.main';
import { ISectionListContainerProps, SectionListContainer } from 'app/containers/section.list.container';

import { ISectionListInitialState } from 'app/entities/section/entity.section.list.reducer';

import { ISectionItem } from 'app/models/model.section';
import { EAgeType, EDialogConfirmType, ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { Header } from 'app/components/header/header.ui';
import { ImageCarousel } from 'app/components/carousel/carousel.image';
import { stylesMainPage } from 'app/pages/mobile/main/main.styles';
import { deepMapTarget } from 'app/utils/util.deep.map.target';
import { ModalConfirm } from 'app/components/modal/modal.confirm';
import { stylesApp } from 'app/app';
import { stylesNavbar } from 'app/components/navbar/styles.navbar';
import DietBanner from 'app/pages/shared/diet/banner/diet.banner.index';
import Banner from 'app/components/banner/banner.index';
import ContentList from './content/content.index';

const cookies = new Cookies();

const MainPageIndex: FC<{
  containerUser: IAuthContainerProps;
  containerCategory: ICategoryContainerProps;
  containerSubcategory: ISubcategoryContainerProps;
  sectionListContainer: ISectionListContainerProps;
  containerContentListMain: IContainerContentListMainProps;
}> = props => {
  const isAgeConfirm = cookies.get('EAgeType') === EAgeType.ADULT;
  const [confirmDialog, setConfirmDialog] = useState<{ type?: EDialogConfirmType | EAgeType; url?: string }>({
    type: null,
    url: ''
  });

  const [currentCategoryId, setCurrentCategoryId] = useGetSet<{ [key: string]: string }>({});
  const [sectionItems, setSectionItems] = useState<ISectionListInitialState[]>([]);

  const classes = stylesMainPage();
  const classesApp = stylesApp();
  const classesNavbar = stylesNavbar();
  const history = useHistory();

  const setCurrentCategory = useCallback((catId, subcatId) => {
    setCurrentCategoryId({ ...currentCategoryId(), [catId]: subcatId });
    fetchSections({ id: subcatId, pager: { limit: 10, offset: 0 } });
  }, []);

  const fetchSections = useCallback((params: IContainerPagerCallbackProps) => {
    props.sectionListContainer.actions.fetchSectionItems(params);
  }, []);

  const categoryItems = useMemo(() => {
    return props.containerCategory.items && props.containerCategory.items?.length > 0 && props.containerCategory.items;
  }, [props.containerCategory.items]);

  const onConfirmAdult = () => {
    history.push(confirmDialog.url);
    cookies.set('EAgeType', EAgeType.ADULT, { path: '/' });
    setConfirmDialog({});
  };

  useEffect(() => {
    props.containerCategory.actions.fetchCategoryItems();
    return () => {
      props.sectionListContainer.actions.resetSectionItems();
    };
  }, []);

  useEffect(() => {
    if (props.sectionListContainer.list && props.sectionListContainer.list?.length > 0) {
      deepMapTarget<ISectionItem, ELoadStatus, Error>({
        list: props.sectionListContainer.list,
        state: sectionItems,
        setState: map => setSectionItems(map as ISectionListInitialState[])
      });
    }
  }, [props.sectionListContainer.list]);

  return (
    <>
      <Banner shuffled masked/>
      <DietBanner />
      { categoryItems && categoryItems.length > 0
        ?
          categoryItems.map((category, index) => {
            const isSubcategoriesExist = category.subcategories && category.subcategories.length > 0;
            const subcategory = isSubcategoriesExist ? category.subcategories[0] : undefined;

            const currentSections =
              isSubcategoriesExist &&
              (sectionItems.find(section => +section.targetId === +currentCategoryId()?.[category.id]) ||
                sectionItems.find(section => +section.targetId === +category.subcategories[0].id));

            return isSubcategoriesExist ? (
              <section
                id={ category.id && category.id.toString() }
                className={ `${classesApp.section} ${index % 2 === 0 ? classesApp.even : ''}` }
                key={ category.id }
              >
                <div className={ classes.titleWrap }>
                  <Header
                    component="h3"
                    className={ classes.header }
                  >
                    { category.name }
                  </Header>
                </div>

                <div className={ classnames(classesNavbar.wrap, category.subcategories?.length > 1 && classesNavbar.headUnderLine) }>
                  { category.subcategories?.length > 1 && (
                    <MobileNavbarMenu
                      classesNavbar={ classnames(classesNavbar.navbar, classes.wrapNavbar) }
                      onClickItem={ id => setCurrentCategory(category.id, Number(id)) }
                      navMenuItems={ category.subcategories as any }
                      activeItemId={ currentCategoryId()?.[category.id] || category.subcategories[0].id }
                    />
                  ) }
                  <div>
                    { currentSections?.items[0]?.contentType === EContentType.BOOK || currentSections?.items[0]?.contentType === EContentType.STUDY
                      ? currentSections.items && <ContentList category={ category } subcategory={ subcategory } sectionItem={ currentSections.items[0] }/>
                      :
                        <ImageCarousel
                          onPassItemProps={
                            !isAgeConfirm && category.ageType === EAgeType.ADULT && (url => setConfirmDialog({ type: EAgeType.ADULT, url }))
                          }
                          callback={ fetchSections }
                          id={ currentSections ? currentSections.targetId : category.subcategories[0].id }
                          srcKey={ 'preview' }
                          titleKey={ 'name' }
                          route={ `/${category.id}/content/list/` }
                          list={ currentSections && currentSections.items }
                          isLoading={ currentSections && currentSections.status !== ELoadStatus.ready }
                          isMore={ currentSections && currentSections.more }
                          // inNotSet
                        />
                    }
                  </div>
                </div>
              </section>
            ) : null;
          })
        : null }
      { !isAgeConfirm ? (
        <ModalConfirm
          confirmType={ EDialogConfirmType.ADULT }
          onConfirm={ onConfirmAdult }
          onClose={ () => setConfirmDialog({}) }
          open={ confirmDialog.type === EDialogConfirmType.ADULT }
          isReverseButtons
          mobile
        />
      ) : null }
    </>
  );
};

export default ContainerUser(
  CategoryContainer(SubcategoryContainer(SectionListContainer(ContainerContentListMain(MainPageIndex))))
);
