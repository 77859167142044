import { combineReducers } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';

import user, { IUserState } from 'app/entities/user/user.reducer';
import banner, { IBannerState } from 'app/entities/banner/banner.reducer';
import contentItem, { IContentItemState } from 'app/entities/content/content.item.reducer';
import contentItemOlympiad, { IContentOlympiadState } from 'app/entities/content/content.item.olympiad.reducer';
import contentItemHtml5, { IContentHTML5State } from 'app/entities/content/html5/html5.reducer';
import contentList, { IContentListState } from 'app/entities/content/content.list.reducer';
import contentListMain, { IContentListMainState } from 'app/entities/content/content.list.main.reducer';
import menu, { IMenuState } from 'app/entities/menu/menu.reducer';
import game, { IGameState } from 'app/entities/game/game.reducer';
import prize, { IPrizeState } from 'app/entities/prize/prize.reducer';
import subscribe, { ISubscribeState } from 'app/entities/subscribe/subscribe.reducer';
import modals, { IModalsState } from 'app/entities/modals/modals.reducer';
import logging, { ILoggingState } from 'app/entities/logging/logging.reducer';
import category, { ICategoryState } from 'app/entities/categories/category/entity.category.reducer';
import subcategory, { ISubcategoryState } from 'app/entities/categories/subcategory/entity.subcategory.reducer';
import sectionMenu, { ISectionMenuState } from 'app/entities/section/entity.section.menu.reducer';
import sectionList, { ISectionListState } from 'app/entities/section/entity.section.list.reducer';
import sectionItem, { ISectionItemState } from 'app/entities/section/entity.section.item.reducer';
import videoItem, { IVideoState } from 'app/entities/video/video.reducer';
import serverError, { IErrorState } from 'app/shared/reducers/error.reducer';
import announce, { IAnnounceState } from 'app/entities/announce/announce.reducer';
import like, { ILikeState } from 'app/entities/like/like.reducer';
import quiz, { IQuizState } from 'app/entities/quiz/quiz.reducer';
import dietIndividualProgram, { IDietIndividualProgramState } from 'app/entities/diet/program/diet.program.reducer';
import notification, { INotificationState } from 'app/entities/notification/notification.reducer';

export interface IRootState {
  readonly user: IUserState;
  readonly banner: IBannerState;
  readonly category: ICategoryState;
  readonly contentItem: IContentItemState;
  readonly contentItemOlympiad: IContentOlympiadState;
  readonly contentItemHtml5: IContentHTML5State;
  readonly contentList: IContentListState;
  readonly contentListMain: IContentListMainState;
  readonly sectionItem: ISectionItemState;
  readonly sectionList: ISectionListState;
  readonly sectionMenu: ISectionMenuState;
  readonly subcategory: ISubcategoryState;
  readonly router: RouterState;
  readonly menu: IMenuState;
  readonly game: IGameState;
  readonly prize: IPrizeState;
  readonly subscribe: ISubscribeState;
  readonly modals: IModalsState;
  readonly logging: ILoggingState;
  readonly videoItem: IVideoState;
  readonly serverError: IErrorState;
  readonly announce: IAnnounceState;
  readonly like: ILikeState;
  readonly quiz: IQuizState;
  readonly dietIndividualProgram: IDietIndividualProgramState;
  readonly notification: INotificationState;
}

const rootReducer = (history: History) => (state, action) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    user,
    banner,
    contentItem,
    contentItemOlympiad,
    contentItemHtml5,
    contentList,
    contentListMain,
    category,
    sectionItem,
    sectionList,
    sectionMenu,
    subcategory,
    menu,
    game,
    prize,
    subscribe,
    modals,
    logging,
    videoItem,
    serverError,
    announce,
    like,
    quiz,
    dietIndividualProgram,
    notification
  })(action.type === 'ROOT:RESET' ? undefined : state, action);

export default rootReducer;
