import React, { FC, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';

import closeIcon from 'app/assets/img/close.svg';

import { useStylesModalIndividualProgram } from './modal.individual.program.styles';
import { CustomTextField } from 'app/components/custom.input/custom.input';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { ETarget, IUserAttributes, EResult, INextWeek } from 'app/entities/diet/program/diet.program.reducer';
import { CustomAutocomplete } from 'app/components/autocomplete/autocomplete';

interface IModalWeekendProgram {
  openModalWeekend: boolean;
  setOpenModalWeekend: (boolean) => void;
  propState: IUserAttributes;
  onNewWeekClick: (data: INextWeek) => void;
  onCompleteClick: (data: INextWeek) => void;
}

const ModalWeekendProgram: FC<IModalWeekendProgram> = ({ openModalWeekend, setOpenModalWeekend, propState, onNewWeekClick, onCompleteClick }) => {
  const styles = useStylesModalIndividualProgram();
  const { t } = useTranslation();
  const history = useHistory();

  const options = useMemo(() => {
    switch (propState?.target) {
      case ETarget.WEIGHT_DOWN:
        return [
          { id: EResult[ETarget.WEIGHT_DOWN + '_SUCCESS'], title: 'Да, удалось снизить вес' },
          { id: EResult[ETarget.WEIGHT_DOWN + '_BETTER'], title: 'Да, я чувствую себя лучше' },
          { id: EResult[ETarget.WEIGHT_DOWN + '_FAIL'], title: 'Нет, у меня не получилось' },
          { id: EResult[ETarget.WEIGHT_DOWN + '_WRONG'], title: 'Нет, мне не подошла диета' }
        ];
      case ETarget.WEIGHT_UP:
        return [
          { id: EResult[ETarget.WEIGHT_UP + '_SUCCESS'], title: 'Да, удалось набрать вес' },
          { id: EResult[ETarget.WEIGHT_UP + '_BETTER'], title: 'Да, я чувствую себя лучше' },
          { id: EResult[ETarget.WEIGHT_UP + '_FAIL'], title: 'Нет, у меня не получилось' },
          { id: EResult[ETarget.WEIGHT_UP + '_WRONG'], title: 'Нет, мне не подошла диета' }
        ];
      case ETarget.WEIGHT_KEEP:
        return [
          { id: EResult[ETarget.WEIGHT_KEEP + '_SUCCESS'], title: 'Да, удалось удерживать вес' },
          { id: EResult[ETarget.WEIGHT_KEEP + '_BETTER'], title: 'Да, я чувствую себя лучше' },
          { id: EResult[ETarget.WEIGHT_KEEP + '_FAIL'], title: 'Нет, у меня не получилось' },
          { id: EResult[ETarget.WEIGHT_KEEP + '_WRONG'], title: 'Нет, мне не подошла диета' }
        ];
      default :
        return [
          { id: EResult.WEIGHT_UNKNOWN_DOWN, title: 'Да, удалось снизить вес' },
          { id: EResult.WEIGHT_UNKNOWN_UP, title: 'Да, удалось набрать вес' },
          { id: EResult.WEIGHT_UNKNOWN_KEEP, title: 'Да, удалось удерживать вес' },
          { id: EResult.WEIGHT_UNKNOWN_WRONG, title: 'Нет, мне не подошла диета' }
        ];
    }
  }, [propState?.target]);

  const [errorWeight, setErrorWeight] = useState(false);
  const [valueAutocomplete, setValueAutocomplete] = useState(options[0]);
  const [inputValueTextField, setInputValueTextField] = useState({
    weight: '',
    result: null
  });

  useEffect(() => {
    if (!openModalWeekend) {
      setInputValueTextField({
        weight: '',
        result: null
      });
      setValueAutocomplete(options[0]);
    }
  }, [openModalWeekend]);

  useEffect(() => {
    valueAutocomplete && setInputValueTextField(prevState => ({
      ...prevState,
      result: valueAutocomplete?.id
    }));
  }, [valueAutocomplete]);

  const handleClose = () => {
    setOpenModalWeekend(false);
  };

  const handleComplete = async () => {
    if (errorWeight) return;
    const { result, weight } = inputValueTextField;
    await onCompleteClick({
      result,
      weight: weight === '' ? null : Number(weight)
    });
    setOpenModalWeekend(false);
    history.push('/');
  };

  const validateForm = (inputValidateItem, stateValidate, min, max) => {
    if (inputValidateItem < min || inputValidateItem > max) {
      stateValidate(true);
    } else {
      stateValidate(false);
    }

    if (inputValidateItem === '' || inputValidateItem === null) {
      stateValidate(false);
    }
  };

  useEffect(() => {
    validateForm(inputValueTextField.weight, setErrorWeight, 20, 300);
  }, [inputValueTextField.weight]);

  const handleNewWeek = () => {
    if (errorWeight) return;
    const { result, weight } = inputValueTextField;
    onNewWeekClick({
      result,
      weight: weight === '' ? null : Number(weight)
    });
  };

  return (
    <div className={ styles.root }>
      <Modal open={ openModalWeekend } className={ styles.modal } aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div className={ styles.paper }>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" style={ { marginBottom: '27px' } }>
            <Typography type="heading" level={ 2 }>{ t('diet.weekendProgram.header') }</Typography>
            <img src={ closeIcon } alt="close" onClick={ handleClose } className={ styles.imgClose } />
          </Grid>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <div>{ t('diet.weekendProgram.desc.0') }</div>
            <p>{ t('diet.weekendProgram.desc.1') }</p>
            <form noValidate>
              <CustomAutocomplete
                value={ valueAutocomplete }
                options={ options }
                id="controllable-states"
                onChange={ setValueAutocomplete }
                style={ { width: '339px', marginBottom: 27 } }
              />

              { !!propState?.weight &&
              <>
                <div
                  style={ { marginBottom: 27 } }
                  dangerouslySetInnerHTML={ { __html: t('diet.weekendProgram.yourStartingWeight', { weight: propState?.weight }) } }
                />
                <CustomTextField
                  type="number"
                  style={ { marginBottom: '18px', width: '339px', borderBottom: 0 } }
                  label={ t('diet.weekendProgram.weightAfter') }
                  value={ inputValueTextField?.weight }
                  onChange={ e => setInputValueTextField({ ...inputValueTextField, weight: e.target.value }) }
                  variant="filled"
                  id="weight"
                  error={ errorWeight }
                  helperText={ errorWeight && t('modalCalcProgram.checkValidData') }
                />
              </>
            }

              <Grid container direction="row" style={ { marginTop: '27px' } }>
                <Button disabled={ errorWeight } style={ { marginRight: '40px' } } onClick={ handleNewWeek }>
                  { t('diet.weekendProgram.startNewProgram') }
                </Button>
                <Button disabled={ errorWeight } type="secondary" onClick={ handleComplete }>
                  { t('diet.weekendProgram.goToKnowledge') }
                </Button>
              </Grid>
            </form>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWeekendProgram;
