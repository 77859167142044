import { IAnswer, IQuizInitialState } from 'app/entities/quiz/quiz.reducer';
import axios from 'axios';

export const QUIZ_ACTIONS = {
  START_QUIZ: 'QUIZ::START',
  FETCH_QUIZ: 'QUIZ::FETCH',
  POST_ANSWER: 'QUIZ:ANSWER::POST',
  END_QUIZ: 'QUIZ::END',
  RESET_QUIZ: 'QUIZ::RESET'
};
export const startQuiz = (id: IQuizInitialState['quizId']) => ({
  type: QUIZ_ACTIONS.START_QUIZ,
  payload: axios.get(`/api/game/new/${id}`)
});
export const fetchQuiz = (id: IQuizInitialState['quizId']) => ({
  type: QUIZ_ACTIONS.FETCH_QUIZ,
  payload: axios.get(`/api/game/question/${id}`)
});
export const endQuiz = (id: IQuizInitialState['quizId']) => ({
  type: QUIZ_ACTIONS.END_QUIZ,
  payload: axios.get(`/api/game/over/${id}`)
});
export type IAnswerIndexes = Array<IAnswer['index']>;
export const postQuizAnswer = (answerIndexes: IAnswerIndexes, gameId: IQuizInitialState['quizId']) => ({
  type: QUIZ_ACTIONS.POST_ANSWER,
  payload: axios.post(`/api/game/answer`, {
    answerIndexes,
    gameId
  })
});
export const resetQuiz = () => ({
  type: QUIZ_ACTIONS.RESET_QUIZ
});
