export const ERROR_ACTIONS = {
  UPDATE_ERROR: 'ERROR::UPDATE',
  RESET: 'ERROR::RESET'
};

const initialState = {
  errorCode: null
};

export type IErrorState = Readonly<typeof initialState>;

export default (state = initialState, action): IErrorState => {
  const { type, payload, meta } = action;
  switch (type) {
    case ERROR_ACTIONS.UPDATE_ERROR:
      return {
        errorCode: payload
      };
    case ERROR_ACTIONS.RESET:
      return initialState;
    default:
      return state;
  }
};

export const updateError = errorNumber => ({
  type: ERROR_ACTIONS.UPDATE_ERROR,
  payload: errorNumber
});

export const resetError = () => ({
  type: ERROR_ACTIONS.RESET
});
