import { IRootState } from 'app/shared/reducers/root.reducer';
import { IContentListState } from 'app/entities/content/content.list.reducer';

import {
  fetchContentList,
  resetContentList,
  updateRoteList,
  IFetchContentListProps,
  getItemLikes
} from 'app/entities/content/content.actions';
import { logView, logDownload } from 'app/entities/logging/logging.actions';

import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';

export type IContainerContentListProps = IContentListState & {
  actions: {
    fetchContentList: (props: IFetchContentListProps) => void;
    resetContentList: typeof resetContentList;
    updateRoteList: typeof updateRoteList;
    logView: () => void;
    logDownload: (url: string, id: number) => void;
    getItemLikes: (arr) => void;
  };
};

const mapStateToProps = (state: IRootState) => state.contentList;
const mapDispatchToProps = (dispatch: Dispatch): IContainerContentListProps['actions'] => ({
  fetchContentList: props => dispatch((fetchContentList(props) as unknown) as Action),
  resetContentList: () => dispatch(resetContentList()),
  updateRoteList: route => dispatch(updateRoteList(route)),
  logView: () => dispatch((logView() as unknown) as Action),
  logDownload: (url, id) => dispatch((logDownload(url, id) as unknown) as Action),
  getItemLikes: arr => dispatch((getItemLikes(arr) as unknown) as Action)
});

export const ContainerContentList = connect<IContainerContentListProps>('containerContentList', mapStateToProps, mapDispatchToProps);
