//#region imports
import React, { Context, FC, createContext, memo, useContext, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import each from 'lodash/each';
//#endregion

export const UserInteractionContext: Context<boolean> = createContext<boolean>(false);

export function useUserInteraction() {
  const state = useContext(UserInteractionContext);
  return state;
}

const UserInteractionProvider: FC = memo(props => {
  const { children } = props;
  const [interacted, setInteracted] = useState(false);

  useEffect(() => {
    const callback = throttle(() => {
      if (!interacted) setInteracted(true);
    }, 300);
    const events = ['mousedown', 'keydown', 'touchstart', 'focus'];
    each(events, eventName => document.addEventListener(eventName, callback, true));
    return () => {
      each(events, eventName => document.removeEventListener(eventName, callback, true));
    };
  }, []);

  return <UserInteractionContext.Provider value={ interacted }>{ children }</UserInteractionContext.Provider>;
});
UserInteractionProvider.displayName = 'UserInteractionProvider';

export default UserInteractionProvider;
