import React, { FC } from 'react';

import RadioOnBtn from 'app/assets/img/radio.on.svg';
import RadioOffBtn from 'app/assets/img/hide.radio.svg';
import { ButtonWrapper, ImgHolder, ImgOnWrapper, ImgOffWrapper } from './radioCheckBox.styles';

interface IRadioCheckbox {
  value: string;
  checked: boolean;
  onClick: () => void;
  style: any;
}

export const RadioCheckbox: FC<IRadioCheckbox> = ({ value, checked, onClick, style }) => (
  <div>
    <ButtonWrapper
      style={ style }
      variant="outlined"
      endIcon={
        checked ? (
          <ImgHolder>
            <ImgOnWrapper src={ RadioOnBtn } alt={ value } />
          </ImgHolder>
        ) : (
          <ImgHolder>
            <ImgOffWrapper src={ RadioOffBtn } alt={ value } />
          </ImgHolder>
        )
      }
      onClick={ onClick }
    >
      { value }
    </ButtonWrapper>
  </div>
);
