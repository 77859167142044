import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { CONTENT_ACTIONS } from 'app/entities/content/content.actions';
import { IOver } from 'app/entities/game/game.reducer';

import { ELoadStatus } from 'app/models/shared.model';
import { IContent } from 'app/models/model.content';
import { ISection } from 'app/models/model.section';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  contentId: null,
  currentStep: 0,
  lastResult: {
    percent: 0,
    correctCount: 0,
    totalCount: 0,
    prizes: []
  } as Pick<IOver, 'percent' | 'correctCount' | 'totalCount' | 'prizes'>,
  lesson: { item: {} as IContent, section: {} as ISection },
  request: {
    status: {
      lesson: { item: ELoadStatus.not_ready, section: ELoadStatus.not_ready }
    },
    error: {
      lesson: { item: null as Error, section: null as Error }
    }
  }
};

export type IContentOlympiadState = Readonly<typeof initialState>;

export default (state = initialState, action): IContentOlympiadState => {
  const { type, payload, meta } = action;
  switch (type) {
    case REQUEST(CONTENT_ACTIONS.FETCH_OLYMPIAD_LESSON):
      return flow([
        set('request.status.lesson.item', ELoadStatus.loading),
        set('request.error.lesson.item', initialState.request.error.lesson.item),
        set('request.status.lesson.section', ELoadStatus.loading),
        set('request.error.lesson.section', initialState.request.error.lesson.item)
      ])(state);
    case SUCCESS(CONTENT_ACTIONS.FETCH_OLYMPIAD_LESSON):
      return flow([
        set('lesson.item', payload.data.content),
        set('lesson.section', payload.data.sectionDetails),
        set('request.status.lesson.item', ELoadStatus.ready),
        set('request.status.lesson.section', ELoadStatus.ready)
      ])(state);
    case FAILURE(CONTENT_ACTIONS.FETCH_OLYMPIAD_LESSON):
      return flow([
        set('request.status.lesson.item', ELoadStatus.error),
        set('request.error.lesson.item', new Error('item fetch failed')),
        set('request.status.lesson.section', ELoadStatus.error),
        set('request.error.lesson.section', new Error('section details fetch failed'))
      ])(state);

    case CONTENT_ACTIONS.RESET_OLYMPIAD_LESSON:
      return flow([
        set('lesson.item', initialState.lesson.item),
        set('lesson.section', initialState.lesson.section),
        set('request.error.lesson.item', initialState.request.error.lesson.item),
        set('request.status.lesson.item', initialState.request.status.lesson.item),
        set('request.error.lesson.section', initialState.request.error.lesson.item),
        set('request.status.lesson.section', initialState.request.status.lesson.item)
      ])(state);

    case CONTENT_ACTIONS.SET_OLYMPIAD_RESULT:
      return flow([set('lastResult', payload)])(state);
    case CONTENT_ACTIONS.RESET_OLYMPIAD_RESULT:
      return flow([set('lastResult', initialState.lastResult)])(state);

    case CONTENT_ACTIONS.SET_OLYMPIAD_STEP:
      return flow([set('currentStep', payload)])(state);
    case CONTENT_ACTIONS.RESET_OLYMPIAD_STEP:
      return flow([set('currentStep', initialState.currentStep)])(state);

    case CONTENT_ACTIONS.SET_OLYMPIAD_ID:
      return flow([set('contentId', payload)])(state);
    case CONTENT_ACTIONS.RESET_OLYMPIAD_ID:
      return flow([set('contentId', initialState.contentId)])(state);

    default:
      return state;
  }
};
