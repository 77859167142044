import React, { FC, useEffect } from 'react';

import { uiSetup } from 'app/configs/theme';

import { useLocation } from 'react-router';
import { useReactPath } from 'app/hooks/useReactPath';

import { LoggingContainer, ILoggingContainer } from 'app/containers/logging.container';
import { ILoggingState } from 'app/entities/logging/logging.reducer';

interface IProps {
  categoryId?: number;
  sectionId?: number;
  contentId?: string;
  canSend: boolean;
  loggingContainer: ILoggingContainer;
}

const LoggerEl = (props: IProps) => {
  const { canSend, loggingContainer, ...newState } = props;
  const { authenticated, actions: { setState, logPosition, logLogin } } = loggingContainer;
  const { pathname, search } = useLocation();

  const path = useReactPath();

  const state: ILoggingState = {
    position: pathname,
    contentId: undefined,
    contentViewId: undefined,
    categoryId: undefined,
    sectionId: undefined,
    subcategoryId: undefined,
    subsectionId: undefined,
    ...newState
  };

  useEffect(() => {
    if (canSend && authenticated) {
      logPosition(state);
      setState(state);
    }
  }, [canSend && authenticated]);

  useEffect(() => {
    if (path.includes('login=success')) {
      logLogin(state);
    }
  }, [path]);

  return null;
};

export const Logger = LoggingContainer(LoggerEl) as FC<Omit<IProps, 'loggingContainer'>>;
