//#region imports
import React, { FC, memo, useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import flow from 'lodash/fp/flow';
import filter from 'lodash/filter';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { ELoadStatus } from 'app/models/shared.model';
import { IRecommendsProps } from '../item.models';
import { EContentType } from 'app/models/model.content';

import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerContentList } from 'app/containers/container.content.list';
import { ContainerUser } from 'app/containers/container.user';
import { ModalsContainer } from 'app/containers/modals.container';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { ImageFallback } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';

import useLimiter from 'app/hooks/useLimiter';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import { useContentItemStyles } from '../page.content.item.styles';
import subscribeCheck from 'app/utils/util.subscribe.check';
//#endregion

const Recommends: FC<IRecommendsProps> = memo(props => {
  const { containerContentItem, containerContentList, contentId, containerUser, modalsContainer } = props;
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
  const styles = useContentItemStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const sectionId = containerContentItem.section?.section?.id;
  const contentType = containerContentItem.item?.type;

  const contents = containerContentList.items;
  const loading = containerContentList.status.items === ELoadStatus.loading;
  const more = containerContentList.more;

  const [paging, pagingEmiter] = useLimiter();
  useEffect(() => {
    if (sectionId) {
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        cancellable: true
      });
      containerContentList.actions.logView();
    }
    return () => {
      pagingEmiter.invokeReset();
      containerContentList.actions.resetContentList();
    };
  }, [sectionId]);

  const scrollRef = useRef<HTMLDivElement>();
  const [recommends, setRecommends] = useState([]);
  useEffect(() => {
    setRecommends(filter(contents, c => c.id !== contentId));
  }, [contentId, contents]);

  const loadData = useCallback(() => {
    if (sectionId) {
      pagingEmiter.invokeNext();
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        more,
        cancellable: true
      });
      containerContentList.actions.logView();
    }
  }, [sectionId, more]);

  const handleClick = (type, id) =>
    subscribeCheck({
      subscribe: containerContentItem.section.subscribe,
      callback: () => history.push(CONTENT_ITEM(type, id)),
      history,
      containerUser,
      containerModals: modalsContainer
    });
  const recommendsExists = recommends?.length > 0;
  if (!recommendsExists) return null;

  switch (contentType) {
    case EContentType.ARTICLE:
      return (
        <div className={ styles.recommends }>
          <Typography className={ styles.recommendsTitle } type="heading" level={ 2 } tag="div" mobile>
            { t('common.recommend') }
          </Typography>
          <VirtualHorizontalList
            className={ styles.virtual }
            parentRef={ scrollRef }
            size={ recommends?.length }
            width={ 280 }
            spacing={ configTheme.indents['indentX1'].margin }
            infinite={ more }
            loading={ loading }
            onLoad={ loadData }
            fallbackHeight={ 266 }
            autoHeight
            renderItem={ memo<any>(({ index }) => {
              const c = recommends?.[index];
              return (
                <Postcard
                  width={ 280 }
                  height={ 192 }
                  src={ c?.imageUrl }
                  fallback={ ImageFallback }
                  classes={ {
                    picture: styles.virtualPicture
                  } }
                  extraTop={ c?.new && <PostcardBadge type="secondary" text={ t('content.new') } offset={ { top: '28px' } } /> }
                  onClick={ () => handleClick(c?.type, c?.id) }
                >
                  <Typography className={ ellipsisStyles[EEllipsisClassKey.line] } type="text" size="large" tag="div" style={ { marginTop: 9 } }>
                    { c?.name }
                  </Typography>
                </Postcard>
              );
            }) }
            renderFallback={ memo<any>(VirtualFallback) }
          />
        </div>
      );
    default:
      return <div className={ styles.recommends } />;
  }
});

export default flow([ModalsContainer, ContainerContentItem, ContainerUser, ContainerContentList])(Recommends);
