//#region imports
import React, { FC, memo, forwardRef, useEffect, useMemo, useState, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import includes from 'lodash/includes';
import toString from 'lodash/toString';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import every from 'lodash/every';
import some from 'lodash/some';
import pick from 'lodash/pick';
import flow from 'lodash/flow';
import each from 'lodash/each';
import find from 'lodash/find';
import map from 'lodash/map';

import { ELoadStatus } from 'app/models/shared.model';
import { ESubcategoryLayout } from 'app/models/model.subcategory';
import { ESectionLayout } from 'app/models/model.section';
import { ECategoryClassKey, CategoryProps, ConnectedCategoryProps } from './category.models';

import { ISectionListInitialState } from 'app/entities/section/entity.section.list.reducer';
import { IContentListMainInitialState } from 'app/entities/content/content.list.main.reducer';

import { SectionListContainer } from 'app/containers/section.list.container';
import { ContainerContentListMain } from 'app/containers/container.content.list.main';

import Wrapper from 'app/components/wrapper/wrapper.index';
import useLimiter from 'app/hooks/useLimiter';
import { useCategoryStyles } from './category.styles';
import { CategoryBlocks } from './category.blocks';
import { CategoryMenu, useCategoryMenuAgent } from './category.menu';
import Headline from '../headline/headline.index';
import VirtualHorizontalList from 'app/components/virtual/virtual.horizontal.list';
import FollowLink from 'app/components/follow-link/follow-link.index';
import { CONTENT_ITEM } from 'app/configs/route.names';
import VirtualFallback from 'app/components/virtual/virtual.fallback';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import { useDesktopContentItemPageStyles } from 'app/pages/desktop/content/item/page.content.item.styles';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { EPostcardClassKey, ImageFallback } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { useHistory } from 'react-router';
//#endregion

//#region Category
export const Category: FC<CategoryProps> = memo(
  forwardRef<HTMLDivElement, CategoryProps>((props, ref) => {
    const {
      className,
      style,
      classes,
      data,
      sections,
      contents,
      loading,
      uAgeType,
      onConfirm,
      onSectionsChangeBy,
      onContentsChangeBy
    } = props;
    const styles = useCategoryStyles({ classes });
    const stylesVirtual = useDesktopContentItemPageStyles();
    const ellipsisStyles = useEllipsisStyles({ lineClamp: 2 });
    const { t } = useTranslation();
    const history = useHistory();
    const scrollRef = useRef<HTMLDivElement>();

    const isSubcategoriesExist = isArray(data.subcategories) && data.subcategories.length > 0;
    const isSubcategoryLayoutMenu = data.subcategoryLayout === ESubcategoryLayout.MENU;
    const isSectionLayoutImage = data.sectionLayout === ESectionLayout.IMAGE;
    const isSectionLayoutMenu = data.sectionLayout === ESectionLayout.MENU;
    const isSectionLayoutNone = data.sectionLayout === ESectionLayout.NONE;

    const [selectedSubcategory, handleSelectSubcategory] = useCategoryMenuAgent({
      data: data?.subcategories,
      onSelect: onSectionsChangeBy
    });
    const [, selectedSubcategoryId] = selectedSubcategory();

    const [selectedSection, handleSelectSection] = useCategoryMenuAgent({
      data: sections,
      onSelect: onContentsChangeBy
    });
    const [, selectedSectionId] = selectedSection();

    const renderBlocks = () => {
      const view = 'box';
      if (isSubcategoriesExist) {
        if (isSubcategoryLayoutMenu || isSectionLayoutMenu) {
          return (
            <>
              <CategoryMenu data={ data?.subcategories } selectedKey={ String(selectedSubcategoryId) } onSelect={ handleSelectSubcategory } />
              {
                data?.subcategories.find(sub => sub?.id === selectedSubcategoryId).sectionLayout === ESectionLayout.IMAGE
                  ? <>
                    <VirtualHorizontalList
                      parentRef={ scrollRef }
                      classes={ {
                        item: stylesVirtual.virtualItem,
                        root: stylesVirtual.recommendsVirtual
                      } }
                      size={ contents?.length }
                      width={ 362 }
                      style={ { marginTop: 36 } }
                      spacing={ configTheme.indents['indentX1'].margin - 4 }
                      fallbackHeight={ 498 }
                      autoHeight
                      renderItem={ memo<any>(({ index }) => {
                        const c = contents?.[index];
                        return (
                          <Postcard
                            width={ 362 }
                            height={ 362 }
                            classes={ {
                              [EPostcardClassKey.picture]: stylesVirtual.recommendsPicture,
                              [EPostcardClassKey.descr]: stylesVirtual.recommendsCardDescr,
                              [EPostcardClassKey.root]: stylesVirtual.recommendsCard
                            } }
                            src={ c?.imageUrl }
                            fallback={ ImageFallback }
                            extraTop={ c?.new && <PostcardBadge text={ t('content.new') } offset={ { top: '36px' } } type="secondary" size="large"/> }
                            extraBottom={ c?.free && <IconText classes={ { root: stylesVirtual.iconFree } } color="white" iconOnly /> }
                            onPictureClick={ () => history.push(CONTENT_ITEM(c?.type, c?.id)) }
                          >
                            <Typography
                              className={ classnames(ellipsisStyles[EEllipsisClassKey.multiline], stylesVirtual.recommendsName) }
                              type="text"
                              size="large"
                              theme="light"
                              onClick={ () => history.push(CONTENT_ITEM(c?.type, c?.id)) }
                            >
                              { c?.name }
                            </Typography>
                          </Postcard>
                        );
                      }) }
                      renderFallback={ memo<any>(VirtualFallback) }
                    />
                    </>
                  : <CategoryBlocks
                    type="section"
                    view={ view }
                    category={ data }
                    data={ sections }
                    loading={ loading }
                    uAgeType={ uAgeType }
                    onConfirm={ onConfirm }
                  />
              }
            </>
          );
        }
        if (isSectionLayoutImage && sections?.length > 1) {
          return (
            <CategoryBlocks
              type="section"
              view={ view }
              category={ data }
              data={ sections }
              loading={ loading }
              uAgeType={ uAgeType }
              onConfirm={ onConfirm }
            />
          );
        }
        if ((isSectionLayoutNone || isSectionLayoutImage) && sections?.length === 1) {
          return (
            <CategoryBlocks
              type="content"
              view={ view }
              category={ data }
              data={ contents }
              loading={ loading }
              uAgeType={ uAgeType }
              onConfirm={ onConfirm }
            />
          );
        }
        if (isSectionLayoutNone && data.content) {
          return <div dangerouslySetInnerHTML={ { __html: data.content } } />;
        }
      }
      return null;
    };

    return (
      <Wrapper color="light">
        <div ref={ ref } className={ classnames(className, styles[ECategoryClassKey.root]) } style={ style } id={ toString(data?.id) }>
          { Headline && <Headline category={ data } sections={ sections } activeSectionId={ +selectedSectionId } uAgeType={ uAgeType } onConfirm={ onConfirm } /> }
          { renderBlocks() }
        </div>
      </Wrapper>
    );
  })
);
Category.displayName = 'CategoryMain';

Category.defaultProps = { data: null, loading: false };
//#endregion

//#region ConnectedCategory
const ConnectedCategory: FC<ConnectedCategoryProps> = memo(
  forwardRef<HTMLDivElement, ConnectedCategoryProps>((connectedProps, ref) => {
    const { sectionListContainer, containerContentListMain, ...props } = connectedProps;
    const { data, loading } = props;
    const [paging, pagingEmitter] = useLimiter(5, 0);

    const isSubcategoriesExist = isArray(data?.subcategories) && data.subcategories.length > 0;
    const isSubcategoryLayoutMenu = data?.subcategoryLayout === ESubcategoryLayout.MENU;
    const isSectionLayoutImage = data?.sectionLayout === ESectionLayout.IMAGE;
    const isSectionLayoutMenu = data?.sectionLayout === ESectionLayout.MENU;
    const isSectionLayoutNone = data?.sectionLayout === ESectionLayout.NONE;

    //#region
    const firstSubcategory = data?.subcategories?.[0];
    const initialTargetId = firstSubcategory?.id;
    const [targetId, setTargetId] = useState(initialTargetId); // subcategory id
    useUpdateEffect(() => setTargetId(initialTargetId), [initialTargetId]);

    useEffect(() => {
      if (targetId) {
        let pager = { limit: 5, offset: 0 };
        if (!isSubcategoryLayoutMenu && isSectionLayoutMenu) pager = { limit: 100, offset: 0 };
        pagingEmitter.invokeReset(pager);
        sectionListContainer.actions.fetchSectionItems({ id: targetId, pager: paging.current() });
      }
    }, [targetId]);

    const sectionList: Partial<ISectionListInitialState> = useMemo(() => {
      const thing = find(sectionListContainer.list, s => s.targetId === targetId);
      if (thing) return pick(thing, ['items', 'status', 'more']);
    }, [sectionListContainer.list, targetId]);
    //#endregion

    //#region
    const firstSection = sectionList?.items?.[0];
    const initialSubjectId = firstSection?.id;
    const [subjectId, setSubjectId] = useState(initialSubjectId); // section id
    useUpdateEffect(() => setSubjectId(initialSubjectId), [initialSubjectId]);

    useEffect(() => {
      if (sectionList?.status === ELoadStatus.ready) {
        if (
          (!isSubcategoryLayoutMenu && isSectionLayoutMenu) ||
          (!isSubcategoryLayoutMenu && isSectionLayoutNone && sectionList?.items?.length === 1 ||
           !isSubcategoryLayoutMenu && isSectionLayoutImage && sectionList?.items?.length === 1)
        ) {
          if (subjectId) {
            containerContentListMain.actions.fetchContentMain({ id: subjectId, pager: { limit: 5, offset: 0 } });
            containerContentListMain.actions.logView();
          }
        } else if (isSubcategoryLayoutMenu && isSectionLayoutMenu) {
          if (sectionList?.items?.length > 0) {
            each(sectionList.items, section => {
              if (section?.id) {
                containerContentListMain.actions.fetchContentMain({ id: section.id, pager: { limit: 5, offset: 0 } });
                containerContentListMain.actions.logView();
              }
            });
          }
        }
      }
    }, [sectionList?.status, sectionList?.items, subjectId]);

    const contentList: Pick<IContentListMainInitialState, 'items' | 'status' | 'more'> = useMemo(() => {
      if (sectionList?.status === ELoadStatus.ready) {
        if (
          (!isSubcategoryLayoutMenu && isSectionLayoutMenu) ||
          (!isSubcategoryLayoutMenu && isSectionLayoutNone) ||
          (!isSubcategoryLayoutMenu && isSectionLayoutImage)
        ) {
          if (subjectId) {
            const thing = find(containerContentListMain.list, c => c.targetId === subjectId);
            if (thing) return pick(thing, ['items', 'status', 'more']);
          }
        } else if (isSubcategoryLayoutMenu && isSectionLayoutMenu) {
          if (sectionList?.items?.length > 0) {
            const subjectIdList = map(sectionList.items, section => section.id);
            const list = filter(containerContentListMain.list, c => c?.targetId > 0 && includes(subjectIdList, c.targetId));

            let status;
            if (some(list, ['status', ELoadStatus.loading])) status = ELoadStatus.loading;
            else if (every(list, ['status', ELoadStatus.ready])) status = ELoadStatus.ready;
            else status = ELoadStatus.not_ready;
            const more = some(list, ['more', true]);

            if (status !== ELoadStatus.ready) return { items: [], status, more };
            return reduce(
              list,
              (acc, c) => {
                // @ts-ignore
                if (c?.items?.length > 0) acc.items.push(...c.items);
                return acc;
              },
              { items: [], status, more }
            );
          }
        }
      }
    }, [containerContentListMain.list, sectionList?.status, sectionList?.items, subjectId, targetId]);
    //#endregion

    const handleSectionsChangeBy = (index: number, id: number) => setTargetId(id);
    const handleContentsChangeBy = (index: number, id: number) => setSubjectId(id);

    if (!isSubcategoriesExist) return null;
    return (
      <Category
        ref={ ref }
        { ...props }
        sections={ sectionList?.items }
        contents={ contentList?.items }
        loading={ loading || sectionList?.status === ELoadStatus.loading || contentList?.status === ELoadStatus.loading }
        onSectionsChangeBy={ handleSectionsChangeBy }
        onContentsChangeBy={ handleContentsChangeBy }
      />
    );
  })
);
ConnectedCategory.displayName = 'ConnectedCategoryMain';
//#endregion

export default flow([SectionListContainer, ContainerContentListMain])(ConnectedCategory) as typeof Category;
