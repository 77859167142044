//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { EContentType } from 'app/models/model.content';

import {
  ECategoryClassKey,
  ECategoryBlocksClassKey,
  ECategoryMenuClassKey,
  CategoryClasses,
  CategoryBlocksClasses,
  CategoryMenuClasses
} from './category.models';
//#endregion

export const useCategoryStyles = makeStyles(
  createStyles<CategoryClasses, {}>({
    [ECategoryClassKey.root]: {
      position: 'relative',
      padding: `${configTheme.indents['indentX2'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
    },
    [ECategoryClassKey.badge]: {
      fontSize: 16
    }
  }),
  { name: 'CategoryMain' }
);

export const useCategoryBlocksStyles = makeStyles(
  createStyles<CategoryBlocksClasses, {}>({
    [ECategoryBlocksClassKey.root]: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: `${configTheme.indents['indentX2'].margin}px`
    },

    [ECategoryBlocksClassKey.box]: { flexFlow: 'row wrap' },

    [ECategoryBlocksClassKey.cell]: { flexFlow: 'row nowrap' },

    [ECategoryBlocksClassKey.item]: {
      [`$${ECategoryBlocksClassKey.box} &`]: {
        cursor: 'pointer',
        flex: '1 1 calc((100% / 3) - 15px)',
        maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
        width: 'calc((100% / 3) - 15px + (15px / 3))',
        margin: `15px 15px 0 0`,
        '&:first-child': {
          flex: '2 2 calc(100% - (100% / 3) - 15px)',
          maxWidth: 'calc(100% - (100% / 3) - 15px + (30px / 3))',
          width: 'calc(100% - (100% / 3) - 15px + (30px / 3))'
        },
        // '&:nth-child(-n + 3)': { marginTop: 0 },
        // '&:nth-child(3n)': { marginRight: 0 },
        '&:nth-child(-n + 2)': { marginTop: 0 },
        '&:nth-child(3n - 1)': { marginRight: 0 }
      },
      [`$${ECategoryBlocksClassKey.cell} &`]: {
        cursor: 'pointer',
        flex: '1 1 calc((100% / 5) - 30px)',
        maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
        width: 'calc((100% / 5) - 30px + (30px / 5))',
        margin: `15px 30px 0 0`,
        '&:nth-child(-n + 5)': { marginTop: 0 },
        '&:nth-child(5n)': { marginRight: 0 }
      }
    },

    [ECategoryBlocksClassKey.iconFree]: {
      marginBottom: '5%',
      marginLeft: '5%',
      padding: '2px 2px',
      borderRadius: '50%',
      backgroundColor: configTheme.colors.background.dark.default
    },

    [ECategoryBlocksClassKey.itemTitle]: {
      [`$${ECategoryBlocksClassKey.item} & span`]: { cursor: 'pointer' }
    },
    [ECategoryBlocksClassKey.itemRoot]: {
      height: '298px!important',
      cursor: 'pointer'
    },
    [ECategoryBlocksClassKey.itemPicture]: {
      width: '100%!important'
    },
    [ECategoryBlocksClassKey.itemDescr]: {
      justifyContent: 'center!important',
      paddingLeft: '18px!important'
    },
    [ECategoryBlocksClassKey.pictureFigure]: {
      width: '100%!important'
    }

  }),
  { name: 'CategoryBlocksMain' }
);

export const useCategoryMenuStyles = makeStyles(
  createStyles<CategoryMenuClasses, {}>({
    [ECategoryMenuClassKey.root]: {
      marginTop: `${configTheme.indents['indentX1'].margin}px`,
      marginBottom: `-${configTheme.indents['indentX1'].margin - 5}px`
    },

    [ECategoryMenuClassKey.menu]: { position: 'relative' },

    [ECategoryMenuClassKey.arrow]: {
      zIndex: 1,
      opacity: 0.25,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      willChange: 'opacity, backgroundColor',
      transition: 'all .3s ease-in-out',
      backgroundColor: configTheme.colors.background.white.translucent,
      color: configTheme.colors.text.light.neutral,
      cursor: 'pointer',
      pointerEvents: 'none',

      '&:hover': { color: configTheme.colors.text.dark.default },
      '&:first-child': { transform: `translateX(-${32 / 4}px)` },
      '&:last-child': { transform: `translateX(${32 / 4}px)` },
      '& svg': { fontSize: 32 },

      [`&$${ECategoryMenuClassKey.arrowDisabled} + $${ECategoryMenuClassKey.container}`]: {
        transform: `translateX(-${32}px)`
      },

      [`$${ECategoryMenuClassKey.prevActive} &:first-child:not($${ECategoryMenuClassKey.arrowDisabled})`]: {
        opacity: 1,
        pointerEvents: 'initial'
      },
      [`$${ECategoryMenuClassKey.nextActive} &:last-child:not($${ECategoryMenuClassKey.arrowDisabled})`]: {
        opacity: 1,
        pointerEvents: 'initial'
      }
    },

    [ECategoryMenuClassKey.arrowDisabled]: { zIndex: 0, opacity: 0, backgroundColor: 'transparent' },

    [ECategoryMenuClassKey.container]: { flex: '1 1 auto' },

    [ECategoryMenuClassKey.list]: {},

    [ECategoryMenuClassKey.listItem]: {},

    [ECategoryMenuClassKey.item]: {
      marginRight: configTheme.indents['indentX2'].margin,
      fontSize: 14,
      lineHeight: '18px',
      color: configTheme.colors.text.light.neutral,
      cursor: 'pointer',
      [`$${ECategoryMenuClassKey.listItem}:last-child &`]: { marginRight: 0 },
      [`&$${ECategoryMenuClassKey.selected}`]: { color: configTheme.colors.text.dark.default, pointerEvents: 'none' }
    },

    [ECategoryMenuClassKey.loading]: {},
    [ECategoryMenuClassKey.prevActive]: {},
    [ECategoryMenuClassKey.nextActive]: {},
    [ECategoryMenuClassKey.selected]: { pointerEvents: 'none' }
  }),
  { name: 'CategoryMenuMain' }
);
