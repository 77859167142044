import React, { useEffect, useRef, useState } from 'react';
import { ContentHeader } from 'app/components/header/content.header';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularLoader from 'app/components/loader/loader.circular';
import { Link } from 'react-router-dom';
import { ICategory } from 'app/models/model.category';
import { ISubcategory } from 'app/models/model.subcategory';
import { ISectionItem } from 'app/models/model.section';
import { IPagerProps } from 'app/containers/container.pager';
import { ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { stylesApp } from 'app/app';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';

import get from 'lodash/get';
import { ResponsiveImage } from 'app/components/image/component.image.responsive';
import { ISubcategoryContainerProps, SubcategoryContainer } from 'app/containers/subcategory.container';
import { useHistory } from 'react-router';

export interface IPageCategoryList extends IRouteProps {
  containerCategory: ICategoryContainerProps;
  containerSubcategory: ISubcategoryContainerProps;
}

const PageCategoryListFC = (props: IPageCategoryList) => {
  const [categoryId, setCategoryId] = useState<ICategory['id']>();
  const [pagerContent, setPagerContent] = useState<IPagerProps['pager']>({ limit: 5, offset: 0 });

  const subcategories = get(props.containerCategory, 'item.subcategories') as ICategoryContainerProps['item']['subcategories'];
  const contentHeaderTitle = get(props.containerCategory, 'item.name') as ICategoryContainerProps['item']['name'];

  const classes = stylesApp();
  const refContainer = useRef<HTMLDivElement>();
  const history = useHistory();

  const onPageChange = () => setPagerContent({ ...pagerContent, offset: pagerContent.offset + pagerContent.limit });

  const onClickLinkToSection = id => {
    const res = props.containerSubcategory.actions.fetchSubcategoryItem(id);
    res.then(r => {
      history.push(`/${categoryId}/content/list/${get(r, 'value.data.firstSectionId')}`);
      return r;
    });
  };

  useEffect(() => {
    setCategoryId(Number(props.computedMatch.params.categoryId));
    props.containerCategory.actions.fetchCategoryItem(Number(props.computedMatch.params.categoryId));
  }, [props.computedMatch.params]);

  return (
    <>
      <div ref={ refContainer }>
        <ContentHeader isClose closeRoute={ '/' }>
          { `Все ${contentHeaderTitle ? contentHeaderTitle.toLowerCase() : ''}` }
        </ContentHeader>
        {
          subcategories && subcategories?.length > 0
            ?
            <InfiniteScroll
              dataLength={ subcategories.length }
              next={ onPageChange }
              hasMore={ false }
              loader={ <CircularLoader center/> }
            >
              {
                subcategories && subcategories?.length > 0 ? subcategories.map(sc => (
                  <span key={ sc.id } className={ classes.linkDNone } onClick={ () => onClickLinkToSection(sc.id) }>
                    <ResponsiveImage key={ sc.id } src={ sc.preview } title={ sc.name }/>
                  </span>
                )) : null
              }
            </InfiniteScroll>
            : null
        }
      </div>
    </>
  );
};

export default CategoryContainer(SubcategoryContainer(PageCategoryListFC));
