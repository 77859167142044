//#region imports
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useGetSet } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useLastLocation } from 'react-router-last-location';
import { useParams, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { Grid } from '@material-ui/core';
import Cookies from 'universal-cookie';

import isNil from 'lodash/isNil';
import find from 'lodash/find';

import { CONTENT_LIST } from 'app/configs/route.names';
import { stylesApp } from 'app/app';
import { useValidateSubscribeCallback } from 'app/utils/util.subscribe';
import useEllipsisStyles from 'app/hooks/useEllipsisStyles';
import { loginExternal } from 'app/utils/auth.external';
import { useContentItemStyles } from '../page.content.item.styles';
import Recommends from './content.item.article.recommends';

import { ILikeItem } from 'app/entities/like/like.model';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { OContentItemModalType } from 'app/models/model.content';
import { IContentItemProps } from '../item.models';

import { ModalHeader } from 'a1s-tele2-react-ui/src/components/mobile/modalHeader/modal.header.index';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';
import { Likes } from 'a1s-tele2-react-ui/src/components/shared/likes/likes.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { ELoadStatus } from 'app/models/shared.model';

const cookies = new Cookies();
//#endregion
export interface IContentArticleProps extends IContentItemProps {
  onArticleLinkClick: (props: any) => void;
}

const ContentItemArticle: FC<IContentArticleProps> = memo<IContentArticleProps>(props => {
  const {
    containerUser,
    containerContentItem,
    containerSubscribe,
    containerLike,
    onArticleLinkClick
  } = props;
  const isUserAuthenticated = containerUser?.token && containerUser?.authenticated;
  const [modalTypeOpen, setModalTypeOpen] = useGetSet<OContentItemModalType>(null);
  const { item: contentItem, section: sectionDetail, status } = containerContentItem;

  const { t } = useTranslation();
  const params = useParams<any>();
  const classesApp = stylesApp();
  const ellipsisStyles = useEllipsisStyles({});
  const styles = useContentItemStyles();
  const history = useHistory();
  const lastLocation = useLastLocation();

  const section = sectionDetail?.section;
  const subcategoryId = sectionDetail?.subcategory?.id;
  const categoryId = sectionDetail?.subcategory?.categoryId;
  const subscribe = sectionDetail?.subscribe;
  const sectionId = section?.parentId ?? section?.id;
  const contentId = params?.contentId || 0;
  const subsectionId = section?.parentId ? section?.id : undefined;
  const ready = status.item === ELoadStatus.ready;
  const validateSubscribe = useValidateSubscribeCallback({ user: containerUser, subscribe });

  useEffect(() => {
    if (ready) {
      if (!props.containerContentItem.item?.free && props.containerContentItem?.section?.subscribe?.status !== ESubscriptionStatus.ACTIVE) {
        history.replace('/');
      }
    }
  }, [ready]);

  useEffect(() => {
    let prevRoute = '';
    if (lastLocation) {
      if (lastLocation.pathname === '/' || lastLocation.pathname.includes('content/list')) {
        prevRoute = lastLocation.pathname;
      }
    }

    if (prevRoute === '') {
      if (cookies.get('prev-route-content-list')?.pathname) {
        prevRoute = cookies.get('prev-route-content-list')?.pathname;
      }
    }

    containerContentItem.actions.updateRoteItem(prevRoute);
  }, [lastLocation]);

  const lastUrl = containerContentItem.route?.length > 0 ? containerContentItem.route : undefined;
  const backUrl = lastUrl ?? (categoryId && subcategoryId && sectionId ? CONTENT_LIST(categoryId, sectionId) : '/');

  const goBack = () => {
    history.push(backUrl, { contentId, sectionId, subsectionId });
    // history.length > 2 ? history.goBack() : history.push('/');
    cookies.remove(`prev-route-content-list`);
    cookies.remove(`prev-route-content-item`);
  };

  useEffect(() => {
    if (contentId) {
      containerLike.actions.fetchLikeList([contentId]);
    }
    return () => {
      containerLike.actions.resetLikeItems();
    };
  }, [contentId]);

  const heart = useMemo(() => {
    return find(containerLike.list, h => h.contentId === contentId);
  }, [contentId, containerLike.list]);

  const handleLike = useCallback((cid: ILikeItem['contentId'], liked: boolean) => {
    if (liked) containerLike.actions.createLikeItem(cid);
    else containerLike.actions.removeLikeItem(cid);
  }, []);

  const handleDecline = useCallback(() => {
    loginExternal(history.location.pathname);
  }, []);

  const hasLikes = !isNil(heart?.count ?? 0);
  const accessible = containerUser.authenticated;

  const indicator = useMemo(() => {
    return !contentItem?.free
      ? subscribe?.status === ESubscriptionStatus.ACTIVE
        ? t('content.subscribed')
        : t('content.bySubscription')
      : undefined;
  }, [contentItem?.free, subscribe?.status]);

  const indicatorIcon = useMemo(() => {
    return !contentItem?.free ? (subscribe?.status === ESubscriptionStatus.ACTIVE ? 'success-large' : 'balance') : undefined;
  }, [contentItem?.free, subscribe?.status]);

  const content = (
      <>
        <ModalHeader isClose closeFn={ goBack } text={ contentItem?.name } />

        { (contentItem?.free || subscribe?.status === ESubscriptionStatus.ACTIVE) && contentItem &&
          <>
            <Typography className={ styles.articleTitle } type="heading" level={ 3 }>{ contentItem?.name }</Typography>
            <img className={ styles.previewImg } src={ contentItem.imageUrl } alt={ contentItem.name }/>

            <div className={ styles.article } dangerouslySetInnerHTML={ { __html: contentItem?.text } } onClick={ onArticleLinkClick }/>

            <Grid style={ { marginBottom: 15 } } container wrap="nowrap" alignItems="center" justifyContent="space-between" spacing={ 1 }>
              { indicator && (
                <Grid item xs="auto">
                  <IconText color="primary" icon={ indicatorIcon } text={ indicator } />
                </Grid>
              ) }
              { !indicator && contentItem?.free && (
                <Grid item xs="auto">
                  <IconText color="primary" text={ t('content.free') }/>
                </Grid>
              ) }
              { hasLikes && (
                <Grid item>
                  <Likes
                    // classes={ { root: styles.likes } }
                    count={ heart?.count ?? 0 }
                    liked={ heart?.active ?? false }
                    declined={ !accessible }
                    onClick={ (e, b) => handleLike(contentId, b) }
                    onDecline={ handleDecline }
                  />
                </Grid>
              ) }
            </Grid>

            <Recommends contentId={ contentId }/>
          </>
        }

      </>
  );

  return (
    <div className={ styles.root }>
      { ready && content }
    </div>
  );
});
ContentItemArticle.displayName = 'ContentItemArticle';

export default ContentItemArticle;
