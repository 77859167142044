//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { AudioPlayerClasses, IAudioPlayerStylesProps } from './audio-player.models';
//#endregion

export const useAudioPlayerStyles = makeStyles(
  theme =>
    createStyles<AudioPlayerClasses, IAudioPlayerStylesProps>({
      root: {
        opacity: ({ haveEnoughData }) => (haveEnoughData ? 1 : 0),
        visibility: ({ haveEnoughData }) => (haveEnoughData ? 'visible' : 'hidden'),
        margin: 0,
        background: '#4D4D4D',
        boxShadow: 'none',
        padding: '0 10px',
        willChange: 'opacity, visibility',
        transition: 'opacity 0.3s, visibility 0s linear 0.3s',
        '& :nth-child(2)': {
          order: 1
        },
        '& :nth-child(3)': {
          order: 3
        },
        '& :nth-child(4)': {
          order: 2,
          alignItems: 'center',
          '& :nth-child(1)': {
            order: 1
          },
          '& :nth-child(3)': {
            order: 2
          }
        }
      },
      playIcon: {
        color: configTheme.colors.icon.white,
        '&:hover': {
          color: configTheme.colors.icon.white
        },
        '&:focus': {
          color: configTheme.colors.icon.white
        }
      },
      muteIcon: {},
      pauseIcon: {
        color: configTheme.colors.icon.white,
        '&:hover': {
          color: configTheme.colors.icon.white
        },
        '&:focus': {
          color: configTheme.colors.icon.white
        }
      },
      volumeIcon: {
        color: configTheme.colors.icon.white,
        fontSize: 20,
        '&:hover': {
          color: configTheme.colors.icon.white
        },
        '&:focus': {
          color: configTheme.colors.icon.white
        }
      },
      closeIcon: {},
      loopIcon: {},
      downloadsIcon: {},
      downloadsContainer: {},
      downloadsItemText: {},
      downloadsItemLink: {},
      progressTime: {
        color: configTheme.colors.icon.white,
        marginBottom: 4,
        fontSize: 14
      },
      mainSlider: {
        padding: '6px 0',
        color: configTheme.colors.icon.white,
        '& .MuiSlider-rail': {
          color: configTheme.colors.icon.white
        },
        '& .MuiSlider-track': {
          color: configTheme.colors.icon.white
        },
        '& .MuiSlider-thumb': {
          color: configTheme.colors.icon.white,
          width: 6,
          height: 6,
          marginTop: '-2px',
          '&:hover': {
            color: configTheme.colors.icon.white,
            boxShadow: `0 0 0 8px #ffffff12`
          },
          '&:focus': {
            boxShadow: `0 0 0 8px #ffffff12`
          }
        },
        '& .MuiSlider-thumb.MuiSlider-active': {
          boxShadow: `0 0 0 14px #ffffff12`
        }
      },
      volumeSlider: {
        display: 'none'
      },
      volumeSliderContainer: {
        display: 'none'
      },
      title: {
        color: configTheme.colors.icon.white,
        marginLeft: `${configTheme.indents['indentX1.5'].margin - 2}px`,
        marginRight: `${configTheme.indents['indentX3'].margin}px`,
        marginBottom: `${configTheme.indents['indentX1'].margin}px`
      },
      iconClose: {
        position: 'absolute',
        cursor: 'pointer',
        top: 32,
        right: 23
      }
    }),
  { name: 'AudioPlayer' }
);
