import { IRootState } from 'app/shared/reducers/root.reducer';
import { ELoadStatus } from 'app/models/shared.model';

import { AxiosResponse } from 'axios';
import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';
import { ILoggingState } from 'app/entities/logging/logging.reducer';

import { logPosition, setState, logLogin, logLogout, logPartVideo, logStartVideo, ILogStartVideo, ILogPartVideo } from 'app/entities/logging/logging.actions';

export type ILoggingContainer = ReturnType<typeof mapStateToProps> & {
  actions: {
    logPosition: (state: ILoggingState) => void;
    setState: typeof setState;
    logLogin: (state: ILoggingState) => void;
    logLogout: () => any;
    logStartVideo: (params: ILogStartVideo) => void;
    logPartVideo: (params: ILogPartVideo) => void;
  }
};

const mapStateToProps = (state: IRootState) => ({
  authenticated: state.user.request.status.token === ELoadStatus.ready && Boolean(state.user.token),
  contentViewId: state.logging.contentViewId
});
const mapDispatchToProps = (dispatch: Dispatch): ILoggingContainer['actions'] => ({
  setState: state => dispatch(setState(state)),
  logPosition: state => dispatch(logPosition(state) as unknown as Action),
  logLogin: state => dispatch(logLogin(state) as unknown as Action),
  logLogout: () => dispatch(logLogout() as unknown as Action),
  logStartVideo: params => dispatch(logStartVideo(params) as unknown as Action),
  logPartVideo: params => dispatch(logPartVideo(params) as unknown as Action)
});

export const LoggingContainer = connect<ILoggingContainer>('loggingContainer', mapStateToProps, mapDispatchToProps);
