import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'mobile-device-detect';
import { createStyles, makeStyles, StandardProps } from '@material-ui/core';

import flow from 'lodash/flow';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import {
  ContainerDietIndividualProgram,
  IContainerDietIndividualProgramProps
} from 'app/containers/container.diet.program';

import { Typography } from 'app/components/desktop/typography/typography.index';

import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import RocketSvg from 'a1s-tele2-react-ui/src/assets/icons/rocket.svg';

import { ELoadStatus } from 'app/models/shared.model';
import { ESex, ETarget, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { ModalParameters as ModalMobileParameters } from 'app/pages/mobile/diet/components/parameters/modal/modal.parameters';
import { ModalParameters } from 'app/pages/desktop/diet/components/parameters/modal/modal.parameters';

interface IParameters {
  containerUser?: IAuthContainerProps;
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  isNoIcon: boolean;
  isNoCard: boolean;
  isNoParametersInfo: boolean;
  className?: string;
}

export const EParametersClassKey = {
  wrapper: 'wrapper',
  table: 'table',
  title: 'title',
  desc: 'desc',
  icon: 'icon',
  actionButton: 'actionButton'
} as const;

export type ParametersClasses = typeof EParametersClassKey[keyof typeof EParametersClassKey];
export type ParametersProps = StandardProps<IParameters, ParametersClasses>;

export interface IParametersStylesProps {
  classes?: ParametersProps['classes'];
  mobile?: boolean;
  card?: boolean;
}

export const useParametersStyles = makeStyles<ParametersClasses, IParametersStylesProps>(
  createStyles({
    wrapper: {
      width: ({ mobile }) => (mobile ? '100%' : 742),
      margin: '36px auto'
    },
    table: {
      minHeight: 378,
      padding: ({ mobile }) => (mobile ? '27px 18px' : 36),
      borderRadius: ({ mobile }) => (mobile ? '10px' : 0),
      boxShadow: ({ card }) => (card ? '0px 1px 8px rgb(0 0 0 / 14%)' : 'none'),
      boxSizing: 'border-box',
      '& .MuiTableCell-root': {
        fontSize: '16px!important',
        fontFamily: `'Tele2 Text', Arial, sans-serif!important`
      },
      '& .MuiTableCell-sizeSmall': {
        paddingLeft: 0
      },
      '& .MuiTableCell-sizeSmall:last-child': {
        paddingRight: 0
      }
    },
    title: {
      display: 'inline-flex',
      marginBottom: ({ mobile }) => (mobile ? 18 : 27)
    },
    desc: {
      marginTop: ({ mobile }) => (mobile ? 18 : 36)
    },
    icon: {
      marginRight: 19
    },
    actionButton: {
      margin: ({ mobile }) => (mobile ? '36px auto 36px' : '36px auto 0px'),
      display: 'flex'
    }
  })
);

const ParametersIndex = memo<ParametersProps>(props => {
  const { className = '' } = props;
  const { t } = useTranslation();
  const { isNoIcon, isNoCard, isNoParametersInfo, classes } = props;
  const { authenticated } = props.containerUser;
  const {
    actions: { fetchDietItem, saveDietItem },
    itemUserAttributes,
    progressStatus
  } = props.containerDietIndividualProgram;
  const styles = useParametersStyles({ classes, mobile: isMobile, card: !isNoCard });
  const [isShowModal, setShowModal] = useState(false);

  useEffect(() => {
    if (authenticated && progressStatus === ELoadStatus.not_ready) {
      fetchDietItem();
    }
  }, [authenticated, progressStatus]);

  enum EHeader {
    HEIGHT = 'height',
    WEIGHT = 'weight',
    AGE = 'age',
    SEX = 'sex',
    TARGET = 'target',
    BREAST_FEEDING = 'breastFeeding'
  }

  const renderValue = parameter => {
    switch (parameter) {
      case 'sex':
        return itemUserAttributes[parameter] === ESex.FEMALE ? t('diet.parameters.woman') : t('diet.parameters.man');
      case 'target':
        let text = t('diet.parameters.maintainWeight');
        if (itemUserAttributes[parameter] === ETarget.WEIGHT_DOWN) {
          text = t('diet.parameters.loseWeight');
        } else if (itemUserAttributes[parameter] === ETarget.WEIGHT_UP) {
          text = t('diet.parameters.gainWeight');
        }
        return text;
      case 'breastFeeding':
        return t(`diet.parameters.${itemUserAttributes[parameter] ? 'yes' : 'no'}`);
      default:
        return itemUserAttributes[parameter];
    }
  };

  const isNullishItemUserAttributes = useMemo(() => {
    return (
      itemUserAttributes &&
      Object.values(itemUserAttributes).every(value => {
        return value === null;
      })
    );
  }, [itemUserAttributes]);

  const handleSave = (data: IUserAttributes) => {
    saveDietItem(data);
  };

  return (
    <>
      <div className={ `${styles.wrapper} ${className}` }>
        <TableContainer component={ Paper } className={ styles.table }>
          <div className={ styles.title }>
            { !isNoIcon && <img src={ RocketSvg } alt="rocket" className={ styles.icon } /> }
            <Typography type="heading" level={ 2 } mobile={ isMobile }>
              { t('diet.parameters.title') }
            </Typography>
          </div>

              {
                isNullishItemUserAttributes ? (
                  <>
                    <Typography type="text" tag="p">
                      { t('diet.parameters.parametersNotSpecified.empty') }
                    </Typography>
                    <Typography type="text" tag="p">
                      { t('diet.parameters.parametersNotSpecified.default') }
                    </Typography>
                  </>
                ) : (
                  <>
                    <Table size="small">
                      <TableBody>
                        { Object.keys(EHeader).map((parameter, index) => (
                          <TableRow key={ index }>
                            <TableCell scope="parameter">{ t(`diet.parameters.${EHeader[parameter]}`) }</TableCell>
                            <TableCell align="right">{ itemUserAttributes[EHeader[parameter]] !== null ? renderValue(EHeader[parameter]) : t('diet.notSpecified') }</TableCell>
                          </TableRow>
                        )) }
                      </TableBody>
                    </Table>
                    { !isNoParametersInfo && (
                      <Typography type="text" theme="dark" color="secondary" tag="div" className={ styles.desc }>
                        { t('diet.parameters.info') }
                      </Typography>
                    ) }
                  </>
                )
              }
        </TableContainer>
        <Button mobile={ isMobile } className={ styles.actionButton } onClick={ () => setShowModal(true) }>
          { isNullishItemUserAttributes ? t('diet.specifyParameters') : t('diet.changeParameters') }
        </Button>
      </div>

      { !isMobile && isShowModal && (
        <ModalParameters
          isModalParameters={ !isMobile && isShowModal }
          setModalParameters={ setShowModal }
          propsState={ itemUserAttributes }
          save={ handleSave }
          loading={ progressStatus }
        />
      ) }
      { isMobile && isShowModal && (
        <ModalMobileParameters
          isShowModal={ isMobile && isShowModal }
          propsState={ itemUserAttributes }
          setShowModal={ setShowModal }
          save={ handleSave }
          loading={ progressStatus }
        />
      ) }
    </>
  );
});
export default flow([ContainerUser, ContainerDietIndividualProgram])(ParametersIndex);
