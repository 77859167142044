import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import reducer, { IRootState } from 'app/shared/reducers/root.reducer';
import DevTools from './devtools';
import { BASENAME } from 'app/configs/const';

import promiseMiddleware from 'redux-promise-middleware';
import notificationMiddleware from 'app/middleware/middleware.notification';
import loggerMiddleware from 'app/middleware/middleware.logger';
import errorMiddleware from 'app/middleware/middleware.error';
import userTimingMiddleware from 'app/middleware/middleware.user.timing';
import serverErrorMiddleware from 'app/middleware/middleware.server.error';
import userActionsMiddleware from 'app/middleware/middleware.user.actions';

export const history = createBrowserHistory({ basename: BASENAME });

const defaultMiddlewares = [
  userTimingMiddleware,
  thunkMiddleware,
  notificationMiddleware,
  promiseMiddleware,
  errorMiddleware,
  loggerMiddleware,
  serverErrorMiddleware,
  routerMiddleware(history),
  userActionsMiddleware
];
const composedMiddlewares = middlewares =>
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...defaultMiddlewares, ...middlewares), DevTools.instrument())
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) => createStore(reducer(history), initialState as any, composedMiddlewares(middlewares));

export default initialize;
