import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { SECTION_ACTIONS } from 'app/entities/section/entity.section.actions';

import { ISection, ISectionItem } from 'app/models/model.section';
import { ELoadStatus } from 'app/models/shared.model';
import { ISubcategory } from 'app/models/model.subcategory';
import { ISubscription } from 'app/models/model.subscription';
import { EContentType } from 'app/models/model.content';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

export interface ISectionItemInitialState {
  item?: ISectionItem;
  isSubsection: boolean;
  distribution: string;
  subcategory: ISubcategory;
  subscribe: ISubscription;
  subsections: ISectionItem[];
  contentType?: EContentType;
  status: ELoadStatus;
  error: Error;
}

const initialState: ISectionItemInitialState = {
  item: {} as ISectionItem,
  contentType: null as EContentType,
  distribution: 'FILE',
  subcategory: {} as ISubcategory,
  subscribe: {} as ISubscription,
  subsections: [] as ISectionItem[],
  status: ELoadStatus.not_ready,
  isSubsection: false,
  error: null
};

export type ISectionItemState = Readonly<typeof initialState>;

export default (state = initialState, action): ISectionItemState => {
  const { type, payload, meta } = action;
  const uncancelled = meta?.props?.uncancelled ?? false;
  switch (type) {
    case REQUEST(SECTION_ACTIONS.FETCH_ITEM):
      return flow([set('status', ELoadStatus.loading), set('error', initialState)])(state);
    case SUCCESS(SECTION_ACTIONS.FETCH_ITEM):
      if (payload?.canceled && uncancelled) return state;
      return flow([
        set('item', payload.data.section),
        set('contentType', payload.data.contentType),
        set('subcategory', payload.data.subcategory),
        set('subscribe', payload.data.subscribe),
        set('subsections', payload.data.subsections),
        set('status', payload?.canceled ? ELoadStatus.error : ELoadStatus.ready),
        set('isSubsection', payload.data.section?.parentId > 0)
      ])(state);
    case FAILURE(SECTION_ACTIONS.FETCH_ITEM):
      return flow([set('item', initialState.item), set('status', ELoadStatus.error), set('error', new Error('item fetch failed'))])(state);

    case SECTION_ACTIONS.RESET_ITEMS:
      return initialState;

    default:
      return state;
  }
};
