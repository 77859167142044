//#region imports
import React, { FC, memo, forwardRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import find from 'lodash/find';

import { SECTIONS, CONTENT_LIST, SUBCATEGORIES } from 'app/configs/route.names';
import { EAgeType } from 'app/models/shared.model';
import { ECategoryAllButtonLink } from 'app/models/model.category';
import { ISectionItem } from 'app/models/model.section';

import { TypographyFollowLink } from 'app/components/follow-link/follow-link.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { HeadlineProps } from './headline.model';
import { EContentType } from 'app/models/model.content';
//#endregion

//#region Headline
const Headline: FC<HeadlineProps> = memo(
  forwardRef<HTMLDivElement, HeadlineProps>((props, ref) => {
    const isAllButtonLinkLikeFirstSection = true; // hard code
    const { category, sections, activeSectionId, uAgeType, onConfirm } = props;
    const { t } = useTranslation();

    const isAgeAdult = uAgeType === EAgeType.ADULT;
    const handleClick = (e, url) => {
      if (!isAgeAdult && category.ageType === EAgeType.ADULT) {
        e.preventDefault();
        if (isFunction(onConfirm)) onConfirm({ type: EAgeType.ADULT, url });
      }
    };

    const isSubcategoriesExist = isArray(category.subcategories) && category.subcategories.length > 0;
    const isSectionsExist = isSubcategoriesExist && isArray(sections) && sections.length > 0;

    const subcategory = isSubcategoriesExist ? category.subcategories[0] : undefined;
    const [firstSection, activeSection] = useMemo(() => {
      let firstItem: ISectionItem;
      let activeItem: ISectionItem;
      if (isSectionsExist) {
        firstItem = sections[0];
        activeItem = find(sections, i => i.id === activeSectionId);
      }
      return [firstItem, activeItem];
    }, [sections, activeSectionId]);

    const linkProps = useMemo(() => {
      let params;
      if (isAllButtonLinkLikeFirstSection) {
        if (activeSection) {
          params = { to: CONTENT_LIST(category.id, activeSection?.id), state: { sectionId: activeSection?.id } };
        } else if (firstSection) {
          params = { to: CONTENT_LIST(category.id, firstSection?.id), state: { sectionId: firstSection?.id } };
        }
      } else {
        switch (category.allButtonLink) {
          case ECategoryAllButtonLink.CUSTOM:
            params = { to: category.allButtonUrl, target: '_blank' };
            break;
          case ECategoryAllButtonLink.ALL_SECTIONS:
            params = { to: SECTIONS(category.id, subcategory?.id) };
            break;
          case ECategoryAllButtonLink.ACTIVE_SECTION:
            if (activeSection) {
              params = { to: CONTENT_LIST(category.id, activeSection?.id), state: { sectionId: activeSection?.id } };
            }
            break;
          case ECategoryAllButtonLink.FIRST_SECTION:
            if (firstSection) {
              params = { to: CONTENT_LIST(category.id, firstSection?.id), state: { sectionId: firstSection?.id } };
            }
            break;
          case ECategoryAllButtonLink.ALL_SUBCATEGORIES:
            params = { to: SUBCATEGORIES(category.id) };
            break;
          default:
            break;
        }
      }
      return params;
    }, [category.allButtonLink, category.allButtonUrl, category.id, subcategory?.id, firstSection?.id, activeSection?.id]);

    return (
      <Grid ref={ ref } container alignItems="baseline" justifyContent="space-between" spacing={ 1 }>
        <Grid item>
          <Typography type="heading" level={ 1 } tag="p">
            { category.name }
          </Typography>
        </Grid>
        <Grid item>
          { (activeSection?.contentType === EContentType.BOOK || sections?.length > 5) && (
            <TypographyFollowLink { ...linkProps } onClick={ handleClick } size="large" style={ { textDecoration: 'none' } }>
              Еще
            </TypographyFollowLink>
          ) }
        </Grid>
      </Grid>
    );
  })
);
Headline.displayName = 'HeadlineMain';
//#endregion

export default Headline;
