//#region imports
import { StandardProps } from '@material-ui/core';

import { ICategory } from 'app/models/model.category';
import { ISubcategory } from 'app/models/model.subcategory';
import { ISectionItem } from 'app/models/model.section';
import { EContentType, IContent } from 'app/models/model.content';
import { ContainerContentListMain, IContainerContentListMainProps } from 'app/containers/container.content.list.main';
//#endregion

//#region Content
export interface IContentProps {
  category?: ICategory;
  subcategory?: ISubcategory;
  sectionItem?: ISectionItem;
  data?: IContent[];
  loadData?: Function;
  loading?: boolean;
  more?: boolean;
  isSubsectionItem?: boolean;
  uAgeType?: string;
  onConfirm?: (e: { type?: string, url?: string }) => void;
}

export const EContentClassKey = {
  root: 'root',
  iconFree: 'iconFree',
  itemText: 'itemText',
  virtual: 'virtual'
} as const;

// const assertions (TS 3.4+)
export type ContentClasses = typeof EContentClassKey[keyof typeof EContentClassKey];

export type ContentProps = StandardProps<IContentProps, ContentClasses>;
//#endregion

//#region ConnectedContent
export interface IConnectedContentProps {
  containerContentListMain: IContainerContentListMainProps;
}

export type ConnectedContentProps = IConnectedContentProps & ContentProps;
//#endregion
