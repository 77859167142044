//#region imports
import React, { FC, memo } from 'react';
import { isMobile } from 'mobile-device-detect';
import flow from 'lodash/flow';

import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ErrorServerContainer, IServerErrorContainer } from 'app/containers/container.server.error';

import AppBar from 'app/components/desktop/app.bar/app.bar.index';
import { ComponentError } from 'app/components/error/component.error';
//#endregion

// tslint:disable-next-line: no-empty-interface
export interface IDesktopErrorPageProps {
  containerUser?: IAuthContainerProps;
  errorServerContainer: IServerErrorContainer;
}

const DesktopErrorPage: FC<IDesktopErrorPageProps> = memo(props => {
  const { errorServerContainer } = props;
  const { errorCode, actions } = errorServerContainer;
  return (
    <>
      <AppBar style={ { borderBottom: '1px solid #D1DBD6' } } color="light" />
      <ComponentError code={ errorCode } callback={ actions.resetError } mobile={ isMobile } />
    </>
  );
});
DesktopErrorPage.displayName = 'DesktopErrorPage';

export default flow([ContainerUser, ErrorServerContainer])(DesktopErrorPage);
