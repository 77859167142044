import queryString from 'query-string';

export const loginExternal = (pathname: string, withoutParams = false) => {
  const searchParams = queryString.parse(window.location.search);
  window.location.href = `/ssoLogin?backUrl=${encodeURIComponent(
    `${pathname}${
      withoutParams
        ? ''
        : searchParams
        ? `?${Object.keys(searchParams)
            .map((i, index) => `${i}=${searchParams[i]}${index !== Object.keys(searchParams).length - 1 ? '&' : '&login=success'}`)
            .join('')}`
        : '?login=success'
    }`
  )}`;
};

export const getUrlLoginExternal = (pathname: string) => `/ssoLogin?backUrl=${encodeURIComponent(`${pathname}?login=success`)}`;

export const logoutExternal = () => {
  window.location.href = `/ssoLogout`;
};
