// TODO: вынести в https://git.a1s/front/react-hooks-ts
//#region imports
import { useCallback } from 'react';
import { useGetSet } from 'react-use';

import { PAGER } from 'app/configs/const';

import { IPagerProps } from 'app/containers/container.pager';
//#endregion

export default function useLimiter(initialLimit: number = PAGER.LIMIT, initialOffset: number = PAGER.OFFSET) {
  const [current, setCurrent] = useGetSet<IPagerProps['pager']>({ limit: initialLimit, offset: initialOffset });
  const [next, setNext] = useGetSet<IPagerProps['pager']>({ limit: current().limit, offset: current().offset + current().limit });
  const [prev, setPrev] = useGetSet<IPagerProps['pager']>({ limit: current().limit, offset: current().offset - current().limit });

  const invokeNext = useCallback(() => {
    setCurrent(next());
    setNext({ limit: current().limit, offset: current().offset + current().limit });
    setPrev({ limit: current().limit, offset: current().offset - current().limit });
  }, []);

  const invokePrev = useCallback(() => {
    setCurrent(prev());
    setNext({ limit: current().limit, offset: current().offset + current().limit });
    setPrev({ limit: current().limit, offset: current().offset - current().limit });
  }, []);

  const invokeReset = useCallback((opts?: IPagerProps['pager']) => {
    const limit = opts?.limit ?? initialLimit;
    const offset = opts?.offset ?? initialOffset;
    setCurrent({ limit, offset });
    setNext({ limit: current().limit, offset: current().offset + current().limit });
    setPrev({ limit: current().limit, offset: current().offset - current().limit });
  }, []);

  return [
    { current, next, prev },
    { invokeNext, invokePrev, invokeReset }
  ];
}
