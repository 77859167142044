//#region imports
import React from 'react';
import { createStyles, withStyles, Slider } from '@material-ui/core';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
//#endregion

export default withStyles(
  createStyles({
    root: {
      boxSizing: 'border-box',
      height: 6,
      padding: 0
    },

    thumb: {
      height: 6,
      width: 6,
      marginTop: 0,
      marginLeft: -3,
      border: '0 none',
      willChange: 'width, left',
      transition: 'all 0.3s linear',
      color: 'rgb(233, 232, 238)',
      '&:focus, &:hover, &$active': { boxShadow: 'none' }
    },

    active: {},

    track: {
      height: 6,
      borderRadius: 6,
      willChange: 'width, left',
      transition: 'all 0.3s linear',
      color: 'rgb(233, 232, 238)'
    },

    rail: {
      height: 6,
      borderRadius: 6,
      opacity: 1,
      color: configTheme.colors.background.dark.medium
    }
  }),
  { name: 'PlayerControlsSlider' }
)(Slider);
