import React, { useEffect, useState } from 'react';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';
import playSvg from 'a1s-tele2-react-ui/src/assets/icons/playCircle.svg';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';
// import { ImPlay3 } from 'react-icons/im';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import noop from 'lodash/noop';

import { stylesApp } from 'app/app';
import { Header } from 'app/components/header/header.ui';

import { EContentType } from 'app/models/model.content';
import { useTranslation } from 'react-i18next';

export interface IContentImageProps {
  src: string;
  title?: string;
  type?: EContentType;
  newContent?: boolean;
  classesImg?: string;
  classesWrap?: string;
  articleTitle?: string;
  articleDescItem?: string;
  contentIndicator?: number;
  bookImg?: string;
  recommends?: string;
  onClick?: () => void;
}

export const stylesContentImage = makeStyles(theme =>
  createStyles({
    wrap: {
      margin: '36px 0'
    },
    contentImgWrap: {
      margin: '36px 0 0'
    },
    contentIndicator: {
      marginRight: 3
    },
    articleImg: {
      width: 135,
      height: 135,
      margin: '0 18px 0 0'
    },
    bookImg: {
      width: 135,
      height: 135,
      margin: '0 18px 0 0'
    },
    articleTitle: {
      opacity: 1,
      color: `${ui.colors.text.dark.default} !important`,
      lineHeight: '22px !important',
      fontWeight: 700
    },
    articleDescItem: {
      fontSize: '14px !important',
      margin: '3px 0 0 !important',
      lineHeight: '18px !important',
      color: ui.colors.text.dark.neutral
    },
    img: {
      width: '100%',
      height: '100%',
      position: 'relative',
      overflow: 'hidden'
    },
    book: {
      height: 135,
      fontSize: '14px',
      lineHeight: '18px',
      color: ui.colors.text.dark.neutral
    },
    video: {
      height: 229
    },
    image: {
      height: 229
    },
    study: {
      height: 229
    },
    olympiad: {
      height: 229
    },
    title: {
      margin: '9px 0',
      fontWeight: 'normal',
      color: ui.colors.text.dark.default
    },
    videoIcon: {
      display: 'inline-block',
      position: 'absolute',
      fontSize: 22,
      textAlign: 'center',
      lineHeight: '60px',
      height: 59,
      width: 59,
      borderRadius: 50,
      color: ui.colors.text.dark.default,
      zIndex: 9,
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%'
    },
    recommends: {
      fontFamily: 'Tele2 Display'
    }
  })
);

const stylesNewContent = makeStyles(theme =>
  createStyles({
    newContent: {
      marginTop: 27,
      color: '#1F2229',
      width: 58,
      height: 23,
      display: 'flex',
      fontSize: 15,
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(-77deg, transparent 6px, #FFE538 0)'
    }
  })
);

export const ContentImage = (props: IContentImageProps) => {
  const classes = stylesContentImage();
  const styleNewBlock = stylesNewContent();
  const classesApp = stylesApp();
  const { t } = useTranslation();

  const [imageSource, setImageSource] = useState(null);
  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageSource(img.src);
    img.onerror = () => setImageSource(null);
    img.onabort = () => setImageSource(null);
    img.src = props.src;
    return () => {
      if (!img.complete) {
        img.onload = noop;
        img.src = '';
        if (img.remove) img.remove();
      }
    };
  }, [props.src]);

  return (
    <div className={ `${props.classesWrap || ''} ${classes.wrap}` } onClick={ props?.onClick }>
      <CardMedia
        key={ props.type }
        image={ imageSource }
        className={ `
          ${props.classesImg || ''}
          ${classes.img}
          ${props.type ? classes[props.type.toLowerCase()] : ''}
          ${!imageSource ? classesApp.skeletonImage : ''}` }
      >
        {
          props.newContent && props.type !== EContentType.STUDY && <PostcardBadge
            type="secondary"
            text={ t('content.new') }
            offset={ { top: [EContentType.BOOK, EContentType.ARTICLE].includes(props.type) ? '18px' : '28px' } }
          />
        }
        { props.type === EContentType.VIDEO ? (
          <div className={ classes.videoIcon }>
            <img src={ playSvg } alt={ 'play' } width={ 59 } height={ 59 } />
          </div>
        ) : null }
      </CardMedia>
      { props.title && (
        <Header component={ 'h4' } className={ classes.title }>
          { props.title }
        </Header>
      ) }
    </div>
  );
};
