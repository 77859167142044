import React, { CSSProperties, FC, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useHistory, useParams } from 'react-router';

import { ContainerMenu, IContainerMenu } from 'app/containers/menu.container';
import { EProtocol, IRouteProps } from 'app/models/shared.model';

import { MobileMenu } from 'a1s-tele2-react-ui/src/components/mobile/menu/menu.index';
import { IMobileDropdownItemModalProps } from 'a1s-tele2-react-ui/src/components/mobile/menu/modal/dropdown/menu.modal.dropdown.model';

import { EIcons } from 'a1s-tele2-react-ui/src/model/icons.model';
import { Footer } from 'app/components/footer/footer';
import CircularLoader from 'app/components/loader/loader.circular';

import toastify from 'app/configs/toastify.async';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { stylesApp } from 'app/app';

import debounce from 'lodash/debounce';
import isNaN from 'lodash/isNaN';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import { EMenuItemType } from 'app/models/model.menu';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerContentItem, IContainerContentItemProps } from 'app/containers/container.content.item';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';
import { loginExternal } from 'app/utils/auth.external';

export const stylesLayout = makeStyles((theme: Theme) => createStyles({
  '@global': {
    '.layoutMobileRoot': {
      padding: '0 18px',
      margin: 'auto',
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 45px)'
    }
  },
  appRoot: {
    // height: '100vh',
    width: '100%',
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
    // position: 'fixed'
  },
  contentRoot: {
    // overflowY: 'auto'
  },
  loginIcon: {
    fontSize: 14,
    color: '#fff',
    border: '2px solid',
    padding: '2px 16px',
    borderRadius: 5,
    position: 'absolute',
    left: -44,
    top: 4,
    ['@media (min-width:319px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: 13,
      left: -46,
      top: 4,
      padding: '3px 18px 2px 18px'
    }
  }
}));

interface IMobileLayoutProps extends IRouteProps {
  isVisibleFooter?: boolean;
  containerMenu: IContainerMenu;
  containerUser: IAuthContainerProps;
  containerContentItem: IContainerContentItemProps;
  containerSectionItem: IContainerSectionItemProps;
}

let toggleMenu;

const MobileLayoutFC: FC<IMobileLayoutProps> = props => {
  const { containerContentItem, containerSectionItem, children, isVisibleFooter = true, ...other } = props;

  const [footerHeight, setFooterHeight] = useState(0);
  const [, rerender] = useState<unknown>();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const params = useParams<any>();

  const menuRef = useRef<HTMLHeadElement>();
  const footerRef = useRef<HTMLElement>(null);

  const locationPathname = other && other?.location && other?.location?.pathname;

  const classes = stylesLayout();
  const classesApp = stylesApp();
  const rootStyles: CSSProperties = (() => {
    const menuHeight = get('current.clientHeight', menuRef);
    return {
      minHeight: menuHeight ? window.innerHeight - menuHeight : undefined,
      paddingBottom: footerHeight
    };
  })();

  const { ToastContainer } = toastify;

  const currentCategoryId = useMemo(() => {
    let nCategoryId = +params?.categoryId || 0;
    if (isNaN(nCategoryId) || nCategoryId <= 0) {
      nCategoryId = containerContentItem.section?.subcategory?.categoryId ?? containerSectionItem.subcategory?.categoryId;
    }
    return nCategoryId ?? 0;
  }, [params?.categoryId, containerContentItem.section?.subcategory?.categoryId, containerSectionItem.subcategory?.categoryId]);

  const memoMenuItems = useMemo(() => {
    const items = props.containerMenu.items;
    return (
      items?.length > 0 &&
      items.map(i => ({
        name: i.name,
        isToggle: true,
        isAnchor: i.menuItemType === EMenuItemType.ANCHOR,
        isBlank: i.menuItemType === EMenuItemType.URL,
        isExternal: i.menuItemType === EMenuItemType.URL && i.value &&
          (i.value.includes(EProtocol.HTTP.toLowerCase()) || i.value.includes(EProtocol.HTTP.toLowerCase())),
        mainLink: i.menuItemType === EMenuItemType.ANCHOR ? `/#${i.value}` : i.value,
        onToggleMenuItemBefore: e => {
          if (i.menuItemType === EMenuItemType.ANCHOR && +i.value === currentCategoryId) {
            e?.preventDefault();
          }
        }
      }) as IMobileDropdownItemModalProps)
    );
  }, [props.containerMenu.items, currentCategoryId]);

  const toggleMenuCallback = t => toggleMenu = t;

  useEffect(() => {
    const anchor = props.location && props.location.hash;
    if (anchor && anchor.match('#')) {
      const domElement = document.getElementById(anchor.replace('#', ''));
      if (domElement) {
        domElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, [props.location && props.location.hash, props.location && props.location.key]);

  useEffect(() => {
    props.containerMenu.actions.itemsFetch();
  }, []);

  useEffect(() => {
    const setHeight = debounce(() => {
      const footerEl = footerRef.current;
      if (footerEl) {
        setFooterHeight(footerEl.clientHeight);
      }
      rerender(performance.now());
    }, 200);
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return (
    <div className={ classes.appRoot }>
      <ToastContainer style={ { width: '50%' } } position={ 'bottom-center' }/>
      <MobileMenu
        logoText={ { text: 'ДИЕТА', fontSize: 22 } }
        logoRedirectUrl={ '/' }
        itemsToolbar={ [
          {
            name: 'profile',
            icon: props.containerUser.authenticated
              ? EIcons['user-drop']
              : <span className={ classes.loginIcon }>Войти</span>,
            iconSize: '27',
            link: props.containerUser && props.containerUser.authenticated
              ? '/profile'
              : () => loginExternal(locationPathname || lastLocation && lastLocation.pathname || '/')
          }
        ] }
        itemsNavbar={ [] }
        itemsFootBar={ [] }
        itemsModal={ memoMenuItems }
        shouldCloseDropdowns={ false }
        toggleMenuCallback={ toggleMenuCallback }
        theme={ 'dark' }
      />
      <div className={ classes.contentRoot }>
        <div className="layoutMobileRoot" style={ rootStyles }>
          <Suspense fallback={ <div className={ classesApp.suspendLoader }><CircularLoader/></div> }>
            { children }
          </Suspense>
          { isVisibleFooter && <Footer ref={ footerRef }/> }
        </div>
      </div>
    </div>
  );
};

export const MobileLayout = flow([ContainerUser, ContainerMenu, ContainerContentItem, ContainerSectionItem])(MobileLayoutFC);
