import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';

import { ELoadStatus } from 'app/models/shared.model';
import { IContentHtml5 } from 'app/models/model.content';
import { CONTENT_ITEM_HTML5_ACTIONS } from 'app/entities/content/html5/html5.actions';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  item: {} as IContentHtml5,
  status: {
    item: ELoadStatus.not_ready
  },
  error: {
    item: null as Error
  }
};

export type IContentHTML5State = Readonly<typeof initialState>;

export default (state = initialState, action): IContentHTML5State => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(CONTENT_ITEM_HTML5_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.loading),
        set('error.item', initialState.error.item)
      ])(state);
    case SUCCESS(CONTENT_ITEM_HTML5_ACTIONS.FETCH_ITEM):
      return flow([
        set('item', payload.data),
        set('status.item', ELoadStatus.ready)
      ])(state);
    case FAILURE(CONTENT_ITEM_HTML5_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.error),
        set('error.item', new Error('item fetch failed'))
      ])(state);
    case CONTENT_ITEM_HTML5_ACTIONS.RESET_ITEM:
      return flow([
        set('item', initialState.item),
        set('error.item', initialState.error.item),
        set('status.item', initialState.status.item)
      ])(state);
    default:
      return state;
  }
};
