import { makeStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { TypographyType, ThemePalette, TextColor, TextSize } from './typography.models';

export const useTypographyRootStyles = makeStyles(
  {
    root: { margin: 0, fontFamily: 'Tele2 Text', fontWeight: 400 },
    serif: { fontFamily: 'Tele2 Display' },
    strong: { fontWeight: 700 },
    themed: {
      [`&$${ThemePalette.dark}`]: { color: configTheme.colors.text.light.default },
      [`&$${ThemePalette.dark}$${TextColor.secondary}`]: { color: configTheme.colors.text.light.neutral },
      [`&$${ThemePalette.light}`]: { color: configTheme.colors.text.dark.default },
      [`&$${ThemePalette.light}$${TextColor.secondary}`]: { color: configTheme.colors.text.dark.neutral }
    },
    p: { marginBottom: 10 },
    [ThemePalette.dark]: {},
    [ThemePalette.light]: {},
    [TextColor.secondary]: {}
  },
  { name: 'Typography' }
);

export const useTypographyHeadingStyles = makeStyles(
  {
    h1: {
      fontSize: '38px',
      lineHeight: '42px',
      '&$mobile': {
        fontSize: '28px',
        lineHeight: '34px'
      }
    },
    h2: {
      fontSize: '28px',
      lineHeight: '34px',
      '&$mobile': {
        fontSize: '24px',
        lineHeight: '28px'
      }
    },
    h3: {
      fontSize: '22px',
      lineHeight: '26px'
    },
    h4: {
      fontSize: '22px',
      lineHeight: '26px',
      '&$mobile': {
        fontSize: '20px',
        lineHeight: '26px'
      }
    },
    h5: {
      fontSize: '16px',
      lineHeight: '22px',
      '&$mobile': {
        fontSize: '16px',
        lineHeight: '20px'
      }
    },
    mobile: {}
  },
  { name: 'Typography' }
);

export const useTypographyTextStyles = makeStyles(
  {
    [`${TypographyType.text}-${TextSize.default}`]: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    [`${TypographyType.text}-${TextSize.tiny}`]: {
      fontSize: '13px',
      lineHeight: '18px'
    },
    [`${TypographyType.text}-${TextSize.small}`]: {
      fontSize: '14px',
      lineHeight: '18px'
    },
    [`${TypographyType.text}-${TextSize.large}`]: {
      fontSize: '22px',
      lineHeight: '28px'
    }
  },
  { name: 'Typography' }
);
