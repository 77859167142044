import isPromise from 'a1s-essence-utils-ts/fp/isPromise';
import toastify from 'app/configs/toastify.async';

import i18n from 'i18next';
import { networkModal } from 'app/entities/modals/modals.actions';

const addErrorAlert = (message, key?) => {
  const { toast } = toastify;
  key = key ? i18n.t(key) : message;
  toast.error(key);
};

export default () => next => action => {
  if (!isPromise(action.payload)) {
    return next(action);
  }
  const { toast } = toastify;
  return next(action)
    .then(response => {
      if (action.message && action.message.success) {
        toast.success(action.message.success);
      } else if (response && response.action && response.action.payload && response.action.payload.headers) {
        const headers = response.action.payload.headers;
        let alert: string = null;
        Object.entries(headers).forEach(([k, v]: [string, string]) => {
          if (k.toLowerCase().endsWith('app-alert')) {
            alert = v;
          }
        });
        if (alert) {
          toast.success(alert);
        }
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      switch (error.response && error.response.status) {
        default:
          return Promise.reject(error);
      }
      // if (action.message && action.message.error) {
      //   toast.error(action.message.error);
      // } else if (error && error.response) {
      //   const response = error.response;
      //   const data = response.data;
      //   if (!(response.status === 401)) {
      //     let i;
      //     switch (response.status) {
      //       // connection refused, server not reachable
      //       case 0:
      //         addErrorAlert('Server not reachable', 'error.server.not.reachable');
      //         break;
      //
      //       case 400:
      //         const headers = Object.entries(response.headers);
      //         let errorHeader = null;
      //         headers.forEach(([k, v]: [string, string]) => {
      //           if (k.toLowerCase().endsWith('app-error')) {
      //             errorHeader = v;
      //           }
      //         });
      //         if (errorHeader) {
      //           addErrorAlert(errorHeader, errorHeader);
      //         } else if (data !== '' && data.fieldErrors) {
      //           const fieldErrors = data.fieldErrors;
      //           for (i = 0; i < fieldErrors.length; i++) {
      //             const fieldError = fieldErrors[i];
      //             if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
      //               fieldError.message = 'Size';
      //             }
      //             // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
      //             const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
      //             const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
      //             addErrorAlert(`Error on field "${fieldName}"`, `error.${fieldError.message}`);
      //           }
      //         } else if (data !== '' && data.error) {
      //           addErrorAlert(data.error, data.error);
      //         } else if (data !== '' && data.message) {
      //           addErrorAlert(data.message, data.message);
      //         } else {
      //           addErrorAlert(data);
      //         }
      //         break;
      //
      //       case 404:
      //         addErrorAlert('Not found', 'error.url.not.found');
      //         break;
      //
      //       default:
      //         if (data !== '' && data.message) {
      //           addErrorAlert(data.message);
      //         } else {
      //           addErrorAlert(data);
      //         }
      //     }
      //   } else {
      //     // logOutAndWarn(dispatch);
      //   }
      // } else if (error && error.message) {
      //   toast.error(error.message);
      // } else {
      //   toast.error('Unknown error!');
      // }
      // return Promise.reject(error);
    });
};
