import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { SUBCATEGORY_ACTIONS } from 'app/entities/categories/subcategory/entity.subcategory.actions';

import { ISubcategory } from 'app/models/model.subcategory';
import { ELoadStatus } from 'app/models/shared.model';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  items: [] as ISubcategory[],
  item: {} as ISubcategory,
  firstSectionId: null,
  status: {
    items: ELoadStatus.not_ready,
    item: ELoadStatus.not_ready
  },
  error: {
    items: null as Error,
    item: null as Error
  }
};

export type ISubcategoryState = Readonly<typeof initialState>;

export default (state = initialState, action): ISubcategoryState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(SUBCATEGORY_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.loading),
        set('errors.items', initialState.error.items)
      ])(state);
    case SUCCESS(SUBCATEGORY_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.ready),
        set('items', payload.data.categories)
      ])(state);
    case FAILURE(SUBCATEGORY_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.error),
        set('error.items', new Error('menu list fetch failed'))
      ])(state);
    case REQUEST(SUBCATEGORY_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.loading),
        set('error.item', initialState.error.item)
      ])(state);
    case SUCCESS(SUBCATEGORY_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.ready),
        set('item', payload.data.subcategory.item),
        set('firstSectionId', payload.data.firstSectionId)
      ])(state);
    case FAILURE(SUBCATEGORY_ACTIONS.FETCH_ITEM):
      return flow([
        set('status.item', ELoadStatus.error),
        set('error.item', new Error('subcategory item fetch failed')),
        set('firstSectionId', null)
      ])(state);
    case SUBCATEGORY_ACTIONS.RESET_ITEM:
      return flow([
        set('status.item', ELoadStatus.not_ready)
      ])(state);
    default:
      return state;
  }
};
