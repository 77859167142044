import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IModalProps, Modal } from 'app/components/modal/modal.ui';
import { Button, IButtonProps } from 'app/components/button/button.ui';
import { useModalPackStyles } from 'app/components/modal/modal.styles';

import { ESubscribeStatus, ESubscriptionStatus } from 'app/models/model.subscription';

export interface IModalButton extends IButtonProps {
  title?: string;
}

export interface IModalNotificationProps extends IModalProps {
  name?: string;
  type?: ESubscribeStatus | ESubscriptionStatus;
}

export const ModalNotification: FC<IModalNotificationProps> = props => {
  const { mobile, type, onClose } = props;
  const { t } = useTranslation();
  const styles = useModalPackStyles({ mobile });

  return (
    <Modal
      { ...props }
      title={ <span className={ styles.title } dangerouslySetInnerHTML={ { __html: t(`dialog.subscribe.${type && type.toLowerCase()}.header`) } } /> }
      mobile={ mobile }
      withClose
      onClose={ onClose }
    >
      <span
        style={ { paddingRight: mobile ? 20 : 0, marginBottom: 36 } }
        className={ styles.text }
        dangerouslySetInnerHTML={ {
          __html: t(`dialog.subscribe.${type && type.toLowerCase()}.text`, { name: props.name })
        } }
      />

      <Button onClick={ onClose } className={ styles.notificationButton } mobile={ mobile }>
        { t(`dialog.subscribe.${type && type.toLowerCase()}.close`) }
      </Button>

    </Modal>
  );
};
