import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import classnames from 'classnames';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import { isMobile } from 'mobile-device-detect';
import 'skeleton-screen-css';
import { useLastLocation } from 'react-router-last-location';

import AudioPlayer from 'material-ui-audio-player';
import { Grid } from '@material-ui/core';

import { stylesApp } from 'app/app';
import { loginExternal } from 'app/utils/auth.external';

import { ISectionListContainerProps, SectionListContainer } from 'app/containers/section.list.container';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';
import { ContainerContentList, IContainerContentListProps } from 'app/containers/container.content.list';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerLike, IContainerLikeProps } from 'app/containers/like.container';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';

import { ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { EContentType, IContent } from 'app/models/model.content';
import { ESubscribeStatus, ESubscriptionStatus } from 'app/models/model.subscription';

import { ContentHeader } from 'app/components/header/content.header';
import { ImageCarousel } from 'app/components/carousel/carousel.image';
import { Header } from 'app/components/header/header.ui';

import { MobileNavbarMenu } from 'a1s-tele2-react-ui/src/components/mobile/menu/navbar/menu.navbar.index';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';
import { Likes } from 'a1s-tele2-react-ui/src/components/shared/likes/likes.index';

import { stylesNavbar } from 'app/components/navbar/styles.navbar';
import CircularLoader from 'app/components/loader/loader.circular';
import { ContentImage, stylesContentImage } from 'app/components/image/content.image';
import { Button } from 'app/components/button/button.ui';

import get from 'lodash/get';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';

import { ModalNotification } from 'app/components/modal/modal.notification';
import { stylesContentAudio, usePlayerStyles } from 'app/pages/mobile/content/item/audio/content.item.audio';
import { numberRegex } from 'app/configs/const';
import { PlugContentLoader } from 'app/components/loader/loader.plug';
import { CONTENT_ITEM } from 'app/configs/route.names';
import includes from 'lodash/includes';
import subscribeCheck from 'app/utils/util.subscribe.check';
import { ILikeItem } from 'app/entities/like/like.model';

export interface IPageContentList extends IRouteProps {
  containerContentList: IContainerContentListProps;
  containerSectionItem: IContainerSectionItemProps;
  sectionListContainer: ISectionListContainerProps;
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  containerCategory: ICategoryContainerProps;
  containerLike: IContainerLikeProps;
  modalsContainer: IModalsContainer;
}

let defaultSectionOffset = 0;
let defaultContentOffset = 10;

const cookies = new Cookies();

// tslint:disable-next-line:cyclomatic-complexity
const ContentList: FC<IPageContentList> = props => {
  const { t } = useTranslation();
  const titleEllipsisStyles = useEllipsisStyles({ lineClamp: 3 });
  const titleEllipsisLineTwoStyles = useEllipsisStyles({ lineClamp: 2 });
  const [subscribeOpenModal, setSubscribeOpenModal] = useState<boolean>(false);
  const [errorSubscribeOpenModal, setErrorSubscribeOpenModal] = useState<boolean>(false);

  const [playedAudioId, setPlayedAudioId] = useState<string>();
  const refAudioContainer = useRef<HTMLDivElement>();

  const [categoryId, setCategoryId] = useState<ICategoryContainerProps['item']['id']>();
  const [sectionId, setSectionId] = useState<IContainerSectionItemProps['item']['id']>();
  const [initialSection, setInitialSection] = useState<IContainerSectionItemProps['item']>();
  const [contentList, setContentList] = useState<{ sectionId?: string; subSectionId?: string; list?: IContent[] }>({});
  const [currentSubsection, setCurrentSubsection] = useState<IContainerSectionItemProps['subsections'][0]>();
  const [navbarSectionItems, setNavbarSectionItems] = useState<ISectionListContainerProps['list'][0]['items']>();

  const history = useHistory();
  const lastLocation = useLastLocation();

  const propsAuth = props.containerUser;
  const propsContentType = get(props.containerSectionItem, 'contentType');
  const propsCategory = props.containerCategory;
  const propsContentList = props.containerContentList;
  const propsSectionItem = props.containerSectionItem;
  const propsSectionCurItem = get(propsSectionItem, 'item') as IContainerSectionItemProps['item'];
  const propsSectionCurSubscribe = get(propsSectionItem, 'subscribe') as IContainerSectionItemProps['subscribe'];
  const propsSectionList = props.sectionListContainer;
  const propsSectionListItems = get(propsSectionList, 'list[0].items') as ISectionListContainerProps['list'][0]['items'];
  const propsSectionListExist = propsSectionList && propsSectionList.list?.length > 0;
  const propsSubscribeError =
    (get(props.containerSubscribe, 'error.change') && get(props.containerSubscribe, 'error.change') === ESubscribeStatus.FAIL) ||
    get(props.containerSubscribe, 'error.change') === ESubscribeStatus.NO_MONEY;
  const subscribe = props.containerSubscribe.subscriptions.find(sub => sub?.id === propsSectionCurSubscribe?.id);
  const isSubscribeActive = subscribe && [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY].includes(subscribe?.status);

  const contentHeaderTitle = get(propsCategory, 'item.name');
  const isSectionItemSubcategoriesExists = props.containerSectionItem.subsections?.length > 0 && propsContentType !== EContentType.OLYMPIAD;
  const nDefaultContentListLimit = 10;

  const classesApp = stylesApp();
  const classesNavbar = stylesNavbar();
  const classesContentImg = stylesContentImage();
  const classesContentAudio = stylesContentAudio();

  const refContainer = useRef<HTMLDivElement>();

  const fetchContentListFromCategory = id => {
    const curId = Number(id);
    propsSectionList.actions.fetchSectionItems({ pager: { limit: nDefaultContentListLimit, offset: 0 }, id: curId }).then(res => {
      setInitialSection(get(res, 'value.data.sections[0]'));
      setNavbarSectionItems(get(res, 'value.data.sections'));
      history.push({ pathname: get(res, 'value.data.sections[0].id') });
    });
  };
  const fetchContentListFromSection = id => {
    if (Number(id) === Number(sectionId)) return;
    defaultContentOffset = 0;
    propsContentList.actions.resetContentList();
    setContentList({});

    history.push(id);
  };
  const fetchContentListFromSubsection = id => {
    if (currentSubsection?.id && Number(id) === Number(currentSubsection.id)) return;
    defaultContentOffset = 0;
    const currentSub = propsSectionItem.subsections.find(i => i.id.toString() === id);
    propsContentList.actions.resetContentList();
    currentSub && setCurrentSubsection(currentSub);
    setContentList({});
    let nLimit;
    propsContentType === EContentType.STUDY
      ? (nLimit = 1000)
      : (nLimit = currentSubsection.allowedSectionContent);
    if (currentSub) {
      props.containerContentList.actions.fetchContentList({
        pager: { limit: nLimit, offset: defaultContentOffset },
        id: currentSub.id,
        isSub: true
      });
      props.containerContentList.actions.logView();
    }
  };
  const fetchContentListFromMore = () => {
    if (currentSubsection && currentSubsection.id) {
      let nLimit;
      propsContentType === EContentType.STUDY
        ? (nLimit = 1000)
        : (nLimit = currentSubsection.allowedSectionContent);
      defaultContentOffset += currentSubsection.allowedSectionContent;
      props.containerContentList.actions.fetchContentList({
        pager: { limit: nLimit, offset: defaultContentOffset },
        id: currentSubsection.id,
        isSub: true
      });
      props.containerContentList.actions.logView();
    } else {
      if (propsSectionItem.item) {
        let nLimit;
        propsContentType === EContentType.STUDY ? (nLimit = 1000) : (nLimit = 10);
        defaultContentOffset += propsSectionItem.item.allowedSectionContent;
        props.containerContentList.actions.fetchContentList({
          pager: { limit: nLimit, offset: defaultContentOffset },
          id: propsSectionItem.item.id
        });
        props.containerContentList.actions.logView();
      }
    }
  };

  const callNavbarSectionScroll = () => {
    if (get(propsSectionList, 'list[0].more')) {
      defaultSectionOffset += 10;
      propsSectionList.actions.fetchSectionItems({
        pager: { limit: nDefaultContentListLimit, offset: defaultSectionOffset },
        id: propsSectionItem.subcategory.id
      });
    }
  };

  const memoSectionList = useMemo(() => {
    if (propsSectionListExist && initialSection && initialSection.id) {
      return unionBy([{ ...initialSection, link: initialSection.id.toString() }], navbarSectionItems, 'id');
    }
  }, [initialSection, navbarSectionItems, propsContentType]);

  const isMemoSectionListExists = memoSectionList?.length > 0 && propsContentType !== EContentType.OLYMPIAD;

  const memoRecommendsList = useMemo(() => {
    if (navbarSectionItems && navbarSectionItems?.length > 0 && propsSectionCurItem) {
      return navbarSectionItems.filter((i: IContainerSectionItemProps['item']) => i.id !== propsSectionCurItem.id);
    }
  }, [propsSectionCurItem, navbarSectionItems, sectionId]);

  useEffect(() => {
    propsSectionListItems &&
      propsSectionListItems?.length > 0 &&
      setNavbarSectionItems(unionBy(navbarSectionItems, propsSectionListItems, 'id'));
  }, [propsSectionListItems]);

  useEffect(() => {
    if (props.computedMatch.params) {
      setSectionId(Number(props.computedMatch.params.sectionId));
      setCategoryId(Number(props.computedMatch.params.categoryId));
    }
  }, [props.computedMatch.params]);

  useEffect(() => {
    if (sectionId) {
      propsSectionItem.actions.fetchSectionItem(sectionId);
      setCurrentSubsection({} as IContainerSectionItemProps['subsections'][0]);
    }
  }, [sectionId]);

  useEffect(() => {
    if (categoryId) {
      if (!numberRegex.test(props.computedMatch.params.sectionId)) {
        history.replace('/error');
      }
      propsCategory.actions.fetchCategoryItem(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    const curSubsection =
      propsSectionItem && propsSectionItem.subsections && propsSectionItem.subsections?.length > 0 && propsSectionItem.subsections[0];
    isEmpty(initialSection) && setInitialSection(propsSectionCurItem);
    history.location.pathname !== '' &&
      propsSectionItem &&
      cookies.set(`contentItemPrevRoute${propsSectionItem.contentType}`, history.location.pathname, { path: '/' });
    if (props.location.search === '?login=success') {
      if (subscribe.status === ESubscriptionStatus.NOT_ACTIVE) {
        setSubscribeOpenModal(true);
        history.push(history.location.pathname);
      }
      if (subscribe.status === ESubscriptionStatus.NO_MONEY) {
        setErrorSubscribeOpenModal(true);
        history.push(history.location.pathname);
      }
    }

    if (curSubsection && curSubsection.id) {
      setContentList({ subSectionId: curSubsection.id.toString(), list: [] });
      props.containerContentList.actions.resetContentList();
      setCurrentSubsection(curSubsection);
      let nLimit;
      // tslint:disable-next-line: prefer-conditional-expression
      if (propsContentType === EContentType.STUDY) nLimit = 1000;
      else nLimit = curSubsection.allowedSectionContent ?? nDefaultContentListLimit;
      props.containerContentList.actions.fetchContentList({
        pager: { limit: nLimit, offset: 0 },
        id: curSubsection.id,
        isSub: true
      });
      props.containerContentList.actions.logView();
    } else {
      setContentList({ sectionId: get(propsSectionItem, 'item.id'), list: [] });
      props.containerContentList.actions.resetContentList();
      let nLimit;
      // tslint:disable-next-line: prefer-conditional-expression
      if (propsContentType === EContentType.STUDY) nLimit = 1000;
      else nLimit = get(propsSectionItem, 'item.allowedSectionContent') ?? nDefaultContentListLimit;
      if (sectionId) {
        props.containerContentList.actions.fetchContentList({
          pager: { limit: nLimit, offset: 0 },
          id: sectionId
        });
        props.containerContentList.actions.logView();
      }
    }
  }, [propsSectionCurItem]);

  useEffect(() => {
    propsSectionItem &&
      propsSectionItem.subcategory &&
      propsSectionItem.subcategory.id &&
      propsSectionList.actions.fetchSectionItems({
        pager: { limit: nDefaultContentListLimit, offset: 0 },
        id: propsSectionItem.subcategory.id
      });
  }, [propsSectionItem && propsSectionItem.subcategory && propsSectionItem.subcategory.id]);

  useEffect(() => {
    const isItemsExist = propsContentList && propsContentList.items && propsContentList.items?.length > 0;
    if (isItemsExist) {
      setContentList({
        sectionId: propsContentList.sectionId,
        list:
          isItemsExist && propsContentList.sectionId === contentList.sectionId
            ? // @ts-ignore
              [...contentList.list, ...propsContentList.items.filter(pli => contentList.list.every(li => li.id !== pli.id) && pli)]
            : propsContentList.items
      });
    }
  }, [propsContentList.items]);

  useEffect(() => {
    const isSubItemsExist = propsContentList.subItems && propsContentList.subItems?.length > 0;
    if (isSubItemsExist) {
      setContentList({
        subSectionId: propsContentList.subsectionId,
        list:
          isSubItemsExist && propsContentList.subsectionId === contentList.subSectionId
            ? [...contentList.list, ...propsContentList.subItems.filter(pli => contentList.list.every(li => li.id !== pli.id) && pli)]
            : propsContentList.subItems
      });
    }
  }, [propsContentList.subItems]);

  useMemo(() => {
    props.containerContentList.actions.updateRoteList(
      lastLocation && (lastLocation.pathname === '/' || lastLocation.pathname.includes('section')) ? lastLocation.pathname : '/'
    );
  }, [lastLocation]);

  useEffect(() => {
    if (lastLocation) {
      (lastLocation.pathname === '/' || lastLocation.pathname.includes('section')) &&
        props.containerContentList.actions.updateRoteList(lastLocation.pathname);
      cookies.set(`contentListPrevRoute`, lastLocation.pathname, { path: '/' });
    } else {
      if (
        (cookies.get(`contentListPrevRoute`) !== undefined && cookies.get(`contentListPrevRoute`).includes('section')) ||
        (cookies.get(`contentListPrevRoute`) !== undefined && cookies.get(`contentListPrevRoute`).includes('/'))
      ) {
        props.containerContentList.actions.updateRoteList(cookies.get(`contentListPrevRoute`) || '/');
        cookies.set(`contentListPrevRoute`, '', { path: '/' });
      } else {
        props.containerContentList.actions.updateRoteList('/');
        cookies.set(`contentListPrevRoute`, '', { path: '/' });
      }
    }
  }, [props.history, lastLocation]);

  useEffect(() => {
    defaultContentOffset = 0;
    setContentList({});
    setNavbarSectionItems([] as ISectionListContainerProps['list'][0]['items']);
    setInitialSection({} as IContainerSectionItemProps['item']);
    props.containerContentList.actions.resetContentList();
    props.sectionListContainer.actions.resetSectionItems();

    return () => {
      defaultContentOffset = 0;
      props.containerContentList.actions.resetContentList();
      propsSectionList.actions.resetSectionItems();
    };
  }, []);

  useEffect(() => {
    document.querySelector('footer').style.opacity = contentList.list?.length ? '1' : '0';
  }, [contentList.list]);

  const playerIcons = (p, id) => {
    const onClick = () => {
      const prevAudioElement = playedAudioId && (document.querySelector(`div#${playedAudioId} audio`) as HTMLAudioElement);
      const curAudioElement = id && (document.querySelector(`div#${id} audio`) as HTMLAudioElement);

      if (id !== playedAudioId) {
        setPlayedAudioId(id);
        if (prevAudioElement) {
          prevAudioElement.pause();
        }
        curAudioElement.pause();
        curAudioElement.play();
        setTimeout(() => {
          curAudioElement.pause();
          curAudioElement.play();
        }, 500);
      } else {
        setPlayedAudioId(null);
        curAudioElement.pause();
      }
    };
    return <div onClick={ onClick }>{ id === playedAudioId ? <MdPause /> : <MdPlayArrow /> }</div>;
  };

  useEffect(() => {
    propsContentList.actions.getItemLikes(propsContentList.items);
  }, [propsContentList.items]);

  const indicator = (free: boolean) => {
    if (!free) {
      if (isSubscribeActive) {
        return (
          <IconText
            align="left"
            color="default"
            icon="success-large"
            iconOnly={ false }
            text={ t('content.subscribed') }
            size={ isMobile ? 'small' : 'default' }
            className={ classesContentImg.contentIndicator }
          />
        );
      } else {
        return (
          <IconText
            align="left"
            color="default"
            icon="balance"
            iconOnly={ false }
            text={ t('content.bySubscription') }
            size={ isMobile ? 'small' : 'default' }
          />
        );
      }
    } else {
      return (
        <>
          <IconText text={ t('content.free') } size={ isMobile ? 'small' : 'default' } />
        </>
      );
    }
  };

  const handleLike = useCallback((cid: ILikeItem['contentId'], liked: boolean) => {
    if (liked) props.containerLike.actions.createLikeItem(cid);
    else props.containerLike.actions.removeLikeItem(cid);
  }, []);

  const handleDecline = useCallback(() => {
    loginExternal(history.location.pathname);
  }, []);

  const validateSubscribe = callback => {
    subscribeCheck({
      subscribe,
      callback,
      containerUser: propsAuth,
      containerModals: props.modalsContainer,
      history
    });
  };

  const onContentClick = contentItem => {
    contentItem?.free || includes([EContentType.STUDY, EContentType.OLYMPIAD, EContentType.BOOK], propsContentType)
      ? history.push(CONTENT_ITEM(contentItem?.type, contentItem?.id))
      : validateSubscribe(() => history.push(CONTENT_ITEM(contentItem?.type, contentItem?.id)));
  };

  return (
    <div ref={ refContainer }>
      <ContentHeader isClose closeRoute={ propsContentList.route }>
        { contentHeaderTitle }
      </ContentHeader>
      <div className={ classesApp.content }>
        { propsCategory.item.subcategories && propsCategory.item.subcategories?.length > 1 ? (
          <MobileNavbarMenu
            classesNavbar={ classesNavbar.navbar }
            onClickItem={ fetchContentListFromCategory }
            navMenuItems={ propsCategory.item.subcategories.map(si => (si = { ...si, link: si.id.toString() })) }
            activeItemId={ get(propsSectionItem, 'subcategory.id') }
          />
        ) : null }
        <div className={ classesNavbar.wrap }>
          { isMemoSectionListExists && memoSectionList?.length > 1 && propsContentType !== EContentType.STUDY ? (
            <MobileNavbarMenu
              classesNavbar={ propsCategory.item.subcategories?.length > 1 ? classesNavbar.subnavbar : classesNavbar.navbar }
              onClickItem={ fetchContentListFromSection }
              exactCustom={ false }
              navMenuItems={ memoSectionList?.length === 1 ? null : memoSectionList }
              activeItemId={ sectionId }
              scrollCallback={ callNavbarSectionScroll }
            />
          ) : null }
        </div>
        { isSectionItemSubcategoriesExists ? (
          <MobileNavbarMenu
            classesNavbar={ classesNavbar.subnavbar }
            exactCustom={ isSectionItemSubcategoriesExists && isMemoSectionListExists }
            onClickItem={ fetchContentListFromSubsection }
            navMenuItems={ props.containerSectionItem.subsections.map(si => (si = { ...si, link: si.id.toString() })) }
            activeItemId={ currentSubsection?.id }
          />
        ) : null }
        { props.containerContentList.status.items === ELoadStatus.loading ? (
          <div className={ classesApp.suspendLoader }>
            <CircularLoader />
          </div>
        ) : null }
        { propsContentType === EContentType.AUDIO ? (
          <div className={ classesApp.contentWrap }>
            { get(propsSectionItem, 'item.preview') ? (
              <div className={ classesApp.imgPreviewCircleWrap }>
                <img className={ classesApp.imgPreviewCircle } src={ get(propsSectionItem, 'item.preview') } />
              </div>
            ) : null }
            <Header className={ `${classesApp.mb36} ${classesApp.serif}` } center component={ 'h4' }>
              { get(propsSectionItem, 'item.name') }
            </Header>
            <div ref={ refAudioContainer }>
              { propsSectionItem.contentType && contentList && contentList && contentList.list && contentList.list?.length > 0
                ? contentList.list.map(cii => (
                    <div className={ classesContentAudio.wrapItem } key={ cii.id }>
                      { cii.name && (
                        <Header component={ 'h5' } className={ classesContentAudio.title }>
                          { cii.name }
                        </Header>
                      ) }
                      { get(cii, 'data.artist') && (
                        <div className={ `${classesContentAudio.titleDesc} ${classesApp.contentItemTitleDesc}` }>{ cii.data.artist }</div>
                      ) }
                      <div id={ cii.id as string }>
                        <AudioPlayer
                          key={ `${cii.id}` }
                          order={ 'reverse' }
                          download={ false }
                          loop={ false }
                          src={ cii.url }
                          useStyles={ usePlayerStyles }
                          icons={ {
                            PlayIcon: p => playerIcons(p, cii.id),
                            PauseIcon: p => playerIcons(p, cii.id)
                          } }
                        />
                      </div>
                    </div>
                  ))
                : null }
            </div>
          </div>
        ) : null }
        { propsContentType === EContentType.IMAGE ||
        propsContentType === EContentType.STUDY ||
        propsContentType === EContentType.OLYMPIAD ? (
          <div className={ classesApp.contentWrap }>
            { propsSectionItem.contentType && contentList && contentList && contentList.list && contentList.list?.length > 0 ? (
              contentList.list.map(si => (
                <React.Fragment key={ si.id }>
                  <Link className={ `${classesApp.linkDNone}` } to={ `/content/${propsSectionItem.contentType.toLowerCase()}/${si.id}` }>
                    <ContentImage
                      classesWrap={ classesContentImg.contentImgWrap }
                      key={ si.id }
                      src={ si.imageUrl || si.url }
                      newContent={ propsContentType !== EContentType.STUDY && si.new }
                      type={ propsSectionItem.contentType }
                    />
                  </Link>
                  <div className={ classesApp.between }>
                    { si.name && (
                      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <Header
                          component={ 'h4' }
                          className={ classnames(titleEllipsisLineTwoStyles[EEllipsisClassKey.multiline], classesContentImg.title) }
                          onClick={ () => history.push(CONTENT_ITEM(si?.type, si?.id)) }
                        >
                          { si.name }
                        </Header>
                        { propsContentType === EContentType.STUDY ? null : (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            style={ { marginTop: '1px' } }
                          >
                            <div>{ indicator(si.free) }</div>
                            <div
                              style={ { display: 'flex' } }
                            >
                              <Likes
                                count={ Number(propsContentList.itemLikes.find(el => el.contentId === si.id)?.count) || 0 }
                                liked={ propsContentList.itemLikes.find(el => el.contentId === si.id)?.active }
                                declined={ !propsAuth.authenticated }
                                onClick={ (e, b) => handleLike(si.id, b) }
                                onDecline={ handleDecline }
                              />
                            </div>
                          </Grid>
                        ) }
                      </Grid>
                    ) }
                  </div>
                </React.Fragment>
              ))
            ) : (
              <PlugContentLoader amount={ 5 } />
            ) }
          </div>
        ) : null }
        { propsContentType === EContentType.VIDEO ? (
          <div className={ classesApp.contentWrap }>
            { propsSectionItem.contentType && contentList && contentList && contentList.list && contentList.list?.length > 0 ? (
              contentList.list.map(si => (
                <React.Fragment key={ si.id }>
                  <ContentImage
                    classesWrap={ classesContentImg.contentImgWrap }
                    key={ si.id }
                    src={ si.imageUrl || si.url }
                    newContent={ si.new }
                    type={ propsSectionItem.contentType }
                    onClick={ () => onContentClick(si) }
                  />
                  <div className={ classesApp.between }>
                    { si.name && (
                      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <Header
                          component={ 'h4' }
                          className={ classnames(titleEllipsisLineTwoStyles[EEllipsisClassKey.multiline], classesContentImg.title) }
                          onClick={ () => onContentClick(si) }
                        >
                          { si.name }
                        </Header>
                        { propsContentType === EContentType.STUDY ? null : (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            style={ { marginTop: '1px' } }
                          >
                            <div>{ indicator(si.free) }</div>
                            <div
                              style={ { display: 'flex' } }
                            >
                              <Likes
                                count={ Number(propsContentList.itemLikes.find(el => el.contentId === si.id)?.count) || 0 }
                                liked={ propsContentList.itemLikes.find(el => el.contentId === si.id)?.active }
                                declined={ !propsAuth.authenticated }
                                onClick={ (e, b) => handleLike(si.id, b) }
                                onDecline={ handleDecline }
                              />
                            </div>
                          </Grid>
                        ) }
                      </Grid>
                    ) }
                  </div>
                </React.Fragment>
              ))
            ) : (
              <PlugContentLoader amount={ 5 } />
            ) }
          </div>
        ) : null }
        { propsContentType === EContentType.ARTICLE ? (
          <div className={ classesApp.contentWrap }>
            { propsSectionItem.contentType && contentList && contentList && contentList.list && contentList.list?.length > 0
              ? contentList.list.map(si => (
                <React.Fragment key={ si.id }>
                  <Grid style={ { display: 'flex', marginBottom: 36 } }>
                    <div style={ { margin: 0 } }>
                      <ContentImage
                        classesWrap={ classesContentImg.articleImg }
                        key={ si.id }
                        src={ si.imageUrl || si.url }
                        newContent={ si.new }
                        type={ propsSectionItem.contentType }
                        onClick={ () => onContentClick(si) }
                      />
                    </div>
                    <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                      <Grid>
                        <div className={ classesApp.between }>
                          <Header
                            component={ 'h6' }
                            className={ classnames(
                              classesContentImg.title,
                              titleEllipsisLineTwoStyles[EEllipsisClassKey.multiline],
                              classesContentImg.articleTitle
                            ) }
                            onClick={ () => onContentClick(si) }
                          >
                            { si.name }
                          </Header>
                        </div>
                        <div
                          className={ classnames(
                            classesApp.descItem,
                            titleEllipsisStyles[EEllipsisClassKey.multiline],
                            classesContentImg.articleDescItem
                          ) }
                        >
                          { si.description }
                        </div>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        style={ { marginTop: '20px' } }
                      >
                        <div>{ indicator(si.free) }</div>
                        <div
                          style={ { display: 'flex' } }
                        >
                          <Likes
                            count={ Number(propsContentList.itemLikes.find(el => el.contentId === si.id)?.count) || 0 }
                            liked={ propsContentList.itemLikes.find(el => el.contentId === si.id)?.active }
                            declined={ !propsAuth.authenticated }
                            onClick={ (e, b) => handleLike(si.id, b) }
                            onDecline={ handleDecline }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
                ))
              : null }
          </div>
        ) : null }

        { propsContentType === EContentType.BOOK ? (
          <div className={ classesApp.contentWrap }>
            { propsSectionItem.contentType && contentList && contentList && contentList.list && contentList.list?.length > 0
              ? contentList.list.map(si => (
                  <React.Fragment key={ si.id }>
                    <Grid style={ { display: 'flex', marginBottom: 36 } }>
                      <Link className={ `${classesApp.linkDNone}` } to={ `/content/${propsSectionItem.contentType.toLowerCase()}/${si.id}` }>
                        <div style={ { margin: 0 } }>
                          <ContentImage
                            classesWrap={ classesContentImg.bookImg }
                            key={ si.id }
                            src={ si.imageUrl || si.url }
                            newContent={ si.new }
                            type={ propsSectionItem.contentType }
                          />
                        </div>
                      </Link>
                      <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
                        <Grid>
                          <Link
                            className={ `${classesApp.linkDNone}` }
                            to={ `/content/${propsSectionItem.contentType.toLowerCase()}/${si.id}` }
                          >
                            <div className={ classesApp.between }>
                              <Header
                                component={ 'h6' }
                                className={ classnames(
                                  classesContentImg.title,
                                  titleEllipsisStyles[EEllipsisClassKey.multiline],
                                  classesContentImg.articleTitle
                                ) }
                              >
                                { si.name }
                              </Header>
                            </div>
                            <div
                              className={ classnames(titleEllipsisStyles[EEllipsisClassKey.multiline], classesContentImg.articleDescItem) }
                            >
                              { si.data?.artist }
                            </div>
                          </Link>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          style={ { marginTop: '20px' } }
                        >
                          <div>{ indicator(si.free) }</div>
                          <div
                            style={ { display: 'flex' } }
                          >
                            <Likes
                              count={ Number(propsContentList.itemLikes.find(el => el.contentId === si.id)?.count) || 0 }
                              liked={ propsContentList.itemLikes.find(el => el.contentId === si.id)?.active }
                              declined={ !propsAuth.authenticated }
                              onClick={ (e, b) => handleLike(si.id, b) }
                              onDecline={ handleDecline }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
              : null }
          </div>
        ) : null }
        { propsContentType === EContentType.GAME_HTML5 || propsContentType === EContentType.GAME_ANDROID ? (
          <div className={ classesApp.contentWrap }>
            { propsSectionItem.contentType && contentList && contentList && contentList.list && contentList.list?.length > 0
              ? contentList.list.map(cii => (
                  <div className={ classesApp.contentItem } key={ cii.id }>
                    <div className={ classesApp.contentItemWrap }>
                      <Link to={ `/content/${propsSectionItem.contentType.toLowerCase()}/${cii.id}` } className={ classesApp.linkDNone }>
                        <div className={ `${classesApp.contentItemThumb} ${(cii.type && cii.type.toLowerCase()) || ''}` }>
                          <img className={ classesApp.contentItemThumbImg } src={ cii.imageUrl } />
                        </div>
                      </Link>
                      <div className={ classesApp.contentItemNameWrap }>
                        <Link to={ `/content/${propsSectionItem.contentType.toLowerCase()}/${cii.id}` } className={ classesApp.linkDNone }>
                          <div className={ classesApp.contentItemTitle }>{ cii.name }</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : null }
          </div>
        ) : null }

        { propsContentList && propsContentList.more ? (
          <div className={ classesApp.contentWrap } style={ { textAlign: 'center' } }>
            <Button onClick={ fetchContentListFromMore } mobile>
              Показать еще
            </Button>
          </div>
        ) : null }

        { propsContentType !== EContentType.STUDY && propsSectionListExist && memoRecommendsList && memoRecommendsList?.length > 0 && (
          <section className={ `${classesApp.section} ${classesApp.even}` }>
            <Typography className={ classesApp.mb27 } type="heading" level={ 2 } tag="div" mobile>
              { t('common.recommend') }
            </Typography>
            <ImageCarousel
              srcKey={ 'preview' }
              titleKey={ 'name' }
              route={ `/${get(propsCategory, 'item.id')}/content/list/` }
              id={ propsSectionCurItem && propsSectionCurItem.id }
              list={ memoRecommendsList && propsSectionCurItem && memoRecommendsList.filter(i => i.id !== propsSectionCurItem.id) }
              isMore={ propsSectionListExist && propsSectionList.list[0] && propsSectionList.list[0].more }
              isLoading={ propsSectionListExist && propsSectionList.list[0].status === ELoadStatus.loading }
              inNotSet
            />
          </section>
        ) }
        { propsContentType === EContentType.BOOK && propsCategory?.item?.subcategories?.length > 0 && (
          <section className={ `${classesApp.section} ${classesApp.even}` }>
            <Typography className={ classesApp.mb27 } type="heading" level={ 2 } tag="div" mobile>
              { t('common.recommend') }
            </Typography>
            <ImageCarousel
              srcKey={ 'preview' }
              titleKey={ 'name' }
              route={ `/${get(propsCategory, 'item.id')}/content/list/` }
              id={ get(propsSectionItem, 'subcategory.id') }
              list={ propsCategory.item.subcategories.filter(i => i.id !== get(propsSectionItem, 'subcategory.id')) }
              callbackLink={ (id, e) => {
                e.preventDefault();
                fetchContentListFromCategory(id);
              } }
              isLoading={ propsSectionListExist && propsSectionList?.list?.[0]?.status === ELoadStatus.loading }
              inNotSet
            />
          </section>
        ) }
      </div>

      <ModalNotification
        open={ errorSubscribeOpenModal || propsSubscribeError }
        onClose={ () => {
          setErrorSubscribeOpenModal(false);
          if (propsSubscribeError) {
            props.containerSubscribe.actions.resetProp('change');
            setSubscribeOpenModal(false);
          }
        } }
        type={
          propsSubscribeError
            ? get(props.containerSubscribe, 'error.change')
            : propsSectionItem.subscribe && propsSectionItem.subscribe.status
        }
        mobile
      />
    </div>
  );
};

export default flow([
  ContainerLike,
  ContainerContentList,
  ContainerSectionItem,
  SectionListContainer,
  CategoryContainer,
  ContainerSubscribe,
  ContainerUser,
  ModalsContainer
])(ContentList);
