//#region imports
import React, { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, useForkRef } from '@material-ui/core';
import { isMobile } from 'mobile-device-detect';
import classnames from 'classnames';

import isFunction from 'lodash/isFunction';

import { IContainerContentListProps, ContainerContentList } from 'app/containers/container.content.list';
import { ImageFallback, EPostcardClassKey, EPostcardLayout } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';
import { Likes } from 'a1s-tele2-react-ui/src/components/shared/likes/likes.index';

import { EContentCardClassKey, ContentCardProps } from './content.card.models';
import { useContentCardStyles } from './content.card.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { useTranslation } from 'react-i18next';
import { ContainerLike, IContainerLikeProps } from 'app/containers/like.container';
import { loginExternal } from 'app/utils/auth.external';
//#endregion

type ContentCardAndLikeProps = ContentCardProps & {
  containerContentList: IContainerContentListProps;
  containerLike: IContainerLikeProps;
  free: boolean;
  itemCardId: string;
  contentItemId?: string;
  authStatus: boolean;
  liked: boolean;
  subscribeStatus: ESubscriptionStatus;
  withIndicator: boolean;
  handleDecline?: () => void;
  handleLike?: (contentId: string, liked: boolean) => void;
};

const ContentCard: FC<ContentCardAndLikeProps> = forwardRef<HTMLDivElement, ContentCardAndLikeProps>((props, ref) => {
  const {
    className,
    classes,
    style,
    origin,
    layout,
    preview,
    extraMiddle,
    extraTop,
    title,
    titleClamp,
    descr,
    free,
    subscribeStatus,
    itemCardId,
    contentItemId,
    liked,
    handleDecline,
    handleLike,
    containerLike,
    authStatus,
    onClick,
    onPictureClick,
    onTitleClick,
    withIndicator
  } = props;
  const isTitleClickable = isFunction(onTitleClick);
  const isPictureClickable = isFunction(onPictureClick);
  const isAllClickable = isFunction(onClick);

  const { t } = useTranslation();
  const ellipsisStyles = useEllipsisStyles({ lineClamp: titleClamp });
  const styles = useContentCardStyles({ classes, origin, mobile: isMobile, isTitleClickable, isPictureClickable, isAllClickable });
  const [isAddLike, setAddLike] = useState(null);

  const history = useHistory();
  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  const indicator = useMemo(() => {
    if (!free) {
      if ([ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY].includes(subscribeStatus)) {
        return (
          <IconText align="left" color="default" icon="success-large" iconOnly={ false } size="default" text={ t('content.subscribed') } />
        );
      } else {
        return <IconText align="left" color="default" icon="balance" iconOnly={ false } size="default" text={ t('content.bySubscription') } />;
      }
    } else {
      return (
        <>
          <IconText text={ t('content.free') } />
        </>
      );
    }
  }, [free, subscribeStatus]);

  return (
    <div ref={ handleRef } className={ classnames(className, styles[EContentCardClassKey.root]) } style={ style }>
      <Postcard
        classes={ {
          [EPostcardClassKey.root]: styles[EContentCardClassKey.inner],
          [EPostcardClassKey.descr]: styles[EContentCardClassKey.descr],
          [EPostcardClassKey.picture]: styles[EContentCardClassKey.picture],
          [EPostcardClassKey.pictureFigure]: styles[EContentCardClassKey.pictureFigure]
        } }
        layout={ layout }
        width={ preview?.width ?? 251 }
        height={ preview?.height ?? 251 }
        src={ preview?.src }
        fallback={ ImageFallback }
        extraMiddle={ extraMiddle }
        extraTop={ extraTop }
        onClick={ onClick }
        onPictureClick={ onPictureClick }
      >
        <div>
          <div
            className={ classnames(styles[EContentCardClassKey.title], {
              [ellipsisStyles[EEllipsisClassKey.line]]: !titleClamp || titleClamp <= 1,
              [ellipsisStyles[EEllipsisClassKey.multiline]]: titleClamp && titleClamp > 1
            }) }
            onClick={ onTitleClick }
          >
            { title }
          </div>

          { descr }
        </div>
        { withIndicator && (
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <div className={ styles[EContentCardClassKey.freeBlock] }>{ indicator }</div>

            <div style={ { display: 'flex' } }>
              <Likes
                count={
                  Number(itemCardId) || 0 }
                declined={ !authStatus }
                liked={ liked }
                onClick={ (e, b) => handleLike(contentItemId, b) }
                onDecline={ handleDecline }
              />
            </div>
          </Grid>
        ) }
      </Postcard>
    </div>
  );
});
ContentCard.displayName = 'ContentCard';

ContentCard.defaultProps = { layout: EPostcardLayout.vertical, origin: null, withIndicator: true } as ContentCardAndLikeProps;

export default ContainerContentList(ContainerLike(ContentCard));
