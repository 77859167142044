import React, { FC, memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import cx from 'classnames';

import { BASENAME } from 'app/configs/const';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { FollowLink } from 'app/components/follow-link/follow-link.index';

export const useAppBarLogoStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none'
    },

    dark: { color: configTheme.colors.text.light.default },
    light: { color: configTheme.colors.text.dark.default },

    text: {
      fontFamily: 'Stencil',
      fontWeight: 700,
      fontSize: 26,
      lineHeight: '40px'
    }
  }),
  { name: 'AppBarLogo' }
);

const AppBarLogo: FC<any> = memo<any>(props => {
  const { className, style, color } = props;
  const styles = useAppBarLogoStyles();
  const logoUrl = BASENAME?.length > 0 ? BASENAME : '/';
  return (
    <FollowLink
      className={ cx(className, styles.root, { [styles.light]: color === 'light', [styles.dark]: color === 'dark' }) }
      style={ style }
      to={ logoUrl }
    >
      <span className={ styles.text }>ДИЕТА</span>
    </FollowLink>
  );
});
AppBarLogo.displayName = 'AppBarLogo';

AppBarLogo.defaultProps = { color: 'dark' };

export default AppBarLogo;
