//#region imports
import React from 'react';
import { Dispatch } from 'redux';

import { connect } from 'app/containers/connect';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { ILikeState } from 'app/entities/like/like.reducer';
import { fetchLikeList, resetLikeItems, createLikeItem, removeLikeItem } from 'app/entities/like/like.actions';
//#endregion

export type IContainerLikeProps = ILikeState & {
  actions: {
    fetchLikeList: typeof fetchLikeList;
    resetLikeItems: typeof resetLikeItems;
    createLikeItem: typeof createLikeItem;
    removeLikeItem: typeof removeLikeItem;
  };
};

const mapStateToProps = (state: IRootState): ILikeState => state.like;
const mapDispatchToProps = (dispatch: Dispatch): IContainerLikeProps['actions'] => ({
  fetchLikeList: contentIdList => dispatch(fetchLikeList(contentIdList)),
  resetLikeItems: () => dispatch(resetLikeItems()),
  createLikeItem: id => dispatch(createLikeItem(id)),
  removeLikeItem: id => dispatch(removeLikeItem(id))
});

export const ContainerLike = connect<IContainerLikeProps>('containerLike', mapStateToProps, mapDispatchToProps);
