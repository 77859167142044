//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { EContentCardClassKey, ContentCardClasses, IContentCardStylesProps } from './content.card.models';
//#endregion

export const useContentCardStyles = makeStyles(
  createStyles<ContentCardClasses, IContentCardStylesProps>({
    [EContentCardClassKey.root]: {
      boxSizing: 'border-box',
      filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.14))',
      background: configTheme.colors.background.white.default
    },

    [EContentCardClassKey.inner]: {
      minHeight: '100%',
      cursor: ({ isAllClickable }) => isAllClickable ? 'pointer' : 'initial'
    },

    [EContentCardClassKey.title]: {
      fontSize: ({ mobile }) => (mobile ? 16 : 22),
      lineHeight: 1.273,
      color: configTheme.colors.text.dark.default,
      cursor: ({ isTitleClickable, isAllClickable }) => isTitleClickable || isAllClickable ? 'pointer' : 'initial'
    },

    [EContentCardClassKey.descr]: {
      padding: ({ mobile, origin }) => (mobile ? '12px 8px' : origin === 'category' ? '24px 12px' : '0 0 0 36px'),
      justifyContent: 'space-between'
    },

    [EContentCardClassKey.picture]: {
      maxWidth: '100%',
      margin: '0 auto !important',
      cursor: ({ isPictureClickable }) => isPictureClickable ? 'pointer' : 'initial'
    },

    [EContentCardClassKey.pictureFigure]: { maxWidth: '100%' },
    [EContentCardClassKey.freeBlock]: {
      display: 'flex',
      '& img': {
        marginRight: '10px'
      }
    },
    [EContentCardClassKey.imgLike]: { cursor: 'pointer', marginRight: '12px' }

  }),
  { name: 'ContentCard' }
);
