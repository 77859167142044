import React, { FC, memo } from 'react';
import { StandardProps, createStyles, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

export type ViewBoxProps = StandardProps<{}, never>;

export const useViewBoxStyles = makeStyles(
  createStyles({
    root: {
      flex: '1 1 auto',
      display: 'flex',
      flexFlow: 'column nowrap'
    }
  }),
  { name: 'ViewBox' }
);

const ViewBox: FC<ViewBoxProps> = memo(props => {
  const { className, classes, style, children } = props;
  const styles = useViewBoxStyles({ classes });
  return <div className={ classnames(className, styles.root) } style={ style }>{ children }</div>;
});

export default ViewBox;
