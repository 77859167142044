import axios from 'axios';
import { BASENAME } from 'app/configs/const';

import { ICategory } from 'app/models/model.category';

export const SUBCATEGORY_ACTIONS = {
  FETCH_LIST: 'SUBCATEGORY:LIST::FETCH',
  FETCH_ITEM: 'SUBCATEGORY:ITEM::FETCH',
  RESET_ITEM: 'SUBCATEGORY:ITEM::RESET'
};

export const fetchSubcategoryItems = (id: ICategory['id']) => ({
  type: SUBCATEGORY_ACTIONS.FETCH_LIST,
  payload: axios.get(`${BASENAME}/api/subcategory/list?categoryId=${id}`)
});

export const fetchSubcategoryItem = (id, linkTo?) => ({
  type: SUBCATEGORY_ACTIONS.FETCH_ITEM,
  payload: axios.get(`${BASENAME}/api/subcategory/get?id=${id}`)
});
