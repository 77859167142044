// import { BASENAME } from 'app/configs/const';
import { IAnswer, IGameInitialState } from 'app/entities/game/game.reducer';

import axios from 'axios';
// import MockAdapter from 'axios-mock-adapter';

// const mockAxios = axios.create();
// const mock = new MockAdapter(mockAxios, { delayResponse: 1500 });

export const GAME_ACTIONS = {
  START_GAME: 'GAME::START',
  FETCH_QUESTION: 'GAME::FETCH:QUESTION',
  POST_ANSWER: 'GAME::POST:ANSWER',
  END_GAME: 'GAME::END',
  RESET_GAME: 'GAME::RESET',
  BUFFER_GAME: 'GAME::BUFFER'
};
export const startGame = (id: IGameInitialState['gameId']) => ({
  type: GAME_ACTIONS.START_GAME,
  payload: axios.get(`/api/game/new/${id}`)
});
export const endGame = (id: IGameInitialState['gameId']) => ({
  type: GAME_ACTIONS.END_GAME,
  payload: axios.get(`/api/game/over/${id}`)
});
export const fetchGameQuestion = (id: IGameInitialState['gameId']) => ({
  type: GAME_ACTIONS.FETCH_QUESTION,
  payload: axios.get(`/api/game/question/${id}`)
});
export type IAnswerIndexes = Array<IAnswer['index']>;
export const postGameAnswer = (answerIndexes: IAnswerIndexes, gameId: IGameInitialState['gameId']) => ({
  type: GAME_ACTIONS.POST_ANSWER,
  meta: { props: { answerIndexes } },
  payload: axios.post(`/api/game/answer`, { answerIndexes, gameId })
});
export const resetGame = () => ({
  type: GAME_ACTIONS.RESET_GAME
});
export const bufferGame = (buffer, id) => ({
  type: GAME_ACTIONS.BUFFER_GAME,
  meta: { props: { targetId: id } },
  payload: buffer ?? false
});

/**
 * Mock
 */
// const totalCount = 6;
// const gameId = 'da7110fb-d9cc-4d7b-94ba-745dd9d2901e';

// let questionIndex = 1;
// let result = [];

// OLYMPIAD_3402_3452
// mock.onGet(`${BASENAME}/api/game/new/STUDY_2852_2902`).reply(config => {
//   questionIndex = 1;
//   result = [];
//   return [
//     200,
//     {
//       gameId,
//       totalCount,
//       question: {
//         id: Math.random(),
//         answerList: [
//           {
//             image: null,
//             text: '2 морковки',
//             index: 0
//           },
//           {
//             image: null,
//             text: '3 морковки',
//             index: 1
//           },
//           {
//             image: null,
//             text: '8 морковок',
//             index: 2
//           }
//         ],
//         text: 'Сколько морковок у кролика в корзинке?',
//         image: 'https://placehold.it/340x230',
//         index: questionIndex
//       }
//     }
//   ];
// });

// mock.onPost(`${BASENAME}/api/game/answer`).reply(config => {
//   const res = Math.random() < 0.5;
//   result.push(res);
//   return [200, { correct: res, last: questionIndex === totalCount }];
// });

// mock.onGet(`${BASENAME}/api/game/question/${gameId}`).reply(config => {
//   questionIndex = questionIndex + 1;
//   return [
//     200,
//     {
//       gameId,
//       totalCount,
//       question: {
//         id: Math.random(),
//         answerList: [
//           {
//             image: null,
//             text: `1. Потому что ${questionIndex}`,
//             index: 0
//           },
//           {
//             image: null,
//             text: `2. Потому что ${questionIndex}`,
//             index: 1
//           },
//           {
//             image: null,
//             text: `3. Потому что ${questionIndex}`,
//             index: 2
//           },
//           {
//             image: null,
//             text: `4. Потому что ${questionIndex}`,
//             index: 3
//           }
//         ],
//         text: 'Почему облака белые?',
//         image: null,
//         index: questionIndex
//       }
//     }
//   ];
// });

// mock.onGet(`${BASENAME}/api/game/over/${gameId}`).reply(config => {
//   const correctCount = result?.filter(value => value)?.length ?? 0;
//   const percent = correctCount / totalCount * 100;
//   return [200, { gameId, percent, totalCount, correctCount }];
// });
