import { makeStyles, createStyles } from '@material-ui/core';

interface IProps {
  isMobile: boolean;
}

export const useDietBannerStyles = makeStyles(
  createStyles<any, IProps>({
    image: {
      marginRight: ({ isMobile }) => (!isMobile ? 30 : undefined),
      width: ({ isMobile }) => isMobile ? '100%' : 'initial !important'
    },
    header: {
      marginBottom: ({ isMobile }) => isMobile ? 27 : 36
    },
    desc: {
      lineHeight: ({ isMobile }) => isMobile ? '22px' : '28px'
    },
    textBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: ({ isMobile }) => (isMobile ? 'center' : 'left'),
      height: '100%'
    },
    textContent: ({ isMobile }) => (!isMobile ?
      {
        display: 'flex',
        flexDirection: 'column'
      }
      : {}),
    button: ({ isMobile }) => (isMobile ?
      {
        margin: '18px 0',
        padding: '12px 34px',
        fontSize: 16,
        height: 48
      }
      : {})
  })
);
