import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { AUTH_ACTIONS } from 'app/entities/user/user.actions';
import { ELoadStatus } from 'app/models/shared.model';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import { IUser } from 'app/models/model.user';

const initialState: IUser = {
  msisdn: null,
  token: null,
  authenticated: false,
  sessionHasBeenFetched: false,
  subscribe: null,
  request: {
    status: {
      msisdn: ELoadStatus.not_ready,
      token: ELoadStatus.not_ready,
      subscribe: ELoadStatus.not_ready
    },
    error: {
      msisdn: null as Error,
      token: null as Error,
      subscribe: null as Error
    }
  }
};

export type IUserState = Readonly<typeof initialState>;

export default (state = initialState, action): IUserState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(AUTH_ACTIONS.CHECK):
      return flow([
        set('request.status.token', ELoadStatus.loading),
        set('request.status.msisdn', ELoadStatus.loading),
        set('request.error.token', initialState.request.error.token),
        set('request.error.msisdn', initialState.request.error.msisdn),
        set('sessionHasBeenFetched', false)
      ])(state);
    case SUCCESS(AUTH_ACTIONS.CHECK):
      if (payload.data.authenticated) {
        return flow([
          set('request.status.token', ELoadStatus.ready),
          set('request.status.msisdn', ELoadStatus.ready),
          set('msisdn', payload.data.msisdn),
          set('token', payload.data.token),
          set('authenticated', payload.data.authenticated),
          set('sessionHasBeenFetched', true)
        ])(state);
      }
      return flow([
        set('request.status.token', ELoadStatus.ready),
        set('request.status.msisdn', ELoadStatus.ready),
        set('msisdn', payload.data.msisdn),
        set('token', payload.data.token)
      ])(state);
    case FAILURE(AUTH_ACTIONS.CHECK):
      return flow([
        set('request.status.token', ELoadStatus.error),
        set('request.status.msisdn', ELoadStatus.error),
        set('request.error.msisdn', new Error('Некорректный номер телефона.')),
        set('request.error.token', new Error('Не удалось получить токен.')),
        set('sessionHasBeenFetched', true)
      ])(state);
    case AUTH_ACTIONS.RESET_PROP:
      return flow([
        set(`request.status.${payload}`, initialState.request.status[payload]),
        set(`request.error.${payload}`, initialState.request.error[payload]),
        set(`${payload}`, initialState[payload])
      ])(state);
    default:
      return state;
  }
};
