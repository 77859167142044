import React from 'react';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { stylesApp } from 'app/app';
import { Header } from 'app/components/header/header.ui';

import { EContentType } from 'app/models/model.content';

export interface IResponsiveImageProps {
  src: string;
  title?: string;
  type?: EContentType;
  classesImg?: string;
  classesWrap?: string;
}

export const stylesResponsiveImage = makeStyles(theme =>
  createStyles({
    wrap: {
      margin: '36px 0'
    },
    cardMedia: {
      height: 229,
      maxWidth: '100%',
      position: 'relative',
      overflow: 'hidden'
    },
    title: {
      margin: '9px 0',
      color: ui.colors.text.dark.default
    },
    overlayVideo: {
      '&:after': {
        content: '"▶"',
        display: 'inline-block',
        position: 'absolute',
        fontSize: 18,
        textAlign: 'center',
        lineHeight: '48px',
        height: 50,
        width: 50,
        borderRadius: 50,
        background: ui.colors.background.light.neutral,
        color: ui.colors.text.dark.default,
        zIndex: 9,
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%'
      }
    }
  })
);

export const ResponsiveImage = (props: IResponsiveImageProps) => {
  const classes = stylesResponsiveImage();
  const classesApp = stylesApp();

  return (
    <>
      <div className={ `${props.classesWrap || ''} ${classes.wrap}` }>
        <CardMedia
          key={ props.type }
          image={ props.src }
          className={ `
          ${props.classesImg || ''}
          ${classes.cardMedia}
          ${classesApp.skeletonImage}` }
        />
        { props.title && (
          <Header component={ 'h4' } className={ classes.title }>
            { props.title }
          </Header>
        ) }
      </div>
    </>
  );
};
