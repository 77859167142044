import { CustomTextField } from 'app/components/custom.input/custom.input';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

import ArrowIcon from 'app/assets/img/arrow.svg';

export const useStylesAutocomplete = makeStyles((theme: Theme) =>
  createStyles({
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      },
      '& .MuiIconButton-label': {
        transform: 'rotate(90deg)'
      }
    }
  })
);

interface IAutocomplete extends Omit<AutocompleteProps<any, boolean, boolean, any>, 'renderInput'> {
  onChange?: (props: any) => void;
}

export const CustomAutocomplete = (props: IAutocomplete) => {
  const { onChange, ...rest } = props;
  const styles = useStylesAutocomplete();
  const { t } = useTranslation();

  return (
    <Autocomplete
      { ...rest }
      className={ styles.autocomplete }
      disableClearable
      getOptionLabel={ option => option.title }
      onChange={ (event: any, newValue) => {
        onChange(newValue);
      } }
      // style={ { width: '339px', marginBottom: 27 } }
      renderInput={ children => (
        <span>
          <CustomTextField { ...children } label={ t('diet.weekendProgram.yourResult') } onClick={ e => {
            e.preventDefault();
            e.stopPropagation();
          } } variant="filled" />
        </span>
      ) }
      popupIcon={ <img src={ ArrowIcon } alt="arrow-icon"/> }
      noOptionsText={ t('common.noResult') }
    />
  );
};
