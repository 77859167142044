import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import isFunction from 'lodash/isFunction';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';
import { Header } from 'app/components/header/header.ui';

export const stylesContentHeader = makeStyles((theme: Theme) =>
  createStyles({
    contentHeader: {
      margin: 0,
      fontSize: 16,
      flexGrow: 1,
      color: 'inherit',
      letterSpacing: '0px',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis'
    },
    contentHeaderWrapper: {
      margin: `0 -18px`,
      padding: `11px 18px`,
      background: ui.colors.background.dark.neutral,
      color: 'white',
      display: 'flex'
    },
    contentHeaderClose: {
      fontFamily: 'icons',
      color: 'inherit',
      fontSize: 25,
      margin: -3,
      textDecoration: 'none'
    }
  })
);

export const ContentHeader: FC<{ isClose: boolean; closeRoute?: string; closeHandler?: () => void }> = props => {
  const { closeHandler, children } = props;
  const history = useHistory();
  const lastLocation = useLastLocation();
  const classes = stylesContentHeader();
  const goBack = () => {
    if (isFunction(closeHandler)) {
      closeHandler();
      return;
    }
    props.closeRoute ? history.push(props.closeRoute) : history.push(lastLocation ? lastLocation.pathname : '/');
  };
  return props.isClose ? (
    <div className={ classes.contentHeaderWrapper }>
      <Header className={ classes.contentHeader } component={ 'h1' }>
        { children }
      </Header>
      <span className={ classes.contentHeaderClose } onClick={ goBack }>
        { '\uE059' }
      </span>
    </div>
  ) : (
    <Header component={ 'h1' } className={ classes.contentHeader }>
      { children }
    </Header>
  );
};
