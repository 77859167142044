import axios from 'axios';
import { BASENAME } from 'app/configs/const';

import { IContent } from 'app/models/model.content';

export const LIKE_ACTION = {
  FETCH_ITEMS: 'CONTENT:ITEMS:LIKE::FETCH',
  CREATE_ITEM: 'CONTENT:ITEM:LIKE::POST',
  REMOVE_ITEM: 'CONTENT:ITEM:LIKE::DELETE',
  RESET_ITEMS: 'CONTENT:ITEM:HTML5::RESET'
};

export type ICreateLikeItemProps = IContent['id'];
export const createLikeItem = (id: ICreateLikeItemProps) => ({
  type: LIKE_ACTION.CREATE_ITEM,
  payload: axios.post(`/api/heart/add`, { contentId: id })
});

export type IRemoveLikeItemProps = IContent['id'];
export const removeLikeItem = (id: IRemoveLikeItemProps) => ({
  type: LIKE_ACTION.REMOVE_ITEM,
  payload: axios.post(`/api/heart/remove`, { contentId: id })
});

export type IFetchLikeListProps = Array<IContent['id']>;
export const fetchLikeList = (ids: IFetchLikeListProps) => ({
  type: LIKE_ACTION.FETCH_ITEMS,
  payload: axios.post(`/api/heart/list`, { contentIdList: ids })
});

export const resetLikeItems = () => ({
  type: LIKE_ACTION.RESET_ITEMS
});
