import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStylesModal = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    paper: {
      width: '100%',
      height: '100vh',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      padding: '36px 18px 0px',
      boxSizing: 'border-box'
    },
    title: {
      fontSize: 28,
      marginTop: 0
    },
    imgClose: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    button: {
      width: 274
    }
  })
);
