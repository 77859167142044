import React, { FC, forwardRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import isFunction from 'lodash/isFunction';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { Grid, LinearProgress } from '@material-ui/core';

import { useStylesModalCalcProgram } from 'app/pages/desktop/diet/program/program.style';
import { DIET, EDietPageSection } from 'app/configs/route.names';
import calcProgramModalImg from 'app/assets/img/calc.program.modal.img.png';
import { loginExternal } from 'app/utils/auth.external';

interface IProgramLoaderProps {
  setModalCalcProgram: (boolean) => void;
  modalClosePayment?: () => void;
  auth?: boolean;
}

export const ProgramLoader: FC<IProgramLoaderProps> = forwardRef(({ setModalCalcProgram, modalClosePayment, auth }, ref) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const styles = useStylesModalCalcProgram();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 450);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!auth) {
        localStorage.setItem('backUrl', '/');
        loginExternal(DIET(EDietPageSection.PROGRAM));
      } else {
        localStorage.setItem('backUrl', '/diet');
        history.push(DIET(EDietPageSection.PROGRAM));
      }
    }, 5000);
    return () => {
      clearInterval(timeout);
      modalClosePayment();
    };
  }, []);

  return (
    <>
      <div className={ styles.paperLoader }>
        <Grid>
          <Typography type="heading" level={ 2 }>{ t('modalCalcProgram.WaitMinute') }!</Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <img src={ calcProgramModalImg } alt="calcProgramModalImg" style={ { marginTop: '72px' } } />
        </Grid>
        <div className={ styles.progress }>
          <LinearProgress variant="determinate" value={ progress } />
        </div>
        <Grid container direction="row" justifyContent="center" alignItems="center" style={ { marginTop: '18px' } }>
          { t('modalCalcProgram.calcProgram') }
        </Grid>
      </div>
    </>
  );
});
