import { ESectionLayout } from 'app/models/model.section';
import { EAgeType } from 'app/models/shared.model';
import { ESubcategoryLayout, ISubcategory } from 'app/models/model.subcategory';

export enum ECategoryAllButtonLink {
  ALL_SUBCATEGORIES = 'ALL_SUBCATEGORIES',
  ALL_SECTIONS = 'ALL_SECTIONS',
  CUSTOM = 'CUSTOM',
  FIRST_SECTION = 'FIRST_SECTION',
  ACTIVE_SECTION = 'ACTIVE_SECTION',
  NONE = 'NONE'
}

export interface ICategory {
  id: number;
  name: string;
  ageType?: EAgeType;
  allButtonLink?: ECategoryAllButtonLink;
  allButtonUrl?: string;
  content?: string;
  sectionLayout?: ESectionLayout;
  subcategoryLayout?: ESubcategoryLayout;
  subcategories?: ISubcategory[];
}
