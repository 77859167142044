import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const stylesSwitch = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 50,
      height: 28,
      padding: 0
    },
    switchBase: {
      margin: 2,
      padding: 0,
      '&$checked': {
        transform: 'translateX(22px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: ui.colors.background.dark.default,
          opacity: 1,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        color: ui.colors.text.dark.default,
        border: '6px solid #fff'
      }
    },
    thumb: {
      width: 24,
      height: 24,
      boxShadow: 'none'
    },
    track: {
      borderRadius: 1000,
      backgroundColor: ui.colors.background.neutral.neutral,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {},
    disabled: {
      color: '#8F9399',
      pointerEvents: 'none'
    }
  })
);

interface IStylesProfileProps {
  mobile?: boolean;
}

export const stylesProfile = makeStyles<Theme, IStylesProfileProps>(theme =>
  createStyles({
    exitButton: {
      marginBottom: 1,
      lineHeight: '22px',
      marginTop: 36
    },
    header: {
      marginBottom: 18
    },
    subHeader: {
      marginBottom: 36
    },
    data: {
      display: 'flex',
      lineHeight: '22px',
      marginBottom: '9px',
      '& span': {
        flex: '0 0 18%',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center'
      },
      '& b': {
        flex: '0 0 50%',
        fontWeight: 'normal'
      }
    },
    prizeItem: { marginTop: ui.indents['indentX1'].margin },
    primarySection: {
      margin: ({ mobile }) => mobile ? '36px -18px 0' : '36px -18px',
      padding: '0 18px',
      background: ui.colors.basic.secondary
    },
    secondarySection: {
      margin: ({ mobile }) => mobile ? '0 -18px 36px' : '54px 0 0 0',
      padding: ({ mobile }) => mobile ? '36px 18px' : '0',
      background: ({ mobile }) => mobile ? ui.colors.background.light.neutral : ui.colors.background.white.default
    },
    switchWrap: {
      marginTop: '-27px'
    },
    item: {
      '&:first-child': {
        marginTop: 36
      },
      '&:last-child': {
        marginBottom: 0
      },
      marginTop: 0,
      marginBottom: 28
    },
    itemDesc: {
      marginTop: 9
    },
    titleLogout: {
      textDecoration: 'underline',
      marginBottom: 36
    },
    parametersWrapper: {
      marginTop: 36,
      margin: 0,
      '& .MuiPaper-root': {
        minHeight: 'auto'
      }
    },
    parametersTable: {
      padding: 0
    },
    parametersBtn: {
      margin: 0,
      marginTop: 36
    }
  })
);
