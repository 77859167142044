import React, { Dispatch, SetStateAction, useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { PAGER } from 'app/configs/const';

export interface IPagerProps {
  pager: {
    limit: number;
    offset: number;
  };
}
export interface IContainerPagerCallbackProps { pager: IPagerProps['pager']; id: number; }
export interface IPagerContainerProps extends IPagerProps {
  actions: {
    setPager: Dispatch<SetStateAction<{ limit: number; offset: number; }>>;
    pageNext: () => void;
    pagePrev: () => void;
    reset: () => void;
  };
}

export function PagerContainer(WrappedComponent) {
  const Wrapper = (props, ref) => {
    const [pager, setPager] = useState<IPagerProps['pager']>({ limit: PAGER.LIMIT, offset: PAGER.OFFSET });

    const pageNext = async () => setPager({ limit: pager.limit, offset: pager.offset + PAGER.LIMIT });

    const pagePrev = async () => setPager({
        limit: pager.limit === PAGER.LIMIT ? PAGER.LIMIT : pager.limit - PAGER.LIMIT,
        offset: pager.offset === PAGER.LIMIT && pager.offset - PAGER.LIMIT
      });

    const reset = () => setPager({ limit: PAGER.LIMIT, offset: PAGER.OFFSET });

    const provideProps = {
      ...props,
      pagerContainer: {
        pager,
        actions: {
          setPager,
          pageNext,
          pagePrev,
          reset
        }
      } as IPagerContainerProps
    };

    return <WrappedComponent ref={ ref } { ...provideProps } />;
  };

  return hoistNonReactStatics(React.forwardRef(Wrapper), WrappedComponent);
}
