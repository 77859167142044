import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { VIDEO_ACTIONS } from 'app/entities/video/video.actions';

import { ELoadStatus } from 'app/models/shared.model';
import { ESubscribeStatus, ESubscriptionStatus } from 'app/models/model.subscription';

// export interface IVideoState {
//   src: string;
//   status: string;
//   token?: string;
//   error: null | string | object;
// }

const initialState = {
  src: '',
  token: '',
  free: false,
  subscribeStatus: ESubscriptionStatus.NOT_ACTIVE,
  progressStatus: ELoadStatus.not_ready,
  errorStatus: null as Error
};

export type IVideoState = Readonly<typeof initialState>;

export default (state = initialState, action): IVideoState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(VIDEO_ACTIONS.FETCH):
      return {
        ...state,
        free: false,
        subscribeStatus: ESubscriptionStatus.NOT_ACTIVE,
        progressStatus: ELoadStatus.loading,
        errorStatus: null
      };
    case SUCCESS(VIDEO_ACTIONS.FETCH):
      return {
        ...state,
        src: payload.src,
        token: payload.token || null,
        progressStatus: ELoadStatus.ready,
        free: payload.free,
        subscribeStatus: payload.subscribeStatus,
        errorStatus: null
      };
    case FAILURE(VIDEO_ACTIONS.FETCH):
      return {
        ...state,
        free: false,
        subscribeStatus: ESubscriptionStatus.NOT_ACTIVE,
        progressStatus: ELoadStatus.error,
        errorStatus: payload.error
      };

    case VIDEO_ACTIONS.RESET:
      return initialState;

    default:
      return state;
  }
};
