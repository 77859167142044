import axios from 'axios';
import { BASENAME } from 'app/configs/const';

export const CATEGORY_ACTIONS = {
  FETCH_LIST: 'CATEGORY:LIST::FETCH',
  FETCH_ITEM: 'CATEGORY:ITEM::FETCH',
  RESET_ITEM: 'CATEGORY:ITEM::RESET'
};
export const SUBCATEGORY_ACTIONS = {
  FETCH_ITEM: 'SUBCATEGORY:ITEM::FETCH',
  RESET_ITEM: 'SUBCATEGORY:ITEM::RESET'
};

export const fetchCategoryItems = () => ({
  type: CATEGORY_ACTIONS.FETCH_LIST,
  payload: axios.get(`${BASENAME}/api/category/list`)
});

export const fetchCategoryItem = id => ({
  type: CATEGORY_ACTIONS.FETCH_ITEM,
  payload: axios.get(`${BASENAME}/api/category/get?id=${id}`)
});

// @ts-ignore
export const resetCategoryItem = () => ({
  type: CATEGORY_ACTIONS.RESET_ITEM
});
