import { MODALS_ACTIONS } from 'app/entities/modals/modals.actions';

export enum ModalTypes {
  null = 'null',
  network = 'network',
  no_money = 'no_money',
  max_notifications = 'max_notifications',
  auth = 'auth',
  subscribe = 'subscribe',
  unsubscribe = 'unsubscribe',
  success_activate = 'success_activate',
  fail_activate = 'fail_activate',
  subscribeNotFound = 'subscribeNotFound',
  not_authorized = 'not_authorized',
  logout = 'logout',
  how_load = 'how_load'
}

export interface ISubscribePayload {
  subscribeId: number;
  handleClose?: () => void;
  handleCancel?: () => void;
}

const initialState = {
  show: false,
  type: null as ModalTypes,
  payload: null as null | ISubscribePayload
};

export type IModalsState = Readonly<typeof initialState>;

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODALS_ACTIONS.NETWORK:
      return {
        ...state,
        show: true,
        type: ModalTypes.network,
        payload
      };
    case MODALS_ACTIONS.NOT_AUTHORIZED:
      return {
        ...state,
        show: true,
        type: ModalTypes.not_authorized,
        payload
      };
    case MODALS_ACTIONS.HOW_LOAD:
      return {
        ...state,
        show: true,
        type: ModalTypes.how_load,
        payload
      };
    case MODALS_ACTIONS.NO_MONEY:
      return {
        ...state,
        show: true,
        type: ModalTypes.no_money,
        payload
      };
    case MODALS_ACTIONS.FAIL_ACTIVATE:
      return {
        ...state,
        show: true,
        type: ModalTypes.fail_activate,
        payload
      };
    case MODALS_ACTIONS.MAX_NOTIFICATIONS:
      return {
        ...state,
        show: true,
        type: ModalTypes.max_notifications,
        payload
      };
    case MODALS_ACTIONS.SUCCESS_ACTIVATE:
      return {
        ...state,
        show: true,
        type: ModalTypes.success_activate,
        payload
      };
    case MODALS_ACTIONS.SUBSCRIBE:
      return {
        ...state,
        show: true,
        type: ModalTypes.subscribe,
        payload
      };
    case MODALS_ACTIONS.UNSUBSCRIBE:
      return {
        ...state,
        show: true,
        type: ModalTypes.unsubscribe,
        payload
      };
    case MODALS_ACTIONS.SUBSCRIBE_NOT_FOUND:
      return {
        ...state,
        show: true,
        type: ModalTypes.subscribeNotFound,
        payload
      };
    case MODALS_ACTIONS.LOGOUT:
      return {
        ...state,
        show: true,
        type: ModalTypes.logout,
        payload
      };
    case MODALS_ACTIONS.RESET:
      return initialState;
    default:
      return state;
  }
};
