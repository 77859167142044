import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core';
import { configTheme as ui } from 'a1s-tele2-react-ui/src/config/config.theme';

export const useStylesIndividualProgram = makeStyles((theme: Theme) =>
  createStyles({
    parameters: {
      paddingBottom: 72
    },
    wrapper: {
      marginTop: '54px'
    },
    btn: {
      textTransform: 'initial',
      color: ui.colors.text.dark.neutral,
      marginRight: '27px',
      marginBottom: '27px',
      fontSize: '14px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    btnActive: {
      color: ui.colors.text.dark.default
    },

    titleDay: {
      fontSize: '14px',
      color: ui.colors.text.dark.neutral,
      marginRight: '27px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    imgTitleBlockMenu: {
      marginRight: '19px'
    },
    btnNextDay: {
      marginTop: '54px',
      marginBottom: '18px',
      minWidth: '276px'
    },

    active: {
      color: ui.colors.text.dark.default
    },

    blockContent: {
      marginLeft: '162px',
      width: 'auto'
    },

    navbar: {
      marginTop: 20,
      marginBottom: 15,
      boxShadow: 'none',
      '& li': {
        borderBottom: 'none',
        cursor: 'pointer',
        '& div': {
          paddingBottom: 0,
          '&:before': {
            display: 'none'
          }
        },
        '&:first-child': {
          '& div': {
            paddingLeft: 0
          }
        }
      }
    }

  })
);
