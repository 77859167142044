import React, { Fragment } from 'react';
import lazyModule from 'a1s-essence-utils-ts/fp/lazyModule';

const toastify = lazyModule(() => {
  import('react-toastify/dist/ReactToastify.css');
  return import('react-toastify');
}, {
  toast: {
    warn: () => {},
    success: () => {},
    error: () => {}
  },
  ToastContainer: () => <Fragment />
});

export default toastify;
