import { RouteComponentProps } from 'react-router';
import axios, * as Axios from 'axios';

export enum EAgeType {
  ADULT = 'ADULT',
  ALL = 'ALL'
}

export enum ELoadStatus {
  loading = 'loading',
  error = 'error',
  ready = 'ready',
  not_ready = 'not ready'
}

export const loadStatusDefValues = {
  status: ELoadStatus.not_ready,
  error: null as Error
};

export enum EDistribution {
  DRM = 'DRM',
  FILE = 'FILE'
}

export enum EDialogConfirmType {
  ADULT = 'ADULT',
  LOGOUT = 'LOGOUT',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  OLYMPIAD_ABORT = 'OLYMPIAD_ABORT',
  OLYMPIAD_PRIZE = 'OLYMPIAD_PRIZE'
}

export enum EProtocol {
  HTTP = 'HTTP',
  HTTPS = 'HTTPS'
}

export interface IPayload<T> {
  type: string;
  payload: Axios.AxiosPromise<T>;
  meta?: any;
}

export type IPayloadResult<T> = (dispatch: any) => IPayload<T> | Promise<IPayload<T>>;
export type IGetAction<T> = (id: string | number) => IPayload<T> | ((dispatch: any) => IPayload<T>);
export type IGetAllAction<T> = (page?: number, size?: number, sort?: string) => IPayload<T> | ((dispatch: any) => IPayload<T>);
export type IPutAction<T> = (data?: T) => IPayload<T> | IPayloadResult<T>;
export type IDeleteAction<T> = (id?: string | number) => IPayload<T> | IPayloadResult<T>;

export interface IRouteProps extends RouteComponentProps {
  computedMatch: {
    path: string;
    url: string;
    isExact: boolean;
    params: {
      [key: string]: string;
    };
  };
}

export interface IPrevRouteProps {
  prevRoute: string;
}

export enum EConfirmationName {
  ageType = 'ageType'
}

export const EConfirmation = {
  [EConfirmationName.ageType]: 'EAgeType'
} as const;

export interface IRequestCancel {
  uncancelled?: boolean;
  cancellable?: boolean;
  cancelToken?: Axios.CancelToken;
}

export const CancelToken = axios.CancelToken;
