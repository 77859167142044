import { IRootState } from 'app/shared/reducers/root.reducer';
import { IVideoState } from 'app/entities/video/video.reducer';

import { fetchVideoItem, resetVideoItem } from 'app/entities/video/video.actions';

import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';
import { logView } from 'app/entities/logging/logging.actions';

export type IContainerContentVideoItemProps = IVideoState & {
  actions: {
    fetchVideoItem: (id: number | string) => void;
    resetVideoItem: () => void;
    logView: () => void;
  }
};

const mapStateToProps = ({ videoItem }: IRootState) => videoItem;
const mapDispatchToProps = (dispatch: Dispatch): IContainerContentVideoItemProps['actions'] => ({
  fetchVideoItem: id => dispatch(fetchVideoItem(id) as unknown as Action),
  resetVideoItem: () => dispatch(resetVideoItem()),
  logView: () => dispatch((logView() as unknown) as Action)
});

export const ContainerContentVideoItem = connect<IContainerContentVideoItemProps>('containerContentVideoItem', mapStateToProps, mapDispatchToProps);
