//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';

import { EBannerClassKey, BannerClasses, BannerKeyframes, IBannerStylesProps } from 'app/entities/banner/banner.model';
//#endregion

export const useBannerStyles = makeStyles(
  theme =>
    createStyles<BannerClasses | BannerKeyframes, IBannerStylesProps>({
      [EBannerClassKey.root]: {
        '& .carousel .slide': { animation: `$disappear 0.35s ${theme.transitions.easing.easeOut}` },
        '& .carousel .slide.selected': { animation: `$appear 0.35s ${theme.transitions.easing.easeIn}` }
      },

      [EBannerClassKey.banner]: { zIndex: 1 },

      [EBannerClassKey.bannerPic]: {
        marginBottom: '0 !important'
        // '& img': {
        //   display: 'none!important'
        // }
      },

      [EBannerClassKey.bannerDescr]: {
        bottom: configTheme.indents['indentX1'].margin * 4,
        padding: '0 18px',
        textAlign: 'left'
      },

      [EBannerClassKey.mask]: {
        '&:after': {
          content: "''",
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0.4,
          backgroundColor: configTheme.colors.background.dark.default
        }
      },

      [EBannerClassKey.backdrop]: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        overflow: 'hidden',
        '&:after': {
          content: "''",
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0.4,
          backgroundColor: configTheme.colors.background.dark.default,
          display: ({ masked }) => masked ? 'block' : 'none'
        }
      },

      [EBannerClassKey.backdropPic]: {
        display: 'block !important',
        minWidth: '100%',
        objectFit: 'cover',
        transform: 'scale(1.5)',
        filter: 'blur(10px)'
      },

      [EBannerClassKey.title]: {
        marginBottom: configTheme.indents['indentX3'].margin,
        fontFamily: 'Stencil',
        fontWeight: 700,
        fontSize: 40,
        lineHeight: '40px',
        color: configTheme.colors.text.light.default
      },

      [EBannerClassKey.button]: {
        height: '60px',
        padding: `0 ${configTheme.indents['indentX1'].margin * 2.5}px`
      },

      '@keyframes disappear': {
        '0%': { opacity: 1 },

        '100%': { opacity: 0.5 }
      },

      '@keyframes appear': {
        '0%': { opacity: 0.5 },

        '100%': { opacity: 1 }
      }
    }),
  { name: 'Banner' }
);
