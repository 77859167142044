//#region imports
import React, { FC, memo, forwardRef, useCallback } from 'react';
import { useUnmount } from 'react-use';
import { isMobile } from 'mobile-device-detect';
import { StandardProps, Grid } from '@material-ui/core';
import { VolumeOff, VolumeUp, PlayArrow, Pause, Fullscreen, FullscreenExit } from '@material-ui/icons';
import cx from 'classnames';

import isBoolean from 'lodash/isBoolean';
import throttle from 'lodash/throttle';
import isFunction from 'lodash/isFunction';

import PlayerControlsSlider from '../player.slider.styles';
import stylesPlayerControls from './player.controls.styles';
import { formatTime } from 'app/utils/util.datetime';
//#endregion

interface IPlayerControlsProps {
  readonly currentTime: number;
  readonly duration: number;
  readonly muted?: boolean;
  readonly paused?: boolean;
  readonly seeking?: boolean;
  readonly isFullscreen?: boolean;
  readonly onPlayed?: () => void;
  readonly onPaused?: () => void;
  readonly onMuted?: (muted: boolean) => void;
  readonly onTimeChange?: (seconds: number) => void;
  readonly onFullscreen?: (isFullscreen: boolean) => void;
}

type TPlayerControlsProps = StandardProps<IPlayerControlsProps, never>;

const PlayerControls: FC<any> = memo(
  forwardRef<HTMLDivElement, TPlayerControlsProps>((props, ref) => {
    const {
      className,
      style,
      currentTime,
      duration,
      muted,
      paused,
      seeking,
      isFullscreen,
      onPlayed,
      onPaused,
      onMuted,
      onTimeChange,
      onFullscreen
    } = props;
    const styles = stylesPlayerControls({ isMobile });

    const handleTimeChange = useCallback(
      throttle((event: any, newValue: number | number[]) => {
        onTimeChange && onTimeChange(newValue as number);
      }, 300),
      [onTimeChange]
    );

    const handleMuted = (): void => {
      if (isFunction(onMuted)) onMuted(!muted);
    };

    const handleFullscreen = (): void => {
      if (isFunction(onFullscreen)) onFullscreen(!isFullscreen);
    };

    useUnmount(() => {
      handleTimeChange?.cancel();
    });

    return (
      <div ref={ ref } className={ cx(className, styles.root) } style={ style }>
        <div>
          <PlayerControlsSlider
            min={ 0 }
            max={ duration }
            step={ 1 }
            value={ currentTime }
            track="normal"
            disabled={ !seeking }
            onChange={ handleTimeChange }
            aria-labelledby={ seeking ? 'continuous-slider' : 'disabled-slider"' }
          />
        </div>

        <Grid className={ styles.buttons } container spacing={ 0 } direction="row" wrap="nowrap" alignItems="center" justify="space-between">
          <Grid className={ styles.btnItemCol } item>
            <span>
              { formatTime(currentTime) } / { formatTime(duration) }
            </span>
          </Grid>

          <Grid className={ styles.playback } item>
            <button className={ styles.button } onClick={ () => (paused ? onPlayed && onPlayed() : onPaused && onPaused()) }>
              { paused ? <PlayArrow className={ styles.playbackIcon } /> : <Pause className={ styles.playbackIcon } /> }
            </button>
          </Grid>

          <Grid className={ styles.btnItemCol } item>
            <Grid container spacing={ 1 } direction="row" wrap="nowrap" justify="flex-end">
              <Grid item>
                <button className={ styles.button } onClick={ handleMuted }>
                  { muted ? <VolumeOff /> : <VolumeUp /> }
                </button>
              </Grid>

              { isBoolean(isFullscreen) && (
                <Grid item>
                  <button className={ styles.button } onClick={ handleFullscreen }>
                    { isFullscreen ? <FullscreenExit /> : <Fullscreen /> }
                  </button>
                </Grid>
              ) }
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  })
);
PlayerControls.displayName = 'PlayerControls';

PlayerControls.defaultProps = { muted: false, paused: false, seeking: true } as TPlayerControlsProps;

export default PlayerControls;
