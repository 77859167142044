import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundary from 'app/components/boundary/error.boundary';

interface IPublicRouteProps extends RouteProps {
  layout?: FunctionComponent;
  layoutProps?: any;
}

export const PublicRoute = ({ component: Component, layout: Layout, layoutProps, ...rest }: IPublicRouteProps) => {
  const encloseInErrorBoundary = props => (
    <ErrorBoundary>
      { Layout ?
        <Layout { ...layoutProps } { ...rest }>
          <Component { ...props } { ...rest }/>
        </Layout>
        :
        <Component { ...props } { ...rest }/>
      }
    </ErrorBoundary>
  );

  if (!Component) throw new Error(`A component needs to be specified for path ${(rest as any).path}`);

  return <Route { ...rest } render={ encloseInErrorBoundary } />;
};

export default PublicRoute;
