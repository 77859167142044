import { updateError } from 'app/shared/reducers/error.reducer';

export default store => next => action => {
  const { payload } = action;

  if (payload && payload.message) {
      const errorCode = payload.message.match(/\d+/g);
      if (errorCode && Number(errorCode.join('')) >= 400) store.dispatch(updateError(Number(errorCode.join(''))));
  }

  return next(action);
};
