import React, { FC, ReactHTML } from 'react';
import classnames from 'classnames';

import { ThemePalette, ITypographyProps, ITypographyRootProps, TypographyType, TextColor } from './typography.models';
import { TypographyHeading } from './typography.heading';
import { TypographyLink } from './typography.link';
import { TypographyText } from './typography.text';
import { useTypographyRootStyles } from './typography.styles';

const useStyled = (props: Pick<ITypographyRootProps, 'type' | 'theme' | 'color' | 'serif' | 'strong' | 'tag'>) => {
  const { type, theme, color, serif, strong, tag } = props;
  const styles = useTypographyRootStyles();
  const className = classnames(styles.root, styles.themed, {
    [styles?.[ThemePalette.dark]]: theme === ThemePalette.dark,
    [styles?.[ThemePalette.light]]: theme === ThemePalette.light,
    [styles?.[TextColor.secondary]]: color === TextColor.secondary,
    [styles.strong]: type === TypographyType.heading || strong,
    [styles.serif]: type === TypographyType.heading || serif,
    [styles.p]: tag === 'p'
  });
  return className;
};

export const Typography: FC<ITypographyProps> = props => {
  const { className, type, children } = props;
  const styledClassName = useStyled(props as ITypographyRootProps);

  switch (type) {
    case TypographyType.heading:
      return (
        <TypographyHeading
          className={ classnames(className, styledClassName) }
          style={ props.style }
          mobile={ props.mobile }
          level={ props.level }
          tag={ props.tag }
          attrs={ props.attrs }
          onClick={ props.onClick }
        >
          { children }
        </TypographyHeading>
      );
    case TypographyType.link:
      return (
        <TypographyLink
          className={ classnames(className, styledClassName) }
          style={ props.style }
          size={ props.size }
          href={ props.href }
          attrs={ props.attrs }
          onClick={ props.onClick }
        >
          { children }
        </TypographyLink>
      );
    case TypographyType.text:
    default:
      return (
        <TypographyText
          className={ classnames(className, styledClassName) }
          style={ props.style }
          size={ props.size }
          tag={ props.tag }
          attrs={ props.attrs }
          onClick={ props.onClick }
        >
          { children }
        </TypographyText>
      );
  }
};

Typography.defaultProps = {
  type: TypographyType.text,
  theme: ThemePalette.light,
  color: TextColor.default,
  serif: false,
  strong: false
} as ITypographyRootProps;
