import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import flow from 'lodash/flow';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { ContainerNotification } from 'app/containers/container.notification';

import ProgramMenuByDayBlock from 'app/pages/shared/diet/program/menu.day/menu.day';
import AdviceDiet from 'app/pages/shared/diet/program/dietolog/dietolog';
import MyProgramWorkout from 'app/pages/shared/diet/program/workout/workout';
import ModalWeekendProgram from 'app/pages/mobile/diet/program/modal/modal.weekend.program';
import { ModalProgramLoader } from 'app/pages/mobile/diet/program/modal/modal.program.loader';
import ModalNotifications from 'app/pages/shared/notification/modal.notifications';
import { RadioCheckbox } from 'app/pages/mobile/diet/components/radioCheckBox/radioCheckBox.index';
import DietDays from 'app/pages/mobile/diet/components/dietDays/diet.days.index';

import { INextWeek } from 'app/entities/diet/program/diet.program.reducer';

import { useStylesMobileProgramLayout } from './program.layout.styles';

const ProgramLayout = ({
  dataDays,
  defaultDayId,
  itemsDay,
  getDietIndividualProgramDayById,
  getDietIndividualProgram,
  postNextWeek,
  last,
  itemUserAttributes,
  containerNotification
}) => {
  const { t } = useTranslation();
  const styles = useStylesMobileProgramLayout();
  const [dietDays, setDietDays] = useState([
    { id: 1, title: `${t('individualProgram.day')} 1`, eatings: [] },
    { id: 2, title: `${t('individualProgram.day')} 2`, eatings: [] },
    { id: 3, title: `${t('individualProgram.day')} 3`, eatings: [] },
    { id: 4, title: `${t('individualProgram.day')} 4`, eatings: [] },
    { id: 5, title: `${t('individualProgram.day')} 5`, eatings: [] },
    { id: 6, title: `${t('individualProgram.day')} 6`, eatings: [] },
    { id: 7, title: `${t('individualProgram.day')} 7`, eatings: [] }
  ]);
  const [activeId, setActiveId] = useState(dietDays[0].id);
  const lastDayId = dietDays[dietDays.length - 1]?.id;
  const [openModalWeekend, setOpenModalWeekend] = useState(false);
  const [openModalProgramLoader, setOpenModalProgramLoader] = useState(false);
  const [isModalNotifications, setIsModalNotifications] = useState(false);

  const onCloseModalNotifications = () => setIsModalNotifications(false);
  const onOpenModalNotifications = () => setIsModalNotifications(true);

  useEffect(() => {
    if (itemsDay.length) {
      window.scrollTo(0, 0);
      setDietDays(itemsDay);
      setActiveId(defaultDayId || itemsDay[0].id);
    }
  }, [itemsDay, defaultDayId]);

  const handleClickDay = (id: number) => {
    setActiveId(id);
    if (itemsDay.length) {
      getDietIndividualProgramDayById(id);
    }
  };

  const handleClickNextDay = () => {
    const activeIndexDay = dietDays.findIndex(item => item.id === activeId);
    const curId = dietDays[activeIndexDay + 1]?.id;
    setActiveId(prev => (activeIndexDay === dietDays.length - 1 ? prev : curId));
    window.scrollTo(0, 0);
    if (itemsDay.length && curId) {
      getDietIndividualProgramDayById(curId);
    }
  };

  const handleClickCompleteWeek = () => {
    setOpenModalWeekend(true);
  };

  const handleClickNewWeek = async (data: INextWeek) => {
    await postNextWeek(data);
    last && setOpenModalProgramLoader(true);
    getDietIndividualProgram();
    setOpenModalWeekend(false);
  };

  return (
    <>
      <ModalWeekendProgram
        openModalWeekend={ openModalWeekend }
        setOpenModalWeekend={ setOpenModalWeekend }
        onCompleteClick={ postNextWeek }
        onNewWeekClick={ handleClickNewWeek }
        propState={ itemUserAttributes }
      />
      <ModalNotifications open={ isModalNotifications } onClose={ onCloseModalNotifications } renderCheckBox={ RadioCheckbox }/>
      { openModalProgramLoader && (
        <ModalProgramLoader isModalProgramLoader={ openModalProgramLoader } setModalProgramLoader={ setOpenModalProgramLoader } />
      ) }
      <DietDays
        className={ styles.titleDay }
        dayList={ dietDays }
        activeId={ activeId }
        handleClickDay={ handleClickDay }
      />
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <ProgramMenuByDayBlock />
        <MyProgramWorkout activeDayId={ activeId } isLastDay={ activeId === lastDayId }/>
        { dataDays !== null && dataDays.adviceVideoUrl !== undefined && dataDays.adviceVideoUrl !== '' ? <AdviceDiet activeDayId={ activeId }/> : null }

        <Button className={ styles.btnNextDay } size="large" mobile onClick={ activeId === lastDayId ? handleClickCompleteWeek : handleClickNextDay }>
          { activeId === lastDayId ? t('individualProgram.weekend') : t('individualProgram.nextDay') }
        </Button>
        <Button className={ styles.setReminders } size="large" mobile onClick={ onOpenModalNotifications }>
          { t(`individualProgram.${containerNotification.items.length > 0 ? 'reminders' : 'setReminders'}`) }
        </Button>
      </Grid>
    </>
  );
};

export default flow([ContainerNotification])(ProgramLayout);
