import { ELoadStatus } from 'app/models/shared.model';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { QUIZ_ACTIONS } from 'app/entities/quiz/quiz.actions';

export interface IAnswer {
  image?: string;
  text?: string;
  index: number;
}

interface IQuestion {
  id: number;
  answerList: IAnswer[];
  text: string;
  image?: string;
  index: number;
}

export interface IQuizInitialState {
  quizId: string;
  totalCount: number;
  question: IQuestion;
  result: boolean[];
  isQuizOver: boolean;
  isLastQuestion: boolean;
  canPostAnswer: boolean;
  correctCount: number;
  status: ELoadStatus;
  error: Error;
}

const initialState: IQuizInitialState = {
  quizId: null,
  totalCount: 0,
  isLastQuestion: null,
  isQuizOver: false,
  canPostAnswer: true,
  question: {} as IQuestion,
  result: [] as boolean[],
  correctCount: null,
  status: ELoadStatus.not_ready,
  error:  null as Error
};

export type IQuizState = Readonly<typeof initialState>;

export default (state = initialState, action): IQuizState => {
  const { type, payload } = action;
  switch (type) {
    // НАЧАЛО ИГРЫ
    case REQUEST(QUIZ_ACTIONS.START_QUIZ):
      return {
        canPostAnswer: initialState.canPostAnswer,
        isQuizOver: initialState.isQuizOver,
        isLastQuestion: initialState.isLastQuestion,
        result: initialState.result,
        quizId: initialState.quizId,
        totalCount: initialState.totalCount,
        question: initialState.question,
        correctCount: initialState.correctCount,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(QUIZ_ACTIONS.START_QUIZ):
      return {
        ...state,
        quizId: payload.data.gameId,
        totalCount: payload.data.totalCount,
        question: payload.data.question,
        status: ELoadStatus.ready
      };
    case FAILURE(QUIZ_ACTIONS.START_QUIZ):
      return {
        ...state,
        status:  ELoadStatus.error,
        error: new Error('quiz fetch failed')
      };
    // ОТПРАВКА ОТВЕТА НА ВОПРОС
    case REQUEST(QUIZ_ACTIONS.POST_ANSWER):
      return {
        ...state,
        canPostAnswer: false,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(QUIZ_ACTIONS.POST_ANSWER):
      return {
        ...state,
        isLastQuestion: payload.data.last,
        result: [...state.result, payload.data.correct],
        status: ELoadStatus.ready
      };
    case FAILURE(QUIZ_ACTIONS.POST_ANSWER):
      return {
        ...state,
        status:  ELoadStatus.error,
        error: new Error('quiz fetch failed')
      };
    // ПОЛУЧЕНИЕ СЛЕДУЮЩЕГО ВОПРОСА
    case REQUEST(QUIZ_ACTIONS.FETCH_QUIZ):
      return {
        ...state,
        question: initialState.question,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(QUIZ_ACTIONS.FETCH_QUIZ):
      return {
        ...state,
        canPostAnswer: true,
        question: payload.data.question,
        status: ELoadStatus.ready
      };
    case FAILURE(QUIZ_ACTIONS.FETCH_QUIZ):
      return {
        ...state,
        status:  ELoadStatus.error,
        error: new Error('quiz fetch failed')
      };
      // ЗАВЕРШЕНИЕ ИГРЫ
    case REQUEST(QUIZ_ACTIONS.END_QUIZ):
      return {
        ...state,
        status: ELoadStatus.loading,
        error: initialState.error
      };
    case SUCCESS(QUIZ_ACTIONS.END_QUIZ):
      return {
        ...state,
        isQuizOver: true,
        correctCount: payload.data.correctCount,
        quizId: payload.data.gameId,
        totalCount: payload.data.totalCount,
        question: initialState.question,
        status: ELoadStatus.ready
      };
    case FAILURE(QUIZ_ACTIONS.END_QUIZ):
      return {
        ...state,
        status:  ELoadStatus.error,
        error: new Error('quiz fetch failed')
      };
    case QUIZ_ACTIONS.RESET_QUIZ:
      return initialState;
    default:
      return state;
  }
};
