import React, { FC, createElement } from 'react';
import classnames from 'classnames';

import { ITypographyHeadingProps } from './typography.models';
import { useTypographyHeadingStyles } from './typography.styles';

export const TypographyHeading: FC<ITypographyHeadingProps> = props => {
  const { className, style, mobile, level, tag, attrs, children, onClick } = props;
  const styles = useTypographyHeadingStyles();

  const heading = `h${level}`;
  return createElement(
    tag || heading,
    { className: classnames(className, styles?.[heading], { [styles.mobile]: mobile }), style, ...attrs, onClick },
    children
  );
};

TypographyHeading.defaultProps = {
  attrs: null,
  level: 1,
  mobile: false
} as ITypographyHeadingProps;
