//#region imports
import React, { FC, memo, useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import toUpper from 'lodash/toUpper';
import flow from 'lodash/fp/flow';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerSectionItem } from 'app/containers/container.section.item';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerUser } from 'app/containers/container.user';
import { ContainerSubscribe } from 'app/containers/container.subscribe';

import ContentMediaVideo from './video/content.media.video.index';
import ContentMediaHtml5 from './html5/content.media.html5.index';
import { IDesktopContentMediaPageProps } from './page.content.media.models';
import { useDesktopContentMediaPageStyles } from './page.content.media.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';
//#endregion

const DesktopContentMediaPage: FC<IDesktopContentMediaPageProps> = memo<IDesktopContentMediaPageProps>(props => {
  const { containerUser, containerContentItem, containerSectionItem, containerSubscribe } = props;

  const lastLocation = useLastLocation();
  const history = useHistory();
  const params = useParams<any>();
  const styles = useDesktopContentMediaPageStyles();

  const content = containerContentItem.item;
  const section = containerContentItem.section?.section;
  const subscribe = containerContentItem.section?.subscribe;
  const accessible = containerUser.authenticated;

  const location = useLocation<any>();
  const contentId = params?.contentId || 0;
  const contentType = toUpper(content?.type) as EContentType;
  const distribution = containerContentItem.section?.distribution;
  const sectionId = section?.parentId ?? section?.id;
  const subsectionId = section?.parentId ? section?.id : undefined;
  const categoryId = containerContentItem.section?.subcategory?.categoryId ?? containerSectionItem.subcategory?.categoryId;
  const subcategoryId = containerContentItem.section?.subcategory?.id ?? containerSectionItem.subcategory?.id;

  useEffect(() => {
    if (contentId) {
      containerContentItem.actions.fetchContentItem(contentId, { cancellable: true });
      props.containerContentItem.actions.logView();
    }
    return () => {
      containerContentItem.actions.resetContentItem();
    };
  }, [contentId, containerSubscribe.status.change]);

  const loading = containerContentItem.status.item === ELoadStatus.loading;
  const ready = containerContentItem.status.item === ELoadStatus.ready;

  useEffect(() => {
    if (ready) {
      if (!containerContentItem?.item?.free && subscribe.status !== ESubscriptionStatus.ACTIVE) {
        history.replace('/');
      }
    }
  }, [ready]);

  if (location?.state?.src || (ready && (containerContentItem?.item?.free || subscribe?.status === ESubscriptionStatus.ACTIVE))) {
    switch (contentType || params?.contentType) {
      case EContentType.VIDEO:
        return (
          <ContentMediaVideo
            containerUser={ containerUser }
            history={ history }
            location={ location }
            subscribe={ subscribe }
            contentId={ contentId }
            sectionId={ sectionId }
            src={ location?.state?.src }
            title={ location?.state?.title }
            subsectionId={ subsectionId }
            content={ content }
            section={ section }
            contentType={ contentType }
            accessible={ accessible }
            distribution={ distribution }
            loading={ loading }
            ready={ ready }
          />
        );
      case EContentType.GAME_HTML5:
        return (
          <ContentMediaHtml5
            containerUser={ containerUser }
            history={ history }
            subscribe={ subscribe }
            contentId={ contentId }
            sectionId={ sectionId }
            subsectionId={ subsectionId }
            content={ content }
            section={ section }
            accessible={ accessible }
            loading={ loading }
            ready={ ready }
          />
        );
      default:
        return <div className={ styles.root } />;
    }
  }

});
DesktopContentMediaPage.displayName = 'DesktopContentMediaPage';

export default flow([ContainerUser, ContainerContentItem, ContainerSectionItem, ContainerSubscribe])(DesktopContentMediaPage);
