import React from 'react';
import { IRootState } from 'app/shared/reducers/root.reducer';
import { Dispatch } from 'redux';

import { connect } from 'app/containers/connect';
import { startQuiz, endQuiz, fetchQuiz, postQuizAnswer, IAnswerIndexes, resetQuiz } from 'app/entities/quiz/quiz.actions';
import { IQuizInitialState, IQuizState } from 'app/entities/quiz/quiz.reducer';

export type IContainerQuizProps = IQuizState & {
  actions: {
    startQuiz: (id: IQuizInitialState['quizId']) => void;
    endQuiz: (id: IQuizInitialState['quizId']) => void;
    fetchQuiz: (id: IQuizInitialState['quizId']) => void;
    postQuizAnswer: (indexes: IAnswerIndexes, id: IQuizInitialState['quizId']) => void;
    resetQuiz: () => void;
  };
};

const mapStateToProps = (state: IRootState) => state.quiz;
const mapDispatchToProps = (dispatch: Dispatch): IContainerQuizProps['actions'] => ({
  startQuiz: id => dispatch(startQuiz(id)),
  endQuiz: id => dispatch(endQuiz(id)),
  fetchQuiz: id => dispatch(fetchQuiz(id)),
  postQuizAnswer: (indexes, id) => dispatch(postQuizAnswer(indexes, id)),
  resetQuiz: () => dispatch(resetQuiz())
});

export const ContainerQuiz = connect<IContainerQuizProps>('containerQuiz', mapStateToProps, mapDispatchToProps);
