import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import flow from 'lodash/flow';

import {
  ContainerDietIndividualProgram,
  IContainerDietIndividualProgramProps
} from 'app/containers/container.diet.program';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';

import { ContentHeader } from 'app/components/header/content.header';
import ProgramLayout from 'app/pages/mobile/diet/program/program.layout';
import { useStylesMobileIndividualProgram } from './individual.program.styles';
import { stylesNavbar } from 'app/components/navbar/styles.navbar';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { DIET, EDietPageSection } from 'app/configs/route.names';
import ProductsList from 'app/pages/shared/diet/products/products.list.index';
import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import subscribeCheck from 'app/utils/util.subscribe.check';

import { MobileNavbarMenu } from 'a1s-tele2-react-ui/src/components/mobile/menu/navbar/menu.navbar.index';

import { IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { loginExternal } from 'app/utils/auth.external';
import { ELoadStatus } from 'app/models/shared.model';

interface IDietIndividualProgramMobileProps {
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  modalsContainer: IModalsContainer;
}

const DietIndividualProgramMobile: FC<IDietIndividualProgramMobileProps> = ({
  containerDietIndividualProgram,
  containerUser,
  containerSubscribe,
  modalsContainer
}) => {
  const {
    dataDays,
    itemsDay,
    programStatus,
    foodBasketText,
    replacementText,
    itemUserAttributes,
    progressStatus,
    last,
    actions: {
      getDietIndividualProgramDayById,
      getDietIndividualProgram,
      getDietStatus,
      postNextWeek,
      saveDietItem,
      resetDietIndividualProgram
    }
  } = containerDietIndividualProgram;
  const { authenticated, request: { status: userStatus } } = containerUser;
  const {} = containerSubscribe;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<any>();
  const styles = useStylesMobileIndividualProgram();
  const classesNavbar = stylesNavbar();
  const [state, setState] = useState(t('header.diet').toString());
  const [dietDays, setDietDays] = useState([
    { id: 1, title: `${t('individualProgram.day')} 1`, eatings: [] },
    { id: 2, title: `${t('individualProgram.day')} 2`, eatings: [] },
    { id: 3, title: `${t('individualProgram.day')} 3`, eatings: [] },
    { id: 4, title: `${t('individualProgram.day')} 4`, eatings: [] },
    { id: 5, title: `${t('individualProgram.day')} 5`, eatings: [] },
    { id: 6, title: `${t('individualProgram.day')} 6`, eatings: [] },
    { id: 7, title: `${t('individualProgram.day')} 7`, eatings: [] }
  ]);
  const [storageActiveDayId, setStorageActiveDayId] = useState(null);
  const activeSection = location?.state?.section || EDietPageSection.PROGRAM;

  const sectionList = [
    {
      name: t('diet.myProgram'),
      id: EDietPageSection.PROGRAM
    },
    {
      name: t('diet.listProduct'),
      id: EDietPageSection.PRODUCTS
    },
    {
      name: t('diet.myParameters'),
      id: EDietPageSection.PARAMETERS
    }
  ];

  const subscribe = containerSubscribe.subscriptions.find(sub => sub.id === programStatus?.id);

  useEffect(() => {
    return () => {
      if (localStorage.getItem('backUrl')) {
        localStorage.removeItem('backUrl');
      }
      resetDietIndividualProgram();
      setStorageActiveDayId(null);
      modalsContainer.actions.closeModal();
    };
  }, []);

  const backUrl = localStorage.getItem('backUrl');

  const onCancel = () => {
    if (itemUserAttributes && Object.values(itemUserAttributes).some(value => value === null)) {
      return backUrl === '/' ? history.push(backUrl) : history.goBack();
    } else {
      if (subscribe.status === ESubscriptionStatus.NOT_ACTIVE) {
        return backUrl === '/' ? history.push(backUrl) : history.goBack();
      } else if (subscribe.status === ESubscriptionStatus.NO_MONEY) {
        if (backUrl) {
          return backUrl === '/' ? history.push(backUrl) : history.go(-2);
        } else {
          history.goBack();
        }
      }
    }
  };

  useEffect(() => {
    if (!authenticated && userStatus.msisdn === ELoadStatus.ready) {
      loginExternal(history.location.pathname, false);
      return;
    }
    if (subscribe) {
      subscribeCheck({
        subscribe,
        callback: () => {
          getDietIndividualProgram();
        },
        callbackCancel: () => {
          onCancel();
        },
        containerUser,
        containerModals: modalsContainer
      });
    }
  }, [subscribe, authenticated, userStatus.msisdn]);

  const fetchDietById = useCallback(() => {
    if (authenticated && subscribe?.status === ESubscriptionStatus.ACTIVE) {
      if (localStorage.getItem('activeDayId') || storageActiveDayId) {
        if (localStorage.getItem('activeDayId')) {
          const id = parseInt(localStorage.getItem('activeDayId'), 10);
          setStorageActiveDayId(id);
          getDietIndividualProgramDayById(id);
          localStorage.removeItem('activeDayId');
        } else {
          getDietIndividualProgramDayById(storageActiveDayId);
        }
      } else {
        if (itemsDay?.length) {
          getDietIndividualProgramDayById(itemsDay[0].id);
        }
      }
    }
  }, [itemsDay, authenticated, subscribe]);

  useLayoutEffect(() => {
    fetchDietById();
  }, [itemsDay]);

  useEffect(() => {
    if (Object.keys(itemUserAttributes).length) {
      if (localStorage.getItem('individualParameters')) {
        const individualParameters = JSON.parse(localStorage.getItem('individualParameters'));
        const mergeUserAttributes = {} as IUserAttributes;
        Object.keys(itemUserAttributes).forEach(key => {
          mergeUserAttributes[key] = key === 'breastFeeding' ? individualParameters[key] || false : individualParameters[key] || itemUserAttributes[key];
        });
        saveDietItem(mergeUserAttributes);
        localStorage.removeItem('individualParameters');
      }
    }
  }, [itemUserAttributes]);

  const renderContent = useMemo(() => {
    switch (activeSection) {
      case EDietPageSection.PROGRAM:
        return (
          <ProgramLayout
            defaultDayId={ storageActiveDayId }
            dataDays={ dataDays }
            itemsDay={ itemsDay }
            getDietIndividualProgramDayById={ getDietIndividualProgramDayById }
            getDietIndividualProgram={ getDietIndividualProgram }
            itemUserAttributes={ itemUserAttributes }
            postNextWeek={ postNextWeek }
            last={ last }
          />
        );
      case EDietPageSection.PRODUCTS:
        return <ProductsList foodBasketText={ foodBasketText } replacementText={ replacementText }/>;
      case EDietPageSection.PARAMETERS:
        return <Parameters />;
      default:
        return (
          <ProgramLayout
            defaultDayId={ storageActiveDayId }
            dataDays={ dataDays }
            itemsDay={ itemsDay }
            getDietIndividualProgramDayById={ getDietIndividualProgramDayById }
            getDietIndividualProgram={ getDietIndividualProgram }
            itemUserAttributes={ itemUserAttributes }
            postNextWeek={ postNextWeek }
            last={ last }
          />
        );
    }
  }, [activeSection, dataDays, itemsDay, getDietIndividualProgramDayById, foodBasketText, itemUserAttributes, last]);

  const handleClickSectionItem = section => {
    history.replace(DIET(section), { section });
  };

  return (
    <>
      <ContentHeader isClose closeRoute={ '/' }>
        { t('header.diet') }
      </ContentHeader>
      <div className={ styles.content }>
        <MobileNavbarMenu
          classesNavbar={ classesNavbar.navbar }
          navMenuItems={ sectionList }
          activeItemId={ activeSection }
          onClickItem={ handleClickSectionItem }
        />
        { renderContent }
      </div>
    </>
  );
};

export default flow([ContainerUser, ContainerDietIndividualProgram, ContainerSubscribe, ModalsContainer])(DietIndividualProgramMobile);
