import useMediaQuery from '@material-ui/core/useMediaQuery';

const useMobile = () => {
  const isMobile434 = useMediaQuery('(max-width: 434px)');

  return {
    isMobile434
  };
};

export default useMobile;
