import React from 'react';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core';
import classnames from 'classnames';

import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import ErrorIcon from '../../assets/img/error.icon.svg';

// configTheme
const color = {
  red: '#F53D5C',
  gray: '#D5D8DD',
  dark: '#9297A0'
};

const useStylesInput = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: error => `1px solid ${error ? color.red : color.gray}`,
      overflow: 'hidden',
      borderRadius: 8,
      fontFamily: 'inherit',
      backgroundColor: configTheme.colors.basic.secondary,
      '& .MuiFormLabel-root.Mui-focused': {
        color: color.dark
      },
      '&:hover': {
        backgroundColor: configTheme.colors.basic.secondary
      },
      '&$focused': {
        backgroundColor: configTheme.colors.basic.secondary,
        boxShadow: configTheme.colors.basic.secondary
      },
      '& input::-webkit-inner-spin-button': {
        display: 'none'
      }
    },
    label: {
      '& .MuiFormLabel-root': {
        color: color.dark,
        fontFamily: 'inherit'
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: `${color.dark}!important`
      },
      '& .MuiFormHelperText-root.Mui-error': {
        color: color.red,
        fontFamily: 'inherit'
      },
      '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
        marginLeft: 0
      }
    },
    floatingLabelFocusStyle: {
      '& .MuiFormLabel-root': {
        color: color.dark
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: `${color.dark}!important`
      }
    },
    focused: {}
  })
);

type TInputProps = Partial<OutlinedInputProps> | Partial<InputProps>;

export const CustomTextField = (props: TextFieldProps) => {
  const { error, className, InputProps: inputProps = {}, ...rest } = props;
  const classes = useStylesInput(error);

  const errorImg = error ? <img src={ ErrorIcon } alt="error" /> : inputProps?.endAdornment;

  return (
    <TextField
      className={ classnames(className, classes.label) }
      InputProps={ { ...inputProps, classes: { root: classes.root }, disableUnderline: true, endAdornment: errorImg } as TInputProps }
      error={ error }
      { ...rest }
    />
  );
};
