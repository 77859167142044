
import { EMePageContent, EMenuItem, EUnsubscribeButtons } from 'app/configs/theme/config.theme.const';
import { ESubscriptionStatus } from 'app/models/model.subscription';

import { createStyles, Theme, ThemeOptions } from '@material-ui/core';

export const uiSetup = {
  isAuthModal: false,
  bookAuthor: true,
  infoBlockHeader: false,
  maxNotificationsInfo: true,
  isAuthExternal: true,
  afterAuthExternalLink: '/',
  sameWidthButton: false,
  contentHeaderWithClose: true,
  showDietTargetInCategory: true,
  showAccessTypeInCategory: true,
  catalogueInSingleLayout: true,
  downloadBeforeDescription: true,
  msisdnInMyData: true,
  dataNotEnteredPlaceholder: true,
  subscribeWithHeader: true,
  floatLabel: true,
  separateErrors: true,
  withGame: true,
  withLogging: true,
  dietTargetFilter: true,
  preInfo: true,
  dietErrorAfterHeader: true,
  showAsterisk: false,
  defaultAttrsEmpty: true,
  maxNotificationsInfoText: true,
  maxNotificationsInErrorModal: true,
  pages: {
    error: {
      isVisibleFooter: false
    },
    me: {
      showNotifications: [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY],
      editAttributes: [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY],
      showAttributes: [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY],
      content: [EMePageContent.DATA, EMePageContent.SUBSCRIPTIONS, EMePageContent.NOTIFICATIONS],
      subscribePopup: true
    }
  },
  blocks: {
    banner: {
      bannerLink: '/category/1',
      isBannerSubtitle: true
    }
  },
  common: {
    redirect: {
      on: deviceDetect => !deviceDetect.isMobile,
      link: 'https://tele2.ru'
    }
  },
  menu: {
    languagePicker: false,
    logoText: true,
    mobileCloseButton: false,
    mobileLegalInfo: false,
    items: [EMenuItem.GAME]
  },
  modal: {
    subscribe: {
      captcha: false,
      customText: true,
      cancelButton: {
        show: false,
        outlined: false,
        center: false
      }
    },
    unsubscribe: {
      buttons: [EUnsubscribeButtons.CANCEL, EUnsubscribeButtons.UNSUBSCRIBE]
    }
  }
};

export const middlewareSetup: { devicesMiddleware } = {
  devicesMiddleware: {}
};

export const calculatorValidation = {
  height: height => height >= 100 && height <= 250,
  weight: weight => weight >= 30 && weight <= 200,
  age: age => age >= 14 && age <= 114
};

export const notificationValidation = {
  text: text => text.length > 0 && text.length <= 70,
  time: { }
};

export const phone = {
  phonemask: ['+', /9/, /9/, /2/, /([0589])/, /([058])/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  placeholder: '+____________',
  initialValue: '+992'
};

export const themeObject: ThemeOptions = {
  palette: {
    primary: {
      main: '#202229'
    }
  }
};

export const stylesApp = {
  themeBgColor: '#fffff',
  mediaBlockHeight: 250,
  mediaBlockHeightSM: 229,
  mediaBlockHeightXS: 100,
  mediaBlockSpacing: 9,
  mediaBlockSpacingSM: 4,
  mediaBlockSpacingXS: 1,
  footerTopPadding: 36,
  categoryCardLines: {
    small: 1,
    big: 2
  },
  categoryCardSize: {
    xs: 12,
    sm: 12,
    md: 6
  },
  calculatorSize: {
    height: {
      xs: 12,
      sm: 12
    },
    weight: {
      xs: 12,
      sm: 12
    },
    age: {
      xs: 12,
      sm: 12
    },
    target: {
      xs: 12,
      sm: 12
    },
    sex: {
      xs: 5,
      sm: 6
    },
    breastFeeding: {
      xs: 12,
      sm: 12
    }
  }
};

export const phoneMask = {
  me: (phoneNumber: string) => {
    if (phoneNumber.length === 11) {
      const code = phoneNumber.slice(0, 1);
      const operator = phoneNumber.slice(1, 4);
      const firstNum = phoneNumber.slice(4, 7);
      const middleNum = phoneNumber.slice(7, 9);
      const lastNum = phoneNumber.slice(9);
      return `+${code} ${operator} ${firstNum}-${middleNum}-${lastNum}`;
    }
    return phone;
  },
  subscribeModal: (phoneNumber: string) => {
    if (phoneNumber.length === 11) {
      const code = phoneNumber.slice(0, 1);
      const operator = phoneNumber.slice(1, 4);
      const firstNum = phoneNumber.slice(4, 7);
      const middleNum = phoneNumber.slice(7, 9);
      const lastNum = phoneNumber.slice(9);
      return `+${code} (${operator}) ${firstNum}-${middleNum}-${lastNum}`;
    }
    return phone;
  }
};

const APP_PADDING = 18;

export const iconsGlyph = {
  arrow: {
    size: {
      width: 50,
      height: 50
    },
    unicode: '\uE068'
  }
};

const colors = {
  borderColor: '#D1D8D6',
  grayTextColor: '#8F9399',
  darkBorderColor: '#d1dbd6',
  blackTextColor: '#1F2229',
  blackBgColor: '#202229',
  grayBgColor: '#F5F5FA',
  errorTextColor: '#d72f4b',
  errorBgColor: '#f3c0c9',
  arrowColor: '#9e9e9e',
  greenColor: '#41a674'
};

export const stylesLayout = (theme: Theme) => createStyles({
  root: {
    maxWidth: '1440px',
    padding: `0 ${APP_PADDING}px`,
    margin: 'auto',
    minHeight: '100%',
    position: 'relative',
    boxSizing: 'border-box'
  },
  appRoot: {
    // height: '100vh',
    width: '100vw',
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
    // position: 'fixed'
  },
  contentRoot: {
    // overflowY: 'auto'
  }
});

export const stylesMenu = (theme: Theme) => createStyles({
  root: {
    background: 'white',
    height: 32,
    padding: '10px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
    overflowX: 'hidden',
    borderBottom: `1px solid ${colors.borderColor}`
  },
  menuOpened: {
    background: colors.grayBgColor,
    '& $groupItem': {
      display: 'none'
    },
    '& $barIcon': {
      paddingRight: 5,
      fontSize: 28,
      color: colors.blackTextColor,
      '&:before': {
        content: "'\\E059'"
      }
    }
  },
  groupedItems: {
    display: 'flex',
    alignItems: 'stretch'
  },
  groupItem: {
    fontSize: 'var(--medium-1)',
    cursor: 'pointer',
    userSelect: 'none',
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 14px',
    '&.active': {
      color: 'var(--main)',
      '&:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        bottom: `10px`,
        left: 0,
        display: 'block',
        height: '4px',
        background: 'var(--main)'
      }
    }
  },
  login: {
    fontSize: 'var(--small-1)',
    textDecoration: 'none',
    color: colors.grayTextColor,
    display: 'flex',
    alignItems: 'center',
    '&:after': {
      content: "''",
      height: '1.5rem',
      width: 1,
      backgroundColor: colors.borderColor
    },
    '& span': {
      fontFamily: 'icons',
      fontSize: 38,
      padding: '0 12px',
      color: 'inherit'
    }
  },
  fixedBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    alignItems: 'center',
    overflow: 'hidden'
  },
  notFixed: {
    zIndex: 0
  },
  barIcon: {
    '&:before': {
      content: `'\\E050'`,
      width: 24,
      height: 14,
      fontFamily: 'icons'
    },
    width: 34,
    color: colors.grayTextColor,
    borderRadius: 25,
    textAlign: 'center',
    height: 34,
    lineHeight: '34px',
    fontSize: 24,
    cursor: 'pointer'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center'
  },
  mobileToolbar: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 999,
    background: '#fff',
    top: 80,
    borderTop: `1px solid ${colors.darkBorderColor}`,
    lineHeight: '50px',
    height: '100vh',
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '& $groupItem': {
      borderBottom: `1px solid ${colors.darkBorderColor}`,
      color: colors.grayTextColor,
      padding: '13px 18px',
      lineHeight: '22px'
    }
  },
  logo: {
    textDecoration: 'none',
    fontFamily: 'Stencil',
    fontSize: 31,
    lineHeight: '105%',
    letterSpacing: 1,
    '& span': {
      fontFamily: 'inherit'
    }
  },
  mobileLogo: {
    maxWidth: '50%',
    padding: '7px 6px',
    letterSpacing: 0,
    fontSize: 32,
    lineHeight: '40px',
    marginTop: '-2px'
  },
  links: {
    display: 'flex'
  },
  languages: { },
  language: { },
  legalInfo: { },
  closeButton: { },
  legalInfoInner: { },
  company: { },
  terms: { }
});

export const stylesHeader = (theme: Theme) => createStyles({
  h1: {
    fontFamily: '"Tele2 Display"',
    fontSize: '1.7rem',
    lineHeight: '128%',
    letterSpacing: 0.4
  },
  h2: {
    fontWeight: 'normal'
  },
  h3: {
    fontWeight: 'normal'
  },
  h4: {},
  h5: {},
  h6: {}
});

export const stylesFooter = (theme: Theme) => createStyles({
  footer: {
    position: 'absolute',
    bottom: 0,
    padding: `27px ${APP_PADDING}px`,
    background: colors.blackBgColor,
    margin: `0 -${APP_PADDING}px`,
    width: '100%',
    boxSizing: 'border-box'
  },
  company: {
    color: colors.grayTextColor,
    marginBottom: 12,
    lineHeight: '22px',
    minHeight: '22px',
    display: 'flex',
    '& a': {
      display: 'contents',
      color: '#fff'
    }
  },
  terms: {
    color: 'white',
    lineHeight: '22px',
    height: '22px',
    display: 'flex'
  }
});

export const stylesContentBookMain = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: 20,
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
    boxSizing: 'border-box',
    marginTop: 36,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  header: {
    fontSize: 23,
    fontFamily: "'Tele2 Display'",
    fontWeight: 'bold',
    marginTop: 36,
    lineHeight: '130%',
    marginBottom: 10
  },
  description: {
    marginTop: 38,
    lineHeight: '135%'
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      width: 180,
      display: 'block'
    }
  },
  text: {
    background: 'white',
    padding: '20px 40px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    minHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      padding: '0',
      background: 'transparent'
    },
    '& b': {
      fontWeight: 'normal',
      marginBottom: 36,
      display: 'block'
    }
  },
  downloadWrapper: {
    margin: '10px auto 0 auto',
    position: 'relative',
    display: 'inline-block'
  },
  button: {
    position: 'relative',
    zIndex: 1
  },
  downloadList: {
    position: 'absolute',
    background: 'white',
    top: '0',
    borderRadius: '1.5rem',
    display: 'none',
    flexDirection: 'column',
    listStyle: 'none',
    width: '100%',
    margin: 0,
    zIndex: 0,
    boxSizing: 'border-box',
    border: '1px solid rgba(0,0,0,0.5)',
    padding: '3rem 0 1rem 0',
    '&.expanded': {
      display: 'flex'
    }
  },
  listItem: {
    padding: '10px',
    cursor: 'pointer',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      background: 'rgba(0,0,0,0.1)'
    }
  },
  tags: {
    marginTop: 38,
    marginBottom: 36,
    lineHeight: '250%',
    overflow: 'hidden',
    '& a': {
      textDecoration: 'none',
      paddingRight: 40
    }
  }
});

export const stylesContentBookSecondary = (theme: Theme) => createStyles({
  active: {
    opacity: 0.5,
    zIndex: -1
  },
  preview: {
    flex: '150px 0 0',
    margin: '0 10px',
    '& img': {
      height: '300px',
      objectFit: 'cover',
      objectPosition: 'center',
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      width: 100,
      flex: '100px 0 0',
      '& img': {
        height: 'auto',
        width: '100%'
      }
    },
    '&:first-child': {
      marginLeft: 0
    }
  },
  previewName: {
    padding: 10,
    background: 'white',
    height: '3em',
    lineHeight: '1.5em',
    [theme.breakpoints.down('sm')]: {
      height: '1.5em',
      //   padding: '5px 10px',
      fontSize: '0.8rem',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  }
});

export const stylesContentVideoMain = (theme: Theme) => createStyles({
  mainItemWrap: {
    margin: `0 -${APP_PADDING}px`
  },
  mainItem: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  mainName: {
    padding: `14px ${APP_PADDING}px 7px`,
    boxSizing: 'border-box',
    marginBottom: 36,
    fontSize: 22,
    fontWeight: 'bold',
    color: 'white',
    background: colors.blackTextColor,
    overflow: 'hidden',
    '& h2': {
      color: 'inherit',
      fontSize: 'inherit',
      margin: 0,
      fontWeight: 'inherit',
      letterSpacing: '-0.2px'
    },
    '& a': {
      color: 'white',
      fontSize: 'initial',
      textDecoration: 'none',
      fontWeight: 'initial',
      paddingRight: 40,
      lineHeight: '250%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: -APP_PADDING,
      marginRight: -APP_PADDING,
      width: 'auto'
    }
  },
  tags: {
    paddingTop: 3
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '0 50px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
});

export const stylesContentVideoSecondary = (theme: Theme) => createStyles({
  active: {
    opacity: 0.5
  },
  preview: {
    display: 'inline-block',
    margin: '0 10px',
    width: '320px',
    '& img': {
      height: '180px',
      objectFit: 'cover',
      objectPosition: 'center',
      display: 'block',
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      width: '163px',
      '& img': {
        height: '100px'
      }
    },
    '&:first-child': {
      marginLeft: 0
    }
  },
  previewName: {
    padding: 10,
    background: 'white',
    height: '3em',
    lineHeight: '1.5em'
  }
});

export const stylesInput = (theme: Theme) => createStyles({
  span: {
    display: 'block'
  },
  spanRadio: {
    marginLeft: '10px'
  },
  inputInline: {
    width: 'unset',
    '& > div > span': {
      padding: 0
    },
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    }
  },
  label: {
    width: 'calc(100% + 8px + 8px)',
    display: 'block',
    margin: '9px 0',
    '& fieldset': {
      border: 0
    },
    '& input': {
      padding: 0,
      borderBottom: `2px solid ${colors.grayTextColor}`,
      paddingBottom: 5
    },
    '& .Mui-focused input': {
      borderBottom: `2px solid ${colors.blackTextColor}`
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(0, 0)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0, -19px) scale(0.75)'
    },
    '& .MuiSelect-root': {
      padding: 0,
      borderBottom: `2px solid ${colors.grayTextColor}`,
      paddingBottom: 4,
      borderRadius: 0
    },
    '& .Mui-focused .MuiSelect-root': {
      borderBottom: `2px solid ${colors.blackTextColor}`
    }
  },
  inlineLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '100%'
  },
  error: {
    display: 'flex',
    marginTop: 24,
    fontSize: 13,
    alignItems: 'flex-start',
    lineHeight: '150%',
    color: colors.errorTextColor,
    '&:before': {
      content: '"!"',
      display: 'inline-flex',
      height: 21,
      width: 21,
      marginRight: 7,
      position: 'relative',
      top: -2,
      borderRadius: '50%',
      background: colors.errorBgColor,
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 15
    }
  },
  withError: {
    '&$label': {
      '& input': {
        color: colors.errorTextColor,
        borderColor: colors.errorTextColor
      },
      '& .MuiSelect-root': {
        borderColor: colors.errorTextColor
      }
    }
  }
});

export const stylesButton = (theme: Theme) => createStyles({
  root: {
    padding: '13px 33px',
    background: colors.blackTextColor,
    color: 'white',
    borderRadius: '1000px',
    cursor: 'pointer',
    border: `2px solid ${colors.blackTextColor}`,
    fontSize: 'var(--medium-1)',
    transition: 'transform .3s',
    fontWeight: 'bold',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'default'
    },
    '&:focus': {
      outline: 'none'
    },
    '&.no-hover:focus': {
      transform: 'none'
    }
  },
  center: {
    display: 'block'
  },
  outlined: {
    background: 'transparent',
    color: 'var(--main)'
  },
  loading: {
    cursor: 'progress',
    '&:disabled': {
      cursor: 'progress'
    }
  },
  link: {
    padding: 0,
    '& a': {
      padding: '13px 33px',
      textDecoration: 'none',
      color: 'inherit',
      display: 'block'
    }
  }
});

export const stylesSwitch = (theme: Theme) => createStyles({
  root: {
    '&&': {
      width: 50,
      height: 30,
      padding: '0 0 2px',
      margin: 0
    }
  },
  switchBase: {
    '&&': {
      padding: 1,
      top: 2,
      '&$checked': {
        transform: 'translateX(24px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: colors.blackTextColor,
          opacity: 1,
          border: 'none'
        }
      }
    },
    '&&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    '&&': {
      width: 22,
      height: 22,
      color: 'white'
    }
  },
  track: {
    '&&': {
      borderRadius: 26 / 2,
      border: 0,
      backgroundColor: colors.darkBorderColor,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    }
  },
  checked: {},
  focusVisible: {}
});

export const stylesCollapsing = (theme: Theme) => createStyles({
  root: {
    borderBottom: `1px solid ${colors.darkBorderColor}`,
    padding: `24.5px 0 5px`,
    '& $root:last-child': {
      borderBottom: 'none'
    }
  },
  nonCollapsed: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& h2': {
      fontSize: 16,
      fontWeight: 600,
      margin: 0
    },
    '& h3': {
      fontSize: 16,
      margin: '12px 0'
    }
  },
  icon: {
    margin: '0px 11px -3px',
    fontFamily: 'icons',
    flexGrow: 1,
    textAlign: 'end',
    fontSize: 29,
    color: colors.arrowColor,
    '&:hover, &:focus': {
      outline: 'none'
    }
  }
});

export const useModalStyles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)'
  },
  inner: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    background: 'white',
    padding: APP_PADDING,
    '& h3': {
      margin: '3px 0 22px',
      fontSize: 27,
      fontFamily: "'Tele2 Display'",
      fontWeight: 'bold',
      lineHeight: '110%',
      width: 'calc(100% - 40px)'
    }
  },
  close: {
    position: 'absolute',
    top: 17,
    right: 19,
    cursor: 'pointer',
    fontFamily: 'icons',
    fontSize: 30,
    '&:hover': {
      opacity: '0.5'
    }
  },
  modalConfirmText: {
    color: colors.grayTextColor,
    display: 'flex',
    minHeight: 18,
    lineHeight: '18px',
    marginTop: 35,
    fontSize: 14
  },
  text: {
    lineHeight: '140%',
    display: 'block',
    whiteSpace: 'pre-wrap'
  },
  subscribeButton: {
    margin: '34px auto 0'
  },
  maxNotificationText: {
    color: colors.errorTextColor,
    fontSize: 14,
    display: 'block',
    marginTop: 16,
    letterSpacing: '-0.5px',
    marginBottom: -3
  },
  maxAmountNotification: {
    margin: '37px auto 0 !important',
    display: 'block'
  }
});

export const stylesError = (theme: Theme) => createStyles({
  root: {
    background: '#ffa9c5',
    padding: 20,
    color: 'red',
    marginTop: 20,
    display: 'inline-block'
  }
});

export const stylesSlider = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    paddingBottom: '200px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  },
  fullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginLeft: -APP_PADDING,
      marginRight: -APP_PADDING
    }
  },
  children: {
    overflowY: 'auto',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flex: '100%'
    },
    flexWrap: 'nowrap',
    flex: `calc(100% - ${ iconsGlyph.arrow.size.width * 2 }px) 0 0`,
    '& *': {
      flexShrink: '0',
      alignContent: 'center'
    },
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  icon: {
    flex: `${ iconsGlyph.arrow.size.width }px 0 0`,
    fontFamily: 'megaicons',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  backward: {
    transform: 'rotate(180deg)'
  }
});

export const stylesBanner = (theme: Theme) => createStyles({
  root: {
    margin: `0 -${APP_PADDING}px`
  },
  skeleton: {
    height: 380,
    background: colors.borderColor
  },
  wrapper: {
    position: 'relative'
  },
  bannerTextWrapper: {
    background: colors.blackTextColor,
    color: 'white',
    margin: `0 -${APP_PADDING}px`,
    padding: `15px ${APP_PADDING + 2}px 16px`,
    display: 'block',
    textDecoration: 'none',
    '& h1': {
      color: 'inherit',
      margin: 0,
      fontFamily: 'Stencil',
      fontSize: 24,
      lineHeight: '24px',
      whiteSpace: 'pre-line'
    },
    '& h2': {
      color: 'inherit',
      fontSize: 14,
      fontWeight: 'normal',
      margin: '3px 0 0',
      lineHeight: '18px'
    }
  },
  imgBannerWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  imgBanner: {
    [theme.breakpoints.down('sm')]: {
      height: '280px',
      width: 'auto !important'
    }
  }
});

export const stylesPageAuth = (theme: Theme) => createStyles({
  media: {
    height: 125,
    backgroundSize: '175px !important'
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  input: {
    padding: '12px 15px 13px 15px',
    margin: '7px 0',
    border: '1px solid',
    background: 'transparent',
    fontSize: '16px',
    height: 23
  },
  card: {
    boxShadow: '0 0 16px rgba(0,0,0,.15)',
    position: 'relative'
  },
  btn: {
    height: 46,
    borderRadius: 24,
    padding: '0px 62px'
  },
  helpButtons: {
    width: '100%',
    margin: '35px 0px 0',
    textAlign: 'center',
    padding: '0 0 40px',
    '& a': {
      fontSize: 14,
      color: '#34aaf2',
      textDecoration: 'none',
      cursor: 'pointer',
      padding: '10px'
    }
  },
  backIcon: {
    position: 'absolute',
    top: '42px',
    left: '24px',
    width: 32,
    height: 16,
    display: 'block',
    zIndex: 10
  },
  error: {
    padding: 25,
    margin: '15px 0 45px',
    background: '#ffbdbd',
    color: '#f10000'
  },
  modal: { }
});

export const stylesPageCalculator = (theme: Theme) => createStyles({
  form: {
    '&&': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '500px'
    }
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
    '& > *': {
      flex: '10px 1 0',
      paddingRight: 10,
      paddingLeft: 10,
      boxSizing: 'border-box'
    },
    '& > *:first-child': {
      paddingLeft: 0
    },
    '& > *:last-child': {
      paddingRight: 0
    }
  },
  button: {
    marginBottom: 16
  },
  error: {
    marginTop: 0,
    marginBottom: 20
  },
  text: {
    lineHeight: '140%',
    marginTop: 38,
    display: 'block',
    marginBottom: 20
  },
  height: { },
  weight: { },
  age: { },
  target: { },
  sex: {
    '&.MuiGrid-item': {
      padding: '0 8px'
    }
  },
  breastFeeding: {
    '&.MuiGrid-item': {
      padding: '0 8px',
      margin: '-8px 0'
    }
  },
  buttons: {
    display: 'inline-flex',
    flexDirection: 'column-reverse',
    alignSelf: 'flex-start',
    width: 'auto',
    flexBasis: 'auto',
    marginTop: 20
  }
});

export const stylesPageCategory = (theme: Theme) => createStyles({
  card: {
    '&&': {
      position: 'relative',
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  cardTitle: {
    '&&': {
      letterSpacing: '-0.2px',
      lineHeight: '1.5em',
      padding: '9px 0 11px',
      fontSize: 22,
      fontWeight: 'bold'
    }
  },
  titleText: { },
  titleTarget: {
    display: 'block',
    fontSize: 16,
    lineHeight: '22px',
    marginTop: 3,
    fontWeight: 'normal',
    color: colors.grayTextColor
  },
  contentHeader: {
    margin: '1px 0 0',
    fontSize: 16,
    flexGrow: 1,
    color: 'inherit',
    letterSpacing: '0px'
  },
  contentHeaderWrapper: {
    margin: `0 -${APP_PADDING}px`,
    padding: `11px ${APP_PADDING}px`,
    background: colors.grayTextColor,
    color: 'white',
    display: 'flex'
  },
  contentHeaderClose: {
    fontFamily: 'icons',
    color: 'inherit',
    fontSize: 25,
    margin: -3,
    textDecoration: 'none'
  },
  navWrapper: {
    '& > .MuiBox-root': {
      padding: '0 0 20px 0'
    }
  },
  contentType: {
    position: 'absolute',
    fontFamily: 'icons',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 32,
    margin: 18,
    padding: '4px',
    textAlign: 'center',
    backgroundColor: colors.blackTextColor,
    color: 'white',
    borderRadius: '50%',
    height: '40px',
    width: '40px'
  },
  accessType: {
    position: 'absolute',
    top: 180,
    left: 18,
    backgroundColor: colors.blackTextColor,
    color: 'white',
    fontSize: 14,
    padding: '7px 9px 6px'
  },
  filterItem: {
    marginRight: 28,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: '17px 0',
    '& h3': {
      color: 'inherit'
    },
    color: colors.grayTextColor,
    '&.active': {
      color: colors.blackTextColor,
      '& h3': {
        color: 'inherit'
      }
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  },
  filterText: {
    margin: 0,
    fontSize: 13
  },
  moreButton: {
    margin: 'auto',
    marginTop: 21,
    marginBottom: -18
  },
  gridContainer: {
    '&&': {
      margin: '-16px',
      '& .MuiGrid-item': {
        width: '100%',
        padding: '16px 16px 10px'
      }
    }
  },
  dietTargetFilter: {
    margin: `0 -${APP_PADDING}px`,
    padding: `0 ${APP_PADDING}px`,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      height: 1,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.borderColor
    }
  },
  dietTargetFilterItem: {
    marginRight: 28,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: '17px 0 16px',
    '& h3': {
      color: 'inherit'
    },
    color: colors.grayTextColor,
    position: 'relative',
    '&.active': {
      color: colors.blackTextColor,
      '&:after': {
        content: '""',
        position: 'absolute',
        height: 1,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: colors.blackTextColor,
        zIndex: 1
      },
      '& h3': {
        color: 'inherit'
      }
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    },
    '&:last-child': {
      marginRight: 0
    }
  }
});

export const stylesPageMain = (theme: Theme) => createStyles({
  itemIcon: {
    background: '#f0f1f2',
    width: '100%',
    height: 196,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  itemText: {
    display: 'block',
    marginTop: 11,
    fontSize: 14,
    lineHeight: '18px'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%'
  },
  header: {
    marginTop: 36,
    marginBottom: 23,
    fontSize: '28px',
    lineHeight: '34px',
    letterSpacing: '0'
  },
  itemHeader: { },
  infoBlocks: {
    '&&': {
      width: 'calc(100% + 34px * 2)',
      margin: -34,
      padding: '20px 18px',
      overflowX: 'auto',
      display: 'flex',
      flexWrap: 'nowrap',
      '&& > .MuiGrid-item': {
        flex: '0 0 250px',
        padding: 0,
        paddingRight: 14
      }
    }
  },
  preInfoBlock: {
    borderRadius: 6,
    flexDirection: 'column',
    display: 'flex',
    margin: '0 8px 11px',
    padding: '18px 18px 27px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, .2)',
    width: '100%',
    textDecoration: 'none'
  },
  preInfoHeader: {
    fontWeight: 700,
    fontSize: 22,
    fontFamily: '"Tele2 Display"',
    lineHeight: '26px',
    marginBottom: 11,
    display: 'flex'
  },
  preInfoText: {
    lineHeight: '22px',
    display: 'flex'
  },
  preInfoBlocks: {
    margin: '-16px -24px -27px'
  },
  preInfoBlocksBox: {
    '&&': {
      padding: '24px 16px 16px'
    }
  }
});

export const stylesPageMe = (theme: Theme) => createStyles({
  row: {
    display: 'flex',
    // '& > div:nth-child(2)': {
    //   flex: '250px 0 0',
    //   alignSelf: 'center'
    // },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
      //   '& > div:nth-child(2)': {
      //     flex: 'auto',
      //     margin: '18px auto 35px 0'
      //   }
    }
  },
  modalButtons: {
    display: 'inline-flex',
    marginTop: 34,
    flexDirection: 'column',
    '& button': {
      marginBottom: 17
    }
  },
  modalConfirmText: {
    color: colors.grayTextColor,
    display: 'flex',
    minHeight: 18,
    lineHeight: '18px',
    marginTop: 35,
    fontSize: 14
  },
  rowButton: {
    color: colors.blackTextColor,
    background: 'white',
    border: `2px solid ${colors.blackTextColor}`,
    margin: '18px auto 0 0',
    '& a': {
      padding: '13px 35px'
    }
  },
  rowButtonOutlined: {
    padding: 0,
    background: 'transparent',
    textDecoration: 'underline',
    border: '2px solid transparent',
    fontWeight: 'normal'
  },
  exitButton: {
    marginBottom: 1,
    lineHeight: '22px',
    marginTop: 36
  },
  sectionHeader: {
    fontFamily: "'Tele2 Display'",
    fontWeight: 'bold',
    fontSize: 28,
    margin: '36px 0 18px',
    lineHeight: '34px',
    '& ~ hr': {
      display: 'none'
    }
  },
  personalDataRow: {
    display: 'flex',
    lineHeight: '22px',
    marginBottom: '9px',
    '& span': {
      flex: '0 0 44%',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center'
    },
    '& b': {
      flex: '0 0 50%',
      fontWeight: 'normal'
    }
  },
  notificationsWrapper: { },
  subscriptionsWrapper: {
    background: colors.blackTextColor,
    color: 'white',
    margin: `36px -${APP_PADDING}px 0`,
    padding: `1px ${APP_PADDING}px`,
    '& *': {
      color: 'inherit'
    },
    '& $rowButton': {
      marginBottom: 36
    },
    '& $rowButton:not($rowButtonOutlined)': {
      color: 'white',
      background: colors.blackTextColor,
      border: '2px solid white'
    }
  },
  myDataWrapper: { },
  subscriptionHeader: {
    fontSize: 22,
    fontWeight: 'bold',
    marginTop: '-2px',
    marginBottom: 7,
    lineHeight: '32px'
  },
  subscriptionCondition: {
    lineHeight: '22px'
  }
});

export const stylesPageDiet = (theme: Theme) => createStyles({
  dayList: {
    margin: `0 -${APP_PADDING}px`,
    overflowX: 'auto'
  },
  dayListInner: {
    marginTop: 0,
    padding: `0 ${APP_PADDING}px`,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginBottom: 0,
    position: 'relative',
    '&:after': {
      bottom: 0,
      content: '""',
      left: 0,
      right: 0,
      height: 1,
      background: colors.borderColor,
      position: 'absolute',
      zIndex: 0
    }
  },
  h1: {
    marginTop: 0
  },
  dayItem: {
    listStyle: 'none',
    padding: '17px 0 16px',
    cursor: 'pointer',
    position: 'relative',
    flexShrink: 0,
    zIndex: 0,
    fontSize: 14,
    marginRight: 27,
    color: colors.grayTextColor,
    display: 'inline-block',
    '&.active': {
      color: colors.blackTextColor,
      zIndex: 1,
      '&:after': {
        bottom: 0,
        content: '""',
        left: -1,
        right: -1,
        height: 1,
        background: colors.blackTextColor,
        position: 'absolute',
        zIndex: 0
      }
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  content: {
    width: '900px',
    maxWidth: '100%',
    marginTop: 26,
    marginBottom: 34
  },
  collapseContent: {
    overflowY: 'hidden',
    transition: 'max-height .5s',
    maxHeight: '2000px',
    '&.collapsed': {
      maxHeight: 0
    }
  },
  button: {
    margin: '20px 0 0',
    color: colors.blackTextColor,
    background: 'white',
    border: `2px solid ${colors.blackTextColor}`
  },
  notification: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not($editButton)': {
      opacity: 0.5
    },
    '& h3:not($editButton)': {
      fontSize: 16
    },
    '&:first-child': {
      marginTop: 11
    },
    '&:last-child': {
      marginBottom: 23
    }
  },
  notificationEnabled: {
    '& > *:not($editButton)': {
      opacity: 1
    }
  },
  editButton: {
    fontFamily: 'icons',
    margin: '0 10px',
    color: 'inherit',
    cursor: 'pointer',
    marginRight: 0,
    fontSize: 32,
    marginTop: -2
  },
  video: {
    margin: `0 -${APP_PADDING}px 32px`,
    justifyContent: 'center',
    display: 'flex',
    padding: `0 ${APP_PADDING}px`,
    height: 'auto !important'
  },
  notificationModal: {
    '&&': {
      marginTop: 17,
      paddingRight: 16
    },
    '&& > .MuiGrid-item': {
      padding: '8px 16px',
      '& > .MuiGrid-item': {
        padding: 0,
        margin: '-10px 0'
      }
    }
  },
  deleteButton: {
    '&&': {
      alignItems: 'center'
    },
    '&&.MuiGrid-item': {
      padding: '34px 5px 9px'
    },
    '& h1': {
      fontFamily: 'icons',
      margin: '0 10px',
      color: 'inherit',
      cursor: 'pointer',
      marginRight: 0,
      fontSize: 32,
      marginTop: -2
    }
  },
  errorNotification: { },
  maxAmountNotification: {
    fontSize: 13,
    textAlign: 'center',
    width: '100%',
    margin: '14px 0 0 16px',
    color: colors.grayTextColor
  },
  modalInfoText: { },
  modalButtons: {
    '&&': {
      flexDirection: 'column-reverse',
      display: 'inline-flex',
      width: '100%',
      flexBasis: 'auto',
      marginTop: 15
    },
    '&& button': {
      marginBottom: 16
    }
  },
  notificationName: { },
  notificationTime: {
    margin: '0 10px'
  },
  notificationSwitch: {
    margin: '0 14px 0px 15px'
  },
  parsedContent: {
    lineHeight: '140%',
    marginTop: 18,
    marginBottom: 34,
    '& h1': {
      fontSize: 24,
      fontFamily: '"Tele2 Display"',
      lineHeight: '115%',
      margin: '18px 0px'
    },
    '& table': {
      width: '100%',
      '& thead': {
        '& th': {
          textAlign: 'left',
          padding: 0
        },
        '& tbody': {
          '& td': {
            height: 27
          }
        }
      }
    }
  },
  error: {
    margin: `0 -${APP_PADDING}px`,
    background: colors.errorBgColor,
    color: colors.errorTextColor,
    fontSize: 14,
    padding: '13px 18px'
  }
});

export const stylesContentArticleMain = (theme: Theme) => createStyles({
  root: {
    marginTop: 35,
    '& img': {
      maxWidth: '100%'
    },
    '& h1': {
      fontSize: 28,
      fontFamily: "'Tele2 Display'"
    },
    '& h2': {
      fontSize: 26,
      fontFamily: "'Tele2 Display'"
    },
    '& h3': {
      fontFamily: "'Tele2 Display'"
    },
    '& h4': {
      fontFamily: "'Tele2 Display'"
    },
    '& h5': {
      fontFamily: "'Tele2 Display'"
    },
    '& h6': {
      fontFamily: "'Tele2 Display'"
    },
    '& p': {
      lineHeight: '150%'
    },
    '& ol': {
      lineHeight: '150%'
    },
    '& ul': {
      lineHeight: '150%'
    }
  },
  tags: { }
});

export const stylesContentArticleSecondary = (theme: Theme) => createStyles({
  root: { }
});

export const stylesPageSubscription = (theme: Theme) => createStyles({
  text: {
    margin: '36px 0',
    display: 'block',
    lineHeight: '140%'
  },
  buttons: {
    display: 'inline-flex',
    flexDirection: 'column-reverse',
    '& button': {
      marginBottom: 18,
      width: 160,
      height: 48
    }
  }
});

export const stylesPageGame = (theme: Theme) => createStyles({
  game: { },
  questionIndex: {
    display: 'block',
    marginTop: 39,
    fontWeight: 'bold',
    marginBottom: 21
  },
  questionText: {
    marginBottom: 18,
    display: 'block',
    lineHeight: '137%'
  },
  questionImages: {
    marginBottom: 17
  },
  questionImage: {
    width: 'calc(50% - 6px)',
    marginRight: 12,
    position: 'relative',
    display: 'inline-block',
    paddingBottom: 'calc(50% - 6px)',
    backgroundSize: 'contain',
    '&:last-child': {
      marginRight: 0
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  imageRight: {
    '&:after': {
      border: `4px solid ${colors.greenColor}`
    }
  },
  imageWrong: {
    '&:after': {
      border: `4px solid ${colors.errorTextColor}`
    }
  },
  explanationText: {
    display: 'block',
    lineHeight: '137%',
    marginBottom: 35
  },
  nextButton: { },
  endResult: {
    background: colors.blackTextColor,
    color: 'white',
    margin: `0 -${APP_PADDING}px`,
    padding: `15px ${APP_PADDING + 2}px 13px`,
    textDecoration: 'none',
    display: 'block',
    '& h1': {
      color: 'inherit',
      margin: 0,
      fontFamily: '"Tele2 Display"',
      fontSize: 24,
      lineHeight: '110%',
      wordSpacing: -3,
      marginBottom: 5
    },
    '& h2': {
      color: 'inherit',
      fontSize: 16,
      fontWeight: 'normal',
      margin: '1px 0 0',
      wordSpacing: 0
    }
  },
  more: {
    'h2&': {
      marginTop: 6
    },
    '&:after': {
      content: '"\\E054"',
      fontFamily: 'icons',
      fontSize: 18,
      margin: '0px 0px 0px 2px',
      lineHeight: '17px',
      display: 'inline-block',
      position: 'relative',
      top: 2
    }
  },
  playAgainButton: {
    padding: 0,
    background: 'transparent',
    textDecoration: 'underline',
    border: '2px solid transparent',
    fontWeight: 'normal',
    margin: '36px auto 0',
    display: 'block',
    color: colors.blackTextColor
  },
  endImg: {
    margin: `0 -${APP_PADDING}px`,
    width: `calc(100% + ${APP_PADDING * 2}px)`,
    display: 'block'
  }
});
