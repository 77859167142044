import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useGetSet, useGetSetState, useUnmount } from 'react-use';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import shuffle from 'lodash/shuffle';
import assign from 'lodash/assign';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import flow from 'lodash/flow';
import find from 'lodash/find';
import pick from 'lodash/pick';
import some from 'lodash/some';
import map from 'lodash/map';

import { CONTENT_ITEM } from 'app/configs/route.names';
import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { eventBus } from 'app/utils/util.event';
import { loginExternal } from 'app/utils/auth.external';
import subscribeCheck from 'app/utils/util.subscribe.check';
import useLimiter from 'app/hooks/useLimiter';

import { CancelToken, ELoadStatus, IRouteProps } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';
import { ESectionLayout, ISectionItem } from 'app/models/model.section';
import { ESubscriptionStatus } from 'app/models/model.subscription';

import { ISectionListInitialState } from 'app/entities/section/entity.section.list.reducer';
import { ISectionListContainerProps, SectionListContainer } from 'app/containers/section.list.container';
import { CategoryContainer, ICategoryContainerProps } from 'app/containers/category.container';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';
import { ContainerContentList, IContainerContentListProps } from 'app/containers/container.content.list';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { IModalsContainer, ModalsContainer } from 'app/containers/modals.container';
import { ContainerLike, IContainerLikeProps } from 'app/containers/like.container';

import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import ItemCard from 'app/components/desktop/item.card/item.card.index';
import { Typography } from 'app/components/desktop/typography/typography.index';
import { Button } from 'a1s-tele2-react-ui/src/components/shared/button/button.index';
import { CategoryMenu } from '../../main/components/category/category.menu';
import { numberRegex } from 'app/configs/const';
import { ILikeItem } from 'app/entities/like/like.model';

export interface IDesktopContentListPageProps extends IRouteProps {
  containerUser: IAuthContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  containerCategory: ICategoryContainerProps;
  containerSectionItem: IContainerSectionItemProps;
  containerContentList: IContainerContentListProps;
  sectionListContainer: ISectionListContainerProps;
  modalsContainer: IModalsContainer;
  containerLike: IContainerLikeProps;
}

export const useDesktopContentListPageStyles = makeStyles(
  createStyles({
    root: { marginBottom: configTheme.indents['indentX4'].margin },

    caption: { marginTop: configTheme.indents['indentX3'].margin },

    contentList: { display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', marginTop: configTheme.indents['indentX3'].margin },
    contentItem: { margin: `${configTheme.indents['indentX3'].margin}px auto 0`, '&:first-child': { marginTop: 0 } },

    contentSheet: { display: 'flex', flexFlow: 'row wrap', marginTop: configTheme.indents['indentX3'].margin },
    contentCell: {
      margin: `${configTheme.indents['indentX3'].margin}px 30px 0 0`,
      flex: '1 1 calc((100% / 5) - 30px)',
      maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
      width: 'calc((100% / 5) - 30px + (30px / 5))',
      '&:nth-child(-n + 5)': { marginTop: 0 },
      '&:nth-child(5n)': { marginRight: 0 }
    },

    moreButton: { cursor: 'pointer', margin: `${configTheme.indents['indentX3'].margin}px auto 0` },

    recommendPack: { marginTop: configTheme.indents['indentX3'].margin },
    recommendList: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      marginTop: configTheme.indents['indentX2'].margin
    },
    recommendItem: {
      flex: '1 1 calc((100% / 3) - 15px)',
      maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
      width: 'calc((100% / 3) - 15px + (15px / 3))',
      margin: `${configTheme.indents['indentX3'].margin}px 15px 0 0`,
      '&:nth-child(-n + 3)': { marginTop: 0 },
      '&:nth-child(3n)': { marginRight: 0 }
    }
  })
);

const DesktopContentListPage: FC<IDesktopContentListPageProps> = props => {
  const {
    containerUser,
    containerSubscribe,
    containerCategory,
    containerSectionItem,
    containerContentList,
    sectionListContainer,
    containerLike
  } = props;

  const history = useHistory();

  const [categoryId, setCategoryId] = useState<ICategoryContainerProps['item']['id']>();
  const [sectionId, setSectionId] = useState<IContainerSectionItemProps['item']['id']>();

  const category = containerCategory.item;
  const subcategory = containerSectionItem.subcategory;
  const sectionItem = containerSectionItem.item;
  const subsections = containerSectionItem.subsections;
  const contentType = containerSectionItem.contentType;
  const subscribe = containerSectionItem.subscribe;

  const isUserAuthenticated = containerUser?.token && containerUser?.authenticated;
  const isUserSubscribed = subscribe?.status === ESubscriptionStatus.ACTIVE;

  const isSubcategoriesMenu = category.subcategories?.length === 1;
  const isSectionLayoutNone = category?.sectionLayout === ESectionLayout.NONE;
  const isContentLayoutList = !includes([EContentType.GAME_HTML5, EContentType.GAME_ANDROID], contentType);
  const isContentLayoutSheet = includes([EContentType.GAME_HTML5, EContentType.GAME_ANDROID], contentType);

  const [selectedSubcategoryId, setSelectedSubcategoryId] = useGetSet(subcategory?.id);
  const [selectedSectionId, setSelectedSectionId] = useGetSet(sectionId);
  const [selectedSubsectionId, setSelectedSubsectionId] = useGetSet(subsections?.[0]?.id);

  const [cancelTokenMap, setCancelTokenMap] = useGetSetState({
    fetchSectionItem: CancelToken.source(),
    fetchContentList: CancelToken.source()
  });
  const [paging, pagingEmitter] = useLimiter();

  const [sectionList, isSectionListLoading] = useMemo(() => {
    const thing = find(sectionListContainer.list, s => s.targetId === selectedSubcategoryId());
    const loading: boolean = some(sectionListContainer.list, ['status', ELoadStatus.loading]);
    const fields: Partial<ISectionListInitialState> = thing ? pick(thing, ['items', 'status', 'more']) : {};
    return [fields, loading];
  }, [sectionListContainer.list, selectedSubcategoryId()]);

  const recommends = useMemo(() => {
    if (contentType === EContentType.STUDY) {
      return slice(shuffle(filter(category?.subcategories, s => s.id !== selectedSubcategoryId())), 0, 3);
    }
    return slice(shuffle(filter(sectionList?.items, s => s.id !== selectedSectionId())), 0, 3);
  }, [
    contentType === EContentType.STUDY ? category?.subcategories : sectionList?.items,
    contentType === EContentType.STUDY ? selectedSubcategoryId() : selectedSectionId()
  ]);

  const { items: sections } = sectionList;
  const { items: contents, more: isContentsMore } = containerContentList;

  const styles = useDesktopContentListPageStyles();

  useEffect(() => {
    if (props.computedMatch.params) {
      setSectionId(Number(props.computedMatch.params.sectionId));
      setCategoryId(Number(props.computedMatch.params.categoryId));
    }
  }, [props.computedMatch.params]);

  useEffect(() => {
    props.modalsContainer.actions.closeModal();
  }, [history.location]);

  useEffect(() => {
    if (sectionId) {
      containerSectionItem.actions.fetchSectionItem(sectionId, {
        uncancelled: true,
        cancelToken: cancelTokenMap().fetchSectionItem?.token
      });
    }
    return () => {
      cancelTokenMap().fetchSectionItem?.cancel('Cancel fetchStream [containerSectionItem.actions.fetchSectionItem]');
      setCancelTokenMap({ fetchSectionItem: CancelToken.source() });
      containerSectionItem.actions.resetSectionItem();
    };
  }, [sectionId]);

  useEffect(() => {
    if (categoryId) {
      if (!numberRegex.test(props.computedMatch.params.sectionId)) {
        history.replace('/error');
      }
      containerCategory.actions.fetchCategoryItem(categoryId);
    }
    return () => {
      sectionListContainer.actions.resetSectionItems();
    };
  }, [categoryId]);

  useEffect(() => {
    if (subcategory?.id && subcategory.id !== selectedSubcategoryId()) {
      setSelectedSubcategoryId(subcategory.id);
      sectionListContainer.actions.fetchSectionItems({ id: subcategory.id, pager: { limit: 100, offset: 0 } });
    }
  }, [subcategory?.id]);

  useEffect(() => {
    if (sectionItem?.id && sectionItem.id !== selectedSectionId()) {
      setSelectedSectionId(sectionItem.id);
    }

    const firstSubsectionId = subsections?.[0]?.id;
    if (firstSubsectionId && firstSubsectionId !== selectedSubsectionId()) {
      setSelectedSubsectionId(firstSubsectionId);
    }
  }, [sectionItem?.id, subsections]);

  useEffect(() => {
    if (containerContentList.items.length) {
      containerContentList.actions.getItemLikes(containerContentList.items);
    }
  }, [containerContentList.items]);

  useEffect(() => {
    if (
      sectionItem?.id === selectedSectionId() &&
      ((!sectionItem?.hasSubsections && selectedSectionId()) || (sectionItem?.hasSubsections && selectedSubsectionId()))
    ) {
      let limit;
      switch (contentType) {
        case EContentType.BOOK:
        case EContentType.VIDEO:
        case EContentType.AUDIO:
        case EContentType.IMAGE:
          limit = isUserAuthenticated && isUserSubscribed ? 10 : 10;
          break;
        case EContentType.OLYMPIAD:
        case EContentType.STUDY:
          limit = 1000;
          break;
        case EContentType.GAME_HTML5:
        case EContentType.GAME_ANDROID:
        default:
          limit = 10;
          break;
      }
      pagingEmitter.invokeReset({ limit, offset: 0 });
      containerContentList.actions.fetchContentList({
        id: !sectionItem?.hasSubsections ? selectedSectionId() : selectedSubsectionId(),
        pager: paging.current(),
        cancelToken: cancelTokenMap().fetchContentList?.token
      });
      containerContentList.actions.logView();
    }
    return () => {
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      setCancelTokenMap({ fetchContentList: CancelToken.source() });
      containerContentList.actions.resetContentList();
    };
  }, [
    sectionItem?.id,
    sectionItem?.hasSubsections,
    contentType,
    isUserAuthenticated,
    isUserSubscribed,
    selectedSectionId(),
    selectedSubsectionId()
  ]);

  const validateSubscribe = callback => {
    subscribeCheck({
      subscribe: props.containerSubscribe.subscriptions.find(sub => sub.id === subscribe.id),
      callback,
      containerUser,
      containerModals: props.modalsContainer,
      history
    });
  };

  const handleSelectSubcategory = useCallback(
    debounce(async (targetId: number) => {
      const result = await sectionListContainer.actions.fetchSectionItems({ id: targetId, pager: { limit: 100, offset: 0 } });
      const data = result?.value?.data;
      const firstSectionId = data?.sections?.[0]?.id;
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      cancelTokenMap().fetchSectionItem?.cancel('Cancel fetchStream [containerSectionItem.actions.fetchSectionItem]');
      setSelectedSubcategoryId(targetId);
      setSelectedSectionId(firstSectionId);
      setSelectedSubsectionId(undefined);
      containerContentList.actions.resetContentList();
      if (firstSectionId) history.push({ pathname: firstSectionId });
    }, 300),
    []
  );

  const handleSelectSection = useCallback(
    debounce(async (targetId: number) => {
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      cancelTokenMap().fetchSectionItem?.cancel('Cancel fetchStream [containerSectionItem.actions.fetchSectionItem]');
      setSelectedSectionId(targetId);
      setSelectedSubsectionId(undefined);
      containerContentList.actions.resetContentList();
      history.push({ pathname: String(targetId) });
    }, 300),
    []
  );

  const handleSelectSubsection = useCallback(
    debounce(async (targetId: number) => {
      cancelTokenMap().fetchContentList?.cancel('Cancel fetchStream [containerContentList.actions.fetchContentList]');
      setSelectedSubsectionId(targetId);
      containerContentList.actions.resetContentList();
    }, 300),
    []
  );

  const handleLoadContent = useCallback(
    debounce(() => {
      pagingEmitter.invokeNext();
      containerContentList.actions.fetchContentList({
        id: !sectionItem?.hasSubsections ? selectedSectionId() : selectedSubsectionId(),
        more: isContentsMore,
        pager: paging.current(),
        cancelToken: cancelTokenMap().fetchContentList?.token
      });
    }, 300),
    [sectionItem?.hasSubsections, isContentsMore]
  );

  useUnmount(() => {
    if (handleSelectSubcategory?.cancel) handleSelectSubcategory.cancel();
    if (handleSelectSection?.cancel) handleSelectSection.cancel();
    if (handleLoadContent?.cancel) handleLoadContent.cancel();
    containerContentList.actions.resetContentList();
    containerSectionItem.actions.resetSectionItem();
  });

  const handleLike = useCallback((cid: ILikeItem['contentId'], liked: boolean) => {
    if (liked) props.containerLike.actions.createLikeItem(cid);
    else props.containerLike.actions.removeLikeItem(cid);
  }, []);

  const handleDecline = useCallback(() => {
    loginExternal(history.location.pathname);
  }, []);

  const onContentClick = contentItem => {
    contentItem?.free || includes([EContentType.STUDY, EContentType.OLYMPIAD, EContentType.BOOK], contentType)
      ? history.push(CONTENT_ITEM(contentItem?.type, contentItem?.id))
      : validateSubscribe(() => history.push(CONTENT_ITEM(contentItem?.type, contentItem?.id)));
  };

  const onItemRecommendClick = item => {
    if (includes([EContentType.STUDY, EContentType.BOOK], contentType)) handleSelectSubcategory(item?.id);
    else handleSelectSection(item?.id);
  };

  return (
    <>
      <ViewBox className={ styles.root }>
        <Wrapper color="light">
          <Typography type="heading" level={ 1 } tag="h1" style={ { marginTop: 54 } }>
            { category.name }
          </Typography>
          { !isSubcategoriesMenu && (
            <CategoryMenu data={ category?.subcategories } selectedKey={ String(selectedSubcategoryId()) } onSelect={ handleSelectSubcategory } />
          ) }

          { !isSectionLayoutNone && !isSectionListLoading && sections?.length !== 1 && (
            <CategoryMenu data={ sections } selectedKey={ String(selectedSectionId()) } onSelect={ handleSelectSection } />
          ) }
          { !isSectionLayoutNone &&
            containerSectionItem.status === ELoadStatus.ready &&
            !isSectionListLoading &&
            subsections?.length > 0 && (
              <CategoryMenu data={ subsections } selectedKey={ String(selectedSubsectionId()) } onSelect={ handleSelectSubsection } />
            ) }

          <div
            className={ classnames(contentType, {
              [styles.contentList]: isContentLayoutList,
              [styles.contentSheet]: isContentLayoutSheet
            }) }
          >
            { map(contents, contentItem => {
              const style = {};
              const preview = { src: null };
              if (isContentLayoutList) {
                assign(style, { width: 740 });
                assign(preview, { src: contentItem.desktopImageUrl || contentItem.imageUrl });
              } else if (isContentLayoutSheet) {
                assign(preview, { src: contentItem.desktopImageUrl || contentItem.imageUrl });
              }
              return (
                <div
                  key={ contentItem?.id }
                  className={ classnames(contentType, {
                    [styles.contentItem]: isContentLayoutList,
                    [styles.contentCell]: isContentLayoutSheet
                  }) }
                >
                  <ItemCard
                    key={ contentItem?.id }
                    style={ style }
                    contentType={ contentType }
                    isNew={ contentItem?.type !== EContentType.STUDY && contentItem?.new }
                    free={ contentItem?.free }
                    subscribeStatus={ props.containerSubscribe.subscriptions.find(sub => sub.id === subscribe?.id)?.status }
                    itemCardId={ containerContentList.itemLikes.find(el => el.contentId === contentItem?.id)?.count }
                    contentItemId={ contentItem?.id }
                    liked={ containerContentList.itemLikes.find(el => el.contentId === contentItem?.id)?.active }
                    handleLike={ handleLike }
                    handleDecline={ handleDecline }
                    authStatus={ containerUser.authenticated }
                    preview={ preview }
                    title={ contentItem.name }
                    data={ contentItem?.data }
                    descr={ contentItem?.description }
                    mediaSrc={ contentItem?.url }
                    withIndicator={ contentType !== EContentType.STUDY }
                    onPictureClick={ () => onContentClick(contentItem) }
                    onTitleClick={ () => onContentClick(contentItem) }
                    onMediaAudioPlayed={ e => eventBus.dispatch('audio.break', { source: e?.target }) }
                  />
                </div>
              );
            }) }

            { contentType && containerContentList.status.items !== ELoadStatus.ready && (
              <>
                { isContentLayoutList && (
                  <>
                    { [...Array(10).keys()].map(item => (
                      <div className={ classnames(styles.contentItem, contentType) } key={ item }>
                        <Skeleton variant="rect" width={ 740 } height={ contentType === EContentType.AUDIO ? 136 : 500 } animation="wave" />
                      </div>
                    )) }
                  </>
                ) }
              </>
            ) }

            { isContentsMore && (
              <Button
                className={ styles.moreButton }
                type="primary"
                disabled={ containerContentList.status.items !== ELoadStatus.ready }
                onClick={ handleLoadContent }
              >
                Показать еще
              </Button>
            ) }
          </div>

          { recommends?.length > 0 && (
            <div className={ styles.recommendPack }>
              <Typography type="heading" level={ 1 } tag="h2">
                Рекомендуем
              </Typography>
              <div className={ styles.recommendList }>
                { map(recommends, (s: ISectionItem) => (
                  <div key={ s?.id } className={ styles.recommendItem }>
                    <ItemCard
                      key={ s?.id }
                      format="compact"
                      contentType={ EContentType.IMAGE }
                      preview={ { src: s.desktopPreview || s.preview } }
                      title={ s.name }
                      onPictureClick={ () => onItemRecommendClick(s) }
                      onTitleClick={ () => onItemRecommendClick(s) }
                      withIndicator={ false }
                    />
                  </div>
                )) }
              </div>
            </div>
          ) }

          { contentType === EContentType.BOOK && (
            <div className={ styles.recommendPack }>
              <Typography type="heading" level={ 1 } tag="h2">
                Рекомендуем
              </Typography>
              <div className={ styles.recommendList }>
                { map(category.subcategories, (s: ISectionItem) => (
                  <div key={ s?.id } className={ styles.recommendItem }>
                    <ItemCard
                      key={ s?.id }
                      format="compact"
                      contentType={ EContentType.IMAGE }
                      preview={ { src: s.desktopPreview || s.preview } }
                      title={ s.name }
                      onPictureClick={ () => onItemRecommendClick(s) }
                      onTitleClick={ () => onItemRecommendClick(s) }
                      withIndicator={ false }
                    />
                  </div>
                )) }
              </div>
            </div>
          ) }
        </Wrapper>
      </ViewBox>
    </>
  );
};
DesktopContentListPage.displayName = 'DesktopContentListPage';

export default flow([
  ContainerUser,
  ContainerSubscribe,
  CategoryContainer,
  SectionListContainer,
  ContainerSectionItem,
  ContainerContentList,
  ModalsContainer,
  ContainerLike
])(DesktopContentListPage);
