import React, { FC, MouseEventHandler, memo } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import classnames from 'classnames';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';

import { CompatElement, CompatMouseEvent } from 'app/components/follow-link/follow-link.model';
import { FollowLink } from 'app/components/follow-link/follow-link.index';
import useEllipsisStyles from 'app/hooks/useEllipsisStyles';

export const useUserChipStyles = makeStyles(
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      color: configTheme.colors.text.light.default
    },

    link: {
      '&, &:hover, &:focus, &:active': {
        textDecoration: 'none',
        outline: 'none',
        cursor: 'pointer'
      }
    },

    icon: {
      fontSize: 20,
      color: 'currentColor'
    },

    label: {
      display: 'inline-block',
      boxSizing: 'border-box',
      marginLeft: configTheme.indents['indentX1'].margin * 0.5,
      fontSize: 16,
      lineHeight: '22px',
      color: 'currentColor'
    },

    btn: {
      display: 'inline-block',
      boxSizing: 'border-box',
      padding: `2px ${configTheme.indents['indentX1'].margin}px`,
      border: `2px solid ${configTheme.colors.border.primary.neutral}`,
      borderRadius: 5,
      fontSize: 13,
      lineHeight: '18px',
      color: 'currentColor'
    }
  }),
  { name: 'UserChip' }
);

export interface IUserChipProps {
  authenticated?: boolean;
  label?: string;
  link?: string;
  onClick?: (e: CompatMouseEvent<CompatElement>, url?: string) => void;
}

const UserChip: FC<IUserChipProps> = memo<IUserChipProps>(props => {
  const { authenticated, label, link, onClick } = props;
  const ellipsisStyles = useEllipsisStyles({});
  const styles = useUserChipStyles();

  const render = node =>
    link ? (
      <FollowLink className={ classnames(styles.root, styles.link) } to={ link } onClick={ onClick }>
        { node }
      </FollowLink>
    ) : (
      <span className={ classnames(styles.root) } onClick={ (onClick as unknown) as MouseEventHandler<HTMLSpanElement> }>
        { node }
      </span>
    );

  return render(
    <>
      { authenticated ? (
        <>
          <IconText className={ styles.icon } icon="user-drop" iconOnly color="white" />
          { label && <span className={ classnames(styles.label, ellipsisStyles.line) }>{ label }</span> }
        </>
      ) : (
        <span className={ styles.btn }>Войти в личный кабинет</span>
      ) }
    </>
  );
});
UserChip.displayName = 'UserChip';

export default UserChip;
