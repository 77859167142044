//#region imports
import React, { FC, memo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { EAgeType, EConfirmationName, EConfirmation } from 'app/models/shared.model';

import DietBanner from 'app/pages/shared/diet/banner/diet.banner.index';
import Wrapper from 'app/components/wrapper/wrapper.index';
import Banner from 'app/components/desktop/banner/banner.index';
import Categories from './components/categories/categories.index';
//#endregion

const cookies = new Cookies();

// tslint:disable-next-line: no-empty-interface
export interface IDesktopMainPageProps {}

const DesktopMainPage: FC<IDesktopMainPageProps> = memo(props => {
  const history = useHistory();

  useEffect(() => {
    cookies.remove(`prev-route-content-list`);
    cookies.remove(`prev-route-content-item`);
  }, [history?.location?.pathname]);

  const sAgeType = cookies.get(EConfirmation[EConfirmationName.ageType]);
  const isAgeAdult = sAgeType === EAgeType.ADULT;
  const [confirmDialog, setConfirmDialog] = useState<{ type?: string; url?: string }>({ type: null, url: '' });

  const onConfirmAdult = () => {
    setConfirmDialog({});
    cookies.set(EConfirmation[EConfirmationName.ageType], EAgeType.ADULT, { path: '/' });
    history.push(confirmDialog.url);
  };

  return (
    <>
      <Banner shuffled masked/>
      <Wrapper>
        <DietBanner />
      </Wrapper>
      <Categories uAgeType={ sAgeType } onConfirm={ e => setConfirmDialog(e) } />
      { !isAgeAdult ? (
        <>
          { confirmDialog.type === EAgeType.ADULT && (
            <>
              <button onClick={ () => setConfirmDialog({ type: null, url: '' }) }>💅 close</button>
              <button onClick={ () => onConfirmAdult() }>💅 confirm</button>
            </>
          ) }
        </>
      ) : null }
    </>
  );
});
DesktopMainPage.displayName = 'DesktopMainPage';

export default DesktopMainPage;
