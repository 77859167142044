import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { isMobile } from 'mobile-device-detect';

import flow from 'lodash/flow';
import get from 'lodash/get';

import { loginExternal, logoutExternal } from 'app/utils/auth.external';

import Grid from '@material-ui/core/Grid';
import { ContentHeader } from 'app/components/header/content.header';

import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';

import { stylesApp } from 'app/app';
import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ContainerPrize, IContainerPrizeProps } from 'app/containers/container.prize';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ILoggingContainer, LoggingContainer } from 'app/containers/logging.container';
import { ELoadStatus, IRouteProps, EDialogConfirmType } from 'app/models/shared.model';
import Switch from '@material-ui/core/Switch';

import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import { ModalNotification } from 'app/components/modal/modal.notification';
import { ModalConfirm } from 'app/components/modal/modal.confirm';
import CircularLoader from 'app/components/loader/loader.circular';

import { phoneMaskDry } from 'a1s-tele2-react-ui/src/utils/utils.mask';
import { stylesProfile, stylesSwitch } from 'app/pages/shared/profile/profile.style';

export interface IProfileProps extends IRouteProps {
  containerUser: IAuthContainerProps;
  containerPrize: IContainerPrizeProps;
  containerSubscribe: IContainerSubscribeProps;
  loggingContainer: ILoggingContainer;
}

const Profile: FC<IProfileProps> = props => {
  const { containerUser, containerPrize, containerSubscribe, computedMatch, ...rest } = props;
  const { actions: { logLogout } } = props.loggingContainer;

  const [deactivateSubscription, setDeactivateSubscription] = useState<ISubscription>();
  const [confirmDialog, setConfirmDialog] = useState<EDialogConfirmType>();
  const [isModalMobileMyDieteCalcProgram, setIsModalMobileMyDieteCalcProgram] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  const classes = stylesProfile({ mobile: isMobile });
  const classesSwitch = stylesSwitch();
  const classesApp = stylesApp();

  const token = get(containerUser, 'token');
  const statusToken = get(containerUser, 'request.status.token');

  const onActivate = (s: ISubscription) => {
    containerSubscribe.actions.setSubscription(true, s.id);
  };
  const onDeactivate = (s: ISubscription) => {
    setDeactivateSubscription(s);
    setConfirmDialog(EDialogConfirmType.UNSUBSCRIBE);
  };

  const onConfirmUnsubscribe = () => {
    deactivateSubscription && containerSubscribe.actions.setSubscription(false, deactivateSubscription.id);
    setDeactivateSubscription(null);
    setConfirmDialog(null);
  };

  const onConfirmLogout = () => {
    setConfirmDialog(null);
    const response = logLogout();
    response.then(res => {
      if (res.status === 200) {
        logoutExternal();
        history.push('/');
      }
    });
  };

  useEffect(() => {
    if (statusToken === ELoadStatus.ready && !token) {
      loginExternal(history.location.pathname);
    } else if (statusToken === ELoadStatus.ready && token) {
      containerPrize.actions.fetchPersonal();
    }

    return () => {
      containerPrize.actions.resetPersonal();
    };
  }, [token, statusToken]);

  return statusToken === ELoadStatus.ready && !token ? (
    <div className={ classesApp.suspendLoader }>
      <CircularLoader />
    </div>
  ) : (
    <>
      <ContentHeader isClose closeHandler={ () => { history.length > 2 ? history.goBack() : history.push('/'); } }>
        { 'Профиль' }
      </ContentHeader>

      <section className={ classes.primarySection }>
        <Typography tag="div" type="heading" level={ 2 } mobile className={ classes.header }>
          { t('me.myData') }
        </Typography>
        <div className={ classes.data }>
          <span>{ t('me.number') }: </span>
          <b>{ containerUser.msisdn && phoneMaskDry(containerUser.msisdn) }</b>
        </div>
      </section>

      <Parameters
        classes={ {
          wrapper: classes.parametersWrapper,
          table: classes.parametersTable
        } }
        isNoIcon
        isNoCard
        isNoParametersInfo
      />

      <section className={ classes.secondarySection }>
        <Typography tag="div" type="heading" level={ 2 } mobile className={ classes.subHeader }>
          { t('me.subscriptions') }
        </Typography>
        { containerSubscribe && containerSubscribe.subscriptions.length > 0
          ? containerSubscribe.subscriptions.map(s => {
            const isDisabled = containerSubscribe?.disabledSubscriptions?.includes(s.id);
            return (
              <div className={ classes.item } key={ s.id }>
                <Typography tag="div" type="text">
                  { s.name }
                </Typography>
                <Grid container justifyContent="space-between" className={ s.name && classes.itemDesc }>
                  <Typography tag="div" type="text" size="small">
                    { s.price }
                  </Typography>
                  { !isDisabled && (
                    <div className={ s.name && classes.switchWrap }>
                      <Switch
                        checked={ s.status === ESubscriptionStatus.ACTIVE || s.status === ESubscriptionStatus.NO_MONEY }
                        onChange={ () => (s.status === ESubscriptionStatus.NOT_ACTIVE ? onActivate(s) : onDeactivate(s)) }
                        focusVisibleClassName={ classesSwitch.focusVisible }
                        disableRipple
                        classes={ {
                          root: classesSwitch.root,
                          switchBase: classesSwitch.switchBase,
                          thumb: classesSwitch.thumb,
                          track: classesSwitch.track,
                          checked: classesSwitch.checked,
                          disabled: classesSwitch.disabled
                        } }
                        { ...rest }
                      />
                    </div>
                  ) }
                </Grid>
              </div>
            );
            })
          : null }
      </section>

      <ModalNotification
        open={ containerSubscribe.status.change === ELoadStatus.error }
        onClose={ () => containerSubscribe.actions.resetProp('change') }
        type={ containerSubscribe.error.change }
        mobile
      />

      <ModalConfirm
        name={ deactivateSubscription?.name }
        confirmType={ EDialogConfirmType.UNSUBSCRIBE }
        onConfirm={ onConfirmUnsubscribe }
        onClose={ () => {
          setConfirmDialog(null);
          setDeactivateSubscription(null);
        } }
        open={ confirmDialog === EDialogConfirmType.UNSUBSCRIBE }
        isArchive={ deactivateSubscription?.archive }
        mobile
      />

      <ModalConfirm
        confirmType={ EDialogConfirmType.LOGOUT }
        onConfirm={ onConfirmLogout }
        onClose={ () => {
          setConfirmDialog(null);
          setConfirmDialog(null);
        } }
        open={ confirmDialog === EDialogConfirmType.LOGOUT }
        mobile
      />

      <div className={ classes.titleLogout } onClick={ () => setConfirmDialog(EDialogConfirmType.LOGOUT) }>
        Выйти
      </div>
    </>
  );
};

export default flow([LoggingContainer, ContainerUser, ContainerPrize, ContainerSubscribe])(Profile);
