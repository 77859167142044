import React, { Fragment, forwardRef, useRef } from 'react';
import { useSize } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useForkRef, makeStyles, createStyles, Grid } from '@material-ui/core';
import classnames from 'classnames';

import { configTheme } from 'a1s-tele2-react-ui/src/config/config.theme';
import Wrapper from 'app/components/wrapper/wrapper.index';

export const useStylesFooter = makeStyles(
  createStyles({
    wrapper: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0
    },

    root: {
      boxSizing: 'border-box',
      padding: '18px 0 26px',
      fontSize: 14,
      lineHeight: '22px',
      color: '#8A8F97',
      '& a': { color: 'inherit' }
    },

    column: { marginRight: configTheme.indents['indentX3'].margin },

    company: {},

    desc: {},

    terms: {
      '& + &::before': { content: "'|'", margin: '0 16px' }
    }
  }),
  { name: 'Footer' }
);

export const Footer = forwardRef<HTMLElement>((props, ref) => {
  const { t } = useTranslation();
  const styles = useStylesFooter();

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  const [footer] = useSize(({ width, height }) => {
    if (width === Infinity) {
      return (
        <Fragment>
          <Wrapper className={ styles.wrapper } color="dark">
            <footer />
          </Wrapper>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Wrapper className={ styles.wrapper } color="dark">
          <footer key={ height?.toString(36) } ref={ handleRef } className={ styles.root }>
            <Grid container wrap="nowrap" alignItems="flex-start" justifyContent="flex-start" spacing={ 0 }>
              <Grid item xs={ 6 }>
                <div className={ styles.column }>
                  <div className={ styles.company }>{ t('footer.company') }</div>
                  <div>
                    <a href="https://msk.tele2.ru/help" target="_blank" className={ styles.terms }>
                      Обратная связь
                    </a>
                    <a href="/oferta.pdf" target="_blank" className={ styles.terms }>
                      Пользовательское соглашение
                    </a>
                  </div>
                </div>
              </Grid>

              <Grid item xs={ 5 }>
                <div className={ classnames(styles.column, styles.desc) }>
                  Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookie в соответствии с&nbsp;
                  <a href={ 'https://tele2.ru/api/media/content?contentId=m3100004' } target="_blank">
                    <span>Политикой Компаний Группы Tele2</span>
                  </a>
                  &nbsp; в области использования файлов Cookie.
                </div>
              </Grid>
            </Grid>
          </footer>
        </Wrapper>
      </Fragment>
    );
  });

  return <>{ footer }</>;
});
