//#region imports
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Grid } from '@material-ui/core';

import find from 'lodash/find';
import isNil from 'lodash/isNil';

import { stylesApp } from 'app/app';
import { useValidateSubscribeCallback } from 'app/utils/util.subscribe';
import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';

import { IContentItemProps, OContentItemModalType } from 'app/models/model.content';
import { ESubscriptionStatus } from 'app/models/model.subscription';

import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';
import { Typography } from 'a1s-tele2-react-ui/src/components/shared/typography/typography.index';
import { Postcard } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.index';
import { IconText } from 'a1s-tele2-react-ui/src/components/shared/icontext/icontext.index';
import { Likes } from 'a1s-tele2-react-ui/src/components/shared/likes/likes.index';
import { PostcardBadge } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.badge';
import { EPostcardClassKey } from 'a1s-tele2-react-ui/src/components/shared/postcard/postcard.models';

import { useDesktopContentItemPageStyles } from '../page.content.item.styles';
import Recommends from './content.item.article.recommends';

import { ILikeItem } from 'app/entities/like/like.model';
import { loginExternal } from 'app/utils/auth.external';
//#endregion

export interface IContentArticleProps extends IContentItemProps {
  onArticleLinkClick: (props: any) => void;
}

const ContentItemArticle: FC<IContentArticleProps> = memo<IContentArticleProps>(props => {
  const {
    containerUser,
    containerContentList,
    containerLike,
    content: contentItem,
    contentType,
    contentId,
    sectionId,
    subsectionId,
    subscribe,
    history,
    loading,
    ready,
    onArticleLinkClick
  } = props;
  const isUserAuthenticated = containerUser?.token && containerUser?.authenticated;

  const { t } = useTranslation();
  const classesApp = stylesApp();
  const ellipsisStyles = useEllipsisStyles({});
  const styles = useDesktopContentItemPageStyles();

  const accessible = containerUser.authenticated;
  const validateSubscribe = useValidateSubscribeCallback({ user: containerUser, subscribe });

  useEffect(() => {
    if (ready) {
      if (!contentItem?.free && subscribe.status !== ESubscriptionStatus.ACTIVE) {
        history.replace('/');
      }
    }
  }, [ready]);

  // useEffect(() => {
  //   if (contentId && subscribe && ready) {
  //     if (!contentItem.free) {
  //       validateSubscribe();
  //     }
  //   }
  // }, [contentId, subscribe, ready]);

  useEffect(() => {
    if (contentId) {
      containerLike.actions.fetchLikeList([contentId]);
    }
    return () => {
      containerLike.actions.resetLikeItems();
    };
  }, [contentId]);

  useEffect(() => {
    if (sectionId && ready) {
      containerContentList.actions.fetchContentList({ id: sectionId, pager: { limit: 100, offset: 0 }, cancellable: true });
    }
    return () => {
      containerContentList.actions.resetContentList();
    };
  }, [sectionId, ready]);

  // useEffect(() => {
  //   const handleSubscribeModalCancel = () => history.goBack();
  //   eventBus.on(OEventName.ModalCancelSubscribe, handleSubscribeModalCancel);
  //   return () => {
  //     eventBus.off(OEventName.ModalCancelSubscribe, handleSubscribeModalCancel);
  //   };
  // }, []);

  const heart = useMemo(() => {
    return find(containerLike.list, h => h.contentId === contentId);
  }, [contentId, containerLike.list]);

  const handleLike = useCallback((cid: ILikeItem['contentId'], liked: boolean) => {
    if (liked) containerLike.actions.createLikeItem(cid);
    else containerLike.actions.removeLikeItem(cid);
  }, []);

  const handleDecline = useCallback(() => {
    loginExternal(history.location.pathname);
  }, []);

  const loader = (
    <div className={ classesApp.suspendLoader }>
      <CircularLoader />
    </div>
  );
  const hasLikes = !isNil(heart?.count ?? 0);

  const indicator = useMemo(() => {
    return !contentItem?.free
      ? subscribe?.status === ESubscriptionStatus.ACTIVE
        ? t('content.subscribed')
        : t('content.bySubscription')
      : undefined;
  }, [contentItem?.free, subscribe?.status]);

  const indicatorIcon = useMemo(() => {
    return !contentItem?.free ? (subscribe?.status === ESubscriptionStatus.ACTIVE ? 'success-large' : 'balance') : undefined;
  }, [contentItem?.free, subscribe?.status]);

  const content = (
    <>
      { (contentItem?.free || subscribe.status === ESubscriptionStatus.ACTIVE) &&
        <>
          <Typography className={ styles.articleTitle } type="heading" level={ 1 }>{ contentItem?.name }</Typography>
          <img className={ styles.previewImg } src={ contentItem.imageUrl } alt={ contentItem.name }/>

          <div className={ styles.article } dangerouslySetInnerHTML={ { __html: contentItem?.text } } onClick={ onArticleLinkClick }/>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between" spacing={ 6 }>
            { indicator && (
              <Grid item xs="auto">
                <IconText color="primary" icon={ indicatorIcon } text={ indicator } />
              </Grid>
            ) }
            { !indicator && contentItem?.free && (
              <Grid item xs="auto">
                <IconText color="primary" text={ t('content.free') }/>
              </Grid>
            ) }
            { hasLikes && (
              <Grid item>
                <Likes
                  // classes={ { root: styles.likes } }
                  count={ heart?.count ?? 0 }
                  liked={ heart?.active ?? false }
                  declined={ !accessible }
                  onClick={ (e, b) => handleLike(contentId, b) }
                  onDecline={ handleDecline }
                />
              </Grid>
            ) }
          </Grid>

          <Recommends contentId={ contentId } subscribe={ subscribe }/>
        </>
      }
    </>
  );

  return (
    <ViewBox className={ styles.root }>
      <Wrapper>{ loading || !ready ? loader : content }</Wrapper>
    </ViewBox>
  );
});
ContentItemArticle.displayName = 'ContentItemArticle';

export default ContentItemArticle;
