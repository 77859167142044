// TODO: вынести в https://git.a1s/front/react-hooks-ts
//#region imports
import { makeStyles, createStyles } from '@material-ui/core';
//#endregion

export const EEllipsisClassKey = {
  line: 'line',
  multiline: 'multiline'
} as const;

// const assertions (TS 3.4+)
export type EllipsisClasses = typeof EEllipsisClassKey[keyof typeof EEllipsisClassKey];

export interface IEllipsisStylesProps {
  classes?: Partial<Record<EllipsisClasses, string>>;
  lineClamp?: number;
}

const useEllipsisStyles = makeStyles(
  createStyles<EllipsisClasses, IEllipsisStylesProps>({
    [EEllipsisClassKey.line]: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },

    [EEllipsisClassKey.multiline]: {
      '--lineClamp': 2,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': ({ lineClamp }) => lineClamp ?? 'var(--lineClamp)',
      overflow: 'hidden',
      whiteSpace: 'normal',
      textOverflow: 'ellipsis'
    }
  }),
  { name: 'Ellipsis' }
);

export default useEllipsisStyles;
