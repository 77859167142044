import React, { FC } from 'react';

import { ContainerUser, IAuthContainerProps } from 'app/containers/container.user';
import { ErrorServerContainer, IServerErrorContainer } from 'app/containers/container.server.error';

import { ComponentError } from 'app/components/error/component.error';

const Error: FC<{ containerUser?: IAuthContainerProps, errorServerContainer: IServerErrorContainer }> = props => {
  const { errorCode, actions } = props.errorServerContainer;
  return (
    <ComponentError code={ errorCode } callback={ actions.resetError }/>
  );
};

export default ContainerUser(ErrorServerContainer(Error));
