import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { USER_ACTIONS } from 'app/entities/subscribe/subscribe.actions';

import { ELoadStatus } from 'app/models/shared.model';
import { ESubscribeStatus, ISubscription } from 'app/models/model.subscription';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import has from 'lodash/fp/has';

const initialState = {
  disabledSubscriptions: [] as number[],
  subscriptions: [] as ISubscription[],
  status: {
    subscriptions: ELoadStatus.not_ready,
    change: ELoadStatus.not_ready
  },
  error: {
    subscriptions: null as Error,
    change: null as ESubscribeStatus
  }
};

export type ISubscribeState = Readonly<typeof initialState>;

export default (state = initialState, action): ISubscribeState => {
  const { type, payload } = action;
  const { error, status } = state;
  switch (type) {
    case REQUEST(USER_ACTIONS.SUBSCRIPTION_FETCH):
      return flow([
        set('status.change', ELoadStatus.not_ready),
        set('status.subscriptions', ELoadStatus.loading),
        set('error.subscriptions', initialState.error.subscriptions)
      ])(state);
    case SUCCESS(USER_ACTIONS.SUBSCRIPTION_FETCH):
      return flow([
        set('status.subscriptions', ELoadStatus.ready),
        set('subscriptions', payload.data.subscribes),
        set('disabledSubscriptions', payload.data.disabledSubscribes)
      ])(state);
    case FAILURE(USER_ACTIONS.SUBSCRIPTION_FETCH):
      return flow([set('status.subscriptions', ELoadStatus.error), set('error.subscriptions', new Error('failed load subscriptions'))])(
        state
      );

    case REQUEST(USER_ACTIONS.SUBSCRIPTION_ACTIVATE):
    case REQUEST(USER_ACTIONS.SUBSCRIPTION_DEACTIVATE):
      return flow([set('status.change', ELoadStatus.loading), set('error.change', initialState.error.change)])(state);
    case SUCCESS(USER_ACTIONS.SUBSCRIPTION_ACTIVATE):
    case SUCCESS(USER_ACTIONS.SUBSCRIPTION_DEACTIVATE):
      switch (payload.data.status) {
        case ESubscribeStatus.FAIL:
          return flow([
            set('status.change', ELoadStatus.error),
            set('error.change', ESubscribeStatus.FAIL) // failed to set subscription
          ])(state);
        case ESubscribeStatus.NO_MONEY:
          return flow([
            set('status.change', ELoadStatus.error),
            set('error.change', ESubscribeStatus.NO_MONEY) // not enough money to set subscription
          ])(state);
        default:
          return flow([set('status.change', ELoadStatus.ready)])(state);
      }
    case FAILURE(USER_ACTIONS.SUBSCRIPTION_ACTIVATE):
    case FAILURE(USER_ACTIONS.SUBSCRIPTION_DEACTIVATE):
      return flow([set('status.change', ELoadStatus.error), set('error.change', new Error('failed to set subscription'))])(state);

    case USER_ACTIONS.RESET_PROP:
      return {
        ...state,
        ...(has(payload, initialState) ? { [payload]: initialState[payload] } : {}),
        status: {
          ...status,
          [payload]: initialState.status[payload]
        },
        error: {
          ...error,
          [payload]: initialState.error[payload]
        }
      };

    default:
      return state;
  }
};
