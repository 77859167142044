import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { MENU_ACTIONS } from 'app/entities/menu/menu.actions';

import { IMenu } from 'app/models/model.menu';
import { ELoadStatus } from 'app/models/shared.model';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

const initialState = {
  items: [] as IMenu[],
  status: {
    items: ELoadStatus.not_ready
  },
  error: {
    items: null as Error
  }
};

export type IMenuState = Readonly<typeof initialState>;

export default (state = initialState, action): IMenuState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(MENU_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.loading),
        set('errors.items', initialState.error.items)
      ])(state);
    case SUCCESS(MENU_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.ready),
        set('items', payload.data.menuItems)
      ])(state);
    case FAILURE(MENU_ACTIONS.FETCH_LIST):
      return flow([
        set('status.items', ELoadStatus.error),
        set('error.items', new Error('menu list fetch failed'))
      ])(state);
    default:
      return state;
  }
};
